import React, {useRef} from 'react';
import styled from 'styled-components';
import {
	getColorScheme,
	getCssSizeValue,
	getFontSize,
	getHtmlFromRedactorField,
	getPaddingsClassFromData,
	removeTags
} from '@repo/utils';
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Reveal from "../../animations/Reveal";
import {useLinesReveal} from "../../animations/LinesReveal";

const StyledQuoteWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StyledQuoteText = styled.div`
	//color: #152731;
	color: var(--secondary-color);
	font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
	max-width: 100%;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		max-width: 65%;
	}
	
	font-size: ${getFontSize(28, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(32, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)};
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(32, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(42, BREAKPOINTS.TABLET)};
		margin-bottom: ${getCssSizeValue(82, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
		margin-bottom: ${getCssSizeValue(105, BREAKPOINTS.DESKTOP)};
	}
	
`;

const StyledAuthorText = styled.div`
	//color: #152731;
	color: var(--secondary-color);
	font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
	font-style: italic;
	text-transform: uppercase;
	font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
	}
`;

const QuoteText = ({ data, textModuleId }) => {
	const quote = data?.quote || '';
	const author = data?.author || '';
	// const redactor = getHtmlFromRedactorField(quote);
	const redactor = getHtmlFromRedactorField(removeTags(quote, ['p', 'span'])); //tag removing so animation works fine
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	const DescRef = useRef()

	// useLinesReveal({
	// 	el:DescRef,
	// 	onInView: true,
	// 	disabled: !quote
	// })
	return (
		<StyledQuoteWrapper id={textModuleId} data-color-scheme={colorScheme} className={`${paddings} px-main`}>
			<Reveal onInView disabled={!quote}>
				<StyledQuoteText ref={DescRef}  dangerouslySetInnerHTML={redactor} />
			</Reveal>
			{author && (
				<Reveal onInView>
					<StyledAuthorText  dangerouslySetInnerHTML={getHtmlFromRedactorField(author)} />
				</Reveal>
			)}
		</StyledQuoteWrapper>
	);
};

export default QuoteText;
