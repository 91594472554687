import React, { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import NextLink from 'next/link';
import {getCssSizeValue, getFontSize, getSlug} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";


const styles = css`
	display: flex;
	align-items: center;
	column-gap: 14px;
	font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	transition: all var(--color-transition-duration) var(--ease);
	font-family: var(--font);
	text-decoration: none;
	position: relative;
	cursor: pointer;
	width: fit-content;
	height: fit-content;
	white-space: nowrap;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	}
	span{
		display: block;
		position: relative;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		border: solid 1px var(--color, white);
		transition: all var(--color-transition-duration) var(--ease);
	}
	
	&:not(&.unstyled){
		font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
		padding:
			${getCssSizeValue(12, BREAKPOINTS.MOBILE)}
			${getCssSizeValue(20, BREAKPOINTS.MOBILE)}
			${getCssSizeValue(12, BREAKPOINTS.MOBILE)}
			${getCssSizeValue(22, BREAKPOINTS.MOBILE)}
		;
		line-height: 13px;
		border-radius: 100px;
		outline: solid 1px transparent;
		background-color: var(--supp-color, transparent);
		color: var(--color, white);
		border: solid 1px var(--color, white);
		@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			padding:
				${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(20, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(22, BREAKPOINTS.MOBILE)}
		;
		}
			//padding: 14px 20px 14px 22px;
		
		&.primary{
		--color: ${({ theme }) => theme?.colors?.white || 'white'};
		--supp-color: transparent;
		
			&:hover, &.hover{
				--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
				--supp-color: ${({ theme }) => theme?.colors?.white || 'white'};
				border: solid 1px transparent;
			}
		}
		&.secondary{
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			--supp-color: ${({ theme }) => theme?.colors?.white || 'white'};
			border: solid 1px transparent;
			&:hover, &.hover{
				--supp-color: transparent;
				--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
				border: solid 1px var(--color);
			}
		}
		&.tertiary{
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			--supp-color: transparent;
	
			&:hover, &.hover{
				--color: ${({ theme }) => theme?.colors?.white || 'white'};
				--supp-color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			}
		}
		
		&.contact-submit {
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black'};
			--supp-color: ${({ theme }) => theme?.colors?.white || 'white'};
			border: solid 1px transparent;
			&:hover{
				--color: ${({ theme }) => theme?.colors?.white || 'white'};
				--supp-color: transparent;
				border: solid 1px var(--color);
			}
		}
		
		&.lets-talk{
			border: unset !important;
			@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			padding:
				${getCssSizeValue(15, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(21, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(15, BREAKPOINTS.MOBILE)}
				${getCssSizeValue(23, BREAKPOINTS.MOBILE)}
		;
				//padding: 15px 21px 15px 23px;
		}
			--color: ${({ theme }) => theme?.colors?.twilightBlue || 'black' } !important;
			--supp-color: ${({ theme }) => theme?.colors?.white || 'white'} !important;

			&:hover{
				--color: ${({ theme }) => theme?.colors?.white || 'white' } !important;
				--supp-color: ${({ theme }) => theme?.colors?.twilightBlue || 'black' } !important;
				&.digital {
					--supp-color: ${({ theme }) => theme?.colors?.red || 'red' } !important;
				}
				&.design {
					--supp-color: ${({ theme }) => theme?.colors?.designBlue || 'blue' } !important;
				}
				&.group {
					--supp-color: ${({ theme }) => theme?.colors?.groupGray || 'gray' } !important;
				}
			}
			
		}

		&.no-border {
			border: none;
			background-color: transparent;
		}
	}

	&.unstyled{
		border: none;
		background-color: transparent;
		span{
			display: none;
		}
	}

	&[disabled=disabled], &:disabled {
		opacity: 0.6;
	}
`;

const BTN = styled.button`
	${styles}
`;
const Anchor = styled.a`
	${styles}
`;
const Link = styled(NextLink)`
	${styles}
`;

function getTag(tag = '') {
	switch (tag) {
		case 'a':
			return Anchor;
		case 'button':
			return BTN;
		case 'link':
			return Link;
		default:
			return BTN;
	}
}

export function getButtonBlockData(data){
	const text = data?.button?.text || ''
	const link = getSlug(data?.button?.link?.slug || '')
	const typename = data?.button?.link?.__typename || ''
	return {
		text,
		link,
		typename,
		exists: Boolean(link) || Boolean(text)
	}
}

const Button = forwardRef(function Button(
	{
		as = 'button',
		variant='primary',
		className='',
		// cursor = CURSOR_TYPES.BUTTON,
		cursor = CURSOR_TYPES.BUTTON_WITHOUT_BORDER,
		children, ...props }, ref) {
	const Tag = useMemo(() => getTag(as), [as]);
	const additionalProps = {}
;
	if(variant !=='unstyled' && cursor !== null){
		if(className.includes('lets-talk')) {
			console.log("let's talk")
		}
		additionalProps['data-cursor'] = cursor;
	}

	return (
		<Tag ref={ref} scroll={false} className={`button ${className} ${variant}`} {...additionalProps} {...props}>
			{children}
			<span className="dot"/>
		</Tag>
	);
});
export default Button;
