import React, {createContext, useCallback, useContext, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import { CMS_ELEMENT_TYPENAME, CONTENT_TYPES} from '@repo/utils/constants';
import Description from './Description';
import Options from './Options';
import Content from "./Content";
import {useIsomorphicLayoutEffect, useScrollContext} from "../../../index";
import { ScrollTrigger, useGSAP } from "@repo/utils/gsap";
import CheckOthers from "../CheckOthers";
import {LOGO_COLORS} from "../../../styles/themeSettings";
import ProjectsTable from "./Table";

export const INIT_ACTIVE_FILTER = 'all';


export const ProjectsContext = createContext({
	isList: false,
	filter: INIT_ACTIVE_FILTER,
	inViewport: false
});



const Wrapper = styled.div`
	//animation: fade-in 1s var(--ease);
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	//z-index: 99;
`;


const Projects =
	({
		 data = [],
		 desc = {},
		 filters = [],
		 checkOthers={},
		 webGL=false,
		 projectsTable = [],
		 type=''
	}) => {
	const ContentRef = useRef(null)
	const [inViewport, setInViewport] = useState(false)
	const [isList, setIsList] = useState(false);

	//initial state for filters
	const [initialFilters] = useState(() =>
		filters.reduce(
			(acc, curr) => ({
				...acc,
				[curr.projectCategoryId]: curr.projectCategoryId === INIT_ACTIVE_FILTER,
			}),
			{},
		),
	);
	const [filtration, setFiltration] = useState(initialFilters);
	const activeFilter = useMemo(() => Object.keys(filtration).find((filter) => Boolean(filtration[filter])), [filtration]);
		// console.log(activeFilter);
		const filteredData = useMemo(() => {
		if (!Array.isArray(data)) return [];
		return data.map((element) => {
			if (filtration.all) return element;
			//filtration through grid component
			if(element[CMS_ELEMENT_TYPENAME] === CONTENT_TYPES.PROJECTS_GRID){
				const grid = element.grid.filter((el) =>
					el.projectCategories.some((filter) => filtration[filter?.projectCategoryId])
				)
				return { ...element, grid }
			}
			//always return horizontal header
			if (element[CMS_ELEMENT_TYPENAME] === CONTENT_TYPES.HORIZONTAL_HEADER) return element;
			if (element[CMS_ELEMENT_TYPENAME] === CONTENT_TYPES.CHECK_OTHERS) return element;

			//return projects from the selected category
			return element?.projectCategories?.some((filter) => filtration[filter?.projectCategoryId]) ? element : null
		}).filter((el) =>  el !== null);
	}, [data, filtration]);

	const scroll = useScrollContext()
	const onUpdate = useCallback(() => {
		scroll.refresh()
	},[])


	useGSAP(() => {
		ScrollTrigger.create({
				id: 'options',
				trigger: ContentRef.current,
				start: 'top 70%',
				end: 'bottom bottom',
				// markers: true,
				onUpdate: ({ isActive }) => {
						setInViewport(isActive)
				}
		})
		onUpdate()
	},{
		dependencies: [isList, activeFilter]
	})

	return (
		<ProjectsContext.Provider value={{ isList, filter: activeFilter, inViewport }}>
			<Wrapper data-logo-color={LOGO_COLORS.DARK}>
				<Options target={ContentRef} data={filters} setIsList={setIsList} setFiltration={setFiltration} />
				<Description data={desc} />
				<Content
					key={activeFilter}
					ref={ContentRef}
					data={filteredData}
					webGL={webGL}
					type={type}
				/>
				<ProjectsTable data={projectsTable} />
				<CheckOthers data={checkOthers}/>
			</Wrapper>
		</ProjectsContext.Provider>
	);
};

export function useProjectsContext(selector) {
	const context = useContext(ProjectsContext);
	if (typeof context === 'undefined') {
		throw new Error('useProjectsContext must be used within a ProjectsContextProvider');
	}
	if(typeof selector === 'function'){
		return selector(context)
	}
	return context;
}
export default Projects;
