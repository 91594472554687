import {
    getCssSizeValue, getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import styled, {css} from "styled-components";
import {BREAKPOINTS, LOGO_COLORS, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import Link from "../common/Link";
import {Button, Icon, useIsDesktop, useIsMobile} from "../../../index";
import {useRef, useState} from "react";
import Image from "../common/Image";
import {ICONS} from "@repo/utils/constants";
import Slogan from "../BlobSlogan/Slogan";
import {getButtonBlockData} from "../common/Button";
import {useRouter} from "next/router";

const StyledWrapper = styled.div`
    position: relative;
    color: var(--secondary-color);
    .reveal-parent {
        overflow: hidden;
        .text {
            width: calc(100vw - 2 * var(--paddingX));

            @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
                width: calc((100vw - 2 * var(--paddingX)) * 0.60);
            }

            @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
                width: calc(100vw - 2 * var(--paddingX));
            }
        }
    }
`

const CardsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${getCssSizeValue(160, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: ${getCssSizeValue(47, BREAKPOINTS.DESKTOP)};
    }
`;

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        flex: 1;
        &:nth-child(1) {
            transform: translateY(${getCssSizeValue(-50, BREAKPOINTS.DESKTOP)});
        }
        &:nth-child(2) {
            transform: translateY(${getCssSizeValue(-150, BREAKPOINTS.DESKTOP)});
            gap: ${getCssSizeValue(30, BREAKPOINTS.DESKTOP)};
        }
        &:nth-child(3) {
            transform: translateY(${getCssSizeValue(100, BREAKPOINTS.DESKTOP)});
        }
    }
`

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    align-items: center;
    text-align: center;
    cursor: pointer;
    //transition: all var(--transition-duration) var(--ease);
    
    > p {
        text-transform: uppercase;
        font-size: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
        letter-spacing: -0.88px;
        
        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            font-size: ${getCssSizeValue(22, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getCssSizeValue(22, BREAKPOINTS.DESKTOP)};
        }
    }
    
    .text {
        em {
            font-weight: ${props => props.theme.fontWeight.book};
        }

        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(36, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        gap: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        width: 100%;
        padding: 5% 0 ${getCssSizeValue(120, BREAKPOINTS.DESKTOP)};

        ${props => props.$isHovered && css`
            background: ${props => props.theme.colors.white};
            color: ${props => props.theme.colors.twilightBlue};
        `};
    }
`

const ArrowAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};

    .icon {
        height: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            height: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            height: ${getCssSizeValue(110, BREAKPOINTS.DESKTOP)};
        }
    }

    margin-top: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        margin-top: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        margin-top: unset;
    }
`

const TabletLayoutContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    
    .first-column {
        display: flex;
        flex-direction: column;
        gap: 100px;
    }
    
    .second-column {
        transform: translateY(25%);
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: 80%;
    height: auto;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        width: 100%;
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        width: 90%;
    }
`

export const TARGET_ID = 'target_slogan'

export default function HowWeCreateStory({ data, isSinglePage }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const slogan = data?.slogan || ''
    const paddings = getPaddingsClassFromData(data)
    const items = data?.items || ''
    const buttonData = getButtonBlockData(data)
    const isDesktop = useIsDesktop()
    const isMobile = useIsMobile()
    const wrapperRef = useRef(null)

    return (
        <StyledWrapper
            id={id}
            ref={wrapperRef}
            className={`${paddings} px-main`}
            data-logo-color={LOGO_COLORS.LIGHT}
            data-color-scheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}
        >
            <Slogan
                id={TARGET_ID}
                data={{align: 'center', containerAlign: 'flex-start', text: slogan}}
                scrollTriggerOptions={{
                    start: 'top center-=8%',
                    end: '+=150%',
                    breakpoint: 0.4
                }}
            />
            {isDesktop || isMobile ? (
                <>
                    <CardsContainer>
                        {items.map((item, index) => (
                            <Card
                                key={item?.id || index}
                                item={item}
                                index={index}
                                buttonText={buttonData.text}
                                buttonLink={buttonData.link}
                                isSinglePage={isSinglePage}
                            />
                        ))}
                    </CardsContainer>
                    {isMobile && (
                        <ArrowAndButtonWrapper>
                            <div className='icon'>
                                <Icon name={ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_MOBILE} />
                            </div>
                            <Button as='link' href={`/${buttonData.link}`}>{buttonData.text}</Button>
                        </ArrowAndButtonWrapper>
                    )}
                </>
            ) : (
                items.length === 3 && (
                    <>
                        <TabletLayoutContainer>
                            <div className='first-column'>
                                {[items[0], items[1]].map((item, index) => (
                                    <Card
                                        key={item?.id || index}
                                        item={item}
                                        index={index}
                                        buttonText={buttonData.text}
                                        buttonLink={buttonData.link}
                                        isSinglePage={isSinglePage}
                                    />
                                ))}
                            </div>
                            <div className='second-column'>
                                {[items[2]].map((item, index) => (
                                    <Card
                                        key={item?.id || index}
                                        item={item}
                                        index={index}
                                        buttonText={buttonData.text}
                                        buttonLink={buttonData.link}
                                        isSinglePage={isSinglePage}
                                    />
                                ))}
                            </div>
                        </TabletLayoutContainer>
                        <ArrowAndButtonWrapper>
                            <div className='icon'>
                                <Icon name={ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_MOBILE} />
                            </div>
                            <Button as='link' href={`/${buttonData.link}`}>{buttonData.text}</Button>
                        </ArrowAndButtonWrapper>
                    </>
                )
            )}
        </StyledWrapper>
    )
}

function Card({ item, index, buttonLink, buttonText, isSinglePage }) {
    const isDesktop = useIsDesktop()
    const [isHovered, setIsHovered] = useState(false)
    const router = useRouter()

    const id = item?.id || ''
    const tag = item?.tag || ''
    const text = item?.text || ''
    const slug = item?.slug?.slug || ''
    const imgSrc = item?.image?.url || ''
    const imgAlt = item?.image?.alt || ''
    const imgWidth = item?.image?.width || 0;
    const imgHeight = item?.image?.height || 1;
    const hoverImgSrc = item?.hoverImage?.url || ''
    const hoverImgAlt = item?.hoverImage?.alt || ''
    const hoverImgWidth = item?.hoverImage?.width || 0;
    const hoverImgHeight = item?.hoverImage?.height || 1;

    const aspectRatio = imgWidth / imgHeight === 0 ? '16/9' : imgWidth / imgHeight;
    const hoverApectRatio = hoverImgWidth / hoverImgHeight === 0 ? '16/9' : hoverImgWidth / hoverImgHeight;

    return (
        <StyledCard
            onClick={() => {
                router.push(isSinglePage ? `${slug}` : `approach/${slug}`)
            }}
        >
            <CardContent
                onPointerEnter={() => setIsHovered(true)}
                onPointerLeave={() => setIsHovered(false)}
                $isHovered={isHovered}
            >
                <StyledImage
                    id={id}
                    src={isHovered ? hoverImgSrc : imgSrc}
                    alt={isHovered ? hoverImgAlt : imgAlt}
                    sizes={'100vw'}
                    $aspectRatio={aspectRatio}
                />
                <p>{tag}</p>
                <div
                    dangerouslySetInnerHTML={getHtmlFromRedactorField(text)}
                    className='text'
                />
            </CardContent>
            {(index === 1 && isDesktop) && (
                <ArrowAndButtonWrapper>
                    <div className='icon'>
                        <Icon name={ICONS.HOW_WE_CREATE_STORY_ARROW_DOWN_DESKTOP} />
                    </div>
                    <Button
                        onClick={(event) => {
                            event.stopPropagation()
                            router.push(`/${buttonLink}`)
                        }}
                    >
                        {buttonText}
                    </Button>
                </ArrowAndButtonWrapper>
            )}
        </StyledCard>
    )
}
