import React, { useRef, memo } from 'react';
import { UseCanvas } from "@14islands/r3f-scroll-rig";
import useVisible3D from "../../../hooks/useVisible3D";
import CommonVideo from '../../common/Video'
import styled from "styled-components";
import dynamic from "next/dynamic";

const TrackVideo = dynamic(() => import('./TrackVideo'), {
	loading: () => {},
	ssr: false
});

const HTMLVideo = styled(CommonVideo)`
	&.transparent-3d{
		opacity: 0;
	}
	pointer-events: none;
`

const Video = ({ id='', src = '',visible=true, autoplay=false, webGL=true, children, className='',...props }) => {
	const VideoRef = useRef();
	const visible3D = useVisible3D(visible && webGL);
	return (
		<>
			<HTMLVideo
				id={id}
				ref={VideoRef}
				src={src}
				autoplay={visible3D ? false : autoplay}
				visible={visible}
				className={`${className} ${visible3D ? 'transparent-3d' : ''}`}
				{...props}
			>
				{children}
			</HTMLVideo>
			{visible3D && (
				<UseCanvas el={VideoRef} playing={autoplay} >
					<TrackVideo src={src} />
				</UseCanvas>
			)}
		</>
	);
};

export default memo(Video);
