import React from 'react';
import QuoteText from "./QuoteText";
import TextCentered from "./TextCentered";
import HeaderDescriptionTwoColumns from "./HeaderDescriptionTwoColumns";
import HeaderDescriptionHalfWidth from "./HeaderDescriptionHalfWidth";
import TextWithBreaksWithoutItalic from "./TextWithBreaksWithoutItalic";
import { CONTENT_TYPES } from "@repo/utils/constants";
import {BigHeaderDescriptionTwoColumns, BigTypography} from "../../../index";
import {getIdWithoutSpecialSymbols} from "@repo/utils";
import TextMedium from "./TextMedium";

// import dynamic from 'next/dynamic';
// const QuoteText = dynamic(() => import('./QuoteText'));
// const TextCentered = dynamic(() => import('./TextCentered'));
// const HeaderDescriptionTwoColumns = dynamic(() => import('./HeaderDescriptionTwoColumns'));
// const HeaderDescriptionHalfWidth = dynamic(() => import('./HeaderDescriptionHalfWidth'));

function TextModules({ data, locale }) {
	const textModulesData = data?.chooseTextModuleVariants || [];
	const textModuleId = getIdWithoutSpecialSymbols(data.id);
	if (!Array.isArray(textModulesData)) return null;
	return textModulesData.map((item, index) => {
		const itemTypename = item?.__typename || null;
		switch (itemTypename) {
			case CONTENT_TYPES.QUOTE_BLOCK:
				return <QuoteText key={item.id || index} data={item} locale={locale} textModuleId={textModuleId} />;
			case CONTENT_TYPES.TEXT_CENTERED_BLOCK:
				return <TextCentered key={item.id || index} data={item} locale={locale} textModuleId={textModuleId} />;
			case CONTENT_TYPES.HEADER_DESCRIPTION_TWO_COLUMNS_BLOCK:
				return <HeaderDescriptionTwoColumns key={item.id || index} data={item} locale={locale} textModuleId={textModuleId} />;
			case CONTENT_TYPES.BIG_HEADER_DESCRIPTION_TWO_COLUMNS_BLOCK:
				return <BigHeaderDescriptionTwoColumns key={item.id || index} data={item} locale={locale} textModuleId={textModuleId} />;
			case CONTENT_TYPES.HEADER_DESCRIPTION_HALF_WIDTH_BLOCK:
				return <HeaderDescriptionHalfWidth key={item.id || index} data={item} locale={locale} textModuleId={textModuleId} />;
			case CONTENT_TYPES.TEXT_BREAK:
				return <TextWithBreaksWithoutItalic key={item.id || index} data={item} locale={locale} textModuleId={textModuleId} />;
			case CONTENT_TYPES.TYPOGRAPHY_BIG:
				return <BigTypography key={item.id || index} data={item} textModuleId={textModuleId} />
			case CONTENT_TYPES.TYPOGRAPHY_MEDIUM_TEXT:
				return <TextMedium key={item.id || index} data={item} textModuleId={textModuleId} />
			default:
				return <h1 key={item.id}>Brak obsługi dla {itemTypename}</h1>;
		}
	});
}

export default TextModules;
