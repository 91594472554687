import {PleoGroupLogo} from '@repo/ui/SVGR';
import styled from 'styled-components';
import {useRef} from 'react';
import {Icon, useQueryElement} from '@repo/ui';
import {useDispatch} from 'react-redux';
import { setSelectedSubItem } from '@/state/menuSlice';
import { useRouter } from 'next/router';
import {ICONS} from "@repo/utils/constants";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "@repo/ui/themeSettings";

const StyledWrapper = styled.div`
    cursor: pointer;
    width: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    position: relative;
    
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(126, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(126)};
    }
`;

const StyledLogoWrapper = styled.div`
    .icon {
        max-width: unset;
    }
`;

const Logo = ({ firstMenuItem }) => {
    const dispatch = useDispatch();
    const { push } = useRouter();
    const logoSvgWidth = useQueryElement('#logo-svg')?.clientWidth || 200;

    const wrapperRef = useRef(null);
    const handleOnClick = () => {
        dispatch(setSelectedSubItem(null))
        push('/', '/', { scroll: false });
    };

    return (
        <StyledWrapper
            onClick={handleOnClick}
            ref={wrapperRef}
            width={logoSvgWidth}
        >
            <StyledLogoWrapper className="logo-wrapper" width={logoSvgWidth}>
                <Icon name={ICONS.PLEO_GROUP_MENU_LOGO} className='no-color' />
            </StyledLogoWrapper>
        </StyledWrapper>
    );
};

export default Logo;
