import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	open: false,
	isAnimating: false,
	menuTransition: false,
	isCarouselVisible: false,
	selectedItem: {},
	selectedSubItem: null
};

export const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setIsMenuOpen: (state, payload) => {
			state.open = payload.payload;
		},
		setIsAnimating: (state, payload) => {
			state.isAnimating = payload.payload;
		},
		setMenuTransition: (state, payload) => {
			state.menuTransition = payload.payload;
		},
		setIsCarouselVisible: (state, payload) => {
			state.isCarouselVisible = payload.payload;
		},
		setSelectedItem: (state, payload) => {
			state.selectedItem = payload.payload;
		},
		setSelectedSubItem: (state, payload) => {
			state.selectedSubItem = payload.payload
		}
	},
});

export const { setIsMenuOpen, setIsAnimating, setMenuTransition, setSelectedSubItem, setSelectedItem, setIsCarouselVisible } = menuSlice.actions;

export default menuSlice.reducer;