import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";

const StyledProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 37px;
    transition: all .2s;
`;

const EmblaProgress = styled.div`
    background-color: ${props => props.theme.colors.lightGray};
    position: relative;
    height: 4px;
    justify-self: flex-end;
    align-self: center;
    width: 140px;
    max-width: 90%;
    overflow: hidden;
    border-radius: 2px;
`

const EmblaProgressBar = styled.div`
    background-color: var(--secondary-color);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: all .1s;
`

export default function ProgressBar({ emblaApi }) {
    const [scrollProgress, setScrollProgress] = useState(0)

    const onScroll = useCallback((emblaApi) => {
        const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()))
        setScrollProgress(progress * 100)
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onScroll(emblaApi)
        emblaApi
            .on('reInit', onScroll)
            .on('scroll', onScroll)
            .on('slideFocus', onScroll)
    }, [emblaApi, onScroll])

    return (
        <StyledProgressWrapper>
            <EmblaProgress>
                <EmblaProgressBar
                    className='flip-element-1'
                    style={{
                        transform: `translate3d(${scrollProgress}%, 0px, 0px)`,
                    }}
                />
            </EmblaProgress>
        </StyledProgressWrapper>
    )
}
