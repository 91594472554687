import {useEffect} from "react";
import {isFunction} from "@repo/utils";

export default function Fallback({ name='', onLoad, log=false }){
    useEffect(() => {
        if(log){
            console.log(`${name} fallback enter`);
        }
        return () => {
            if(log){
                console.log(`${name} fallback exit`);
            }
            if(isFunction(onLoad)){
                onLoad()
            }
        }
    }, []);

    return null

}
