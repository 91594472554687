/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import useMediaQuery from './useMediaQuery';
import { useTheme } from 'styled-components';
import useMobileDevice from "./useMobileDevice";
import {BREAKPOINTS} from "../../styles/themeSettings";

export default function useIsDesktop(deviceCheck=true) {
	const theme = useTheme();
	const mediaQueryMatches = useMediaQuery(`(min-width:${theme?.breakpoints?.[BREAKPOINTS.DESKTOP] || 1024}px) and (orientation: landscape)`);
	const isMobileDevice = useMobileDevice()
	// const isMobileDevice = true //testing
	if(!deviceCheck) return mediaQueryMatches
	return mediaQueryMatches && !isMobileDevice
}
