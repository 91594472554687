import styled from "styled-components";
import SimpleCard from "./SimpleCard";
import useEmblaCarousel from "embla-carousel-react";
import {forwardRef, useEffect, useRef, useState} from "react";
import { useGSAP } from '@repo/utils/gsap'
import { gsap } from 'gsap'
import { useIsMobile } from "../../../index";
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import {AboutTeamCloseButtonCross} from "../common/SVGR";
import {CURSOR_TYPES} from "@repo/utils/constants";
import {getCssSizeValue} from "@repo/utils";
import Card from "./Card";

const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background: var(--primary-color);
    position: absolute;
    top: 0;
    left: 110vw;
    transition: all .4s;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        //z-index: -1;
        //left: 33.3%;
    }
`

const StyledEmblaViewPort = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
`

const EmblaContainer = styled.div`
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    transform: translateX(100vw);
`

const StyledCloseButtonWrapper = styled.div`
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 3;
    top: -35px;
    right: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};;
    height: 28px;
    width: 30px;
    transform: scale(0.8);
    transition: all .3s;
    
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        top: -35px;
        right: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
        height: 60px;
        width: 60px;
        transform: unset;
        transition: all .3s;
    }

   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: 65px;
        width: 65px;
        right: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
        &:hover {
            transform: scale(1.1);
        }
    }
`

const StyledMobileBackgroundIndicator = styled.div`
    position: absolute;
    background: ${theme.colors.white};
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 58%;
    width: auto;
    display: flex;
    aspect-ratio: 640 / 850;
    align-items: flex-end;
    padding-left: 23px;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        display: none;
    }
`

function useReinitCarouselOnMobile(emblaApi) {
    const isMobile = useIsMobile()

    useEffect(() => {
        if (!emblaApi) return

        if (isMobile) {
            emblaApi.reInit({dragFree: false})
            return
        }

        emblaApi.reInit({ dragFree: true })
    }, [isMobile, emblaApi])
}


const SubCarousel = forwardRef(({ data: item, isExpanded, setIsExpanded, collapseButtonText, expandButtonText, setSubCarouselReference }, ref) => {
    const isMobile = useIsMobile()

    const childItems = item?.aboutTeamSingleCardItems || []
    const [bigTextAnimationTrigger, setBigTextAnimationTrigger] = useState(false)

    const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true, align: 'center' })
    const wrapperRef = useRef(null)
    const tl = useRef(null)

    useEffect(() => {
        if (!emblaApi) return;
        setSubCarouselReference(emblaApi)
        emblaApi.scrollTo(0)
    }, [item, emblaApi, isExpanded]);

    useReinitCarouselOnMobile(emblaApi)

    useGSAP(() => {
        tl.current = gsap.timeline({ paused: true })
        tl.current
            .set('.subCarouselWrapper', { zIndex: 3 })
            .to('.subCarouselWrapper', {
                ease: 'none',
                left: 0,
                duration: 0.2,
                onComplete: () => { setBigTextAnimationTrigger(true) },
                onReverseComplete: () => { setBigTextAnimationTrigger(false) }})
            .to('.change-slide-button', {
                y: '100svh',
                duration: 0.1})
            .to('.subCarouselSimpleCard', {
                x: 0,
                duration: 0.1,
                stagger: 0.1}, '+=0.2')
    }, { scope: wrapperRef, dependencies: [item] })

    useEffect(() => {
        isExpanded ? tl.current.play() : tl.current.reverse()
    }, [isExpanded]);

    useEffect(() => {
        gsap.to('.text-bold', {
            x: isExpanded ? '200%' : 0,
            duration: 0.3,
        })
    }, [bigTextAnimationTrigger]);

    return (
        <StyledWrapper
            ref={(localRef) => {
                wrapperRef.current = localRef
                ref.current = localRef
            }}
            className={`${isExpanded ? 'expanded' : 'not-expanded'} subCarouselWrapper`}
        >
            <StyledMobileBackgroundIndicator />
            <StyledCloseButtonWrapper
                onClick={() => {
                    gsap.to('.menu-top-row', { y: 0, duration: 0.2, ease: 'power2.in' })
                    setIsExpanded(false)
                }}
                data-cursor={CURSOR_TYPES.CIRCLE}
            >
                <AboutTeamCloseButtonCross />
            </StyledCloseButtonWrapper>
            <StyledEmblaViewPort ref={emblaRef}>
                <EmblaContainer className='subCarouselContainer'>
                    {!isMobile && <Card className='subCarouselCard'
                                        item={item}
                                        isMainCardOnExpandedCarouses
                                        isSubCarousel
                                        setIsSubCarouselExpanded={setIsExpanded}
                                        expandButtonText={expandButtonText}
                                        collapseButtonText={collapseButtonText}
                    />}
                    {childItems.map((item) => {
                        return (
                            <SimpleCard className='subCarouselCard' isExpanded={isExpanded} key={item.id} item={item} />
                        )
                    })}
                </EmblaContainer>
            </StyledEmblaViewPort>
        </StyledWrapper>
    );
})

export default SubCarousel
