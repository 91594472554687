import React, {useEffect} from 'react';
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";
import NextLink from "next/link";
import {gsap, ScrollTrigger,useGSAP, SplitText} from '@repo/utils/gsap'
import {useWindowSize} from "../../../index";

const StyledSocialMediaWrapper = styled.div`
	flex-direction: column;
	width: 100%;
    position: relative;
    z-index: 3;
    align-items: center;
    display: flex;
    background-color: ${theme.colors.lightGray};
    //background-color: var(--secondary-color);
    a {
        text-decoration: none;
    }
`;

const StyledLinkContainer = styled.div`
    overflow: hidden;
`

const StyledSocialsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: max-content;
    margin-top: ${getCssSizeValue(-132, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin-top: ${getCssSizeValue(-100, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-top: ${getCssSizeValue(-110, BREAKPOINTS.DESKTOP)};
    }
`


const StyledSocialMediaLink = styled(NextLink)`
    --font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    letter-spacing: calc(var(--font-size) * -0.04);
    color: ${theme.colors.twilightBlue};
    font-size: var(--font-size);
    position: relative;
    line-height: normal;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    cursor: default;
    //transition: opacity 0.1s ease-in-out, transform 0.3s ease-in-out!important;
    //opacity: 0;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        --font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(98, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(110, BREAKPOINTS.DESKTOP)};
    }
    &:before {
        background: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
    }
    &:hover {
        &:before {
            background: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
        }
    }
`

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);
const SocialMedia = ({data}) => {
    const id = getIdWithoutSpecialSymbols(data?.id);
    const colorScheme = getColorScheme(data);
    const paddings = getPaddingsClassFromData(data);
    const windowSize = useWindowSize()
    const socialMedia = data?.socialMedia || [];
    useGSAP(() => {
        const el = document.getElementById('socials-parent').children;
        let split = new SplitText(el, { type: 'lines' });
        ScrollTrigger.create({
            id: "social-media-contact",
            trigger: '#socials-parent',
            start: 'top bottom',
            // once: true,
            // markers: true,
            onEnter: () => {
                gsap.from(split.lines, {
                    duration: 0.6,
                    // duration: 1,
                    // y: 40,
                    y: '100%',
                    toggleActions: 'play pause resume reverse',
                    // autoAlpha: 0,
                    stagger: 0.2,
                    // ease: "power2.inOut",
                    ease: "sine.out",
                    // ease: "power3.out",
                });
            }   ,
        });
    }, {
        dependencies: [windowSize.width],
        revertOnUpdate: true
    });
    return (
        <StyledSocialMediaWrapper id={id}
                                  data-color-scheme={colorScheme}
                                  data-cursor={CURSOR_TYPES.HTML}
                                  className={`${paddings} px-main`}>
            <StyledSocialsWrapper id='socials-parent'>
                {socialMedia.map((item) => {
                    const title = item?.title || '';
                    const link = item?.link || '';
                    return(
                        <StyledLinkContainer key={title} className='line-parent'>
                            <StyledSocialMediaLink
                                // className='underline-animation'
                                // data-cursor={CURSOR_TYPES.LINK}
                                className="underline-animation-lowercase"
                                data-cursor={CURSOR_TYPES.LINK_LOWERCASE}
                                href={link}
                                target="_blank">{title}
                            </StyledSocialMediaLink>
                        </StyledLinkContainer>
                    )
                })}
            </StyledSocialsWrapper>
        </StyledSocialMediaWrapper>
    );
};

export default SocialMedia;
