import styled from 'styled-components';
import { forwardRef } from 'react';
import useSpecifyColor from "../../hooks/useSpecifyColor";

const SvgWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 20px;
	height: 20px;

	svg {
		display: block;
		path,
		circle {
			transition: all var(--color-transition-duration) var(--ease);
			pointer-events: none;
		}
		path {
			fill: currentColor;
		}
		circle {
			stroke: white;
		}
	}

	&.wave {
		border-radius: 50%;
		overflow: hidden;
		width: 32px;
		height: auto;
		aspect-ratio: 1;
		svg {
			width: 100%;
			height: 100%;
			z-index: 2;

			path {
				transition: all var(--color-transition-duration) var(--ease);
				stroke: var(--tertiary-color);
				transform: translate(-25%, 17%);
			}
		}
		&.move-left {
			svg {
				transform-origin: center;
				transform: scale(0);
			}
		}
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		&.wave {
			width: 66px;

			&.active {
				svg {
					path {
						transform: translate(-75%, 17%);
					}
				}
			}
		}
	}
`;

export const ContactSubmitArrow = forwardRef(function ContactSubmitArrow({ className, style, children, ...props }, ref) {
    return (
        <SvgWrapper ref={ref} className={className} style={style}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.585786 20.5858C-0.195262 21.3668 -0.195262 22.6332 0.585786 23.4142C1.36684 24.1953 2.63316 24.1953 3.41421 23.4142L0.585786 20.5858ZM24 2C24 0.89543 23.1046 -1.74203e-07 22 6.68734e-07L4 9.21615e-07C2.89543 2.47266e-07 2 0.895431 2 2C2 3.10457 2.89543 4 4 4L20 4L20 20C20 21.1046 20.8954 22 22 22C23.1046 22 24 21.1046 24 20L24 2ZM3.41421 23.4142L23.4142 3.41421L20.5858 0.585787L0.585786 20.5858L3.41421 23.4142Z"
                    fill="#FFD010" />
            </svg>
            {children}
        </SvgWrapper>
    );
});

export const ActiveMenuItem = () => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.38281 8.13672C3.6914 8.13672 4.75222 9.19754 4.75222 10.5061V18.3497C4.75222 19.6583 5.81304 20.7191 7.12162 20.7191C8.43021 20.7191 9.49103 19.6583 9.49103 18.3497V10.5061C9.49103 9.19754 10.5519 8.13672 11.8604 8.13672C13.169 8.13672 14.2298 9.19754 14.2298 10.5061V18.3497C14.2298 19.6583 15.2907 20.7191 16.5992 20.7191C17.9078 20.7191 18.9687 19.6583 18.9687 18.3497V10.5061C18.9687 9.19754 20.0295 8.13672 21.3381 8.13672C22.6466 8.13672 23.7075 9.19753 23.7075 10.5061V18.3497C23.7075 19.6583 24.7683 20.7191 26.0769 20.7191"
                stroke="var(--secondary-color)"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                id="menu-item-svg-1"
            />
            <path
                d="M14.23 27.7978C21.6144 27.7978 27.6006 21.8116 27.6006 14.4272C27.6006 7.04287 21.6144 1.05664 14.23 1.05664C6.8456 1.05664 0.859375 7.04287 0.859375 14.4272C0.859375 21.8116 6.8456 27.7978 14.23 27.7978Z"
                stroke="var(--secondary-color)"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const LongArrowLeft = forwardRef(function LongArrowLeft() {
    return (
        <svg width="51" height="16" viewBox="0 0 51 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM51 7L1 7L1 9L51 9V7Z"
                fill="black"/>
        </svg>
    );
});

export const LongArrowRight = forwardRef(function LongArrowRight() {
    return (
        <svg width="51" height="16" viewBox="0 0 51 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M50.7071 8.70711C51.0976 8.31658 51.0976 7.68342 50.7071 7.29289L44.3431 0.928932C43.9526 0.538407 43.3195 0.538407 42.9289 0.928932C42.5384 1.31946 42.5384 1.95262 42.9289 2.34315L48.5858 8L42.9289 13.6569C42.5384 14.0474 42.5384 14.6805 42.9289 15.0711C43.3195 15.4616 43.9526 15.4616 44.3431 15.0711L50.7071 8.70711ZM-8.74228e-08 9L50 9L50 7L8.74228e-08 7L-8.74228e-08 9Z"
                fill="black"/>
        </svg>
    );
});

export const ShortArrowLeft = forwardRef(function LongArrowRight() {
    return (
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.46967 5.46967C0.176777 5.76256 0.176777 6.23744 0.46967 6.53033L5.24264 11.3033C5.53553 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53553 0.403806 5.24264 0.696699L0.46967 5.46967ZM12 5.25L1 5.25V6.75L12 6.75V5.25Z"
                fill="#051D27"/>
        </svg>
    );
});

export const ShortArrowRight = forwardRef(function LongArrowRight() {
    return (
        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5303 6.53033C11.8232 6.23744 11.8232 5.76257 11.5303 5.46967L6.75736 0.6967C6.46447 0.403807 5.98959 0.403807 5.6967 0.6967C5.40381 0.989593 5.40381 1.46447 5.6967 1.75736L9.93934 6L5.6967 10.2426C5.4038 10.5355 5.4038 11.0104 5.6967 11.3033C5.98959 11.5962 6.46447 11.5962 6.75736 11.3033L11.5303 6.53033ZM-1.31134e-07 6.75L11 6.75L11 5.25L1.31134e-07 5.25L-1.31134e-07 6.75Z"
                fill="#051D27"/>
        </svg>

    );
});

export const AboutTeamCloseButtonCross = forwardRef(function AboutTeamCloseButtonCross() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
        >
            <circle cx={32.5} cy={32.5} r={32.5} fill="#051D27"/>
            <path
                fill="#fff"
                d="M39 26.41 37.59 25 32 30.59 26.41 25 25 26.41 30.59 32 25 37.59 26.41 39 32 33.41 37.59 39 39 37.59 33.41 32 39 26.41Z"
            />
        </svg>
    );
});