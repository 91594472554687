import React from 'react';
import styled from 'styled-components';
import {getFontSize} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";
import Link from "./Link";

const StyledTag = styled(Link)`
    padding: 9px 13px 7px;
    border-radius: 100px;
    border: ${({$accentColor}) => `2px solid ${$accentColor}`};
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    background-color: transparent;
    color: var(--secondary-color);
    text-transform: uppercase;
    font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
    line-height: normal;
    font-weight: ${({theme}) => theme.fontWeight.regular};
    letter-spacing: normal;
    cursor: pointer;
    text-decoration: none;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        padding: 4px 13px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
    }
    &:hover {
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        background-color: ${({$accentColor}) => $accentColor};
        color: white;
    }
`
const Tag = ({ className='', as, href, accentColor, onClick, children} ) => {
    return (
        <StyledTag className={`tag ${className}`} onClick={onClick} data-cursor={CURSOR_TYPES.PILL} as={as} href={href} $accentColor={accentColor}>
           {children}
        </StyledTag>
    );
};

export default Tag;
