import React, {createContext, forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import {formatHTML, getOnlyBreaksTexts} from "./utils";
import useCustomContext from "../../../hooks/useCustomContext";
import dynamic from "next/dynamic";

const ClassicTextWithBreaks = dynamic(() => import('./Classic'), {
    ssr: false,
});
const StaticTextWithBreaks = dynamic(() => import('./Static'), {
    ssr: false,
});

export const VARIANT = {
    CLASSIC: 'classic',
    STATIC: 'static'
}

const TextWithBreaksContext = createContext({
    variant: VARIANT.CLASSIC,
    text: '',
    formatted: [],
    breaks: {},
    webGL: false,
    color: undefined
});

const TextWithBreaks = forwardRef(function TextWithBreaks({ data, className='', variant:vt =VARIANT.CLASSIC, imageLoadPriority = false, color='', webGL: gl=false, left=false, ...props }, fref){
    const WrapperRef = useRef(null);
    const text = data?.text || ''
    const parentId = data?.parentId || ''
    const breaks = data?.breaks || []
    const variant = useMemo(() => vt || VARIANT.CLASSIC, [vt])
    const webGL = variant === VARIANT.CLASSIC ? gl : false
    const formatted = useMemo(() => formatHTML(text || ''), [text])
    const onlyBreaksTexts = useMemo(() => getOnlyBreaksTexts(formatted), [formatted])
    // console.log(formatted);
    // console.log(onlyBreaksTexts, breaks, formatted);
    useImperativeHandle(fref, () => WrapperRef.current, [variant]);
    return (
        <TextWithBreaksContext.Provider value={{ variant, text, formatted, breaks:{ data: breaks, texts: onlyBreaksTexts }, color, webGL }}>
            {variant === VARIANT.CLASSIC ? (
                <ClassicTextWithBreaks
                    ref={WrapperRef}
                    className={className}
                    imageLoadPriority={imageLoadPriority}
                    parentId={parentId}
                    left={left}
                    {...props}
                />
            ) : (
                <StaticTextWithBreaks
                    ref={WrapperRef}
                    className={className}
                    imageLoadPriority={imageLoadPriority}
                    parentId={parentId}
                    left={left}
                    {...props}
                />
            )}
        </TextWithBreaksContext.Provider>
    );
});

export function useTextWithBreaksContext(selector) {
    return useCustomContext(TextWithBreaksContext, selector)
}

export default TextWithBreaks;
