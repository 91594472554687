import React, {startTransition, useMemo, useRef, memo} from 'react';
import {useCursor} from "../Cursor/Cursor";
import {useMask} from "@react-three/drei";
import PortalVideo from "../WebGL/Portal/Video3D";
import BandVideo from "../../assets/video/contact-banner-vertical.mp4";
import {useFrame, useThree} from "@react-three/fiber";
import { useIsomorphicLayoutEffect} from "../../../index";
import {gsap} from "@repo/utils/gsap";
import LensMask from "../WebGL/Portal/LensMask";

const MASK_ID = 1
const Blob = ({ _visible = false, context}) => {
    const MeshRef = useRef()
    const three = useThree()
    const portalLens = useCursor((state) => state.portalLens)
    const moveVector = useMemo(() => portalLens.position.clone(), [portalLens.position]);
    const stencil = useMask(MASK_ID)

    // const scaleVector = useMemo(() => new Vector3(0, 0, 0), [])
    // const scaleVector = useMemo(() => new Vector3(PORTAL_SCALE, PORTAL_SCALE,PORTAL_SCALE), [])

    const steps = useMemo(() => ([
        {
            timeline: gsap.timeline({ paused: true }),
            animations: [
                {
                    target: moveVector,
                    x: 0,
                    y: 10,
                    // z: getPositionZ(PORTAL_LENS_RADIUS, three.viewport)
                    z: 0
                },
            ]
        },
    ]),[moveVector, three.viewport])

    useIsomorphicLayoutEffect(() => {
        steps.forEach((step) => {
            step.animations.forEach((animation) => {
                step.timeline.to(animation.target, {
                    x: animation.x,
                    y: animation.y,
                    z: animation.z,
                    ease: 'none'
                }, 0 ) //important for animations to be applied simultaneously
            })
        })

        // console.log(moveVector);

        return () => {
            steps.forEach((step) => step.timeline.revert())
            moveVector.setScalar(0)
        }
    },[steps, three.viewport])

    useFrame(() => {
        const pg = context.step1.current
        const finalPg = pg + 0.6*pg;
        steps[0].timeline.progress(pg)
        portalLens.position.copy(moveVector)
        // portalLens.scale.copy(scaleVector)

        startTransition(() => {
            MeshRef.current.position.copy(portalLens.position)
            MeshRef.current.scale.copy(portalLens.scale)
            MeshRef.current.visible = _visible
        })
    })
    // console.log(context?.src);

    return (
        <group ref={MeshRef} >
            {/*<mesh ref={MeshRef} position={[0,0, 0.01]}>*/}
            {/*    <LensGeometry/>*/}
            {/*    /!*<circleGeometry args={[3, 32]}/>*!/*/}
            {/*    /!*<meshBasicMaterial color="skyblue"/>*!/*/}

            {/*    <MeshPortalMaterial transparent>*/}
            {/*        /!*<color attach={"background"} args={['skyblue']}/>*!/*/}
            {/*        /!*<mesh scale={[5,5,1]}>*!/*/}
            {/*        /!*    <planeGeometry/>*!/*/}
            {/*        /!*    <meshBasicMaterial color="red"/>*!/*/}
            {/*        /!*</mesh>*!/*/}
            {/*        <PortalVideo*/}
            {/*            src={context?.src || BandVideo}*/}
            {/*            visible*/}
            {/*            cover*/}
            {/*        />*/}
            {/*    </MeshPortalMaterial>*/}
            {/*</mesh>*/}
            <LensMask id={MASK_ID}/>
            <PortalVideo
                src={context?.src || BandVideo}
                visible
                cover
                stencil={stencil}
            />
        </group>
    );
};

export default memo(Blob);
