import React, {createContext, useMemo, useState, memo, useEffect, useCallback, Suspense} from 'react';
import HtmlCursor from './HtmlCursor';
import Lens from '../WebGL/Lens';
import {CURSOR_INITIAL_TARGET} from '@repo/utils/constants';
import Background from '../WebGL/Background';
import { Vector2, Vector3 } from 'three';
import { useRouter } from "next/router";
import { Preload } from "@react-three/drei";
import { ForwardThree } from "../WebGL/Canvas";
import useCustomContext from "../../hooks/useCustomContext";
import useIsDesktop from "../../hooks/useIsDesktop";
import { isFunction } from "@repo/utils";
import useLoadedManager, { LoadingHandler } from "../../hooks/useLoadedManager";
import {Canvas} from "../../../index";



const INIT_CONTEXT = {
	target: CURSOR_INITIAL_TARGET,
	setTarget: () => {},
	position: new Vector2(0.5, 0.5),
	after: {
		current: null,
		position: new Vector2(0.5, 0.5),
	},
	lens: {
		current: null,
		fbo: null
	},
	portalLens: {
		current: null,
		visible: false,
		position: new Vector3(0,0,0),
		scale: new Vector3(1,1,1)
	},
}
const CursorContext = createContext(INIT_CONTEXT);
const Children = ({ children }) => {
	const loaded = useLoadedManager()

	const onLoad = useCallback(() => {
		// console.log('html load');
		loaded.onHTMLLoaded()
	},[])

	return (
		<Suspense fallback={null}>
			<LoadingHandler onLoad={onLoad}>
				{children}
			</LoadingHandler>
		</Suspense>
	)
}

const GlobalCanvas = memo(function GlobalCanvas({ transitionCompleted, eventSource }) {
	const router = useRouter()
	const isDesktop = useIsDesktop()
	const [init, setInit] = useState(false)
	const loaded = useLoadedManager()

	const isProjectSite = useMemo(() => {
		if (!transitionCompleted) return
		const path = router.asPath
		return path.split('/').length === 3 && path.includes('projects')
	},[transitionCompleted])

	const onLoad = useCallback(() => {
		// console.log('webgl load');
		loaded.onWebGLLoaded()
	},[])

	useEffect(() => {
		setInit(true)
	},[])

	useEffect(() => {
		// console.log('init:', init);
		// if(isFunction(onLoad)){
		// 	onLoad()
		// }
		if(!init) return
		if(!isDesktop){
			if(isFunction(onLoad)){
				onLoad()
			}
		}
	}, [init]);


	if(!isDesktop) return
	return (
		<Canvas visible={!isProjectSite} eventSource={eventSource} onLoad={onLoad}>
			{(globalChildren) => (
				<Suspense fallback={null}>
					<LoadingHandler onLoad={onLoad}>
						<ForwardThree>
							<Lens >
								<Background />
								{globalChildren}
							</Lens>
							<Preload/>
						</ForwardThree>
					</LoadingHandler>
				</Suspense>
			)}
		</Canvas>
	)
})

const Cursor = ({  eventSource, children, transitionCompleted = true }) => {
	const [cursorTarget, setCursorTarget] = useState(CURSOR_INITIAL_TARGET);
	const context = useMemo(() => ({
		...INIT_CONTEXT,
		target: cursorTarget,
		setTarget: setCursorTarget,
	}),[cursorTarget])

	return (
		<CursorContext.Provider value={context}>
			<GlobalCanvas key="global-canvas" transitionCompleted={transitionCompleted} eventSource={eventSource}/>
			<Children>
				<HtmlCursor />
				{children}
			</Children>
		</CursorContext.Provider>
	);
};

export function useCursor(selector = undefined) {
	return useCustomContext(CursorContext, selector)
}

export default memo(Cursor);
