import {getAspectRatioFromImage} from "@repo/utils";
import {CURSOR_ASSET_SIZE, MIME_TYPE} from "@repo/utils/constants";
import React, {useRef} from "react";
import dynamic from "next/dynamic";
import styled from "styled-components";
import blobsvg from '../../assets/images/blob.svg'
import {useIsDesktop} from "../../../index";
import {gsap} from "@repo/utils/gsap";
import {useGSAP} from "@gsap/react";
import {AnimatePresence, motion} from "framer-motion";
import {ENTER_DIRECTIONS} from "./useCursorAsset";

const Image = dynamic(() => import('../common/Image'), {
    ssr: false,
    loading: () => <Fallback/>
})

const Video = dynamic(() => import('../common/Video'), {
    ssr: false,
    loading: () => <Fallback/>
})

const Fallback = styled.div`
	position: relative;
	background-color: red;
	inset: 0;
	width: 100%;
	height: 100%;
`

const Asset = styled.div`
	position: absolute !important;
	top: 50%;
	left: 50%;
	width: ${props => props.$size === CURSOR_ASSET_SIZE.LARGE ? '29vw' : '15vw'} !important;
	height: auto !important;
	aspect-ratio: ${props => props.$aspectRatio ? props.$aspectRatio : '4/3'};
	--size: 0;
	mask: url('${blobsvg}');
	mask-repeat: no-repeat;
	mask-size: ${props => props.$size === CURSOR_ASSET_SIZE.LARGE ? 'calc(25vw * var(--size))' : 'calc(18vw * var(--size))'} !important;
	//mask-size:cover;
	mask-position: center;
	transform-origin: center;
	z-index: 2;
    transition: transform var(--color-transition-duration) var(--ease);
    transform: translate(-130%, -50%);
`
const transition = { duration: 0.2 }

export const assetAnimationVariants = {
    enter: (entryDirection) => ({
        y: entryDirection === ENTER_DIRECTIONS.TOP ? "100%" : "-100%",
    }),
    animate: {
        y: 0,
    },
    exit: (entryDirection) => ({
        y: entryDirection === ENTER_DIRECTIONS.TOP ? "-100%" : "100%",
    }),
};

export default function AssetCursor({ cursorAsset, cursorAssetSize, cursorType, entryDirection }) {
    const id = cursorAsset?.id || ''
    const isDesktop = useIsDesktop()
    const AssetRef = useRef()

    useGSAP(() => {
        if (cursorAsset) {
            gsap.to(AssetRef.current, {
                duration: 0.65,
                '--size': cursorAssetSize === CURSOR_ASSET_SIZE.LARGE ? 3 : 2,
                ease: 'sine'
            })
        }
    }, { dependencies: [cursorType]})

    return (
        <Asset
            ref={AssetRef}
            $size={cursorAssetSize}
            $aspectRatio={getAspectRatioFromImage(cursorAsset)}
        >
            {cursorAsset && (
                <AnimatePresence mode='popLayout' custom={entryDirection}>
                    <motion.div
                        key={`${id}-${entryDirection || ENTER_DIRECTIONS.TOP}-${Math.random()}`}
                        initial="enter"
                        animate="animate"
                        exit="exit"
                        custom={entryDirection || ENTER_DIRECTIONS.TOP}
                        variants={assetAnimationVariants}
                        transition={transition}
                        style={{height: '100%', width: '100%'}}
                    >
                        {cursorAsset?.mimeType === MIME_TYPE.video ? (
                            <Video
                                src={cursorAsset.url}
                                playing
                                visible={!!cursorAsset.url && isDesktop}
                                fallback={<Fallback/>}
                            />
                        ) : (
                            <Image
                                src={cursorAsset.url}
                                visible={!!cursorAsset.url && isDesktop}
                                priority
                            />
                        )
                        }
                    </motion.div>
                </AnimatePresence>
            )}
        </Asset>
    )
}