import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';
import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin';
import { ScrambleTextPlugin } from 'gsap/dist/ScrambleTextPlugin';
import { Flip } from 'gsap/dist/Flip';
import { MorphSVGPlugin } from 'gsap/dist/MorphSVGPlugin';
import { CustomEase } from 'gsap/dist/CustomEase';
import {TextPlugin} from 'gsap/TextPlugin'
import { EASE } from '../constants';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(Flip);
gsap.registerPlugin(TextPlugin)

const golden_ratio = (1 + Math.sqrt(5)) / 2;
const reciprocal_gr = 1 / golden_ratio;
const duration = reciprocal_gr; // 0.6180339887498948s
const customEase = CustomEase.create('ease', EASE.join(', '));
const delay = duration / 2
const eases = {
	custom: customEase,
	main: 'sine.inOut',
	easeOut: 'power2.out',
	easeInOut: 'power2.inOut',
};

// Configuring GSAP with custom settings that aren't Tween-specific
gsap.config({
	autoSleep: 60,
	nullTargetWarn: false,
});

// Setting default animation properties that should be inherited by ALL tweens
gsap.defaults({
	duration,
	ease: eases.custom,
});

// Once the desired configurations are set, we simply export what we need to work with in the future.
export {
	duration,
	delay,
	golden_ratio,
	gsap,
	CustomEase,
	SplitText,
	ScrollTrigger,
	Flip,
	DrawSVGPlugin,
	ScrambleTextPlugin,
	eases,
	useGSAP
};
