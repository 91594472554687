import styled from "styled-components";
import {
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import {Button, Reveal} from "../../../index";
import {BREAKPOINTS, LOGO_COLORS, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import {useRef} from "react";
import { gsap, useGSAP, ScrollTrigger } from '@repo/utils/gsap'
import {HomeLine} from "../common/Icon/SVGR";
import {getButtonBlockData} from "../common/Button";

const StyledWrapper = styled.section`
    position: relative;
    color: var(--secondary-color);
    transition: color var(--color-transition-duration) var(--ease);
    overflow: hidden;
    height: auto;
    
    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
    }

    svg{
        path{
            transition: stroke var(--color-transition-duration) var(--ease);
            stroke: var(--secondary-color);
        }
    }
`

const StyledDesktopSvgWrapper = styled.div`
    display: none;
    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        position: relative;
        z-index: 1;
        display: initial;
        width: 100%;
        height: 100%;
    }
`

const StyledMobileSvgWrapper = styled.div`
    width: 100%;
    margin-top: 30px;

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        display: none;
    }
`

const StyledContent = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    
    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        flex-direction: row;
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: auto;
        top: 8%;
        left: 0;
    }
`

const StyledColumn = styled.div`
    div.description {
        font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
        margin-bottom: ${getCssSizeValue(36, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            width: ${getCssSizeValue(340, BREAKPOINTS.TABLET)};
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
            margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            width: ${getCssSizeValue(840, BREAKPOINTS.DESKTOP)};
            font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
            margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
        }
    }
    
    div.header {
        text-transform: uppercase;
        width: 100%;
        font-weight: ${({theme}) => theme.fontWeight.black};
        font-size: ${getFontSize(148, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(136, BREAKPOINTS.MOBILE)};
        margin-bottom: ${getCssSizeValue(52, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
            font-size: ${getFontSize(140, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(114, BREAKPOINTS.MOBILE)};
            margin-bottom: unset;
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(309, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(255, BREAKPOINTS.DESKTOP)};
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {

    }
    
    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        
    }
`

export default function DescriptionLinePleo({data}) {
    const id = getIdWithoutSpecialSymbols(data?.id)
    const header = data?.header || ''
    const description = data?.description || ''
    const buttonData = getButtonBlockData(data)
    const paddings = getPaddingsClassFromData(data)
    const wrapperRef = useRef(null)
    const drawLineTl = useRef(null)
    const button = data?.button

    useGSAP(() => {
        drawLineTl.current = gsap.timeline({ paused: true })
            .fromTo("#svg-home-line", {drawSVG: "0%"}, { drawSVG: "100%"});

        ScrollTrigger.create({
            trigger: wrapperRef.current,
            start: 'top top+=30%',
            end: 'center top',
            scrub: 1,
            animation: drawLineTl.current,
        })
    }, { scope: wrapperRef, dependencies: [] })

    return (
        <StyledWrapper
            id={id}
            className={paddings}
            data-logo-color={LOGO_COLORS.LIGHT}
            data-color-scheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}
            ref={wrapperRef}
        >
            <StyledDesktopSvgWrapper
                className={`${paddings}`}
            >
                <HomeLine />
            </StyledDesktopSvgWrapper>

            <StyledContent className='px-main'>
                <StyledColumn>
                    <Reveal onInView>
                        <div
                            className='description'
                            dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}
                        />
                    </Reveal>
                    {button && (
                        <Button as='link' href={buttonData.link}>
                            {buttonData.text}
                        </Button>
                    )}
                </StyledColumn>
                <StyledColumn>
                    <Reveal onInView>
                        <div className='header'>{header}</div>
                    </Reveal>
                </StyledColumn>
            </StyledContent>
            <StyledMobileSvgWrapper
                className={`${paddings}`}
            >
                <HomeLine />
            </StyledMobileSvgWrapper>
        </StyledWrapper>
    )
}
