import React, {useRef, memo, useMemo} from 'react';
import {useVideoTexture} from "@react-three/drei";
import {gsap, useGSAP} from "@repo/utils/gsap";
import {COLOR_TRANSITION} from "../../../../styles/globalStyles";
import {PORTAL_LENS_RADIUS} from "@repo/utils/constants";

const Video3D = ({ src = '', autoplay=true, visible=false, cover=false, stencil, ...props }) => {
    const MeshRef = useRef();
    const texture = useVideoTexture(src, { start: visible && autoplay });
    const sizes = useMemo(() => {
        const { videoWidth, videoHeight } = texture.source.data
        const aspect = videoWidth/videoHeight
        let width = PORTAL_LENS_RADIUS * 2
        let height = width / aspect
        if(cover){
             height = PORTAL_LENS_RADIUS * 2
             width = height * aspect
        }
        return { height, width  }
    },[texture, cover])

    useGSAP(() => {
        const opacity = visible ? 1 : 0
        const delay = visible ? COLOR_TRANSITION : 0

        gsap.to(MeshRef.current.material, {
            delay,
            duration: COLOR_TRANSITION,
            opacity
        })
    },{
        dependencies: [visible],
        revertOnUpdate: true
    })



    return (
        <mesh ref={MeshRef}  position={[0,0,-0.1]}>
            <planeGeometry args={[sizes.width, sizes.height, 1]}/>
            <meshBasicMaterial
                map={texture}
                opacity={0}
                depthWrite={false}
                depthTest={false}
                transparent
                toneMapped={false}
                {...stencil}
            />
        </mesh>
    )
};
export default memo(Video3D);
