import styled from 'styled-components';
import {
    getColorScheme,
    getCssSizeValue,
    getPaddingClass,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getFontSize
} from '@repo/utils';
import { ScrollTrigger, gsap, useGSAP } from '@repo/utils/gsap';
import {useRef, useState} from 'react';
import {BREAKPOINTS, LOGO_COLORS} from "../../../styles/themeSettings";
import MockupVideo from "../../assets/video/band-white-horizontal.mp4";
import {useTopBarHeight, useWindowSize, Video} from "../../../index";
import {CURSOR_TYPES} from "@repo/utils/constants";

const StyledWrapper = styled.div`
    overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
	width: 100%;
	height: auto;
	background: ${({theme}) => theme.colors.white};
`;

const StyledRow = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 3;
	gap: 40px;
    background: ${({theme}) => theme.colors.white};
	padding-bottom: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};
	
	&.row-content {
		position: relative;
		justify-content: flex-end;
		min-height: 100svh;
		z-index: 2;
		background: transparent;
	}
    
    &.first-row {
        padding-top: ${({$paddingTop}) => `${$paddingTop * 1.2}px`};
        margin-bottom: ${getCssSizeValue(400)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		gap: 60px;
		padding-bottom: unset;
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
		justify-content: space-between;
        padding-bottom: ${getCssSizeValue(20)};
	}
`

const StyledHeader = styled.div`
	font-weight: 400;
	color: var(--secondary-color);
	--font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    font-size: var(--font-size);
    letter-spacing: calc(var(--font-size) * -0.04);
	line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		--font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		--font-size: ${getFontSize(116)};
		line-height: ${getFontSize(136)};
	}
`

const StyledTextRight = styled.div`
	color: var(--secondary-color);
	font-weight: 400;
	font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	max-width: ${getCssSizeValue(270, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
		max-width: ${getCssSizeValue(360, BREAKPOINTS.TABLET)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		text-align: right;
		font-size: ${getFontSize(24)};
		max-width: ${getCssSizeValue(270)};
	}
`

const StyledVideoWrapper = styled.div`
    position: absolute;
    z-index: 1;
    height: 15svh;
    width: auto;
    top: 25%;
    left: ${getCssSizeValue(17, BREAKPOINTS.MOBILE)};
    aspect-ratio: 220 / 125;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        top: 25%;
        height: 29svh;
        left: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
        aspect-ratio: 536 / 306;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 60svh;
        aspect-ratio: 1110 / 635;
    }
`

const StyledItemsWrapper = styled.div`
`

const StyledItem = styled.div`
    position: relative;
    z-index: 2;
    text-align: right;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.dimmedGray};
    font-weight: 600;
    transition: all .2s;
    
    letter-spacing: -1.6px;
	font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(50, BREAKPOINTS.MOBILE)};
    
    &.active {
        color: var(--secondary-color);
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		letter-spacing: -3px;
		font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		letter-spacing: -4.64px;
		font-size: ${getFontSize(116)};
		line-height: ${getFontSize(100)};
	}
`

const StyledBottomText = styled.div`
    position: relative;
    z-index: 2;
    text-align: right;
    color: ${({theme}) => theme.colors.dimmedGray};
    font-style: italic;
    font-weight: 300;

    letter-spacing: -1.6px;
    font-size: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    line-height: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    margin-top: 80px;
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        letter-spacing: -3px;
        font-size: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
        line-height: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        letter-spacing: -1.92px;
        font-size: ${getCssSizeValue(48)};
        line-height: ${getCssSizeValue(100)};
    }
`

const WhoWeWorkedWith = ({ data }) => {
    const id = getIdWithoutSpecialSymbols(data?.id);
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingClass(data) //TODO: tu chyba powinna być użyta funkcja getPaddingClassFromData
    const header = data?.header || '';
    const textRight = data?.description || ''
    const bottomText = data?.bottomText || ''
    const videoUrl = data?.video?.url || ''
    const videoAlt = data?.video?.alt || ''
    const items = data?.items || []
    const windowSize = useWindowSize()
    const topBarHeight = useTopBarHeight()

    const [activeItem, setActiveItem] = useState(items[0])

    const wrapperRef = useRef(null);
    const firstRow = useRef(null)

    useGSAP(() => {
        const { height } = firstRow.current.getBoundingClientRect()

        ScrollTrigger.create({
            trigger: wrapperRef.current,
            pin: '.first-row',
            start: 'top top',
            end: 'bottom top',
            pinSpacing: false
        })

        ScrollTrigger.create({
            trigger: '.items-wrapper',
            pin: '.video-wrapper',
            start: `top top+=${height}`,
            end: 'bottom top',
            pinSpacing: false,
        })

        const clients = gsap.utils.toArray('.client-element')

        clients.forEach((item, index) => {
            ScrollTrigger.create({
                trigger: item,
                start: `top center`,
                endTrigger: wrapperRef.current,
                onEnter: () => {
                    setActiveItem(items[index])
                },
                onLeaveBack: () => {
                    if (index === 0) {
                        setActiveItem(items[0])
                    } else {
                        setActiveItem(items[index-1])
                    }
                }
            })
        })
    }, {  dependencies: [windowSize.width ,firstRow.current], revertOnUpdate: true })

    return (
        <StyledWrapper
            id={id}
            ref={wrapperRef}
            data-color-scheme={colorScheme}
            data-logo-color={LOGO_COLORS.DARK}
            data-cursor={CURSOR_TYPES.HTML}
        >
            <StyledRow $paddingTop={topBarHeight} ref={firstRow} className={`first-row px-main`}>
                <StyledHeader>{header}</StyledHeader>
                <StyledTextRight>{textRight}</StyledTextRight>
            </StyledRow>
            <StyledRow className='row-content'>
                <StyledVideoWrapper className='video-wrapper'>
                    <Video src={videoUrl ? videoUrl : MockupVideo} autoplay playing  />
                </StyledVideoWrapper>
                <StyledItemsWrapper className='px-main items-wrapper'>
                    {
                        items.map(item => {
                            const text = item?.text || ''
                            const isActive = activeItem === item

                            return (
                                <StyledItem
                                    key={item?.id || index}
                                    className={`client-element ${isActive ? 'active' : ''}`}
                                    dangerouslySetInnerHTML={getHtmlFromRedactorField(text)}
                                />
                            )
                        })
                    }
                    <StyledBottomText>{bottomText}</StyledBottomText>
                </StyledItemsWrapper>
            </StyledRow>
        </StyledWrapper>
    );
};

export default WhoWeWorkedWith;
