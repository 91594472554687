import Chip from "../common/Chip";
import {COLORS, theme} from "../../../styles/themeSettings";

export const ChipsList = ({ data, onClick, selectedValue = [] }) => {
    return (
        <>
            {data.map((item) => {
                const text = item?.text || ''
                const value = item?.value || ''
                const borderColor = item?.color?.cssRgb || theme.colors.white
                const isSelected = typeof selectedValue === 'string' ? selectedValue === value : selectedValue.includes(value)
                return (
                    <Chip onClick={() => onClick(value)} isSelected={isSelected} borderColor={borderColor} color={COLORS.white} key={item.id}>{text}</Chip>
                )
            })}
        </>
    );
}