import React from 'react';
import CommonButton from "./Button";

const LetsTalkButton = ({ onClick = () => {}, pleo='digital', children}) => {
    return (
        <CommonButton onClick={onClick} variant={`lets-talk ${pleo}`}>
            {children}
        </CommonButton>
    );
};

export default LetsTalkButton;
