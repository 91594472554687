import React, {forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import PinnedElement from "./PinnedElement";
import Text from "../common/Text";
import styled from "styled-components";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, partition} from "@repo/utils";
import { gsap } from "@repo/utils/gsap";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Reveal, useIsDesktop} from "../../../index";
import {REVEAL_CLASSNAME} from "../../animations/Reveal";
import {useGSAP} from "@gsap/react";
import {SURPLUS} from "./index";

const Wrapper = styled(PinnedElement)`
    grid-template-columns: 1fr;
    display: grid !important;
    row-gap: ${getCssSizeValue(70)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        grid-template-columns: 1fr 1fr;
    }
`

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: ${getCssSizeValue(21, BREAKPOINTS.MOBILE)};
    margin-top: auto;
    padding: 0 var(--paddingX);
    width: 100%;
    bottom: ${getCssSizeValue(50,BREAKPOINTS.MOBILE)};
    left: 50%;
    translate: -50% 0;
    align-items: center;
    text-align: center;
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        bottom: ${getCssSizeValue(200, BREAKPOINTS.TABLET)};
        padding: 0 ${getCssSizeValue(75,BREAKPOINTS.TABLET)};
        align-items: start;
        text-align: start;
        .${REVEAL_CLASSNAME}-child{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        position: relative;
        height: 100%;
        margin-top: unset;
        padding: unset;
        row-gap: ${getCssSizeValue(21)};
        bottom: unset;
        left: unset;
        translate: unset;
        >div{
            max-width: ${getCssSizeValue(460)};
        }
        
        &:nth-of-type(2), &:nth-of-type(3){
            justify-content: end;
        }
        
        &.right{
            >div{
                margin-left: auto;
            }
        }

        .${REVEAL_CLASSNAME}-child{
            display: flex;
        }
    }
`

const Header = styled(Text)`
    display: flex;
    align-items: center;
    column-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    pointer-events: auto;
    font-size:  ${getFontSize(22, BREAKPOINTS.MOBILE)};
    padding: 5px;
    height: fit-content;
    font-weight: ${({ theme }) => theme?.fontWeight?.light || 200};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin-right: auto;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-right: unset;
        width: 100%;
        font-size:  ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        column-gap: ${getCssSizeValue(30, BREAKPOINTS.DESKTOP)};
    }
`
const Number = styled.div`
    --size: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    display: flex;
    position: relative;
    border-radius: 50%;
    outline: solid 2px var(--secondary-color);
    aspect-ratio: 1;
    width: var(--size);
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme?.fontWeight?.book || 300};
    font-style: normal;
    //line-height: 30px;
    height: var(--size);
    justify-content: center;
    align-items: center;
    padding-top: 2px;


    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        --size: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        margin-right: auto;
    }

    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
        margin-right: unset;
        padding: 1px unset unset;
        font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
    }
`

const Description = styled.div`
    font-size:  ${getFontSize(20, BREAKPOINTS.MOBILE)};
    line-height:  ${getFontSize(36, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px){
        font-size:  ${getFontSize(24, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        font-size:  ${getFontSize(24, BREAKPOINTS.DESKTOP)};
    }
`

const Step = forwardRef(function Step({ data, index, side='left', animation }, fref) {
    const ContainerRef = useRef()
    const number = `0${index + 1}`
    const description = getHtmlFromRedactorField(data?.description || 'Description')
    const isDesktop = useIsDesktop()
    const RevealDynamicProps = useMemo(() => {
        return isDesktop ? ({
            axis: 'x',
            direction: `${side === 'left' ? 'backward' : 'forward'}`
        }) : ({
            axis: 'y',
            direction: 'forward'
        })
    },[isDesktop, side])

    useImperativeHandle(fref, () => ContainerRef.current, [])
    return (
        <Container ref={ContainerRef} className={`${side}`}>
               <Reveal
                   paused
                   timeline={animation}
                   animationVars={{
                       duration: 0.5
                   }}
                   {...RevealDynamicProps}
               >

                   <Header className="italic">
                       <Number>
                           {number}
                       </Number>
                       {data?.header || 'Header'}
                   </Header>
                   <Description dangerouslySetInnerHTML={description}/>
               </Reveal>
        </Container>
    )
})

const Steps = ({ data, steps, endTrigger}) => {
    const [top, bottom] = useMemo(() => partition(steps, (el,index) => index % 2 === 0),[steps])
    const length = useMemo(() => steps.length + SURPLUS, [steps])
    const StepsRef = useRef(new Array(steps.length))
    const isDesktop = useIsDesktop()
    const animations = useMemo(
        () => [...Array(length)].map(() =>
            gsap.timeline({ paused: true }))
        , [length])
    const header = getHtmlFromRedactorField(data?.header || '')

    useGSAP(() => {
        if(isDesktop){
            const tl = animations[length - 1]
            const [left, right] = partition(StepsRef.current, (item, index) => index <= 1)
            tl
                .to(left, {
                    xPercent: 10,
                    // ease: 'none'
                },0)
                .to(right, {
                    xPercent: -10,
                    // ease: 'none'
                },0)
        }

    },{
        dependencies: [animations, length, isDesktop],
        revertOnUpdate: true
    })

    return (
        <Wrapper endTrigger={endTrigger} animations={animations} offset={isDesktop ? 0.25 : 0.05}>
                <Reveal
                    paused
                    timeline={animations[0]}
                    className="header centered-in-blob"
                >
                    <Text
                        as="div"
                        dangerouslySetInnerHTML={header}
                    />
                </Reveal>
                {top.map((step, index) =>
                    <Step
                        ref={(fref) => StepsRef.current[step.index] = fref}
                        key={step?.id || step?.index || index}
                        data={step}
                        index={step.index}
                        side={index % 2 === 0 ? 'left' : 'right'}
                        animation={animations[step.index + 1]}
                    />
                )}
                {bottom.map((step, index) =>
                    <Step
                        ref={(fref) => StepsRef.current[step.index] = fref}
                        key={step?.id || step?.index || index}
                        data={step}
                        index={step.index}
                        side={index % 2 === 0 ? 'left' : 'right'}
                        animation={animations[step.index + 1]}
                    />
                )}
        </Wrapper>
);
};

export default Steps;
