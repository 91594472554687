import React, { useCallback, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import CardsSlider from "../common/CardsSlider";
import Slogan from "./Slogan";
import { getIdWithoutSpecialSymbols, getPaddingsClassFromData, partition, setCssVars} from "@repo/utils";
import PortalLens from "../common/StaticLens/PortalLens";
import BlobPortal from "./Blob";
import useInView from "../../hooks/useInView";
import Transition from "../common/StaticLens/Transition";
import {ScrollTrigger, useGSAP} from "@repo/utils/gsap";
import {useIsDesktop, useWindowSize} from "../../../index";


const Wrapper = styled.div`
    width: 100%;
    overflow: hidden;
    min-height: 100svh;
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--secondary-color);
    transition: color var(--color-transition-duration) var(--ease);
`

export const ID = 'blob-slogan'

export const TARGET_ID = 'blob_slogan_target'
const BlobSlogan = ({ data }) => {
    const id = `${ID}-${data?.id || ''}`
    const wrapperId = getIdWithoutSpecialSymbols(data?.id || '')
    const servicesPages = data?.servicesPages || []
    const VideoRef = useRef()
    const [showVideo, setShowVideo] = useState(false)
    const isDesktop = useIsDesktop()
    const windowSize = useWindowSize()
    const [firstSlogan, rest] = useMemo(() => partition(data?.slogans || [], (el, index) => index === 0), [data])
    const paddings = getPaddingsClassFromData(data)
    const { ref: InViewRef, inView } = useInView({
        id: ID,
        trigger: {
            start: 'top center',
            end: 'bottom bottom'
        },
        once: false,
        pinSpacing: false
    })

    const Step1ProgressRef = useRef(0)
    const Step2ProgressRef = useRef(0)
    const context = useMemo(() => ({
        step1: Step1ProgressRef,
        step2: Step2ProgressRef,
        lensVisible: inView,
    }), [inView])

    const updateStep1 = useCallback(({ progress }) => {
        context.step1.current = progress
        // context.step1.current = clamp((progress * 2) - 1.0, 0.0, 1.0)
    },[context])

    const updateStep2 = useCallback(({ progress }) => {
        // context.step1.current = progress
        context.step2.current = progress
    },[context])


    useGSAP(() => {
        if(!isDesktop){
            ScrollTrigger.create({
                id: `pin-video-${ID}`,
                trigger: InViewRef.current,
                start: 'top top',
                end: 'bottom bottom',
                pin: VideoRef.current,
                onEnter: () => {
                    setShowVideo(true)
                },
                onEnterBack: () => {
                    setShowVideo(true)
                },
                onLeave: () => {
                    setShowVideo(false)
                },
                onLeaveBack: () => {
                    setShowVideo(false)
                },
                onUpdate: () => {
                    setCssVars(VideoRef.current, { 'pg-step1': context.step1.current })
                    setCssVars(VideoRef.current, { 'pg-step2': context.step2.current })
                }
            })
        }
    }, {
        dependencies: [isDesktop, windowSize.width],
        revertOnUpdate: true,
    })

    return (
            <Wrapper
                ref={InViewRef}
                key={id}
                id={wrapperId}
                className={`${paddings}`}
                data-color-scheme={THEME_COLOR_SCHEMES.PLEO_EXTRA}
            >
                <Slogan id={TARGET_ID} data={firstSlogan[0]} onUpdate={updateStep1} />
                <Transition id={ID} start="bottom" onUpdate={updateStep2}/>
                {rest.map((slogan, index) =>
                    <Slogan key={slogan?.id || index} data={slogan} />
                )}
                <CardsSlider id={ID} data={servicesPages}/>
                <PortalLens
                    visible={inView}
                    mobile={{
                        ref: VideoRef,
                        visible: showVideo,
                        autoplay: showVideo,
                        className: ID
                    }}
                    context={context}
                >
                    <BlobPortal key={ID} />
                </PortalLens>
            </Wrapper>
    );
};

export default BlobSlogan;
