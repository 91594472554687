import {createGlobalStyle, css} from 'styled-components';
import localFont from 'next/font/local';
import { EASE } from '@repo/utils/constants';
import { duration } from '@repo/utils/gsap'
import {REVEAL_CLASSNAME} from "../src/animations/Reveal";
import {OVERLAY_CLASS} from "../src/components/common/Portal";

export const COLOR_TRANSITION = duration; //sec
export const Glober = localFont({
	src: [
		{
			path: '../public/fonts/Glober/GloberThin.woff',
			weight: '100',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberLight.woff',
			weight: '200',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberBook.woff',
			weight: '300',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberRegular.woff',
			weight: '400',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberSemiBold.woff',
			weight: '600',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberBold.woff',
			weight: '700',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberHeavy.woff',
			weight: '800',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberBlack.woff',
			weight: '900',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'normal',
		},
		{
			path: '../public/fonts/Glober/GloberThinItalic.woff',
			weight: '100',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'italic',
		},
		{
			path: '../public/fonts/Glober/GloberLightItalic.woff',
			weight: '200',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'italic',
		},
		{
			path: '../public/fonts/Glober/GloberBookItalic.woff',
			weight: '300',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'italic',
		},
		{
			path: '../public/fonts/Glober/GloberRegularItalic.woff',
			weight: '400',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'italic',
		},
		{
			path: '../public/fonts/Glober/GloberSemiBoldItalic.woff',
			weight: '600',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'italic',
		},
		{
			path: '../public/fonts/Glober/GloberHeavyItalic.woff',
			weight: '800',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'italic',
		},
		{
			path: '../public/fonts/Glober/GloberBlackItalic.woff',
			weight: '900',
			display: 'swap',
			fontDisplay: 'swap',
			style: 'italic',
		},
	],
});

const GlobalStyle = createGlobalStyle`
  :root {
	--ease: cubic-bezier(${EASE.join(', ')});
    --cursor-border-color: rgb(28, 28, 28);
	--color-transition-duration: ${COLOR_TRANSITION}s;
    --transition-duration: 0.75s;
    --font: ${Glober?.style?.fontFamily};
	--leva-sizes-rootWidth: 400px !important;
	--typo-size: 1;
  }
  
  html.lenis {
    height: auto;
    pointer-events: auto !important;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }

  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }

  .lenis.lenis-stopped {
    overflow: hidden;
  }

  .lenis.lenis-scrolling iframe {
    pointer-events: none !important;
  }

  .ScrollRig-visibilityHidden {
    visibility: hidden;
  }

  .ScrollRig-transparentColor {
    color: transparent !important;
  }
  
  
  html {
    caret-color: rgba(0, 0, 0, 0);
  }

  html, body, #__next {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: var(--font);
	//disabled, caused issues with scroll rig on different browsers
	scrollbar-width: none;
  	//scrollbar-color: var(--tertiary-color) transparent;
	  
  }
  
  
  
  body {
  	display: block;
	-webkit-tap-highlight-color: transparent !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
  	--project-color: unset;
	background-color: var(--project-color, var(--primary-color));
	transition: background-color var(--color-transition-duration) var(--ease);
  	will-change: background-color;
  }

  .ril-caption-content {
	  width: 100%;
  }
  
  .translate-y-100 {
	  transform: translateY(100%);
  }

	//::view-transition-old(root) {
	//    animation: none;
	//}
	//
	//::view-transition-new(root) {
	//    animation: none;
	//}

    *, *::before, *::after {
      box-sizing: border-box;
    }

    textarea:focus, input:focus {
      outline: none;
    }

    h1, h2, h3, h4, h5, h6, p, ul, figure {
      margin: 0;
      padding: 0;
    }

    button {
      font-family: Glober, sans-serif;
      margin: 0;
      padding: 0;
    }

	  .${OVERLAY_CLASS}{
		  position: fixed;
		  inset: 0;
		  z-index: 1000; //wchui
		  background-color: rgba(125,125,125,0.5);
	  }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
	
	.${REVEAL_CLASSNAME}-parent{
		&.axis-y {
			overflow-y: hidden;
		}
		&.axis-x {
			overflow-x: hidden;
		}
	}

  .full-width{
	  width: calc(100% + 2 * var(--paddingX)) !important;
	  margin: 0 calc(-1 * var(--paddingX));
  }
	
    .underline-animation {
      &:before {
        position: absolute;
        width: 100%;
        height: 1px;
        top: 100%;
        left: 0;
        pointer-events: none;
        content: '';
        transform-origin: 100% 50%;
        transform: scale3d(0, 1, 1);
        transition: transform 0.3s;
		background: var(--tertiary-color, currentColor);
      }

      &.active {
        &:before {
          transform-origin: 0 50%;
          transform: scale3d(1, 1, 1);
        }
      }
		
		&:not(&.no-hover){
			&:hover {
				&:before {
					transform-origin: 0 50%;
					transform: scale3d(1, 1, 1);
				}
			}

		}

    }

  .underline-animation-lowercase {
	  &:before {
		  position: absolute;
		  width: 100%;
		  height: 1px;
		  bottom: 13%;
		  left: 0;
		  pointer-events: none;
		  content: '';
		  transform-origin: 100% 50%;
		  transform: scale3d(0, 1, 1);
		  transition: transform 0.3s;
		   }

	  &.active {
		  &:before {
			  transform-origin: 0 50%;
			  transform: scale3d(1, 1, 1);
		  }
	  }

	  &:hover {
		  &:before {
			  transform-origin: 0 50%;
			  transform: scale3d(1, 1, 1);
		  }
	  }


  }
`;

export const multilineUnderlineCss = css`
	.multiline-underline-animation {
		display: inline;
		background:linear-gradient(var(--secondary-color),var(--secondary-color)) left bottom no-repeat;
		background-size:0% 1px;
		text-decoration: none;
		transition: all var(--transition-duration) var(--ease);

		&:hover {
			background-size:100% 1px;
		}
	}
`
export default GlobalStyle;
