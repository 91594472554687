import React, { memo, useEffect, useMemo, useState} from 'react';
import {createPortal as createPortal3D, useFrame, useLoader, useThree} from '@react-three/fiber';
import {useFBO} from '@react-three/drei';
import {easing} from 'maath';
import {Quaternion, Scene, Vector2, Vector3} from 'three';
import {useControls} from 'leva';
import {AFTER_SIZE, CURSOR_TYPES} from '@repo/utils/constants';
import {useRouter} from 'next/router';
import { setCssVars } from '@repo/utils';
import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import {transform} from "framer-motion";
import {useSelector} from "react-redux";
import  {useCursor} from "../Cursor/Cursor";
import { EquirectangularReflectionMapping } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import lookRotation from "@repo/utils/lookRotation";
import dynamic from "next/dynamic";
import {useForwardedThree} from "./Canvas";
import {useTheme} from "styled-components";

// const envMapSource = '/images/envMap_1.hdr';
// const envMapSource = '/images/mud_road_puresky_1k.hdr';
// const envMapSource = '/images/blue_lagoon_night_1k.hdr';
// const envMapSource = '/images/studio_small_01_1k.hdr';
// const envMapSource = '/images/studio_small_04_1k.hdr';
// const envMapSource = '/images/unfinished_office_night_1k.hdr';
// const envMapSource = '/images/surgery_1k.hdr';
// const envMapSource = '/images/st_peters_square_night_1k.hdr';
// const envMapSource = '/images/canary_wharf_1k.hdr';
// const envMapSource = '/images/hospital_room_2_1k.hdr';
// const envMapSource = '/images/sunflowers_puresky_1k.hdr';
// const envMapSource = '/images/autumn_field_puresky_1k.hdr';
const envMapSource = '/images/pizzo_pernice_puresky_1k.hdr';
// const envMapSource = '/images/hangar_interior_1k.hdr';
// const envMapSource = '/images/metro_noord_1k.hdr';

const Blob = dynamic(() => import('./Blob/Blob'), {
	loading: () => {},
	ssr: false
});
const FboBackground = dynamic(() => import('./FboBackground'), {
	loading: () => {},
	ssr: false
});
const StaticLens = dynamic(() => import('./Portal/StaticLens'), {
	loading: () => {},
	ssr: false
});

const Lens = ({ children }) => {
	const buffer = useFBO({ stencilBuffer: true });
	const Cursors = useCursor()
	const { target } = Cursors
	const forwardThree = useForwardedThree()
	const viewport = useThree((state) => state.viewport);
	const screenSize = useThree((state) => state.size);
	const pointer = useThree((state) => state.pointer);
	const [scene] = useState(() => new Scene());

	const [blobPosVector] = useState(() => new Vector3((pointer.x * viewport.width) / 2, (pointer.y * viewport.height) / 2, 3));
	const [blobRotation] = useState(() => new Quaternion());
	const [cursorPosVector] = useState(() => new Vector2((pointer.x * screenSize.width) / 2, (pointer.y * screenSize.height) / 2));
	const [cursorAfterPosVector] = useState(() => new Vector2((pointer.x * screenSize.width) / 2, (pointer.y * screenSize.height) / 2));
	const [mouseVector] = useState(() => new Vector2(0.5, 0.5));
	const [afterSticky, setAfterSticky] = useState(true);
	const { asPath } = useRouter();

	const EnvTexture = useLoader(RGBELoader, envMapSource);
	EnvTexture.mapping = EquirectangularReflectionMapping;;

	const isMenuOpen = useSelector(state => state?.menu?.open || false)
	const isBlobVisible = useMemo(() => {
		return asPath.split('/').length <= 2 && !isMenuOpen
	}, [asPath, isMenuOpen]);
	const theme = useTheme()

	const [displacementPower, setDisplacementPower] = useState(1);
	const { moveDamping, scaleDamping } = useControls('Blob', {
		moveDamping: {
			value: 0.15,
			step: 0.01,
			min: 0,
			max: 1,
		},
		scaleDamping: {
			value: 0.2,
			step: 0.01,
			min: 0,
			max: 1,
		},
	});
	const targetBlobHide = !!target.el && target.blobScale === 0;
	const afterTargetCondition =
		!targetBlobHide
		|| target.type === CURSOR_TYPES.IMAGE
		|| target.type === CURSOR_TYPES.HIDE
		|| target.type === CURSOR_TYPES.HTML
		|| target.type === CURSOR_TYPES.SLIDER
		|| target.type === CURSOR_TYPES.SLIDER_OPEN
		|| target.type === CURSOR_TYPES.MARQUEE
		|| target.type === CURSOR_TYPES.MARQUEE_EXPANDED
		|| target.type === CURSOR_TYPES.CHECK
		|| target.type === CURSOR_TYPES.PILL
		|| target.type === CURSOR_TYPES.CLOSE
		|| target.type === CURSOR_TYPES.SOON

	Cursors.lens.fbo = buffer

	useThree(({ scene, gl }) => {
		scene.environment = EnvTexture;
		scene.environmentIntensity = 0.25
	})


	useFrame((state, delta) => {
		//cursor position in pixels

		cursorPosVector.set((state.pointer.x * state.size.width) / 2, (state.pointer.y * state.size.height) / 2);
		Cursors.position.copy(cursorPosVector)
		//position after cursor
		const cursorAfterTarget = afterTargetCondition ? cursorPosVector : target.position
		const distanceAfter = cursorAfterPosVector.distanceTo(cursorAfterTarget).toFixed(5); //toFixed to avoid floating point errors;
		if (!targetBlobHide) {
			//if the cursor is not on an element, we check the distance between the cursor and the target
			if (distanceAfter < 1) {
				//if the distance is less than 1px, we consider the cursor to sticky
				setAfterSticky(true);
			}
		} else {
			//if the cursor is on an element, we consider it not sticky
			setAfterSticky(false);
		}
		const stickyLerpPower = isBlobVisible ? 0 : moveDamping;
		const cursorAfterLerpPower = !targetBlobHide ? (afterSticky ? stickyLerpPower : moveDamping) : moveDamping;
		easing.damp2(cursorAfterPosVector, cursorAfterTarget, cursorAfterLerpPower, delta);
		setCssVars(Cursors.after.current, { x: `${cursorAfterPosVector.x}px`, y: `${cursorAfterPosVector.y}px` });
		Cursors.after.position.copy(cursorAfterPosVector)
		// Rotate cursor
		const afterDistance = cursorAfterTarget.clone().sub(cursorAfterPosVector)
		const angleTarget = target.el || isBlobVisible ? 0 : Math.atan2(afterDistance.x, afterDistance.y)
		const width = target.bounds?.width || AFTER_SIZE * 20;
		const height = target.bounds?.height || AFTER_SIZE * 20;
		let newScaleY = transform(distanceAfter * 0.2, [0, width / 2], [1, 1.1])
		let newScaleX = transform(distanceAfter * 0.2, [0, height / 2], [1, 0.8])
		setCssVars(Cursors.after.current, { stickyCursorScaleX: newScaleX, stickyCursorScaleY: newScaleY, stickyCursorRotationAngle: `${angleTarget}rad` })

		if (Cursors.lens.current) {
			//position the blob
			const { position, scale, rotation, material } = Cursors.lens.current;
			const viewport = state.viewport.getCurrentViewport(state.camera, [0, 0, 3]);
			blobPosVector.set((state.pointer.x * viewport.width) / 2, (pointer.y * viewport.height) / 2, 3);
			easing.damp3(position, blobPosVector, moveDamping || 0, delta);
			const distance = position.distanceTo(blobPosVector);

			const WorldSpace3DMouse = {
			    x: (position.x || 0) / viewport.width * 2,
			    y: (position.y || 0) / viewport.height * 2
			}

			//normalized, with top right corner being [1,1]
			mouseVector.set(
				(WorldSpace3DMouse.x + 1) / 2, //[0,1]
				(WorldSpace3DMouse.y + 1) / 2  //[0,1]
			)
			forwardThree.mouse2 = mouseVector
			forwardThree.viewport2 = viewport
			// console.log(state.pointer, mouseVector);


			if(target.blobScale > 0){
				material.uniforms.stretch.value = transform(distance, [0, 5], [0, 0.8])
				const lookRotation1 = lookRotation(position, blobPosVector);
				blobRotation.slerp(lookRotation1, 0.1)
				rotation.setFromQuaternion(blobRotation)
			}

			//scale the blob
			const targetScale = afterSticky && isBlobVisible ? (distance / 5 + 1) * target.blobScale : 0;
			easing.damp3(scale, targetScale, scaleDamping, delta);

		}

		//render 3D scene
		state.gl.setRenderTarget(buffer);
		state.gl.render(scene, state.camera);
		state.gl.setRenderTarget(null);
	});

	useEffect(() => {
		if (target.type === CURSOR_TYPES.HIDE) {
			setDisplacementPower(0);
		} else {
			setDisplacementPower(1);
		}
	}, [target]);


	//TODO: Remove After Sticky & move this effect ot html cursor
	useIsomorphicLayoutEffect(() => {
		let size = {
			width: isBlobVisible ? AFTER_SIZE : AFTER_SIZE * 20,
			height: isBlobVisible ? AFTER_SIZE : AFTER_SIZE * 20,
		}
		let scale = isBlobVisible && afterSticky ? 0 : 1
		if(target.el){
			size = { ...target.size }
			scale = target.scale
		}
		setCssVars(Cursors.after.current, { width: `${size.width}px`, height: `${size.height}px`, scale });
	}, [isBlobVisible, afterSticky, target]);


	return (
			<>
				{createPortal3D(children, scene)}
				<FboBackground/>
				<Blob displacementPower={displacementPower}/>
				<StaticLens/>
				{/*<Environment preset="studio" environmentIntensity={0.3}  />*/}
				{/*<directionalLight*/}
				{/*	intensity={10}*/}
				{/*	distance={5}*/}
				{/*/>*/}
				{/*<ambientLight color={theme.colors.twilightBlue} intensity={1}/>*/}
			</>
	);
};



export default memo(Lens);
