import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import styled from 'styled-components';
import CommonButton from "../common/Button";
import Text from "../common/Text";
import useColor from "../../hooks/useColor";
import { useGSAP, gsap } from "@repo/utils/gsap";
import {ProjectBanner, useIsomorphicLayoutEffect, useWindowSize} from "../../../index";
import {getFontSize, setCssVars} from "@repo/utils";
import {BREAKPOINTS, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";


const Wrapper = styled.div`
    position: relative;
    --bg-color: ${({$bgColor, theme}) => $bgColor || theme?.colors?.lightGray || 'var(--primary-color)'};
    --text-color: ${({$textColor, theme}) => $textColor || theme?.colors?.black || 'var(--secondary-color)'};
    background: var(--bg-color);
    
    .text {
        color: var(--text-color);
    }
    z-index: 29; //just below page transition curtain
    overflow: hidden;

    .project-banner {
        transform: translateY(10%);
    }
`
const Tag = styled(Text)`
    --font-size: ${getFontSize(16,BREAKPOINTS.MOBILE)};
    
    position: absolute;
    top: var(--padding-top);
    left: var(--px-main);
    
    font-size: var(--font-size);
    letter-spacing: calc(var(--font-size) * -0.04);

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        --font-size: ${getFontSize(16,BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getFontSize(18,BREAKPOINTS.DESKTOP)};
    }
`

const Button = styled(CommonButton)`
    --offset:0;
    position: absolute;
    left: var(--px-main);
    transform: translateY(calc(var(--offset) + var(--row-gap) * 5));
    //--supp-color: var(--text-color) !important;
    //--color: var(--bg-color) !important;
    //
    //&:hover{
    //    --supp-color: transparent !important;
    //    --color: var(--text-color) !important;
    //}

    --supp-color: transparent !important;
    --color: var(--text-color) !important;

    &:hover{
        --supp-color: var(--text-color) !important;
        --color: var(--bg-color) !important;
    }
`

const NextProject = forwardRef(
    function NextProject({
                data,
                bgColor: bgCl,
                textColor: textCl,
                onButtonClickStart = (slug)=> {},
                onButtonClickComplete = (slug)=> {}
            }, fref)
    {
    const { tag =  'Next story', button= { text: 'Button', slug: '#' }, ...banner } = data
    const bgColor = useColor(bgCl)
    const textColor = useColor(textCl)
    const slug = `/projects/${button.slug}`
    const WrapperRef = useRef()
    const BannerRef = useRef()
    const ButtonRef = useRef()
    const TagRef = useRef()
    const { contextSafe } = useGSAP({scope: BannerRef.current });
    const windowSize = useWindowSize()

    useIsomorphicLayoutEffect(() => {
        const desc = BannerRef.current?.children?.[1]?.children?.[0]?.children?.[0]
        if(desc){
            const bounds = desc.getBoundingClientRect()
            setCssVars(ButtonRef.current, { offset: `${bounds.height / 2}px`})
        }
    }, [windowSize.width]);

    const onClick = contextSafe(() => {
        //TODO: remove if not needed
        //code for gradient backgrounds
        // gsap.to(WrapperRef.current, {
        //     '--bg-opacity': 1,
        // })
        gsap.to(ButtonRef.current, {
            autoAlpha: 0,
            pointerEvents: 'none'
        })
        gsap.to(TagRef.current, {
            autoAlpha: 0,
            pointerEvents: 'none'
        })
        gsap.to(BannerRef.current, {
            y: 0,
            onStart: () => {
                if(typeof onButtonClickStart === 'function') onButtonClickStart(slug)
            },
            onComplete: () => {
                if(typeof onButtonClickComplete === 'function') onButtonClickComplete(slug)
            }
        })
    });

    useImperativeHandle(fref, () => WrapperRef.current, [])

    return (
        <Wrapper
            ref={WrapperRef}
            $bgColor={bgColor}
            $textColor={textColor}
            data-color-scheme={THEME_COLOR_SCHEMES.PLEO_MAIN}
        >
            <ProjectBanner ref={BannerRef} data={banner} animationVars={{
                delay: 0
            }}>
                <Tag ref={TagRef}>
                    {tag}
                </Tag>
                <Button ref={ButtonRef} onClick={onClick} >
                    {button.text}
                </Button>
            </ProjectBanner>
        </Wrapper>
    );
})

export default NextProject;
