import React from 'react';
import styled from 'styled-components';
import TextWithBreaks from "../common/TextWithBreaks";
import {getColorScheme, getCssSizeValue, getFontSize, getPaddingsClassFromData} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Reveal from "../../animations/Reveal";

const StyledTextWithBreaksWithoutItalicWrapper = styled.div`
`

const Text = styled(TextWithBreaks)`
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
    font-weight: 400;
    padding: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)} 0;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding: ${getCssSizeValue(50, BREAKPOINTS.TABLET)} 0;
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding: ${getCssSizeValue(160)} 0;
        font-size: ${getFontSize(116)};
        line-height: ${getFontSize(136)};
        letter-spacing: -4.6px;
    }
    .italic {
        font-style: normal;
    }
`
const TextWithBreaksWithoutItalic = ({data, textModuleId}) => {
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)
    return (
        <StyledTextWithBreaksWithoutItalicWrapper data-color-scheme={colorScheme} id={textModuleId} className={`${paddings} px-main`}>
            <Reveal onInView>
                <Text data={data}/>
            </Reveal>
        </StyledTextWithBreaksWithoutItalicWrapper>
    );
};

export default TextWithBreaksWithoutItalic;
