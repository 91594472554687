import {createContext} from "react";

export const GlobalsContext = createContext({});

const GlobalsProvider = ({ data = {}, children }) => {
    return (
        <GlobalsContext.Provider value={data}>
            {children}
        </GlobalsContext.Provider>
    )
}

export default GlobalsProvider