import React, { useImperativeHandle, useRef } from 'react';
import MeshBlobMaterial from './MeshBlobMaterial';
import { useControls } from 'leva';
import useVisible3D from '../../../hooks/useVisible3D';
import {BLOB_RADIUS} from "@repo/utils/constants";
import {useCursor} from "../../Cursor/Cursor";



export const defaults = {
	ior: 1.3,
	thickness: 0.2,
	anisotropy: 0,
	anisotropicBlur: 0,
	chromaticAberration: 0.05,
	distortion: 0.1,
	distortionScale: 1,
	temporalDistortion: 0.1,
	displacement: {
		power: {
			min: 0.01,
			max: 1,
		},
		speed: {
			min: 0.1,
			max: 1.5,
		},
		smoothTime: 0.5,
	},
};

// eslint-disable-next-line no-redeclare
const Blob = ({ displacementPower: dp = 1, visible = true }) =>  {
	const MeshRef = useRef()
	const MaterialRef = useRef();
	const lens = useCursor(((state) => state.lens))

	const visible3D = useVisible3D(visible);
	const {
		ior,
		thickness,
		anisotropy,
		chromaticAberration,
		anisotropicBlur,
		distortion,
		distortionScale,
		temporalDistortion,
		displacementPower,
		displacementSpeed,
		displacementSmoothTime,
	} = useControls('Blob', {
		ior: {
			value: defaults.ior,
			step: 0.1,
			min: 0,
			max: 3,
		},
		thickness: {
			value: defaults.thickness,
			step: 0.1,
			min: 0,
			max: 3,
		},
		anisotropy: {
			value: defaults.anisotropy,
			step: 0.01,
			min: 0,
			max: 1,
		},
		anisotropicBlur: {
			value: defaults.anisotropicBlur,
			step: 0.01,
			min: 0,
			max: 1,
		},
		chromaticAberration: {
			value: defaults.chromaticAberration,
			step: 0.01,
			min: 0,
			max: 1,
		},
		distortion: {
			value: defaults.distortion,
			step: 0.1,
			min: 0,
			max: 3,
		},
		distortionScale: {
			value: defaults.distortionScale,
			step: 0.1,
			min: 0,
			max: 3,
		},
		temporalDistortion: {
			value: defaults.temporalDistortion,
			step: 0.1,
			min: 0,
			max: 3,
		},
		displacementPower: {
			value: 0.2,
			step: 0.01,
			min: defaults.displacement.power.min,
			max: defaults.displacement.power.max,
		},
		displacementSpeed: {
			value: 0.2,
			step: 0.01,
			min: defaults.displacement.speed.min,
			max: defaults.displacement.speed.max,
		},
		displacementSmoothTime: {
			value: defaults.displacement.smoothTime,
			step: 0.1,
			min: 0.1,
			max: 5,
		},
	});

	useImperativeHandle(lens, () => MeshRef.current, [])

	return (
		<mesh
			ref={MeshRef}
			position={[0, 0, 3]}
			visible={visible3D}
		>
			<icosahedronGeometry args={[BLOB_RADIUS, 10]} />
			<MeshBlobMaterial
				ref={MaterialRef}
				buffer={lens.fbo?.texture}
				ior={ior}
				thickness={thickness}
				anisotropy={anisotropy}
				anisotropicBlur={anisotropicBlur}
				chromaticAberration={chromaticAberration}
				distortion={distortion}
				distortionScale={distortionScale}
				temporalDistortion={temporalDistortion}
				displacementPower={displacementPower * dp}
				displacementSpeed={displacementSpeed}
				displacementSmoothTime={displacementSmoothTime}
				// envMapIntensity={0.5}
			/>
			{/*<meshBasicMaterial ref={MaterialRef} color="red"/>*/}
			{/*<shaderMaterial fragmentShader={fragmentShader} vertexShader={vertexShader} uniforms={uniforms} />*/}
		</mesh>
	);
};

export default Blob;
