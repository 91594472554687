import React from 'react';
import styled from "styled-components";
import { getHtmlFromRedactorField } from "@repo/utils";
import CommonImage from "./Image";
import Text from "./Text";
import {Asset as CommonAsset} from "./SingleAsset";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--secondary-color);
    text-align: right;
    row-gap: 10px;
    
    .text{
        line-height: 24px;
        font-weight: ${({ theme }) => theme?.fontWeight?.book || 300};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        .text{
            line-height: 34px;
        }
    }
    
`

// eslint-disable-next-line no-redeclare
const Asset = styled(CommonAsset)`
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
`

const TextSmallAsset = ({ data, className='', ...props }) => {
    const { text, asset, id } = data
    const redactor = getHtmlFromRedactorField(text)
    return (
        <Wrapper  className={`${className} text-small-asset`} {...props}>
            <Asset id={id} asset={asset?.asset || {}} sizes="80px" />
            <Text as="div" dangerouslySetInnerHTML={redactor}/>
        </Wrapper>
    );
};

export default TextSmallAsset;
