import React, {useMemo} from 'react';
import {BREAKPOINTS, LOGO_COLORS} from '../../../styles/themeSettings';
import TextWithBreaks from "../common/TextWithBreaks";
import Banner from "./Banner";
import styled from "styled-components";
import {getCssSizeValue, getFontSize} from "@repo/utils";

const Wrapper = styled(Banner)`
	--circle-size: ${getFontSize(32, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		--circle-size: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		--circle-size: ${getCssSizeValue(76, BREAKPOINTS.DESKTOP)};
		justify-content: center;
	}
`


const Text3DBanner = ({ data, className='', children, webGL=false, ...props }) => {
	const header = data?.header || {}
	const text = { parentId: data?.text?.id || '', ...data?.text?.text }
	const variant = data?.variant || 'light'
	const color = useMemo(() => {
		if(variant === 'dark') return 'white'
		return 'secondary'
	},[variant])
	const logoColor = useMemo(() => {
		return variant === 'light' ? LOGO_COLORS.DARK : LOGO_COLORS.LIGHT
	}, [variant])

	const textData = text?.parentId?.length > 0 ? text : header
	return (
		<Wrapper className={`${className} text-3d-banner`} data-logo-color={logoColor} {...props} >
			<TextWithBreaks data={textData} color={color} webGL={webGL} imageLoadPriority />
			{children}
		</Wrapper>
	);
};

export default Text3DBanner;
