import {COLORS, THEME_MODES} from "../../styles/themeSettings";
import {useMemo} from "react";
import {useExtendedTheme} from "../components/Theme";

export default function useMenuColor(invert = false) {
    const extendedTheme = useExtendedTheme()
    return useMemo(() => {
        const mode = extendedTheme?.mode || THEME_MODES.LIGHT
        if (invert) {
            return mode !== THEME_MODES.DARK ? COLORS.white : COLORS.twilightBlue
        }

        return mode === THEME_MODES.DARK ? COLORS.white : COLORS.twilightBlue
    }, [extendedTheme.mode])
}
