const glsl = `
float displacement = cnoise(position + vec3(time * displacementSpeed));
vec3 newPosition = position + normal * (displacement * displacementPower);
newPosition.y *= 1.0 - stretch;
newPosition.x *= 1.0 - stretch;
// newPosition.y *= 0.5;
transformed = newPosition;
`

export default glsl
