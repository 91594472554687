//calculate z position of the spherical element to cover the entire screen
//based on element's radius & assuming element is at z = 0
export default function getPositionZ(radius, viewport){
    if(!viewport || !radius) return 0
    const a = viewport.width;
    const b = viewport.height
    const viewportDiagonal = Math.sqrt(a * a + b * b) //Pythagoras bro
    const viewportRadius = viewportDiagonal / 2
    const size = viewportRadius / radius
    return viewport.distance - viewport.distance / size
}
