import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Text from '../../common/Text'
import {getCssSizeValue, getFontSize} from "@repo/utils";
import Reveal, {REVEAL_CLASSNAME} from "../../../animations/Reveal";
import {BREAKPOINTS} from "../../../../styles/themeSettings";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    z-index: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    //top: 40%;
    //transform: translateY(-50%);

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(169, BREAKPOINTS.TABLET)};
        row-gap: ${getCssSizeValue(21, BREAKPOINTS.TABLET)};
        align-items: start;
        top: unset;
        transform: unset;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        row-gap: ${getCssSizeValue(21)};
        width: ${getCssSizeValue(363)};
    }
`
const Number = styled(Text)`
    --font-size: ${getFontSize(120, BREAKPOINTS.MOBILE)};
    color: var(--secondary-color);
    font-weight: 700;
    font-size: var(--font-size);
    line-height: calc(var(--font-size) * 0.78317); 
    //line-height: calc(var(--font-size) * 0.62316); 
    text-align: start;
    //line-height: 242px;
    letter-spacing: calc(var(--font-size) * -0.04);
    margin-bottom: ${getCssSizeValue(43, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        --font-size: ${getFontSize(129, BREAKPOINTS.TABLET)};
        margin-bottom: unset;
        word-break: break-all;
        translate: -20% 0;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getFontSize(309)};
    }
`

const Paragraph = styled(Text)`
    --font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    color: var(--secondary-color);
    font-size: var(--font-size);
    line-height: calc(var(--font-size) * 1.16667);
    width: 70%;
    letter-spacing: calc(var(--font-size) * -0.04);
    text-align: center;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        --font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        text-align: start;
        width: unset;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getFontSize(48)};

    }
`

const Slide = ({ data, pinnedContainer }) => {
    const { number = '', caption = '', description = '' } = data
    return (
        <Wrapper >
                <Number>
                    <Reveal key={data.id} as="span" pinnedContainer={pinnedContainer} animationVars={{
                        delay: 0.3,
                    }}>
                        {number}
                    </Reveal>
                </Number>
                <Paragraph className="light">
                    <Reveal key={data.id} as="span" pinnedContainer={pinnedContainer}  animationVars={{
                        delay: 0.4,
                    }}>
                        {caption}
                    </Reveal>
                </Paragraph>
                <Paragraph className="desc">
                    <Reveal key={data.id} as="span" pinnedContainer={pinnedContainer}  animationVars={{
                        delay: 0.5,
                    }}>
                        {description}
                    </Reveal>
                </Paragraph>
        </Wrapper>
    );
};

export default Slide;
