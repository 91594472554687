import React, {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import {usePleoContext} from "../index";
import Transition from "../../common/StaticLens/Transition";
import CardsSlider from "../../common/CardsSlider";


const ID = 'our-values'

const OurValues = forwardRef(function OurValues({ data,...props }, fref){
    const WrapperRef = useRef()
    const pleoContext = usePleoContext()

    const updateContext = useCallback(({ progress }) => {
        pleoContext.ourValues.current = progress
    },[])

    useImperativeHandle(fref, () => WrapperRef.current, [])

    return (
        <>
            <Transition id={ID} onUpdate={updateContext} />
            <CardsSlider hasFixedId ref={WrapperRef} id={ID} data={data} isDirectElementCardSource />
        </>
    );
});

export default OurValues;
