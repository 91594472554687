import {
    getColorScheme, getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import SingleNumberBlock from "./SingleNumberBlock";
import {Reveal} from "../../../index";

const StyledWrapper = styled.section`
    position: relative;
    color: var(--secondary-color);
`

const StyledHeading = styled.div`
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(92, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: 75%;
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(40, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(130, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 60%;
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(66, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(160, BREAKPOINTS.DESKTOP)};
    }
`

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        grid-template-columns: repeat(2, 1fr);
        gap: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
        & > :nth-child(2n) {
            transform: translateY(${getCssSizeValue(26, BREAKPOINTS.TABLET)});
        }
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
        & > :nth-child(2n) {
            transform: translateY(${getCssSizeValue(42, BREAKPOINTS.DESKTOP)});
        }
    }
`

export default function NumbersModule({ data }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)
    const title = data?.heading || ''
    const numbers = data?.numbers || []

    return (
        <StyledWrapper
            id={id}
            className={`${paddings} px-main`}
            data-color-scheme={colorScheme}
        >
            <Reveal onInView>
                <StyledHeading dangerouslySetInnerHTML={getHtmlFromRedactorField(title)} />
            </Reveal>
            <GridWrapper>
                {numbers.map((item, index) => <SingleNumberBlock item={item} index={index} key={item?.id || ''} />)}
            </GridWrapper>
        </StyledWrapper>
    )
}