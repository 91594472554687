import styled from 'styled-components';
import { CURSOR_TYPES } from "@repo/utils/constants";
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import {getCssSizeValue, getFontSize} from "@repo/utils";
import {useMemo, useState} from "react";

const ChipWrapper = styled.div`
    align-items: center;
    display: flex;
	width: fit-content;
    position: relative;
	border-radius: 100px;
	transition: all 0.2s ease-in-out;
    background: ${({ $backgroundColor }) => $backgroundColor ? $backgroundColor : 'transparent'};
    cursor: pointer;
	border: 1px solid ${({ $borderColor, theme }) => $borderColor ? $borderColor : theme.colors.white};
    color: ${({ $color }) => $color };

    > span {
        display: block;
        position: relative;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        border: solid 1px ${({ $color }) => $color };
    }

    margin-bottom: ${getCssSizeValue(5, BREAKPOINTS.MOBILE)};
    column-gap: 14px;
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
    padding:
            ${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(19, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(14, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(30, BREAKPOINTS.MOBILE)}
    ;
`;

function Chip({ variant, children, onClick, color= theme.colors.white, borderColor = theme.colors.white, isSelected = false, ...props }) {
    const [isHovered, setIsHovered] = useState(false);

    const finalBackgroundColor = useMemo(() => {
        return (isSelected || isHovered) ? borderColor : 'transparent'
    }, [borderColor, isSelected, isHovered])

    const finalColor = useMemo(() => {
        return ((isSelected || isHovered) && borderColor === theme.colors.white) ? theme.colors.twilightBlue : color
    }, [isSelected, color, borderColor, isHovered])

    return (
        <ChipWrapper
            $color={finalColor}
            $backgroundColor={finalBackgroundColor}
            $borderColor={borderColor}
            data-cursor={CURSOR_TYPES.PILL}
            className={variant}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...props}
        >
            {children}
            <span className="dot" />
        </ChipWrapper>
    );
}

export default Chip;
