import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import {COLORS_LIST} from "../../styles/themeSettings";
import {useExtendedTheme} from "../components/Theme";

//returns appropriate color
//example: const color = useColor('primary'); returns 'var(--primary-color)'
//example: const color = useColor('primary', true); returns current color for primary as rgba 'rgba(178, 225, 253, 1)'
//example: const color = useColor('blue'); returns 'rgba(178, 225, 253, 1)'
export default function useColor(color = '', strict=false) {
	const theme = useTheme();
	const extendedTheme = useExtendedTheme()
	return useMemo(() => {
		if (Object.values(COLORS_LIST).includes(color)) {
			if(strict){
				return extendedTheme?.palette[color]
			}
			return `var(--${color}-color)`;
		}
		return theme?.colors[color] || color;
	}, [theme, extendedTheme, color, strict]);
}
