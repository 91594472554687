import React, { useRef} from 'react';
import styled from 'styled-components';
import {
	getColorScheme,
	getCssSizeValue,
	getFontSize,
	getHtmlFromRedactorField,
	getPaddingsClassFromData,
	removeTags
} from '@repo/utils';
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Reveal from "../../animations/Reveal";
import {useLinesReveal} from "../../animations/LinesReveal";

const StyledHeaderDescriptionHalfWidthWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	position: relative;
	transition: color var(--color-transition-duration) var(--ease);
	color: var(--secondary-color);

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: calc(50% + var(--paddingX));
	}
`;

const StyledHeader = styled.div`
	width: 100%;
	//color: white;
	color: var(--secondary-color);
	transition: color var(--color-transition-duration) var(--ease);
	font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
	font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)}; 
	margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.MOBILE)};
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
		margin-bottom: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
    }
`;

const StyleDescription = styled.div`
	width: 100%;
	//color: white;
	color: var(--secondary-color);
	transition: color var(--color-transition-duration) var(--ease);
	font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
	font-weight: 300;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
	}
	p{
		overflow: hidden; 
	}
	a {
		text-decoration: underline;
		text-decoration-color: var(--secondary-color);
		text-decoration-style: solid;
		text-decoration-thickness: 1px;
		color: var(--secondary-color);
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
		font-weight: 300;
		@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
		}
	}
`;

const HeaderDescriptionHalfWidth = ({ data, textModuleId }) => {
	const header = data?.header || '';
	const description = data?.description || '' ;
	// const redactor = getHtmlFromRedactorField(description);
	const redactor = getHtmlFromRedactorField(removeTags(description, ['p', 'span'])); //tag removing so animation works fine
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	const WrapperRef = useRef()
	const DescRef = useRef()

	// useLinesReveal({
	// 	el:DescRef,
	// 	onInView: true,
	// 	disabled: !description
	// })


	return (
		<StyledHeaderDescriptionHalfWidthWrapper
			id={textModuleId}
			data-color-scheme={colorScheme}
			className={`${paddings} px-main`}
			ref={WrapperRef}
		>
			{header && (
				<Reveal onInView>
					<StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
				</Reveal>
			)}
			{description && (
				<Reveal onInView>
					<StyleDescription ref={DescRef} dangerouslySetInnerHTML={redactor} />
				</Reveal>
			)}
		</StyledHeaderDescriptionHalfWidthWrapper>
	);
};

export default HeaderDescriptionHalfWidth;
