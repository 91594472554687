import React, {useState} from 'react';
import styled from "styled-components";
import Image from "../common/Image";
import CommonIcon from "../common/Icon/Icon";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, getPrefix, getSlug} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {ICONS} from "@repo/utils/constants";
import Link from "../common/Link";
import {Reveal} from "../../../index";

const SingleBlockWrapper = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        column-gap: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        column-gap: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
    }
`

const Icon = styled(Image)`
    aspect-ratio: 1;
    height: unset;
    width: ${getCssSizeValue(82, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(82, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(135, BREAKPOINTS.DESKTOP)};   
    }
`

const TextsContainer = styled.div`
    overflow: hidden;
    padding: 10px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding: 0;
    }
`

const Heading = styled.div`
	color: var(--secondary-color);
    em {
        font-weight: ${({ theme }) => theme.fontWeight.light};
        font-style: italic;
    }
	font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
    letter-spacing: -0.96px;
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
	    font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
	    line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
        min-width: ${getCssSizeValue(184, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
	    font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
	    line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        letter-spacing: -1.92px;
        min-width: ${getCssSizeValue(368, BREAKPOINTS.DESKTOP)};
        transition: all var(--transition-duration) var(--ease);
        transform: translateY(0);
        opacity: 1;
        &.hover {
            transition: all var(--transition-duration) var(--ease);
            transform: translateY(-200%);
            opacity: 0;
        }
	}
`

const HoverDescription = styled.div`
	color: var(--secondary-color);
    display: none;
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
	    font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
	    line-height: ${getFontSize(27, BREAKPOINTS.DESKTOP)};
        width: ${getCssSizeValue(333, BREAKPOINTS.DESKTOP)};
        position: absolute;
        left: 0;
        display: block;
	}
    transition: all var(--transition-duration) var(--ease);
    transform: translateY(100%);
    opacity: 0;
    &.hover {
        transition: all var(--transition-duration) var(--ease) 0.1s;
        transform: translateY(-50%);
        top: 50%;
        opacity: 1;
    }
`

const ArrowIcon = styled(CommonIcon)`
    width: 15px;
    transform: rotate(90deg);
    transition: var(--transition-duration) var(--ease);
    &.hover{
        transform: translateX(50%) rotate(90deg);
    }
`
const SingleCheckOtherService = ({data}) => {
    const title = data?.title || '';
    const hoverDescription = data?.hoverDescription || '';
    const linkPage = data?.chooseLinkPage || '';
    const linkPageTypename = linkPage?.__typename || '';
    const iconUrl = (linkPageTypename === "CommonServicePageRecord" || linkPageTypename === "CommonCompetencesPageRecord") ? linkPage?.card?.icon?.url || '' : linkPage.icon?.url || '';
    const slug = linkPage?.slug || '';
    const finalSlug = getSlug(slug)
    const prefix = getPrefix(linkPageTypename)
    const href = `${prefix}/${finalSlug}`
    const [hover, setHover] = useState(false)
    const handleMouseEnter = () => {
        setHover(true);
    };
    const handleMouseLeave = () => {
        setHover(false);
    };
    return (
        <SingleBlockWrapper
            href={href}
            onPointerEnter={handleMouseEnter} onPointerLeave={handleMouseLeave}
        >
            <Icon src={iconUrl} fit="contain"/>
            <TextsContainer>
                <Heading className={`${hover ? 'hover' : ''} `} dangerouslySetInnerHTML={getHtmlFromRedactorField(title)}/>
                <HoverDescription className={`${hover ? 'hover' : ''} `} dangerouslySetInnerHTML={getHtmlFromRedactorField(hoverDescription)}/>
            </TextsContainer>
            <ArrowIcon name={ICONS.ARROWUP} className={`${hover ? 'hover' : ''} `} color="secondary"/>
        </SingleBlockWrapper>
    );
};

export default SingleCheckOtherService;
