import React, {forwardRef, memo, useImperativeHandle, useMemo, useRef} from 'react';
import styled from 'styled-components';
import Text from '../common/Text'
import {getFontSize, getHtmlFromRedactorField, removeTags} from "@repo/utils";
import { BREAKPOINTS } from "../../../styles/themeSettings";
import {Loader, Reveal} from "../../../index";
import {REVEAL_CLASSNAME} from "../../animations/Reveal";

const Wrapper = styled.div`
	 --padding-top:${getFontSize(64, BREAKPOINTS.MOBILE)};
	--padding-bottom:${getFontSize(100, BREAKPOINTS.MOBILE)};
	--row-gap: 15px;
	position: relative;
	width: 100%;
	height: 100lvh;
	display: flex;
	top: 0;
	left: 0;
	flex-direction: column;
	justify-content: center;
	row-gap: var(--row-gap);
	padding-top: var(--padding-top);
	padding-bottom: var(--padding-bottom);
	color: var(--secondary-color);
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) { 
		--padding-top:${getFontSize(64, BREAKPOINTS.TABLET)}; 
		--padding-bottom:${getFontSize(121, BREAKPOINTS.TABLET)}; 
    }

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: calc(80% + var(--paddingX));
		--padding-top:${getFontSize(92, BREAKPOINTS.DESKTOP)};
		--padding-bottom:${getFontSize(129, BREAKPOINTS.DESKTOP)};
    }
	
	
`;

const Name = styled(Text)`
	font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)}; 

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
    }

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(24)};
		line-height: ${getFontSize(56)};
    }
`

const Description = styled(Text)`
	position: relative;
	--font-size: ${getFontSize(37, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(50, BREAKPOINTS.MOBILE)};
	font-size: var(--font-size);
	letter-spacing: calc(var(--font-size) * -0.04);
	em{
		font-weight: ${({ theme }) => theme?.fontWeight?.light || 200};
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		--font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		--font-size: ${getFontSize(116)};
		line-height: ${getFontSize(136)};
	}


	//column-gap: calc(var(--font-size)/5); //somewhat close to actual space
	//word-break: break-word;
	//display: flex;
	//flex-wrap: wrap;
	// .${REVEAL_CLASSNAME}-parent{
	// 	width: fit-content;
	// 	${REVEAL_CLASSNAME}-child{
	// 		word-break: break-word;
	// 	}
	// }
`


const ProjectBanner = forwardRef(function ProjectBanner({ data, children, className='', animate=true, animationVars, ...props }, fref){
	const WrapperRef = useRef()
	const name = data?.name || 'Project';
	const description = data?.description || 'Project Description'
	// const redactor = getHtmlFromRedactorField(description) ;
	const redactor = getHtmlFromRedactorField(removeTags(description, ['p'])); //tag removing so animation works fine
	const DescRef = useRef()
	useImperativeHandle(fref, () => WrapperRef.current, [])
	const descVars = useMemo(() =>({
		delay: 2.3,
		...animationVars
	}),[animationVars])

	// useLinesReveal({
	// 	el:DescRef,
	// 	onInView: true,
	// 	disabled: !description || !animate,
	// 	animationVars: {
	// 		delay: 2.3,
	// 		...animationVars
	// 	}
	// })
	return (
		<Wrapper ref={WrapperRef} className={`${className} project-banner px-main`} {...props}>
			<Reveal disabled={!animate} animationVars={{
				delay: 2
			}}>
				<Name>
					{name}
				</Name>
			</Reveal>
			<Reveal disabled={!animate} animationVars={{
				delay: 2
			}}>
				<Description ref={DescRef} className="project-desc text"  dangerouslySetInnerHTML={redactor} />
			</Reveal>
			{children}
		</Wrapper>
	);
})

export default memo(ProjectBanner);
