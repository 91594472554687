import styled from 'styled-components'

const StyledContainer = styled.div`
    overflow: hidden;
`

const revealAnimationContainer = (Component, targetClassName, options = { axis: 'Y', percentValue: 100, skipOnMobile: false}) => {
    return ({children, ...props}) => {
        const newClassName = `${(props && props.className) || ''} ${targetClassName}`;

        return <StyledContainer>
            <Component
                {...props}
                style={{ transform: `translate${options.axis}(${options.percentValue}%)`}}
                className={newClassName}
            >
                {children}
            </Component>
        </StyledContainer>
    }
};

export default revealAnimationContainer