import React, {useCallback, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {
    getColorScheme,
    getFontSize,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData, setCssVars
} from "@repo/utils";
import PortalLens from "../common/StaticLens/PortalLens";
import useInView from "../../hooks/useInView";
import Steps from "./Steps";
import Header from "./Header";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Transition from "../common/StaticLens/Transition";
import Blob from "./Blob";
import CardsSlider from "../common/CardsSlider";
import {ScrollTrigger, useGSAP} from "@repo/utils/gsap";
import {useIsDesktop, useWindowSize} from "../../../index";

const Wrapper = styled.div`
    position: relative;
    display: block;
    overflow-x: clip;

    width: 100%;
    
    
    .pin-spacer{
        pointer-events: none;
    }
    
    .portal-wrapper{
        //position: absolute;
        overflow-x: hidden;
    }
    
    .header{
        text-align: center;
        color: var(--secondary-color);
        --font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
        font-size: var(--font-size);
        letter-spacing: calc(var(--font-size) * -0.04);
        line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
        em{
            font-weight: ${({ theme }) => theme?.fontWeight?.light || 200};
        }
    }
    
    .centered-in-blob{
        position: absolute;
        top: 65vw;
        left: 50%;
        text-align: center;
        translate: -50% -50%;
        width: max-content;
    }

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		.header{
            --font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        }
        .centered-in-blob {
            top: 45vw;
        }
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		.header{
            --font-size: ${getFontSize(116)};
            line-height: ${getFontSize(136)};
        }
        .centered-in-blob {
            top: 50%;
        }
	}
`


export const ID = 'how-we-work-module'
export const SURPLUS = 2 //extra height and animations controller

const StepsContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    height: ${({ $height }) => `${$height <= 0 ? 3 : $height * 3}00svh`};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: ${({ $height }) => `${$height <= 0 ? 1 : $height}00svh`};
    
    }
`

const HowWeWorkModule = ({ data }) => {
    const ContainerRef = useRef()
    const VideoRef = useRef()
    const Step1ProgressRef = useRef(0)
    const entryId = getIdWithoutSpecialSymbols(data?.id);
    const finalId = `${ID}-${entryId}`
    const paddings = getPaddingsClassFromData(data)
    const windowSize = useWindowSize()
    const colorScheme = getColorScheme(data)
    const isDesktop = useIsDesktop()
    const [showVideo, setShowVideo] = useState(true)
    const cards = data?.cards || []
    const steps = data?.steps.map((item, index) => ({ ...item, index })) || []
    const moduleHeight = steps.length + SURPLUS //in 100vh
    // console.log(data, colorScheme, paddings);
    const { ref: InViewRef, above } = useInView({
        id: ID,
        trigger: {
            start: 'top top',
            end: 'bottom center'
        },
        once: false,
        pinSpacing: false,
        // markers: true
    })

    const context = useMemo(() => ({
        step1: Step1ProgressRef,
        lensVisible: !above,
    }), [above])

    const updateContext = useCallback(({ progress }) => {
        context.step1.current = progress
        // if(!isDesktop) {
        //     setCssVars(VideoRef.current, { 'pg-step1': context.step1.current })
        // }
    },[context, isDesktop])

    useGSAP(() => {
        if(!isDesktop){
            ScrollTrigger.create({
                id: `pin-video-${ID}`,
                trigger: VideoRef.current,
                endTrigger: InViewRef.current,
                start: 'top top',
                end: 'bottom bottom',
                pin: true,
                // markers: true,
                onEnterBack: () => {
                    setShowVideo(true)
                },
                onLeave: () => {
                    setShowVideo(false)
                },
                onUpdate: () => {
                    setCssVars(VideoRef.current, { 'pg-step1': context.step1.current })
                }
            })
        }
    }, {
        dependencies: [isDesktop, windowSize.width],
        revertOnUpdate: true,
    })

    return (
        <Wrapper
            ref={InViewRef}
            id={finalId}
            className={`${paddings}`}
            data-color-scheme={colorScheme}
        >
           <div className="portal-wrapper">
               <PortalLens
                   visible={!above}
                   mobile={{
                       ref: VideoRef,
                       visible: showVideo,
                       autoplay: showVideo,
                       className: ID
                   }}
                   context={context}
               >
                   <Blob/>
               </PortalLens>
           </div>
            <StepsContainer
                ref={ContainerRef}
                $height={moduleHeight}
            >
                <Steps
                    data={data}
                    steps={steps}
                    endTrigger={ContainerRef}
                />
            </StepsContainer>
            <Header data={data}/>
            <Transition id={`${ID}-step1`} onUpdate={updateContext}/>
            <CardsSlider id={`${ID}`} data={cards} isDirectElementCardSource/>
        </Wrapper>
    );
};

export default HowWeWorkModule;
