import {useRef, useMemo, memo, useImperativeHandle, useEffect} from 'react';
import styled from 'styled-components';
import {gsap, useGSAP, duration} from '@repo/utils/gsap';
import useInView from "../hooks/useInView";
import useAnimationDebug from "./useAnimationDebug";
import {useIsomorphicLayoutEffect} from "../../index";


const Parent = styled.div`
	//inherit
	display: inherit;
	flex-direction: inherit;
	row-gap: inherit;
	column-gap: inherit;
	align-items: inherit;
	justify-content: inherit;
	flex-wrap: inherit;
	//inherit

	position: relative;
	//&.axis-x {
	//	padding: 0 1vw;
	//}
	//&.axis-y{
	//	padding: 1svh 0;
	//}
`;

const Child = styled.div`
	//inherit
	display: inherit;
	flex-direction: inherit;
	row-gap: inherit;
	column-gap: inherit;
	align-items: inherit;
	justify-content: inherit;
	flex-wrap: inherit;
	//inherit
	height: 100%;

	&.axis-x {
		&.direction-forward {
			transform: translateX(-100%);
		}
		&.direction-backward {
			transform: translateX(100%);
		}
	}
	&.axis-y {
		&.direction-forward {
			transform: translateY(100%);
		}
		&.direction-backward {
			transform: translateY(-100%);
		}
	}
`;
export const REVEAL_CLASSNAME = 'reveal';
const SlideInY = ({ target, timeline = null, vars = {} }) => {
	const tl = timeline || gsap.timeline();
	tl.to(target, {
		y: 0,
		...vars,
	});
	return tl;
};
const SlideInX = ({ target, timeline = null, vars = {}}) => {
	const tl = timeline || gsap.timeline();
	tl.to(target, {
		x: 0,
		...vars,
	});
	return tl;
};

export function useReveal({
	    el,
		target,
		id: passedId = '',
		onInView = false,
		animationVars = {},
		timeline,
		pinnedContainer = null,
		axis = 'y',
		once = true,
		paused=false,
		debug: forceDebug=false,
		start= 'top',
		startViewPort = 'bottom',
		disabled=false
	  }){
	const debug = useAnimationDebug(forceDebug)
	const { ref: InViewRef, intersected } = useInView({
		id: `${REVEAL_CLASSNAME}-${passedId}`,
		pinnedContainer: pinnedContainer,
		disabled: !onInView || disabled,
		trigger: {
			start: debug ? `${start} center` : `${start} ${startViewPort}`,
			end: debug ? `${start} center` : `${start} ${startViewPort}`
		},
		markers: debug,
		once: debug ? !debug : once,
	});

	const animationTL = useMemo(() => timeline || gsap.timeline(), [timeline]);
	const memoAnimationVars = useRef(animationVars)

	useIsomorphicLayoutEffect(() => {
		memoAnimationVars.current = animationVars
	},[animationVars]);

	useImperativeHandle(InViewRef, () => el?.current, [])

	useGSAP(() => {
		if(!disabled && target?.current){
			animationTL.pause()
			const vars = {
				duration: duration * 2, //2 golden ratio's
				...memoAnimationVars.current,
			}
			if (axis === 'x') {
				SlideInX({
					target: target.current,
					vars,
					timeline: animationTL
				});
			} else {
				SlideInY({
					target: target.current,
					vars,
					timeline: animationTL
				});
			}

			if(intersected && !paused){
				animationTL.play()
			}
		}
	},{
		dependencies: [animationTL,disabled, paused, axis, intersected],
		revertOnUpdate: true
	})
}

const Reveal = ({
		children,
		as='div',
		id = '',
		className,
		onInView = false,
		animationVars = {},
		timeline,
		pinnedContainer = null,
		axis = 'y',
		direction = 'forward',
		once = true,
		paused=false,
		debug=false,
		start= 'top',
		startViewPort = 'bottom',
		disabled=false,
		parentProps,
		childProps,
		...props
	}) => {
	const ParentRef = useRef()
	const TargetRef = useRef()

	useReveal({
		el: ParentRef,
		target: TargetRef,
		id,
		onInView,
		animationVars ,
		timeline,
		pinnedContainer,
		axis,
		once,
		paused,
		debug,
		start,
		startViewPort,
		disabled,
		...props
	})

	return (
		<Parent
			as={as}
			ref={ParentRef}
			className={`${REVEAL_CLASSNAME}-parent ${!disabled ? `axis-${axis}` : ''}  ${className || ''} reveal-parent`}
			{...parentProps}
		>
			<Child
				as={as}
				ref={TargetRef}
				className={`${REVEAL_CLASSNAME}-child ${!disabled ? `axis-${axis}` : ''} direction-${direction}`}
				{...childProps}
			>
				{children}
			</Child>
		</Parent>
	);
};

export default memo(Reveal);
