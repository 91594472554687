/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import fadeIn from '../../animations/fadeIn';
import {BREAKPOINTS, COLORS} from '../../../styles/themeSettings';
import Button from '../common/Button';
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import { setShowCookies, setIsCookiesModalExpanded } from '@/state/showCookiesSlice';
import {useDispatch} from "react-redux";

const SwitchStyles = css`
	background-color: ${({theme}) => theme.colors.lightGray};
	transition: background-color, transform 10s var(--ease);
	position: relative;
`;

const ExtendedWrapper = styled.div`
	position: fixed;
	background-color: ${({theme}) => theme.colors.twilightBlue};
	color: ${({theme}) => theme.colors.white};
	border-radius: 19px;
	z-index: 15;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: color, background-color, height .3s var(--ease);
	row-gap: 15px;
	opacity: 0;
	height: 60svh;
	left: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	right: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};;
	padding:
		${getCssSizeValue(40, BREAKPOINTS.MOBILE)}
		${getCssSizeValue(34, BREAKPOINTS.MOBILE)}
		${getCssSizeValue(48, BREAKPOINTS.MOBILE)};
	
	&.settings {
		height: 90svh;
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		top: 50%;
		left: 50%;
		right: unset;
		transform: translate(-50%, -50%);
    	width: ${getCssSizeValue(600, BREAKPOINTS.TABLET)};
		height: ${getCssSizeValue(450, BREAKPOINTS.TABLET)};
		
		padding:
			${getCssSizeValue(40, BREAKPOINTS.TABLET)}
			${getCssSizeValue(34, BREAKPOINTS.TABLET)}
			${getCssSizeValue(48, BREAKPOINTS.TABLET)};
		
		&.settings {
			height: ${getCssSizeValue(640, BREAKPOINTS.TABLET)};
		}
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: ${getCssSizeValue(720)};
		height: ${getCssSizeValue(500)};
		padding:
			${getCssSizeValue(40)}
			${getCssSizeValue(34)}
			${getCssSizeValue(48)};
		
		&.settings {
			height: ${getCssSizeValue(768)};
		}
	}
`;

const Content = styled.div`
	display: flex;
	row-gap: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	text-align: justify;
	flex-direction: column;
	height: calc(100% - ${getCssSizeValue(150, BREAKPOINTS.MOBILE)});
	overflow: hidden auto;
	margin-bottom: 15px;
	
	::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		row-gap: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
		margin-bottom: none;
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		row-gap: ${getCssSizeValue(30)};
    }
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex-wrap: wrap;
	&.options {
		flex-direction: initial;
	}
	button {
		text-transform: lowercase;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		flex-direction: initial;
		flex-wrap: nowrap;
		gap: 10px;
    }
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		column-gap: 15px;
	}
`;

const Cookie = styled.div`
	display: flex;
	flex-direction: column;
`;

const CookieOptions = styled.div`
	margin-top: 16px;
	display: flex;
	column-gap: 15px;
	flex-flow: row nowrap;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		margin-top: 26px;
	}
`;

const Switcher = styled.label`
	border-radius: 34px;
	width: 60px;
	height: 30px;
	display: block;
	opacity: 0.4;

	span {
		position: absolute;
		content: '';
		height: 20px;
		width: 20px;
		left: 5px;
		bottom: 4px;
		background-color: ${({theme}) => theme.colors.twilightBlue};
		-webkit-transition: background-color, transform var(--color-transition-duration) var(--ease);
		transition: background-color var(--color-transition-duration) var(--ease), transform var(--color-transition-duration) var(--ease);
		border-radius: 50%;
		pointer-events: none;
	}

	input {
		opacity: 0;
		width: 60px;
		height: 30px;

		&:not([readonly]) {
			cursor: pointer;
		}
	}

	input:checked + span {
		transform: translateX(28px);
		background-color: #9C0E04;
	}

	&:has(input:checked) {
		background: ${({theme}) => theme.colors.red};
		opacity: 1;
	}

	${SwitchStyles}
`;

const CookieName = styled.div`
	font-weight: 700;
	letter-spacing: -0.072px;
	font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(18, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(18)};
		line-height: ${getFontSize(22)};
	}
`

const CookieDescription = styled.div`
	padding-right: ${getCssSizeValue(30)};
	letter-spacing: -0.072px;
	font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(20, BREAKPOINTS.MOBILE)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(16)};
		line-height: ${getFontSize(22)};
	}
`

const CookieNameAndSwitcherWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const OptionButton = styled.div`
	font-weight: 400;
	letter-spacing: -0.112px;
	font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	cursor: pointer;
	
	&.settings {
		font-weight: 900;
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(22, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(28)};
		line-height: ${getFontSize(28)};
	}
`

const CookiesExtended = ({ showExtended, handleConfig, cookies, setCookies, handleClose, extended }) => {
	const [settings, setSettings] = useState(true);
	const ExtendedRef = useRef(null);
	const { acceptAllButton, acceptSelectedButton, backButton, configButton, generalButton, generalDescription, list } = extended;
	const dispatch = useDispatch()

	useEffect(() => {
		if (showExtended) {
			fadeIn(ExtendedRef.current);
		}
	}, [showExtended]);

	const toggleSettings = () => {
		setSettings((prevState) => !prevState);
	};

	return (
		<ExtendedWrapper ref={ExtendedRef} className={`${settings ? 'settings' : ''}`}>
			<Buttons className='options'>
				<OptionButton
					className={`${!settings ? 'settings' : ''}`}
					onClick={() => {
						if (!settings) return;
						toggleSettings();
					}}
				>
					{generalButton || ''}
				</OptionButton>
				<OptionButton
					className={`${settings ? 'settings' : ''}`}
					onClick={() => {
						if (settings) return;
						toggleSettings();
					}}
				>
					{configButton || ''}
				</OptionButton>
			</Buttons>
			<Content data-lenis-prevent>
				<>
					{settings
						? list.map((cookie, key) => {
								const { cookieType, cookieName, cookieDescription } = cookie;
								return (
									<Cookie key={`cookie-${cookieType}`}>
										<CookieNameAndSwitcherWrapper>
											<CookieName>{cookieName}</CookieName>
											{cookieType === 'necessary' ? (
												<Switcher>
													<input type="checkbox" checked={true} readOnly />
													<span />
												</Switcher>
											) : (
												<Switcher>
													<input
														type="checkbox"
														checked={cookies[cookieType]}
														onChange={() => {
															setCookies((prevState) => ({
																...prevState,
																[cookieType]: !prevState[cookieType],
															}));
														}}
													/>
													<span />
												</Switcher>
											)}
										</CookieNameAndSwitcherWrapper>
										<CookieOptions>
											<CookieDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(cookieDescription)} />
										</CookieOptions>
									</Cookie>
								);
						  })
						: <CookieDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(generalDescription)} /> || ''}
				</>
			</Content>
			<Buttons>
				<Button onClick={handleConfig}>{backButton || ''}</Button>
				<Button onClick={handleClose}>{acceptSelectedButton || ''}</Button>
				<Button
					onClick={() => {
						setCookies({
							hasConsent: true,
							analytics: true,
							marketing: true,
							functional: true
						});

						dispatch(setShowCookies(false));
						dispatch(setIsCookiesModalExpanded(false))
					}}
				>
					{acceptAllButton || ''}
				</Button>
			</Buttons>
		</ExtendedWrapper>
	);
};
export default CookiesExtended;
