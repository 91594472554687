import styled, {css} from "styled-components";
import {
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getPaddingsClassFromData,
    getPrefix,
    getSlug
} from "@repo/utils";
import {Button, Icon, Image, useIsMobile} from "../../../../index";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import {CMS_ELEMENT_TYPENAME, CONTENT_TYPES, CURSOR_TYPES, ICONS, CURSOR_ASSET_SIZE} from "@repo/utils/constants";
import {useCallback, useState} from "react";
import { ScrollTrigger } from '@repo/utils/gsap'
import {useRouter} from "next/router";
import {multilineUnderlineCss} from "../../../../styles/globalStyles";

const hideColumnsCss = css`
    &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        display: none;
    }

    &:nth-child(1) {
        width: 50%;
    }
    
    &:not(:nth-child(6)) {
        padding-right: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)}
    }

    &:last-child {
        display: table-cell;
        text-align: end;
        width: 7%;
        .button-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }

    @media (min-width: 600px) {
        &:nth-child(1) {
            width: 20%;
        }
        &:nth-child(2) {
            display: table-cell;
        }
    }

    @media (min-width: 1000px) {
        &:nth-child(1) {
            width: 12%;
        }
        &:nth-child(3) {
            display: table-cell;
        }

        &:nth-child(5) {
            text-align: center;
            display: table-cell;
            width: 2%;
        }
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 15%;
        &:nth-child(1) {
            width: 8%;
        }
        &:nth-child(4) {
            display: table-cell;
        }
        &:nth-child(5) {
            padding-right: unset;
        }
    }
`

const Container = styled.div`
    color: ${props => props.theme.colors.twilightBlue}
    position: relative;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.th`
    font-weight: ${props => props.theme.fontWeight.regular};
    text-align: left;
    ${hideColumnsCss};

    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(28, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        padding-bottom: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        padding-bottom: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
    }
`;

const TableRow = styled.tr`
    border-bottom: 1px solid ${props => props.theme.colors.twilightBlue};
    &:first-child {
        border-top: 1px solid ${props => props.theme.colors.twilightBlue};
    }
    &:last-child {
        border-bottom: none;
    }
`;

const TableCell = styled.td`
    ${hideColumnsCss};
    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    padding: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)} 0 ${getCssSizeValue(38, BREAKPOINTS.MOBILE)};
    text-transform: lowercase;
    
    .icon {
        width: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)}
    }

    ${multilineUnderlineCss};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
        padding: ${getCssSizeValue(20, BREAKPOINTS.TABLET)} 0 ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
        
        .icon {
            width: unset;
        }
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
        padding: ${getCssSizeValue(23, BREAKPOINTS.DESKTOP)} 0 ${getCssSizeValue(18, BREAKPOINTS.DESKTOP)};
    }
    
    transition: all .3s;
    
    &.hovered {
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            font-weight: 700;
            line-height: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
            padding: ${getCssSizeValue(23, BREAKPOINTS.DESKTOP)} 0 ${getCssSizeValue(18, BREAKPOINTS.DESKTOP)};
        }
    }
`;

const StyledText = styled.div`
    width: ${getCssSizeValue(320, BREAKPOINTS.MOBILE)};
    font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(150, BREAKPOINTS.DESKTOP)};
    margin-bottom: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(470, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(40, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(800, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(150, BREAKPOINTS.DESKTOP)};
    }
`

const LoadMoreButtonWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: ${getCssSizeValue(110, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
		margin-top: 0;
	}
`

const StyledPreloadedImages = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: -10;
    pointer-events: none;
    opacity: 0;
    height: 100px;
    width: 100px;
`

export default function ProjectsTable({ data: projectTableData }) {
    const data = projectTableData.length > 0 ? projectTableData[0] : null;
    const paddings = getPaddingsClassFromData(data)
    const loadMoreButtonText = data?.loadMoreButtonText || ''
    const headers = data?.headers || []
    const items = data?.items || [];
    const buttonText = data?.buttonText || ''
    const headerText = data?.header || ''
    const showLoadMoreButton = data?.showLoadMoreButton
    const [visibleRows, setVisibleRows] = useState(showLoadMoreButton ? 8 : items.length)

    const loadMore = useCallback(() => {
        setVisibleRows(items.length)
        ScrollTrigger.refresh()
    }, [items])

    return (
        data && (
            <Container className={`px-main ${paddings}`}>
                <StyledPreloadedImages>
                    {items.map((item, index) => {
                        const url = item?.cursorAsset?.url || ''
                        return <Image key={item?.id || index} src={url} priority />
                    })}
                </StyledPreloadedImages>
                <StyledText dangerouslySetInnerHTML={getHtmlFromRedactorField(headerText)} />
                <Table>
                    <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <TableHeader key={index}>{header.text}</TableHeader>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {items.slice(0, visibleRows).map((item, index) => {
                        return <Row item={item} key={item?.id || index} buttonText={buttonText} />
                    })}
                    </tbody>
                </Table>

                {visibleRows < items.length && (
                    <LoadMoreButtonWrapper>
                        <Button onClick={loadMore} variant="secondary">{loadMoreButtonText}</Button>
                    </LoadMoreButtonWrapper>
                )}
            </Container>
        )
    )
}

function Row({ item, buttonText }) {
    const router = useRouter()
    const isMobile = useIsMobile()
    const client = item?.client || ''
    const industry = item?.industry || ''
    const servicesAndCompetences = item?.servicesAndCompetences || []
    const technologiesAndTools = item?.technologiesAndTools || []
    const year = item?.year || ''
    const externalUrl = item?.externalUrl || ''
    const shouldOpenInNewTab = item?.shouldOpenInNewTab
    const cursorAsset = item?.cursorAsset || { url: '', width: 0, height: 1, id: '' };
    const innerProjectTypename = item?.chooseProject?.__typename || '';
    const innerProjectSlug = item?.chooseProject?.slug || '';
    const finalSlug = getSlug(innerProjectSlug)
    const prefix = getPrefix(innerProjectTypename)
    const href = `${prefix}/${finalSlug}`
    const hasInnerProjectLink = innerProjectTypename !== '' && innerProjectSlug !== '';

    const [isHovered, setIsHovered] = useState(false)

    const handleRedirect = () => {
        if (hasInnerProjectLink) {
            router.push(href);
        } else {
            if (shouldOpenInNewTab) {
                window.open(externalUrl, '_blank');
            } else {
                window.location.href = externalUrl;
            }
        }
    }

    return (
        <TableRow
            data-cursor={CURSOR_TYPES.IMAGE}
            data-cursor-asset={JSON.stringify(cursorAsset)}
            data-video-size={CURSOR_ASSET_SIZE.SMALL}
            onClick={handleRedirect}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <TableCell className={`${isHovered ? 'hovered' : ''}`} dangerouslySetInnerHTML={getHtmlFromRedactorField(client)} />
            <TableCell dangerouslySetInnerHTML={getHtmlFromRedactorField(industry)} />
            <TableCell>
                {servicesAndCompetences.map((item, index) => {
                    const text = item?.name || ''
                    const slug = item?.slug || ''
                    const href = item?.[CMS_ELEMENT_TYPENAME] === CONTENT_TYPES.SERVICE_PAGE_RECORD ? `/services/${slug}` : `/competence/${slug}`
                    return (
                        <p
                            key={index}
                            className='multiline-underline-animation'
                            onClick={(event) => {
                                event.stopPropagation();
                                router.push(href)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {text}
                            {index < servicesAndCompetences.length - 1 && ', '}
                        </p>
                    )
                })}
            </TableCell>
            <TableCell>
                {technologiesAndTools.map((item, index) => {
                    const text = item?.name || ''
                    const slug = item?.slug || '';
                    const href = `/tools-methods/${slug}`;
                    return (
                        <p
                            className='multiline-underline-animation'
                            key={index}
                            onClick={(event) => {
                                event.stopPropagation();
                                router.push(href)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {text}
                            {index < technologiesAndTools.length - 1 && ', '}
                        </p>
                    )
                })}
            </TableCell>
            <TableCell>{year}</TableCell>
            <TableCell>
                <div className='button-wrapper'>
                    <Button
                        cursor={null}
                        className={`${isHovered ? 'hover' : ''} `}
                        variant='secondary'
                        onClick={handleRedirect}
                    >
                        {
                            !isMobile ? buttonText : <Icon name={ICONS.BUTTON_ARROW_RIGHT} />
                        }
                    </Button>
                </div>
            </TableCell>
        </TableRow>
    )
}