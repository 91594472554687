import React, {useRef} from 'react';
import styled from 'styled-components';
import Image from '../common/Image';
import {
	getColorScheme,
	getCssSizeValue,
	getFontSize,
	getHtmlFromRedactorField,
	getPaddingsClassFromData,
	removeTags
} from '@repo/utils';
import PageContainer from "../pageContainer/PageContainer";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Reveal} from "../../../index";
import {REVEAL_CLASSNAME} from "../../animations/Reveal";
import {useScaleFadeIn} from "../../animations/ScaleFadeIn";
import {useLinesReveal} from "../../animations/LinesReveal";


const StyledWrapper = styled.section`
	color: var(--secondary-color);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transition: color var(--color-transition-duration) var(--ease);
	width: 100%;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		.header, .desc{
			width: 50%;
		}
	}
`;

const StyledHeader = styled.div`
	width: 100%;
	color: var(--secondary-color);
	transition: color var(--color-transition-duration) var(--ease);
	font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
	font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(33, BREAKPOINTS.MOBILE)};
	
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
		margin-bottom: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
    }
`;

const StyledDescription = styled.div`
	width: 100%;
	color: var(--secondary-color);
	transition: color var(--color-transition-duration) var(--ease);
	font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
	font-weight: 300;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
	}
`;

const StyledImageWrapper = styled.div`
	overflow: hidden;
	position: relative;
	aspect-ratio: ${({ aspectRatio }) => aspectRatio};
	margin-top: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)};
	width: 100%;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		margin-top: ${getCssSizeValue(120, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		margin-top: ${getCssSizeValue(236, BREAKPOINTS.DESKTOP)};
	}
	&.full {
		width: 100%;
	}
	&.center {
		width: ${({assetWidth}) => assetWidth}px;
		max-width: 100%;
		margin: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)} auto 0;
		@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			margin: ${getCssSizeValue(120, BREAKPOINTS.TABLET)} auto 0;
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			margin: ${getCssSizeValue(236, BREAKPOINTS.DESKTOP)} auto 0;
		}
	}
	.${REVEAL_CLASSNAME}-parent{
		height: 100%;
	}
`;
const AssetWithHeaderAndDescription = ({ data }) => {
	// console.log(data);
	const { header } = data;
	const id = data?.id?.replace(/[^a-zA-Z ]/g, "") || '';
	const imageVariant = data?.imageVariant || 'full';
	const assetUrl = data?.asset?.url || '';
	const description = data?.description || '';
	// const redactor = getHtmlFromRedactorField(description);
	const redactor = getHtmlFromRedactorField(removeTags(description, ['p', 'span'])); //tag removing so animation works fine
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	const assetWidth = data?.asset?.width || 0;
	const assetHeight = data?.asset?.height || 1;
	const aspectRatio = assetWidth / assetHeight === 0 ? '16/9' : assetWidth / assetHeight;
	const ImageRef = useRef()
	const DescRef = useRef()

	useScaleFadeIn({
		el: ImageRef,
		onInView: true
	})

	// useLinesReveal({
	// 	el:DescRef,
	// 	onInView: true,
	// 	disabled: !description
	// })

	return (
		<PageContainer id={id} className="full-width">
			<StyledWrapper
				className={`${paddings} px-main`}
				data-color-scheme={colorScheme}
			>
				{header && (
					<StyledHeader className="header">
						<Reveal onInView>
							{header}
						</Reveal>
					</StyledHeader>
				)}
					{description && (
						<Reveal onInView>
							<StyledDescription ref={DescRef} className="description" dangerouslySetInnerHTML={redactor} />
						</Reveal>
					)}

					<StyledImageWrapper className={imageVariant} assetWidth={assetWidth} aspectRatio={aspectRatio}>
						<Image ref={ImageRef} src={assetUrl} alt="asset" fill sizes={'100vw'}/>
					</StyledImageWrapper>
			</StyledWrapper>
		</PageContainer>
	);
};

export default AssetWithHeaderAndDescription;
