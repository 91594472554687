import React from 'react';
import styled from "styled-components";
import {getColorScheme, getFontSize, getHtmlFromRedactorField, getPaddingsClassFromData} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: grid;
    align-items: end;
    grid-template-columns: 1fr;
    row-gap: 30px;
    
`
const Description = styled.div`
    
    font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
    transition: color var(--color-transition-duration) var(--ease);
    color: var(--secondary-color);
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        max-width: 50%;
        &.side-right{
            margin-left: auto;
        }
    }
`


//used to have slogan but it's just description now
const DescriptionSlogan = ({ data }) => {
    const { description } = data
    const id = data?.id?.replace(/[^a-zA-Z ]/g, "") || '';
    const side = data?.side || 'left'
    const redactor = getHtmlFromRedactorField(description)
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)
    return (
        <Wrapper id={id} className={`${paddings} px-main`} data-color-scheme={colorScheme}>
            <Description className={`side-${side}`} dangerouslySetInnerHTML={redactor}/>
        </Wrapper>
    );
};

export default DescriptionSlogan;
