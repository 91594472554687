import styled from "styled-components";
import {getColorScheme, getFontSize, getHtmlFromRedactorField, getPaddingsClassFromData} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const StyledWrapper = styled.section`
    width: 100%;
    position: relative;
    display: flex;
`

const StyledHeader = styled.h2`
    font-weight: ${props => props.theme.fontWeight.regular};
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 70%;
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(40, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        width: 65%;
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(66, BREAKPOINTS.DESKTOP)};
    }
`

export default function TextMedium({ data, textModuleId }) {
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)
    const align = data?.align || 'flex-start'
    const text = data?.text || 'text'

    return (
        <StyledWrapper id={textModuleId} data-color-scheme={colorScheme} className={`px-main ${paddings}`} style={{ justifyContent: align }}>
            <StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
        </StyledWrapper>
    )
}
