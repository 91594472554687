import styled from "styled-components";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Button, getCardData, Image, Reveal, Video} from "../../../index";
import {useMemo, useState} from "react";
import {useRouter} from "next/router";

const StyledEmblaWrapper = styled.div`
	flex: 0 0 auto;
	max-width: 90%;
	min-width: 0;
	position: relative;
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
		flex: 0 0 auto;
		max-width: 80%;
        cursor: pointer;
	}
`;

const StyledCard = styled.div`
    overflow: hidden;
    user-select: none;
    background: ${props => props.theme.colors.lightGray};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    max-width: ${getCssSizeValue(360, BREAKPOINTS.MOBILE)};
    max-height: ${getCssSizeValue(552, BREAKPOINTS.MOBILE)};
    padding: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)} ${getCssSizeValue(44, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        max-width: unset;
        max-height: unset;
        width: ${getCssSizeValue(450, BREAKPOINTS.TABLET)};
        height: ${getCssSizeValue(630, BREAKPOINTS.TABLET)};
        padding: ${getCssSizeValue(20, BREAKPOINTS.TABLET)} ${getCssSizeValue(28, BREAKPOINTS.TABLET)} ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(550, BREAKPOINTS.DESKTOP)};
        height: ${getCssSizeValue(840, BREAKPOINTS.DESKTOP)};
        padding: ${getCssSizeValue(26, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(64, BREAKPOINTS.DESKTOP)};
    }
    
    .video {
        aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
        width: 100%;
        height: auto;
    }
    
    .header {
        font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
        font-weight: ${({ theme }) => theme.fontWeight.regular || 400};
        margin-top: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(80, BREAKPOINTS.TABLET)};
            margin-top: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(120, BREAKPOINTS.DESKTOP)};
            letter-spacing: -4.64px;
            margin-top: ${getCssSizeValue(20)};
        }
    }
    
    .description {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    }
    
    .button {
        margin-top: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            margin-top: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            margin-top: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
        }
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: auto;
    height: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
        height: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        height: ${getCssSizeValue(120)};
    }
`

export default function SingleCard({ item, buttonText = '', index }) {
    const router = useRouter()
    const { header, description, icon, button } = getCardData(item?.servicePage?.card || {})

    const videoSrc = item?.video?.url || ''
    const videoAlt = item?.video?.alt || ''
    const videoWidth = item?.image?.width || 0;
    const videoHeight = item?.image?.height || 1;
    const aspectRatio = useMemo(() => {
        let result;
        if (videoSrc !== '') {
            result =  videoWidth / videoHeight === 0 ? '16/9' : videoWidth / videoHeight;
        } else {
            result = icon.width / icon.height === 0 ? '16/9' : icon.width / icon.height;
        }

        return result
    }, [])

    const [isHovered, setIsHovered] = useState(false)

    return (
        <StyledEmblaWrapper
            onClick={() => router.push(`/services/${button.link}`)}
        >
            <Reveal onInView>
                <StyledCard
                    $aspectRatio={aspectRatio}
                    className='single-card'
                    onMouseEnter={() => setIsHovered(prevState => !prevState)}
                    onMouseLeave={() => setIsHovered(prevState => !prevState)}
                >
                    <div>
                        <div>
                            {icon.url !== '' && (
                                <StyledImage
                                    src={icon.url}
                                    alt={icon.alt}
                                    sizes={'100vw'}
                                    $aspectRatio={aspectRatio}
                                />
                            )}
                            {videoSrc !== '' && (
                                <div className='video'>
                                    <Video src={videoSrc} alt={videoAlt} playing={isHovered} />
                                </div>
                            )}
                        </div>
                        <div className='header'>{header}</div>
                    </div>
                    <div>
                        <div className='description' dangerouslySetInnerHTML={description}/>
                        <div className='button'>
                            <Button className={`${isHovered ? 'hover': ''}`} as='link' href={`/services/${button.link}`} variant='secondary'>{buttonText}</Button>
                        </div>
                    </div>
                </StyledCard>
            </Reveal>
        </StyledEmblaWrapper>
    )
}
