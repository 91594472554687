import React, {memo} from 'react';
import {GlobalCanvas, SmoothScrollbar} from "@14islands/r3f-scroll-rig";
import {useIsDesktop} from "../../../index";
// import {Canvas} from "@react-three/fiber";

const Canvas2 = ({ children }) => {
    const isDesktop = useIsDesktop();
    // console.log(isDesktop);
    return (
        <>
            <GlobalCanvas
                //forcing re-init fixes page speed report break, check also in SmoothScrollbar
                key={isDesktop}
            >
                {(globalChildren) => (
                    <>
                        {globalChildren}
                        {children}
                    </>
                )}
            </GlobalCanvas>
            {/*<SmoothScrollbar key={isDesktop} scrollRestoration="manual"  enabled={isDesktop}/>*/}
        </>
    );
};

export default memo(Canvas2);
