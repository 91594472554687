import useIsDesktop from './useIsDesktop';
import { useMemo } from 'react';
import {useScrollContext} from "../components/Scroll";

export default function useVisible3D(visible = undefined) {
	const isDesktop = useIsDesktop();
	const { isCanvasAvailable } = useScrollContext('rig')
	return useMemo(() => {
		const base = isDesktop && isCanvasAvailable
		if (typeof visible === 'undefined') return base;
		return base && visible ;
	}, [visible, isCanvasAvailable, isDesktop]);
}
