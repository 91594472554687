import React from "react";
import styled from 'styled-components';
import Text from '../../common/Text'
import {getCssSizeValue, getFontSize} from "@repo/utils";
import Reveal from "../../../animations/Reveal";
import {BREAKPOINTS} from "../../../../styles/themeSettings";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    column-gap: ${getCssSizeValue(32)};
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(72)};
    }
`

const Indicators = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        row-gap: ${getCssSizeValue(19)};
    }
`

const Indicator = styled.span`
    position: relative;
    width: 2px;
    display: flex;
    height: ${getCssSizeValue(74, BREAKPOINTS.TABLET)};
    border-radius: 2px;
    background-color: rgba(210, 210, 210, 0.13);
    transition: background-color var(--color-transition-duration) var(--ease);
    
    &.active{
        background-color: var(--secondary-color);
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 4px;
        height: ${getCssSizeValue(130)};
    }
`

const Index = styled(Text)`
    display: none;
    margin-top: auto;
    --font-size: ${getFontSize(13)};
    color: var(--secondary-color);
    font-size: var(--font-size);
    text-align: start;
    //line-height: 242px;
    letter-spacing: calc(var(--font-size) * -0.04);
    column-gap: calc(var(--font-size) * 0.3);

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: flex;
    }
`
const Counter = ({ data, current }) => {
    return (
        <Wrapper>
            <Indicators>
                {data.map((item, index) => <Indicator key={index} className={`${current === index ? 'active' : ''}`}/>)}
            </Indicators>
            <Index>
                <Reveal key={current} as="span" animationVars={{
                    delay: 0.3,
                }}>
                    {current + 1}
                </Reveal>
                 / {data.length}
            </Index>
        </Wrapper>
    )
}

export default Counter
