import styled from "styled-components";
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Reveal} from "../../../index";

const StyledWrapper = styled.section`
    color: var(--secondary-color);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(84, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
       gap: ${getCssSizeValue(200, BREAKPOINTS.DESKTOP)};
    }
`

const StyledHeader = styled.h2`
    font-weight: ${props => props.theme.fontWeight.regular};
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 70%;
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(40, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        width: 65%;
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(66, BREAKPOINTS.DESKTOP)};
    }
`

const StyledGridWrapper = styled.div`
    display: flex;

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        justify-content: flex-end;
    }
`

const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: ${getCssSizeValue(43, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: ${getCssSizeValue(18, BREAKPOINTS.TABLET)};
        row-gap: ${getCssSizeValue(46, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        width: 75%;
        grid-template-columns: repeat(3, 1fr);
        column-gap: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)};
        row-gap: ${getCssSizeValue(120, BREAKPOINTS.DESKTOP)};
    }
`

const StyledSingleBenefit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${getCssSizeValue(140, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        min-height: ${getCssSizeValue(140, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        min-height: ${getCssSizeValue(240, BREAKPOINTS.DESKTOP)};
    }

    .circle-line-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 12px;
        
        .circle {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            border: 1px solid var(--secondary-color); 
        }
        
        .line {
            background: ${props => props.theme.colors.white};
            height: 1px;
            width: 100%;
        }
    }
    
    .title {
        font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(41, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(41, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(41, BREAKPOINTS.DESKTOP)};
        }
    }    
    
    .text {
        width: 90%;
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            width: 90%;
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(28, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    } 
    
`

function BenefitBlock({ benefit }) {
    const title = benefit?.title || ''
    const text = benefit?.text || ''

    return (
        <StyledSingleBenefit>
            <div className='circle-line-title-wrapper'>
                <Reveal onInView className='circle-line-title-wrapper'>
                    <div className='circle-line-wrapper'>
                        <div className='circle' />
                        <div className='line'></div>
                    </div>
                    <div className='title'>
                        {title}
                    </div>
                </Reveal>
            </div>
            <Reveal onInView>
                <div className='text' dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
            </Reveal>
        </StyledSingleBenefit>
    )
}

export default function BenefitsModule({data}) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)
    const header = data?.header || ''
    const benefits = data?.benefits || []

    return (
        <StyledWrapper
            id={id}
            data-color-scheme={colorScheme}
            className={`${paddings} px-main`}
        >
            <Reveal onInView>
                <StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
            </Reveal>
            <StyledGridWrapper>
                <StyledGrid>
                    {benefits.map(benefit => <BenefitBlock key={benefit?.id || ''} benefit={benefit} />)}
                </StyledGrid>
            </StyledGridWrapper>
        </StyledWrapper>
    )
}