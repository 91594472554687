import { createSlice } from '@reduxjs/toolkit';

export const ShowCookiesSlice = createSlice({
	name: 'showCookies',
	initialState: {
		isShown: true,
		isCookiesModalExpanded: false,
	},
	reducers: {
		setShowCookies: (state, action) => {
			state.isShown = action.payload;
		},
		setIsCookiesModalExpanded: (state, action) => {
			state.isCookiesModalExpanded = action.payload;
		},
	},
});

export const { setShowCookies, setIsCookiesModalExpanded } = ShowCookiesSlice.actions;

export default ShowCookiesSlice.reducer;