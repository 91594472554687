import React, { useRef } from 'react';
import styled from 'styled-components';
import {getColorScheme, getHtmlFromRedactorField, getPaddingsClassFromData} from '@repo/utils';
import SectionHeaderWave from '../inlineSvg/SectionHeaderWave';
import { DrawSVGPlugin, gsap, ScrollTrigger } from '@repo/utils/gsap';
import useMobileDevice from '../../hooks/useMobileDevice';
import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect';
import HeaderArrowRight from "../inlineSvg/HeaderArrowRight";

const StyledSectionHeaderWrapper = styled.section`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	//background-color: var(--primary-color);
	transition: background-color var(--color-transition-duration) var(--ease) 0.7s;
`;

const StyledHeaderWrapper = styled.div`
	display: flex;
	position: relative;
	svg {
		path {
			stroke: ${({$color}) => $color};
		}
	}
`;

const HeaderTextWrapper = styled.div`
	width: 100%;
	color: ${({$color}) => $color};
	font-size: 14px;
	font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
	line-height: 24px;
	position: relative;

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: 21px;
		line-height: 24px;
		width: 100%;
		margin-bottom: 0;
	}

	.expand-line {
		position: absolute;
		top: 50%;
		left: -3%;
		transform: scaleX(1);
		width: 106%;
		height: 1px;
		background-color: ${({$color}) => $color};
	}
`;

const StyledHeader = styled.div`
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: 100%;
		margin-bottom: 0;
		display: flex;
	}
`;

const StyledSvgWrapper = styled.div`
	position: absolute;
	left: calc(100% + 10px);
`;

const StyledLineAndArrowWrapper = styled.div`
	position: relative;
	width: 40px;
	height: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

`

const StyledArrowWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0, -50%);
	width: 5px;
	height: 7px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	svg {
		position: absolute;
		path {
			fill: var(--secondary-color);
		}
	}
`

const StyledHeaderLine = styled.div`
	width: 30px;
	height: 2px;
	background-color: var(--secondary-color);
	transform: scaleX(0);
`


gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger);

const SectionHeader = ({ data, textColor = 'var(--secondary-color)', pxMain = true }) => {
	const isMobileDevice = useMobileDevice();
	const text = data?.text || '';
	const anchor = data?.anchor || '';
	const id = data?.id || '';
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	const headerSelector = `#${anchor}-header-wave #header-wave-hover`;
	const wrapperRef = useRef();

	const drawWave = () => {
		gsap.to(headerSelector, { drawSVG: '100% 0%', duration: 0.4, opacity: 1 });
		gsap.to('.expand-line', {
			scaleX: 0,
			duration: 0.4,
			transformOrigin: 'left center',
		});
	};

	const drawMarker = () => {
		gsap.to('.header-line', { scaleX: 1, duration: 0.4, transformOrigin: 'right center' });
		gsap.to('.header-arrow', {
			opacity: 1,
			duration: 0.4,
		});
	};

	const hideWave = () => {
		gsap.to(headerSelector, { drawSVG: '0% 0%', duration: 0.4, opacity: 0.6 });
		gsap.to('.expand-line', {
			scaleX: 1,
			duration: 0.4,
		});
	};

	useIsomorphicLayoutEffect(() => {
		const ctx = gsap.context(() => {
			gsap.to(headerSelector, { drawSVG: '0% 0%', duration: 0.4, opacity: 0.6 });
			gsap.to(headerSelector, {
				scrollTrigger: {
					trigger: wrapperRef.current,
					start: 'center 60%',
					end: 'center 20%',
					// markers: true,
					onEnter: () => {
						drawWave();
						drawMarker();
					},
					onEnterBack: () => {
						drawWave();
						drawMarker();
					},
					onLeave: () => {
						hideWave();
					},
					onLeaveBack: () => {
						hideWave();
					},
				},
			});
		}, wrapperRef);

		return () => ctx.revert();
	}, []);

	return (
		<StyledSectionHeaderWrapper
			ref={wrapperRef}
			data-color-scheme={colorScheme}
			className={`${paddings} ${pxMain ? 'px-main' : ''}`}
			id={anchor}
		>
			<StyledHeaderWrapper $color={textColor} id={`${anchor}-header-wave`}>
				<HeaderTextWrapper $color={textColor}>
					<StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
					{!isMobileDevice && <div className="expand-line"></div>}
				</HeaderTextWrapper>
				{!isMobileDevice && (
					<StyledSvgWrapper>
						<SectionHeaderWave />
					</StyledSvgWrapper>
				)}
			</StyledHeaderWrapper>
			<StyledLineAndArrowWrapper>
				<StyledArrowWrapper className='header-arrow'>
					<HeaderArrowRight/>
				</StyledArrowWrapper>
				<StyledHeaderLine className='header-line'/>
			</StyledLineAndArrowWrapper>
		</StyledSectionHeaderWrapper>
	);
};

export default SectionHeader;
