import styled from "styled-components";
import {getColorScheme, getCssSizeValue, getIdWithoutSpecialSymbols, getPaddingsClassFromData} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import useEmblaCarousel from "embla-carousel-react";
import SingleCard from "./SingleCard";
import {useIsMobile} from "../../../index";
import {CURSOR_TYPES} from "@repo/utils/constants";
import {gsap, useGSAP} from "@repo/utils/gsap"
import ProgressBar from "./ProgressBar";
import {useRef} from "react";

const Container = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
    color: var(--secondary-color);
`

const StyledEmblaContainer = styled.div`
	width: 100%;
	height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
	gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        flex-direction: row;
        backface-visibility: hidden;
        touch-action: pan-y;
		gap: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(42, BREAKPOINTS.TABLET)};
    }

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        gap: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(42, BREAKPOINTS.DESKTOP)};
    }
`;

export default function ServicesCards({ data }) {
    const isMobile = useIsMobile()
    const id = getIdWithoutSpecialSymbols(data?.id);
    const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true, align: 'start' });
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)
    const buttonText = data?.buttonText || ''
    const items = data?.items || []

    const wrapperRef = useRef(null)

    useGSAP(() => {
        const mm = gsap.matchMedia();
        mm.add({
            small: '(max-width: 767px)',
            tablet: `(max-width: 1023px) and (min-width: 768px)`,
            desktop: `(min-width: 1024px) and (orientation: landscape)`
        }, (ctx) => {
            const { small } = ctx.conditions;

            if (small) return

            gsap.set('.single-card', { yPercent: 60})
            gsap.to('.single-card', {
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    start: 'top center-=10%',
                },
                yPercent: 0,
                duration: 1,
                stagger: i => (i + 1) % 2 * 0.1,
            })
        })
    }, { scope: wrapperRef, dependencies: [isMobile] })

    return (
        <Container
            ref={emblaRef}
            id={id}
            className={`${paddings} px-main`}
            data-cursor={CURSOR_TYPES.SLIDER}
            data-color-scheme={colorScheme}
        >
            <StyledEmblaContainer
            ref={wrapperRef}
            >
                {items.map((item, i) => (
                    <SingleCard
                        key={item?.id || i}
                        item={item}
                        buttonText={buttonText}
                        index={i}
                    />
                ))}
            </StyledEmblaContainer>
            {!isMobile && <ProgressBar
                emblaApi={emblaApi}
            />}
        </Container>
    )
}