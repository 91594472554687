import styled from "styled-components";
import {Button, Video} from "../../../index";
import VideoSrcDigital from '../../assets/video/tasma-digital-404.mp4'
import VideoSrcDesign from '../../assets/video/tasma-design-404.mp4'
import VideoSrcGroup from '../../assets/video/tasma-group-404.mp4'
import {getColorScheme, getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";
import {useMemo} from "react";

const Wrapper = styled.section`
    position: relative;
    height: 100svh;
    width: 100%;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.twilightBlue};
`

const BackgroundVideoWrapper = styled.div`
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    left: 0;
`

const ContentWrapper = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};

    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        gap: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(80)};
    }
    
    .button {
        border-color: ${props => props.theme.colors.twilightBlue} !important;
        color: ${props => props.theme.colors.twilightBlue} !important;

        &:hover {
            background: ${props => props.theme.colors.twilightBlue} !important;
            color: ${props => props.theme.colors.white} !important;
        }
        
        span {
            border-color: ${props => props.theme.colors.twilightBlue} !important;
        }
    }
`

const Header = styled.div`
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
        letter-spacing: -4.64px;
    }
    
    em {
        font-weight: ${props => props.theme.fontWeight.book};
    }
`

const Description = styled.div`
    font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(470, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(40, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(800, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
    }
`

export default function HeaderDescriptionButtonBanner({data, type='digital'}) {
    const colorScheme = getColorScheme(data)
    const { header = '', description = '', buttonText = ''} = data
    const src = useMemo(() => {
        switch (type){
            case "digital":
                return  VideoSrcDigital
            case "design":
                return  VideoSrcDesign
            case "group":
                return  VideoSrcGroup
            default:
                return VideoSrcDigital
        }
    },[type])

    return (
        <Wrapper
            data-color-scheme={colorScheme}
            className='px-main'
            data-cursor={CURSOR_TYPES.HTML}
        >
            <BackgroundVideoWrapper>
                <Video src={src} autoplay='force' />
            </BackgroundVideoWrapper>
            <ContentWrapper>
                <Header dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
                <Description dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
                <Button as='link' href='/' variant='primary'>{buttonText}</Button>
            </ContentWrapper>
        </Wrapper>
    )
}
