'use client';
import Menu from '../components/Menu';
import React, {useMemo, memo} from 'react';
import {useScrollContext, Cursor, usePageTransitionContext, Cookies} from '@repo/ui';
import Footer from "@/components/Footer/Footer";
import {useRouter} from "next/router";

const Layout = ({ cookies, children, menu, footer, data, globals }) => {
    const scroll = useScrollContext()
    const { transitionCompleted } = usePageTransitionContext()
    const router = useRouter()
    const isProjectSite = useMemo(() => {
        const path = router.asPath
        return path.split('/').length === 3 && path.includes('projects')
    },[router.asPath])

    return  (
            <Cursor eventSource={scroll.main} transitionCompleted={transitionCompleted} >
                <Menu menu={menu} globals={globals} />
                <Cookies data={cookies} />
                {children}
                {!isProjectSite && <Footer data={footer}/>}
            </Cursor>
        )
}
export default memo(Layout)
