import styled from "styled-components";
import {
    getColorScheme,
    getCssSizeValue,
    getHtmlFromRedactorField,
    getPaddingsClassFromData, removeTags,
    replaceDataColorWithStyle
} from "@repo/utils";
import {BREAKPOINTS, LOGO_COLORS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";
import Reveal from "../../animations/Reveal";
import {useLinesReveal} from "../../animations/LinesReveal";
import {useRef} from "react";

const StyledWrapper = styled.div`
    position: relative;
	width: 100%;
    height: 100%;
`

const StyledText = styled.div`
    color: var(--secondary-color);
    letter-spacing: -0.04em;
    font-size: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    line-height: ${getCssSizeValue(57, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
        line-height: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getCssSizeValue(116, BREAKPOINTS.DESKTOP)};
        line-height: ${getCssSizeValue(136, BREAKPOINTS.DESKTOP)};
    }
`

export default function BigTypography({ data, textModuleId, animation=true, className='',...props }) {
    const text = data?.text || ''
    const id = data?.id?.replace(/[^a-zA-Z ]/g, "") || '';
    const formattedText = replaceDataColorWithStyle(text)
    // const redactor = getHtmlFromRedactorField(formattedText)
    const redactor = getHtmlFromRedactorField(removeTags(formattedText, ['p'])); //tag removing so animation works fine
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)
    const TextRef = useRef()
    const disabled = !animation || !text

    // useLinesReveal({
    //     el:TextRef,
    //     onInView: true,
    //     disabled: !text
    // })

    return (
        <StyledWrapper
            id={textModuleId}
            data-color-scheme={colorScheme}
            data-logo-color={LOGO_COLORS.DARK}
            className={`${className} ${paddings} px-main`}
            {...props}
        >
            <Reveal onInView disabled={disabled}>
                <StyledText ref={TextRef} dangerouslySetInnerHTML={redactor} />
            </Reveal>
        </StyledWrapper>
    )
}
