import React, {useMemo} from 'react'
import { motion } from 'framer-motion';
import styled from "styled-components";

const StyledSvg = styled(motion.svg)`
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    transform: translate(-100% ,100%);
    width: 100%;
    height: 310px;
    fill: white;
    stroke: none;

    path {
        opacity: 0;
    }
`

export default function Curve({ variant = 'open', svgPaths, className='', id = '' }) {
    const svgPath = useMemo(() => {
        if (!svgPaths) return

        return svgPaths[variant].initialPath
    }, [variant, svgPaths])

    return (
        <StyledSvg className={`${id}-curved-svg-wrapper`}>
            <path className={`${id}-curved-${variant}`} d={svgPath}></path>
        </StyledSvg>
    )
}