import React, {startTransition, useMemo, useRef, memo} from 'react';
import {gsap} from "@repo/utils/gsap";
import {MeshPortalMaterial} from "@react-three/drei";
import BandVideo from "../../assets/video/contact-banner-vertical.mp4";
import {useFrame, useThree} from "@react-three/fiber";
import {LensGeometry} from "../WebGL/Portal/StaticLens";
import {useCursor} from "../Cursor/Cursor";
import {useExtendedTheme, useIsomorphicLayoutEffect} from "../../../index";
import { Vector3 } from 'three';
import {PORTAL_LENS_RADIUS} from "@repo/utils/constants";
import {LOTTIE_WRAPPER_ID} from "./whoWeAre/WhoWeAreCommon";
import PortalVideo from '../WebGL/Portal/Video3D'
import {THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import getPositionZ from "../common/StaticLens/getPositionZ";
import LensMask from "../WebGL/Portal/LensMask";
import {useMask} from "@react-three/drei";


//scale down portal initially
const PORTAL_SCALE = 0.18 / PORTAL_LENS_RADIUS
const MASK_ID = 3


const Blob = ({ _visible = false, context, ...props }) => {
    const MeshRef = useRef()
    const portalLens = useCursor((state) => state.portalLens)
    const moveVector = useMemo(() => portalLens.position.clone(), [portalLens.position]);
    const scaleVector = useMemo(() => new Vector3(PORTAL_SCALE, PORTAL_SCALE, PORTAL_SCALE), [])
    const three = useThree()
    const extendedTheme = useExtendedTheme()
    const stencil = useMask(MASK_ID)
    const visible = useMemo(() => {
        return extendedTheme.colorScheme === THEME_COLOR_SCHEMES.PLEO_EXTRA && _visible
    },[extendedTheme.colorScheme, _visible])
    const steps = useMemo(() => ([
        {
            timeline: gsap.timeline({ paused: true }),
            animations: [
                {
                    target: scaleVector,
                    x: 1,
                    y: 1,
                    z: 1,
                },
                {
                    target: moveVector,
                    x: -0.3,
                    y: 0,
                    z: 0
                },
            ]
        },
        {
            timeline: gsap.timeline({ paused: true }),
            animations: [
                {
                    target: moveVector,
                    x: 0,
                    y: 0,
                    z: getPositionZ(PORTAL_LENS_RADIUS, three.viewport)
                }
            ]
        },
    ]),[scaleVector, moveVector, three.viewport])


    useIsomorphicLayoutEffect(() => {
        //positioning blob in the lottie canvas center
        const { size: windowSize, viewport } = three
        const lottie = document.getElementById(LOTTIE_WRAPPER_ID)?.children[0].children[0]
       if(lottie){
           const lottieBounds = lottie.getBoundingClientRect()
           const lottieCanvasCenter = {
               x: lottieBounds.left + lottieBounds.width / 2 - windowSize.width / 2,
               y: lottieBounds.height/2 - windowSize.height / 2 //doesn't take into account vertical axis, assumes element touches bottom
           }
           const coords3d = {
               x: (lottieCanvasCenter.x / windowSize.width * viewport.width),
               y: (lottieCanvasCenter.y / windowSize.height * viewport.height)
           }
           moveVector.setX(coords3d.x)
           moveVector.setY(coords3d.y)
       }

        steps.forEach((step) => {
            step.animations.forEach((animation) => {
                step.timeline.to(animation.target , {
                    x: animation.x,
                    y: animation.y,
                    z: animation.z,
                    ease: 'none'
                }, 0 ) //important for animations to be applied simultaneously
            })
        })
        return () => {
            steps.forEach((step) => step.timeline.revert())
            scaleVector.setScalar(PORTAL_SCALE)
            moveVector.setScalar(0)
        }
    },[three, steps])


    useFrame(() => {
        const numbersPg = context.numbers.current
        const valuesPg = context.ourValues.current
        steps[0].timeline.progress(numbersPg)
        if(numbersPg >= 1){
            steps[1].timeline.progress(valuesPg)
            if(portalLens.current){
                portalLens.current.material.opacity = 1.0 - valuesPg
            }
        }

        //update props of both lens and portal
        //position && scale
        portalLens.position.copy(moveVector)
        portalLens.scale.copy(scaleVector)

        startTransition(() => {
            MeshRef.current.position.copy(portalLens.position)
            MeshRef.current.scale.copy(portalLens.scale)
            MeshRef.current.visible = _visible
        })


    })

    return (
        <group ref={MeshRef}>
            <LensMask id={MASK_ID}/>
            <PortalVideo
                src={BandVideo}
                visible={visible}
                stencil={stencil}
            />
        </group>
    )
}

export default memo(Blob);
//241
//185
