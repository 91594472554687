import {useIsDesktop, useWindowSize} from "../../index";
import {useMemo} from "react";

export default function useMenuCurveSvgPaths() {
    const isDesktop = useIsDesktop();
    const {width} = useWindowSize()

    return useMemo(() => {
        if (width) {
            return {
                open: {
                    initialPath: `M0 0 L${width} 0 Q${width / 2} ${isDesktop ? -300 : -100} 0 0`,
                    targetPath: `M0 0 L${width} 0 Q${width / 2} ${isDesktop ? 400 : 100} 0 0`,
                    finalPath: `M0 0 L${width} 0 Q${width / 2} 10 0 0`
                },
                close : {
                    targetPath: `M0 0 L${width} 0 L${width} ${isDesktop ? 200 : 100} Q${width / 2} ${isDesktop ? -100 : -50} 0 ${isDesktop ? 200 : 100} Z`,
                    initialPath: `M0 0 L${width} 0 L${width} 0 Q${width / 2} 0 0 0 Z`
                }
            }
        }

        return null
    }, [isDesktop, width])
}