import React, {forwardRef} from 'react';
import {Video} from "../../../../index";
import MockupVideo from "../../../assets/video/contact-banner-vertical.mp4";
import WhiteBandVideo from "../../../assets/video/band-white-horizontal.mp4";
import styled from 'styled-components';
import Image from "../Image";
import Lens from '../../../assets/images/blob.png'
import {getFontSize} from "@repo/utils";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import { ID as BLOB_SLOGAN_ID } from '../../BlobSlogan/index'
import { ID as PLEO_CONTEXT_ID } from '../../PleoContext/index'
import { ID as HOW_WE_WORK_MODULE_ID } from '../../HowWeWorkModule/index'
import { ID as AREAS_OF_EXPERTISE_ID } from '../../AreasOfExpertise/AreasOfExpertise';

const Wrapper = styled.div`
    --overflow: 0.1; //little overflow added so the circle covers the whole screen
    --lensOpacity: 1; // wygaszenie zdjęcia
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    z-index: -1;
    pointer-events: none;
    transform-origin: center;
    transition: opacity var(--color-transition-duration) var(--ease);
    opacity: 0;
    
    &.visible{
        opacity: 1;
    }
    
    .video, figure{
        position: absolute;
    }
    >div{
        position: relative;
        display: flex;
        border-radius: 50%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transform-origin: center;
    }
    .lens{
        width: 100%;
        height: 100%;
        opacity: var(--lensOpacity);
    }

    //.video{
    //    position: relative;
    //    display: flex;
    //    justify-content: center;
    //    overflow: hidden;
    //    height: auto;
    //    width: 100%;
    //    aspect-ratio: 16/9;
    //    figure{
    //        top: 50%;
    //        transform: translateY(-50%);
    //    }
    //}
    
    &.${BLOB_SLOGAN_ID}{
        --original-scale: 0.12;
        --pg-step1: 0;
        --pg-step2: 0;
        --offset: ${getFontSize(57, BREAKPOINTS.MOBILE)}; //text line-height
        --step1-scale: var(--original-scale);
        --step2-scale: calc((1 - var(--original-scale)) + var(--overflow));
        --lensOpacity: calc(1 - var(--pg-step2));
        top: 50svh;
        transform: translateY(-50%);
        
        >div{
            //scale: calc(var(--original-scale) + (var(--step2-scale) * var(--pg-step1)));
            scale: calc(var(--step1-scale) * var(--pg-step1) + var(--step2-scale) * var(--pg-step2));
            //transform: translateY();
            translate: 0 calc(var(--offset) * var(--lensOpacity));
        }
        
    }
    
    &.${PLEO_CONTEXT_ID}{
        --pg-numbers: 0;
        --pg-values: 0;
        --numbers-scale: 0.75;
        --lensOpacity: calc(1 - var(--pg-values));
        --values-scale: calc((1 - var(--numbers-scale)) + var(--overflow));
        left: 50%;
        transform: translateX(-50%) translateY(calc(-50% - 50svh + var(--mobile-lens-offset-top)));

        >div{
            scale: calc(var(--numbers-scale) * var(--pg-numbers) + var(--values-scale) * var(--pg-values));
        }
        
    }

    &.${HOW_WE_WORK_MODULE_ID}{
        --original-scale: 0.6;
        --pg-step1: 0;
        --target-scale: calc(1 - var(--original-scale) + var(--overflow));
        --lensOpacity: calc(1 - var(--pg-step1));
        margin-top: -90svh;
        //translate: 0 -50%;
        >div {
            scale: calc(var(--original-scale, 0.6) + (var(--target-scale, 0.4) * var(--pg-step1, 0)));
            translate: 0 calc(10svh * var(--pg-step1) + 15%);
        }
    }
    &.areas-of-expertise-module {
        --target-scale: 0.5;
        --pg-step1: 0;
        //margin-top: -90svh;
        //border: 2px solid deeppink;
        //background-color: red;
        margin-top: -90svh;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
            width: 232px;
            height: 232px;
            //scale: 0.54;
            //background-color: rebeccapurple;
        }
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        --overflow: 0.2;

        &.${BLOB_SLOGAN_ID}{
            //--original-scale: 0.08;
            --offset: ${getFontSize(88, BREAKPOINTS.TABLET)};//text line-height
        }

        &.${PLEO_CONTEXT_ID}{
            --numbers-scale: 0.45;
            transform: translateX(-50%) translateY(-50%);
            >div{
                transform: translateX(calc(-5% * ( 1 - var(--pg-values))));
            }
        }

        &.${HOW_WE_WORK_MODULE_ID}{
            --original-scale: 0.5;
            >div{
                translate: 0 calc(10svh * var(--pg-step1) - 5%);
            }
        }

        &.areas-of-expertise-module {
            --target-scale: 0.5;
            --pg-step1: 0;
            > div {
                width: 332px;
                height: 332px;
            }
        }
    }
    
`

const Portal =forwardRef(function Portal({ autoplay=false, visible=false, className='', ...props  }, fref){
    const hasWhiteBand = props?.hasWhiteBand || false;
    const blobVideoSrc = props?.blobVideoSrc || WhiteBandVideo;
    return (
        <Wrapper ref={fref} className={`${visible ? 'visible' : ''} ${className}`} {...props}>
            <div>
                <Video
                    id={className}
                    src={hasWhiteBand ? blobVideoSrc : MockupVideo}
                    playing={autoplay}
                    // autoplay={autoplay ? 'force' : false}
                />
                <Image src={Lens}  className="lens"/>
            </div>
        </Wrapper>
    )

})

export default Portal;
