import React from 'react';
import styled from 'styled-components';
import { useProjectsContext } from "./";
import Icon from "../common/Icon/Icon";
import { useScrollbar } from "@14islands/r3f-scroll-rig";
import { getOldCssSizeValue } from "@repo/utils";
import { BREAKPOINTS } from "../../../styles/themeSettings";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import Filters from "./Filters";
import { useIsMobile } from "../../../index";


const StyledContainer = styled.div`
	--height: 47px;
	--gap: 9px;
	--border-radius: 7px;
	display: flex;
	flex-direction: row;
	position: fixed;
	bottom: ${getOldCssSizeValue(30, BREAKPOINTS.MOBILE)};
	left: 50%;
	column-gap: ${getOldCssSizeValue(9, BREAKPOINTS.MOBILE)};
	gap: var(--gap);
	
	transform: translateX(-50%);
	align-items: center;
	//z-index: 101;
	z-index: 25;
	transition: opacity var(--transition-duration) var(--ease);
	opacity: 0;
	pointer-events: none;
	overflow: visible;
	width: max-content;
	&.visible{
		transition: opacity 0.3s ease-in-out;
		opacity: 1;
		pointer-events: auto;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		--height: ${getOldCssSizeValue(47, BREAKPOINTS.DESKTOP)};
		--gap: ${getOldCssSizeValue(9, BREAKPOINTS.DESKTOP)};
		bottom: ${getOldCssSizeValue(30, BREAKPOINTS.DESKTOP)};
	}
`

const Wrapper = styled.div`
	--gap: 16px;
	--border-radius: 7px;
	display: flex;
	flex-direction: row;
	backdrop-filter: blur(20px);
	background-color: rgba(231, 231, 231, 0.4);
	border-radius: 7px;
	height: var(--height);
	gap: var(--gap);
	align-items: center;
	padding: 6px 12px;
	z-index: 101;
	transition: opacity var(--transition-duration) var(--ease);
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		padding: 0 ${getOldCssSizeValue(18, BREAKPOINTS.DESKTOP)} 0 ${getOldCssSizeValue(23, BREAKPOINTS.DESKTOP)};
	}
	
`;

const StyledArrowTopWrapper = styled.div`
	//width: 47px;
	//height: 47px;
	height: var(--height);
	aspect-ratio: 1;
	background-color:rgba(238, 238, 238, 0.4);
	border-radius: 7px;
	backdrop-filter: blur(20px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.arrow-up{
		padding: 10px;
	}
	.elevator-array {
		width: 32px!important;
		height: 32px!important;
	}
	svg {
		transition: scale 0.2s ease-in-out;
		//scale: 0.3;
	}
	&:hover {
		transition: transform 0.2s ease-in-out;
		svg {
			transition: scale 0.2s ease-in-out;
			scale: 1.1;
		}
	}
`;

// eslint-disable-next-line no-redeclare
export const Option = styled.div`
	display: flex;
	color: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
	transition: color var(--color-transition-duration) inherit;
	//transition: all 300ms linear;
	position: relative;
	font-size: ${getOldCssSizeValue(14, BREAKPOINTS.MOBILE)};
	align-items: center;
	cursor: pointer;
	font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
	p {
		position: relative;
		&:before {
			background: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
		}
	}
	&.filter {
		text-transform: uppercase;
		column-gap: var(--gap);
		&:before{
			transition: color var(--color-transition-duration) inherit;
			position: absolute;
			left: 0;
			display: block;
			content: attr(title);
			font-weight: ${({ theme }) => theme?.fontWeight?.bold || 700};
			height: inherit;
			color: transparent;
			overflow: hidden;
			visibility: hidden
		}

		&:after{
			content: '';
			position: relative;
			display: flex;
			width: 2px;
			height: 2px;
			background-color: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
			border-radius: 50%;
			transform: translateY(-50%);
		}
	}

	&.active {
		color: transparent;
		&.filter {
			&:before{
				color: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
				visibility: visible;
			}
		}
		
	}
	
	.icon{
		width: ${getOldCssSizeValue(14, BREAKPOINTS.MOBILE)};
		aspect-ratio: 1;
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getOldCssSizeValue(14, BREAKPOINTS.DESKTOP)};
		line-height: 24px;
	}
	
`;

const TypeSwitcher = styled.div`
	display: flex;
	column-gap: ${getOldCssSizeValue(16, BREAKPOINTS.MOBILE)};

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		column-gap: ${getOldCssSizeValue(16, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		column-gap: ${getOldCssSizeValue(16, BREAKPOINTS.DESKTOP)};
	}
`


const Options = ({ data = [], setIsList, setFiltration, target, ...props }) => {
	const isMobile = useIsMobile()
	const isList = useProjectsContext((state) => state.isList)
	const inViewport = useProjectsContext((state) => state.inViewport)
	const { __lenis } = useScrollbar()

	function scrollToSectionTop(){
		if(!target?.current) return
		__lenis.scrollTo(target.current, { offset: -100, lock: true })
	}

	return (
		<StyledContainer className={`${inViewport ? 'visible' : ''} ${!isMobile ? 'with-bg' : ''}`} {...props}>
			<StyledArrowTopWrapper>
				<Icon className='elevator-array' data-cursor={CURSOR_TYPES.CIRCLE} name={ICONS.ARROWUP} color="#051D27" onClick={() => {
					__lenis.scrollTo('top')
				}}/>
			</StyledArrowTopWrapper>
			<Wrapper>
				<Filters data={data} onFilterClick={scrollToSectionTop} setFiltration={setFiltration} />
				<TypeSwitcher>
					<Option
						onClick={() => {
							setIsList(false)
							scrollToSectionTop()
						}}
					>
						<Icon name={ICONS.TILES} color={isList ? '#051D27' : 'secondary'}/>
					</Option>
					<Option
						onClick={() => {
							setIsList(true)
							scrollToSectionTop()
						}}
					>
						<Icon name={ICONS.LIST} color={isList ? 'secondary' : '#051D27'}/>
					</Option>
				</TypeSwitcher>
			</Wrapper>
		</StyledContainer>
	);
};

export default Options;
