import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
export default function useMobileDevice() {
	const [isMobileDevice, setMobileDevice] = useState(false);

	useEffect(() => {
		setMobileDevice(isMobile);
	}, []);

	return isMobileDevice;
}
