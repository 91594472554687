import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {useIsDesktop, useWindowSize} from "../../../../index";
import {gsap, ScrollTrigger, useGSAP} from "@repo/utils/gsap";
import {getCssSizeValue, setCssVars} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import Card from './Card'

const Wrapper = styled.div`
    --row-gap: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    position: relative;
    display: flex;
    width: 100%;
    height: 100svh;
    z-index: 1;
    color: var(--secondary-color);
    transition: color var(--color-transition-duration) var(--ease);
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
    row-gap: var(--row-gap);
    padding:  ${getCssSizeValue(30, BREAKPOINTS.MOBILE)} 0;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        --row-gap: ${getCssSizeValue(124, BREAKPOINTS.TABLET)};
        padding:  ${getCssSizeValue(50, BREAKPOINTS.TABLET)} 0;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --row-gap: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
        padding:  ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)} 0;
    }
`


const Container = styled.div`
    display: flex;
    width: max-content;
    column-gap: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    transform: translateX(100vw);
    padding-right: var(--paddingX);
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        column-gap: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        max-height: calc(100% - var(--row-gap) - 4px);
        column-gap: ${getCssSizeValue(82, BREAKPOINTS.DESKTOP)};
        padding-right: ${getCssSizeValue(116, BREAKPOINTS.DESKTOP)};
    }
`


const ProgressBar = styled.div`
    --progress: 0;
    position: relative;
    width: 142px;
    height: 4px;
    border-radius: 2px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity var(--transition-duration) var(--ease);
    &:before{
        content: ' ';
        position: absolute;
        width: inherit;
        inset: 0;
        border-radius: inherit;
        background: ${({ theme }) => theme.colors.dimGray};
    }
    &:after{
        content: ' ';
        position: absolute;
        width: calc(var(--progress) * 100%);
        inset: 0;
        border-radius: inherit;
        background: var(--secondary-color);
    }
    
    &.visible{
        opacity: 1;
    }
    
    
`

const PinSpacer = styled.div`

`

const CardsSlider = forwardRef(function Slider({ data, id, hasFixedId, isDirectElementCardSource = false, ...props}, fref){
    const cards = data || []
    const SpacerRef = useRef()
    const ContainerRef = useRef()
    const WrapperRef = useRef()
    const ProgressRef = useRef()
    const windowSize = useWindowSize()
    const isDesktop = useIsDesktop()

    useImperativeHandle(fref, () => WrapperRef.current, [])

    useGSAP(() => {
        const vWidth = windowSize?.width || 0
        const multiplier = isDesktop ? 1 : 3
        const x = -(ContainerRef.current.scrollWidth - vWidth) + "px";
        const tl = gsap.timeline()
        tl.to(ContainerRef.current,
            {
                x,
                ease: 'none',
                scrollTrigger: {
                    id: `${id}-slider`,
                    trigger: WrapperRef.current,
                    start: 'top top',
                    end: `+=${ContainerRef.current.scrollWidth * multiplier}`,
                    // markers: true,
                    pin: true,
                    pinSpacer: SpacerRef.current,
                    pinSpacing: true,
                    scrub: true,
                    onEnter: () => {
                        ProgressRef.current.classList.add('visible')
                    },
                    onEnterBack: () => {
                        ProgressRef.current.classList.add('visible')
                    },
                    onLeave: () => {
                        ProgressRef.current.classList.remove('visible')
                        // ScrollTrigger.refresh()
                    },
                    onLeaveBack: () => {
                        ProgressRef.current.classList.remove('visible')
                    },
                    onUpdate: ({ progress }) => {
                        setCssVars(ProgressRef.current, { progress })
                    }
                }
            }
        )
    }, {
        dependencies: [windowSize.width, isDesktop],
        scope: WrapperRef.current,
        revertOnUpdate: true
    })

    return (
        <PinSpacer id={hasFixedId ? 'our-values-slider' : null} ref={SpacerRef}>
            <Wrapper ref={WrapperRef}>
                <Container ref={ContainerRef}>
                    {cards.map((el, index) => {
                        return <Card data={isDirectElementCardSource ? el : el?.card || {}} key={el?.id || index}/>
                    })}
                </Container>
                <ProgressBar ref={ProgressRef}/>
            </Wrapper>
        </PinSpacer>

    );
});

export default CardsSlider;
