import {usePageTransitionContext} from "./index";
import styled, { keyframes } from "styled-components";
import {AnimatePresence, motion} from "framer-motion";
import {Icon} from "../../../index";
import {ICONS} from "@repo/utils/constants";
import {getCssSizeValue} from "@repo/utils";
import {useEffect, useState} from "react";

const colorChangeAnimation = keyframes`
    0% {
        fill: #EE6255;
    }
    25% {
        fill: #1BBDD5
    }
    50% {
        fill: #8973F9;
    }
    75% {
        fill: #47B68D;
    }
    100% {
        fill: #EE6255;
    }
`

const LoaderContainer = styled(motion.div)`
    position: relative;
    width: ${getCssSizeValue(48)};
    height: ${getCssSizeValue(22)};
    
    svg {
        path,
        circle {
            animation: ${colorChangeAnimation} 4s infinite ease;
            fill: ${({ $color }) => $color || 'var(--secondary-color)'};
        }
    }
`

const loaderVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 }
};

export default function Loader() {
    const { isLoaded } = usePageTransitionContext()

    // Prevents FOC
    const [isFirstRender, setIsFirstRender] = useState(true)

    useEffect(() => {
        setIsFirstRender(false)
    }, []);

    return (
        <AnimatePresence mode='wait'>
            {!isFirstRender && !isLoaded && (
                <LoaderContainer
                    variants={loaderVariants}
                    initial='hidden'
                    animate='visible'
                    exit='exit'
                >
                    <Icon name={ICONS.PRELOADER} />
                </LoaderContainer>
            )}
        </AnimatePresence>
    )
}