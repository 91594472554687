import React, {useCallback, useRef, useState} from 'react';
import styled from 'styled-components';
import Image from '../common/Image'
import {getCssSizeValue, getFontSize} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import {useRouter} from "next/router";
import Reveal from "../../animations/Reveal";

const ProductsGridContainer = styled.div`
    position: relative;
    margin-top: 5px;
    padding-bottom: 200px;
`

const StyledSingleProductsRow = styled.div`
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    margin-bottom: 45px;
    &.one {
        grid-template-columns: 1fr;
    }
    &.two {
        grid-template-columns: 1fr;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    &.three {
        grid-template-columns: 1fr;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
`

const StyledSingleProductCover = styled.div`
    aspect-ratio: 4/3;
    width: 100%;
`

const StyledSingleProductWrapper = styled.div`
    width: 100%;
`

const SmallHeading = styled.div`
	color: #a6a6a6;
    text-transform: uppercase;
	font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
	font-weight: ${({theme}) => theme.fontWeight.regular};
    margin-top: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
	    font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
	    line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        margin-top: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
	    font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
	    line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        margin-top: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
    }
`

const ImageCaption = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    color: ${({theme}) => theme.colors.white};
	font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
	    font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
	    line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
	    font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
	    line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
    }
`

const BigHeading = styled.div`
	color: var(--secondary-color);
	font-size: ${getFontSize(34, BREAKPOINTS.MOBILE)};
	line-height: ${getFontSize(54, BREAKPOINTS.MOBILE)};
	font-weight: ${({theme}) => theme.fontWeight.regular};
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
	    font-size: ${getFontSize(34, BREAKPOINTS.TABLET)};
	    line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
	    font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
	    line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
	}
`

const customStyles = {
    overlay: {
        zIndex: '100001',
    },
};

const ProductsGrid = ({data}) => {
    const {locale} = useRouter();
    const items = data?.productsGridItems || [];
    let rows = []
    items.forEach((el) => {
        const typename = el.__typename;
        if(typename === 'ProductsOneInRowRecord') {
            rows.push({
                type: "one",
                products: [el?.chooseProduct] || []
            })
        } else if (typename === 'ProductsTwoInRowRecord') {
            rows.push({
                type: "two",
                products: el?.chooseProduct || []
            })
        } else {
            rows.push({
                type: "three",
                products: el?.chooseProduct || []
            })
        }
    })
    let coverImageUrls = [];
    rows.forEach(obj => {
        obj.products.forEach((product) => {
            // coverImageUrls.push(product.coverImage.url);
            coverImageUrls.push({
                url: product?.coverImage?.url || '',
                text: product?.productName || '',
            });
        });
    });
    const [isGalleryOpened, setIsGalleryOpened] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const clientPlaceholder = locale === 'pl' ? 'KLIENT' : 'CLIENT';
    return (
        <ProductsGridContainer className='px-main'>
            {isGalleryOpened &&<Lightbox
                mainSrc={coverImageUrls[photoIndex].url}
                nextSrc={coverImageUrls[(photoIndex + 1) % coverImageUrls.length].url}
                prevSrc={coverImageUrls[(photoIndex + coverImageUrls.length - 1) % coverImageUrls.length].url}
                reactModalStyle={customStyles}
                onCloseRequest={() => setIsGalleryOpened(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + coverImageUrls.length - 1) % coverImageUrls.length)
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % coverImageUrls.length)
                }
                imageCaption={<ImageCaption>{coverImageUrls[photoIndex]?.text || 'product'}</ImageCaption>}
            />}
            {rows.map((item) => {
                const type = item?.type || '';
                const products = item?.products || [];
                return(
                    <StyledSingleProductsRow className={type}>
                        {products && products.map((item) => {
                            const productName = item?.productName || ''
                            const client = item?.client || ''
                            const imageUrl = item?.coverImage?.url || '';
                            const productIndex = coverImageUrls.findIndex(p => p.url === imageUrl && p.text === productName) || 0;
                            return(
                            <StyledSingleProductWrapper>
                                <Reveal onInView>
                                    <StyledSingleProductCover onClick={() => {
                                        setPhotoIndex(productIndex);
                                        setIsGalleryOpened(!isGalleryOpened)}}
                                    >
                                        <Image src={imageUrl} sizes="100%" />
                                    </StyledSingleProductCover>
                                    <SmallHeading>{clientPlaceholder}: {client}</SmallHeading>
                                    <BigHeading>{productName}</BigHeading>
                                </Reveal>
                            </StyledSingleProductWrapper>
                        )})}
                    </StyledSingleProductsRow>
                )
            })}
        </ProductsGridContainer>
    );
};

export default ProductsGrid;
