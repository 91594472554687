import React, {useRef, useState} from 'react';
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import styled from 'styled-components';
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";

const StyledWrapper = styled.div``;

const StyledQuestionWrapper = styled.div`
	display: flex;
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
    padding: 0;
    &:hover {
        .dot {
            transition: background-color 0.3s ease-in-out;
            background-color: ${theme.colors.twilightBlue};
        }
    }
`;

const StyledDot = styled.div`
    border-radius: 50%;
    border: 1px solid ${theme.colors.twilightBlue};
    background-color: ${theme.colors.white};
    display: none;
    transition: background-color 0.3s ease-in-out;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        display: block;
        width: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
        min-width: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
        height:${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
    }
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        width: ${getCssSizeValue(6, BREAKPOINTS.DESKTOP)};
        min-width: ${getCssSizeValue(6, BREAKPOINTS.DESKTOP)};
        height: ${getCssSizeValue(6, BREAKPOINTS.DESKTOP)};
    }
    &.active {
        transition: background-color 0.3s ease-in-out;
        background-color: ${theme.colors.twilightBlue};
    }
`

const StyledQuestionNumber = styled.div`
	display: none;
	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border: 1px solid ${theme.colors.twilightBlue};
		min-width: 42px;
		min-height: 42px;
		margin-right: ${getCssSizeValue(126, BREAKPOINTS.TABLET)};
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        line-height: normal;
        margin-left: ${getCssSizeValue(54, BREAKPOINTS.TABLET)};
	}
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        margin-left: ${getCssSizeValue(152, BREAKPOINTS.DESKTOP)};
        margin-right: ${getCssSizeValue(227, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        min-width: 62px;
        min-height: 62px;
    }
`;

const StyledFaqQuestion = styled.div`
	cursor: pointer;
	flex-grow: 1;
    color: ${theme.colors.twilightBlue};
    font-style: normal;
    font-weight: 400;
    margin: ${getCssSizeValue(49, BREAKPOINTS.MOBILE)} 0 ${getCssSizeValue(18, BREAKPOINTS.MOBILE)};
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(31, BREAKPOINTS.TABLET)};
        margin: ${getCssSizeValue(66, BREAKPOINTS.TABLET)} 0 ${getCssSizeValue(46, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
        margin: ${getCssSizeValue(54, BREAKPOINTS.DESKTOP)} 0 ${getCssSizeValue(44, BREAKPOINTS.DESKTOP)};
    }
`;

const StyledFaqAnswer = styled.div`
	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        padding-left: ${getCssSizeValue(236, BREAKPOINTS.TABLET)};
	}
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-left: ${getCssSizeValue(540, BREAKPOINTS.DESKTOP)};
    }
    max-height: 1px;
    opacity: 0;
    overflow-y: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
    &.open {
        max-height: 500px;
        opacity: 1;
        transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
        padding-bottom: 18px;
            @media(min-width:${theme?.breakpoints?.tablet || 768}px){
            padding-bottom: 48px;
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            padding-bottom: 60px;
        }
    }
`;

const StyledAnswer = styled.div`
    color: ${theme.colors.twilightBlue};
    font-weight: 400;
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(31, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(41, BREAKPOINTS.DESKTOP)};
    }
`

const SingleQuestionAndAnswer = ({index, question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);
    const answerRef = useRef(null);
    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <StyledWrapper data-cursor={isOpen ? CURSOR_TYPES.MARQUEE_EXPANDED : CURSOR_TYPES.MARQUEE}>
            <StyledQuestionWrapper onClick={() => handleClick()}>
                <StyledDot className={isOpen ? 'active dot' : 'dot'}></StyledDot>
                <StyledQuestionNumber>0{index + 1}</StyledQuestionNumber>
                <StyledFaqQuestion dangerouslySetInnerHTML={getHtmlFromRedactorField(question)} />
            </StyledQuestionWrapper>
            <StyledFaqAnswer ref={answerRef} className={isOpen ? 'open' : ''}>
                <StyledAnswer className={isOpen ? 'open' : ''} dangerouslySetInnerHTML={getHtmlFromRedactorField(answer)}/>
            </StyledFaqAnswer>
        </StyledWrapper>
    )
};

export default SingleQuestionAndAnswer;
