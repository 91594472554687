import React from 'react';
import styled from "styled-components";
import {
    getColorScheme,
    getCssSizeValue, getFontSize,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import SingleQuestionAndAnswer from "./SingleQuestionAndAnswer";
import {BREAKPOINTS, theme} from "../../../styles/themeSettings";
import TextWithBreaks from "../common/TextWithBreaks";
import {CURSOR_TYPES} from "@repo/utils/constants";

const StyledFaqWrapper = styled.div`
	flex-direction: column;
	width: 100%;
    position: relative;
    background-color: ${({theme, noBackground}) => noBackground ? 'transparent' : theme.colors.lightGray};
    padding-top: 40svh;
    display: flex;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		justify-content: space-between;
	}
`;

const StyledQuestionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & > div {
        border-top: 1px solid ${theme.colors.twilightBlue};
        width: 100%;
        padding-bottom: ${getCssSizeValue(31, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            padding-bottom: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            padding-bottom: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
        }
        &:last-child {
            border-bottom: 1px solid ${theme.colors.twilightBlue};
        }
    }
`

const Header = styled(TextWithBreaks)`
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
    font-weight: 400;
    text-align: right;
    margin: 0 0 ${getCssSizeValue(65, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin: 0 0 ${getCssSizeValue(85, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin: 0 0 ${getCssSizeValue(138, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(116)};
        line-height: ${getFontSize(136)};
        letter-spacing: -4.6px;
    }
    em {
        font-weight: 200;
        font-style: italic;
    }
`

const FAQ = ({ data, noBackground }) => {
    const id = getIdWithoutSpecialSymbols(data?.id);
    const text = { parentId: data?.text?.id || '', ...data?.text?.text }
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)
    const questions = data?.questions || [];
    const header = text?.parentId?.length > 0 ? text : data?.header || [];

    return (
        <StyledFaqWrapper
            id={id}
            noBackground={noBackground}
            data-color-scheme={colorScheme}
            data-cursor={CURSOR_TYPES.HTML}
            className={`${paddings} px-main`}
        >
            <Header data={header} color={theme.colors.twilightBlue} left/>
            <StyledQuestionsWrapper>
                {questions.map((item, key) => {
                    const question = item?.question || '';
                    const answer = item?.answer || '';
                    return (
                        <div key={question}>
                            <SingleQuestionAndAnswer index={key} question={question} answer={answer}></SingleQuestionAndAnswer>
                        </div>
                    )
                })}
            </StyledQuestionsWrapper>
        </StyledFaqWrapper>
    );
};

export default FAQ;
