import { createGlobalStyle } from 'styled-components';
import { getFontSize } from '../../utils';
import { BREAKPOINTS } from './themeSettings';

const Typography = createGlobalStyle`

	//Mobile
	@media (max-width:  ${({ theme }) => theme.breakpoints[BREAKPOINTS.TABLET] - 1}px) {
		h1 {
			font-size: ${getFontSize(48, BREAKPOINTS.MOBILE)};
		}

		h3 {
			font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
		}

		a {
			font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
		}
	}

	//Tablet
	@media (min-width:  ${({ theme }) => theme.breakpoints[BREAKPOINTS.TABLET]}px) and (max-width:  ${({ theme }) =>
		theme.breakpoints[BREAKPOINTS.DESKTOP] - 1}px){
		h1 {
			font-size: ${getFontSize(48, BREAKPOINTS.TABLET)};
		}

		h3 {
			font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
		}

		a {
			font-size: ${getFontSize(12, BREAKPOINTS.TABLET)};
		}
	}

	//Desktop
	@media (min-width:  ${({ theme }) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) {
		h1 {
			font-size: ${getFontSize(96, BREAKPOINTS.DESKTOP)};
		}

		h3 {
			font-size: ${getFontSize(40, BREAKPOINTS.DESKTOP)};
		}

		a {
			font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
		}
	}

`;

export default Typography;
