import React, {createContext, memo} from 'react';
import {GlobalCanvas} from "@14islands/r3f-scroll-rig";
import {SCALE_MULTIPLIER} from "@repo/utils/constants";
import {useThree} from "@react-three/fiber";
import useCustomContext from "../../hooks/useCustomContext";
import {useIsDesktop} from "../../../index";

const ForwardedThree = createContext({});

export const ForwardThree = ({ children }) => {
    const three = useThree();

    return (
        <ForwardedThree.Provider value={three} >
            {children}
        </ForwardedThree.Provider>
    )
}

// for components rendered in the another render target
// some of the values of r3f useThree (like pointer) does not get updated in time
// since they are replaced wit original scene
// this workaround forwards proper context for them
export function useForwardedThree(selector = undefined) {
    return useCustomContext(ForwardedThree, selector)
}

const Canvas = ({ visible=true, eventSource, children }) => {
    const isDesktop = useIsDesktop()
    return (
        <GlobalCanvas
            // key={isDesktop}
            gl={{
                // preserveDrawingBuffer: true,
                stencil: true
            }}
            debug={false} //increases performance
            camera={{
                fov: 15,
            }}
            //changing event source to parent element allows grabbing events from html elements
            //https://github.com/14islands/r3f-scroll-rig#gotchas-
            eventSource={eventSource}
            eventPrefix={'client'}
            style={{
                pointerEvents: 'none',
                zIndex: !visible ? -1 : 'unset',
                visibility: !visible ? 'hidden' : 'unset'
            }}
            scaleMultiplier={SCALE_MULTIPLIER} //fixes z-fighting
            flat
        >
            {children}
        </GlobalCanvas>
    );
};

export default memo(Canvas);
