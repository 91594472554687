import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {ScrollTrigger, useGSAP} from "@repo/utils/gsap";
import {useIsDesktop, useWindowSize} from "../../../index";
import {alternateProgress, getRangeProgress} from "@repo/utils";

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
`

const PinnedElement = ({
   children,
   endTrigger=null,
   className='',
   animations,
   offset=0,
   end = 'bottom bottom',
   ...props
}) => {
    const WrapperRef = useRef()
    const [trg, setTrg] = useState(null)
    const windowSize = useWindowSize()
    const isDesktop = useIsDesktop()
    // const [readyAnimations, setReadyAnimations] = useState([])
    const animData = useMemo(() => {
        const anim = animations || []
        const range = (1 - offset) / anim.length
        return anim.map((tl, index) => {
            const floor = index * range
            const ceil = floor + range
            return {
                tl,
                floor,
                ceil,
            }
        })
    },[animations, offset])

    useEffect(() => {
        setTrg(endTrigger?.current || WrapperRef.current)
    },[])

    // useEffect(() => {
    //     const updated = animData.map(() => false)
    //     setReadyAnimations(updated)
    // },[animData])


    // useEffect(() => {
    //     animData.forEach((animation, index) => {
    //         const shouldPlay = readyAnimations[index]
    //         if(shouldPlay) {
    //             animation.tl.play()
    //         } else {
    //             animation.tl.reverse()
    //         }
    //     })
    // },[readyAnimations])

    useGSAP(() => {
        const revertStart = 1 - offset

        ScrollTrigger.create({
            trigger: WrapperRef.current,
            endTrigger: trg,
            end: end,
            // markers: true,
            pin: true,
            pinSpacing: false,
            onUpdate: ({ progress }) => {
                // let updatedArray = [false]
                // if(progress > 1 - offset){
                //     animData.forEach((animation, index) =>  updatedArray[index] = false)
                // } else {
                //     animData.forEach((animation, index) => {
                //         const { floor, ceil, tl } = animation
                //         updatedArray[index] = progress > floor
                //     })
                // }
                // setReadyAnimations((prevState) =>{
                //     if(JSON.stringify(prevState) !== JSON.stringify(updatedArray)) return updatedArray
                //     return prevState
                // })
                // console.log(updatedArray);


                if(isDesktop) {
                    if(progress > revertStart) {
                        //reverting animation
                        const prg = 1 - getRangeProgress(progress, revertStart, 1)  //common progress so they revert together
                        animData.forEach((animation, index, arr) => {
                            if(arr.length > 1 && index === arr.length - 1) return //skip reverting last animation
                            animation.tl.progress(prg)
                        })
                    } else {
                        //animating with individual progress
                        animData.forEach((animation) => {
                            const {floor, ceil, tl} = animation
                            const prg = getRangeProgress(progress, floor, ceil)
                            tl.progress(prg)
                        })
                    }
                } else {
                    if(progress > revertStart) {
                        const prg = 1 - getRangeProgress(progress, revertStart, 1)  //common progress so they revert together
                        // console.log(prg, progress);
                        animData[0].tl.progress(prg)
                    } else {
                        animData.forEach((animation, index, array) => {
                            const {floor, ceil, tl} = animation
                            const ceiling = array.length === 1 ? revertStart : ceil
                            const prg = getRangeProgress(progress, floor, ceiling)
                            // console.log(prg, progress);
                            if(index === 0){
                                tl.progress(prg)
                            } else {
                                const alternatingPRG = alternateProgress(prg)
                                tl.progress(alternatingPRG)
                            }
                        })
                    }
                }

            }
        })
    },{
        dependencies: [trg, windowSize.width, animData, offset, end, isDesktop],
        revertOnUpdate: true,
    })

    return (
        <Wrapper
            ref={WrapperRef}
            className={`px-main ${className} pt-medium pb-medium`}
            {...props}
        >
            {children}
        </Wrapper>
    );
};

export default PinnedElement;
