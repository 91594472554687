import React, {createContext, useEffect, useMemo, useState} from 'react';
import {useIsDebug, usePageTransitionContext} from "../../index";
import {isFunction} from "@repo/utils";
import useCustomContext from "./useCustomContext";

export const LoadingHandler = ({ onLoad, children }) => {
    const debug = useIsDebug()

    useEffect(() => {
        if(isFunction(onLoad)){
            onLoad()
        }

        if(debug){
            console.log('test loaded');
        }
        return ()=> {
            if(debug){
                console.log('test exit');
            }
        }
    },[])

    return children
}

const INIT_CONTEXT = {
    webgl: false,
    html: false,
    onWebGLLoaded: () => {},
    onHTMLLoaded: () => {}
}

const LoadedContext = createContext(INIT_CONTEXT);

export const LoadedProvider = ({ children }) => {
    const [webGLLoaded, setWebGLLoaded] = useState(false)
    const [htmlLoaded, setHtmlLoaded] = useState(false)
    const { setIsLoaded } = usePageTransitionContext()
    const context = useMemo(() => {
        return ({
            webgl: webGLLoaded,
            html: htmlLoaded,
            onWebGLLoaded: () => setWebGLLoaded(true),
            onHTMLLoaded: () => setHtmlLoaded(true),
        })
    }, [webGLLoaded, htmlLoaded])

    useEffect(() => {
        if(!htmlLoaded || !webGLLoaded) return
        console.log('loaded');
        setIsLoaded(true)
    },[webGLLoaded, htmlLoaded])

    return (
        <LoadedContext.Provider value={context}>
            {children}
        </LoadedContext.Provider>
    );
};

export default function useLoadedManager(selector) {
    return useCustomContext(LoadedContext, selector)
}
