import styled from "styled-components";
import HorizontalHeader from "./HorizontalHeader";
import {getColorScheme, getPaddingsClassFromData} from "@repo/utils";

const Wrapper = styled.div`
    width: 100%;
    position: relative;
    z-index: 2;
    background: ${({$bgTransparent}) => $bgTransparent ? 'transparent' : 'var(--primary-color)'};
    transition: all .2s;
`

export default function HorizontalHeaderModule({ data }) {
    const text = data?.text || ''
    const colorScheme = getColorScheme(data);
    const paddings = getPaddingsClassFromData(data)
    const isBgTransparent = data?.transparentBackground
    const direction = data?.direction || ''

    return (
        <Wrapper data-color-scheme={colorScheme} className={paddings} $bgTransparent={isBgTransparent}>
            <HorizontalHeader text={text} id={data?.id} bgTransparent={isBgTransparent} direction={direction} />
        </Wrapper>
    )
}