import { configureStore } from '@reduxjs/toolkit';
import showCookies from '@/state/showCookiesSlice';
import menu from '@/state/menuSlice';

export default configureStore({
	reducer: {
		showCookies,
		menu,
	},
});
