import React from 'react';
import styled from 'styled-components';
import {getCssSizeValue, getFontSize, getIdWithoutSpecialSymbols, getPaddingsClassFromData} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import SingleCheckOtherService from "./SingleBlock";
import {Reveal} from "../../../index";

const StyledCheckOtherServicesWrapper = styled.div`
    position: relative;
    display: block;
    row-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        display: grid;
        grid-template-columns: min-content min-content;
        column-gap: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
        row-gap: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        column-gap: ${getCssSizeValue(90, BREAKPOINTS.DESKTOP)};
        row-gap: ${getCssSizeValue(30, BREAKPOINTS.DESKTOP)};
    }
    
`
const CheckOtherServices = ({data}) => {
    const id = getIdWithoutSpecialSymbols(data?.id);
    const paddings = getPaddingsClassFromData(data)
    const items = data?.chooseSeeAlsoBlocks || [];
    return (
        <Reveal onInView={true}>
            <StyledCheckOtherServicesWrapper id={id} className={`${paddings} px-main`}>
                {items.map((item) => {
                    const title = item?.title || '';
                    return(
                        <SingleCheckOtherService key={title} data={item}/>
                    )
                })}
            </StyledCheckOtherServicesWrapper>
        </Reveal>
    );
};

export default CheckOtherServices;
