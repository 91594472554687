import {useIsDebug} from "../../../../index";
import {LensGeometry} from "./StaticLens";
import {Mask} from "@react-three/drei";
import React from "react";
import {PORTAL_LENS_RADIUS} from "@repo/utils/constants";

const LensMask = ({ id=1, ...props}) => {
    const isDebug = useIsDebug()
    return (
        <group {...props}>
            {isDebug && <Frame />}
            <Mask id={id} >
                <LensGeometry/>
            </Mask>
        </group>
    )
}

const Frame = (props) => (
    <mesh {...props}>
        <ringGeometry args={[PORTAL_LENS_RADIUS ,PORTAL_LENS_RADIUS + 0.1,64]}  />
        <meshBasicMaterial color="skyblue" />
    </mesh>
)

export default LensMask
