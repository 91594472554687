import React from 'react';
import Tile from "./Tile";
import {
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import styled from "styled-components";
import Text from "../common/Text";
import Button, {getButtonBlockData} from "../common/Button";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";

const Wrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    min-height: 100svh;
    flex-direction: column;
    row-gap: ${getCssSizeValue(129)};
    
`

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    .text{
        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(31, BREAKPOINTS.MOBILE)};
        font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
    }
    
    .button{
        display: none;
        height: fit-content;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        .text{
            font-size: ${getFontSize(48)};
            line-height: ${getFontSize(66)};
        }
        display: block;
    }
`

const Items = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
        grid-template-columns: repeat(2, 1fr);
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(20)};
        grid-template-columns: repeat(4, 1fr);
    }
`

const Tiles = ({ data }) => {
    const id = getIdWithoutSpecialSymbols(data?.id || '');
    const items = data?.items || []
    const paddings = getPaddingsClassFromData(data)
    const header = getHtmlFromRedactorField(data?.header || '')
    const buttonData = getButtonBlockData(data)
    return (
        <Wrapper id={id} className={`${paddings} px-main`}>
            <Top>
                <Text as="div" dangerouslySetInnerHTML={header}/>
                <Button as="link" href={buttonData.link} variant="tertiary">
                    {buttonData.text}
                </Button>
            </Top>
            <Items data-cursor={CURSOR_TYPES.CHECK}>
                {items.map((item, index) => {
                    return (
                        <Tile key={item?.id || index} data={item}/>
                    )
                })}
            </Items>
        </Wrapper>
    );
};

export default Tiles;