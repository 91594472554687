import * as React from "react"
const HeaderArrowRight = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={5}
        height={7}
        fill="none"
        {...props}
    >
        <path
            fill="#04232F"
            d="M4.478 2.929C3.848 2.289 1.846.227 1.846.227c-.21.091-.417.238-.599.437-.182.197-.315.419-.392.637l2.207 2.244L.855 5.789c.077.218.21.437.392.637.182.197.392.345.599.437l.745-.783 1.887-1.92c.164-.166.312-.338.315-.615-.003-.277-.151-.45-.315-.616Z"
        />
    </svg>
)
export default HeaderArrowRight
