import {useState, useRef} from 'react';
import styled from "styled-components";
import { ScrollTrigger, gsap, useGSAP } from '@repo/utils/gsap'
import {
    getAspectRatioFromImage,
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols
} from "@repo/utils";
import {AnimatePresence, motion} from "framer-motion";
import {Button, useIsDesktop, useWindowSize} from "../../../index";
import {BREAKPOINTS, LOGO_COLORS} from "../../../styles/themeSettings";
import revealAnimationContainer from "../common/RevealAnimationContainer";
import {CURSOR_TYPES} from "@repo/utils/constants";
import Image from "../common/Image";

const REVEAL_ANIMATION_TARGET_CLASS = 'branches-reveal-up-animation-target'

const StyledWrapper = styled.div`
    overflow: hidden;
    width: 100%;
    transition: all .3s;
    height: ${({$height}) => $height ? $height : 120}svh;
    background: ${({$backgroundColor}) => $backgroundColor ? $backgroundColor : 'var(--primary-color)'};
    position: relative;
    z-index: 1;
`

const StyledMiddleContent  = styled.div`
    display: flex;
    justify-content: center;    
    align-items: center;
    flex-direction: column;
    gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(24)};
    }
`

const StyledLogoWrapper = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const StyledButtonWrapper = styled(revealAnimationContainer('div', REVEAL_ANIMATION_TARGET_CLASS))`
    display: flex;
    align-items: center;
`

const CustomCursorArea = styled.a`
    display: none;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: block;
        z-index: 2;
        background: transparent;
        position: absolute;
        margin: 0 auto;
        width: 40vw;
        height: 40svh;
    }
`

const RevealWrapper = revealAnimationContainer('div', REVEAL_ANIMATION_TARGET_CLASS)

const StyledLogoIcon = styled(motion.div)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    
    .icon{
        max-width: unset;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        height: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: ${getCssSizeValue(115, BREAKPOINTS.DESKTOP)};
    }
`

const TextWrapper = styled(revealAnimationContainer('div', REVEAL_ANIMATION_TARGET_CLASS))`
    width: 100%;
    display: flex;
    justify-content: center;
`

const StyledText = styled(motion.div)`
    color: var(--primary-color);
    font-weight: 400;
    font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
    em {
        font-weight: 200;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(36)};
    }
`

const StyledDescriptionWrapper = styled.div`
`

const StyledDescription = styled(motion.div)`
    color: var(--primary-color);
    text-transform: uppercase;
    text-align: center;
    font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        text-align: start;
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        transform: translateY(${getCssSizeValue(-100, BREAKPOINTS.TABLET)});
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(24)};
        max-width: ${getCssSizeValue(1200)};
        transform: unset;
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: auto;
    height: 100%;
`

const PleoBranches = ({ data }) => {
    const id = getIdWithoutSpecialSymbols(data?.id);
    const items = data?.items || []
    const redirectButtonText = data?.redirectButtonText || ''
    const colorScheme = getColorScheme(data)
    const isDesktop = useIsDesktop()
    const windowSize = useWindowSize()
    const wrapperRef = useRef(null);
    const [activeItem, setActiveItem] = useState({...items[0], backgroundColor: null})

    useGSAP(() => {
        const mm = gsap.matchMedia();
        mm.add({
            //TODO: would be nice to use breakpoints from theme in here
            small: '(max-width: 767px)',
            tablet: `(max-width: 1023px) and (min-width: 768px)`,
            desktop: `(min-width: 1024px) and (orientation: landscape)`
        }, (ctx) => {
            const { desktop, tablet, small } = ctx.conditions;
            gsap.set('.branches-styled-middle-content', {
                y: desktop ? '50svh' : tablet ? '50svh' : '40svh'
            })

            gsap.set('.branches-description', {
                y: desktop ? '80svh' : tablet ? '85svh' : '60svh'
            })

            gsap.utils.toArray('.branches-pin-content').forEach((el, index) => {
                ScrollTrigger.create({
                    id: 'middle-content-wrapper' + index,
                    trigger: wrapperRef.current,
                    start: `${desktop || small ? 'top top-=15%' : 'top top-=20%' }`,
                    end: `bottom bottom`,
                    pin: el,
                    pinSpacing: false,
                })
            })

            ScrollTrigger.create({
                trigger: wrapperRef.current,
                start: `top top`,
                endTrigger: wrapperRef.current,
                onEnter() {
                    gsap.to(`.${REVEAL_ANIMATION_TARGET_CLASS}`, { y: 0, stagger: 0.1 })
                },
                onLeaveBack() {
                    gsap.to(`.${REVEAL_ANIMATION_TARGET_CLASS}`, { y: '100%', stagger: -0.1 })
                }
            })

            items.forEach((item, index) => {
                ScrollTrigger.create({
                    trigger: wrapperRef.current,
                    start: `top+=${(index / items.length) * 90}% top`,
                    endTrigger: wrapperRef.current,
                    invalidateOnRefresh: true,
                    onEnter: () => {
                        setActiveItem(items[index])
                    },
                    onLeaveBack: () => {
                        if (index === 0) {
                            setActiveItem({...items[0], backgroundColor: null})
                        } else {
                            setActiveItem(items[index-1])
                        }
                    }
                })
            })
        })
    }, { scope: wrapperRef, dependencies: [windowSize.width,items], revertOnUpdate: true })

    return (
        <StyledWrapper
            id={id}
            ref={wrapperRef}
            data-logo-color={LOGO_COLORS.LIGHT}
            data-cursor={CURSOR_TYPES.HTML}
            data-color-scheme={colorScheme}
            $height={items.length * (isDesktop ? 150 : 300)}
            $backgroundColor={activeItem?.backgroundColor?.hex}
        >
            <StyledMiddleContent
                className='branches-pin-content branches-styled-middle-content'
            >
                <CustomCursorArea
                    data-cursor={CURSOR_TYPES.CHECK}
                    href={activeItem?.link || ''}
                    target='_blank'
                />
                <RevealWrapper>
                    <StyledLogoWrapper>
                        <AnimatePresence mode="wait">
                            <StyledLogoIcon
                                key={activeItem?.id || ''}
                                initial={{ y: '100%'}}
                                animate={{ y: 0 }}
                                exit={{y: '-100%'}}
                            >
                                <StyledImage
                                    src={activeItem?.logoImage?.url || ''}
                                    alt={activeItem?.logoImage?.alt || ''}
                                    sizes='100vw'
                                    $aspectRatio={getAspectRatioFromImage(activeItem?.logoImage)}
                                />
                            </StyledLogoIcon>
                        </AnimatePresence>
                    </StyledLogoWrapper>
                </RevealWrapper>

                <TextWrapper>
                    <AnimatePresence mode='wait' initial={false}>
                        <StyledText
                            key={activeItem?.id || ''}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            dangerouslySetInnerHTML={getHtmlFromRedactorField(activeItem?.subheader || '')}
                        />
                    </AnimatePresence>
                </TextWrapper>

                {!isDesktop &&
                    <StyledButtonWrapper className='branches-button-wrapper'>
                        <Button href={activeItem?.link || ''} target='_blank' as='a' className='no-border'>{redirectButtonText}</Button>
                    </StyledButtonWrapper>
                }

            </StyledMiddleContent>
            <StyledDescriptionWrapper className='px-main branches-description branches-pin-content'>
                <AnimatePresence mode='wait'>
                    <StyledDescription
                        dangerouslySetInnerHTML={getHtmlFromRedactorField(activeItem?.description || '')}
                        key={activeItem?.id || ''}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                    />
                </AnimatePresence>
            </StyledDescriptionWrapper>
        </StyledWrapper>
    );
};

export default PleoBranches;
