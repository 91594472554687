import Head from "next/head";
import { renderMetaTags } from 'react-datocms';
import { useMemo } from 'react';
import { LOCALES } from '@repo/utils/constants';
import { useRouter } from 'next/router';
import { slugify } from '@repo/utils';

export default function Seo({ data = [] }) {
    const metaTags = data?._seoMetaTags || [];
    const router = useRouter();
    const { locale, asPath } = router
    const canonicalHref = useMemo(() => {
        let slug = `${asPath || ''}`.replace('/', '')
        const localePrefix = locale === LOCALES.EN ? '' : `${LOCALES.PL}/`
        if(slug === 'home'){
            slug = ''
        }
        return slugify(`${process.env.SITE_URL}${localePrefix}${slug}`)
    }, [asPath, locale])
    
    return (
        <Head>
            {renderMetaTags(metaTags)}
            <link
                rel="canonical"
                href={canonicalHref}
                hrefLang={locale}
                key={`canonical-${locale}`}
            />
            <meta name="robots" content="index, follow" />
        </Head>
    )
}
