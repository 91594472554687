import { Provider as Redux } from 'react-redux';
import store from '@/state/store';
import React, {useEffect, useMemo, useState} from 'react';
import Layout from '@/layout/Layout';
import {useRouter} from "next/router";
import {GlobalsProvider, Seo, usePageTransitionContext} from "@repo/ui";
import Head from "next/head";
import dynamic from "next/dynamic";

const Theme = dynamic(() => import('@repo/ui').then((mod) => mod.Theme), {
	loading: () => {},
	ssr: false
});
const Debug = dynamic(() => import('@repo/ui').then((mod) => mod.Debug), {
	loading: () => {},
	ssr: false
});
const Scroll = dynamic(() => import('@repo/ui').then((mod) => mod.Scroll), {
	loading: () => {},
	ssr: false
});
const PageTransition = dynamic(() => import('@repo/ui').then((mod) => mod.PageTransition), {
	loading: () => {},
	ssr: false
});

const LoadedProvider = dynamic(() => import('@repo/ui').then((mod) => mod.LoadedProvider), {
	loading: () => {},
	ssr: false
});


export default function App({ Component, pageProps }) {
	const { data, globals, cookies, menu, footer } = pageProps;
	const darkTheme = data?.themeDark || null;
	const lightTheme = data?.themeLight || null;
	const cookiesData = cookies?.[0] || [];
	const menuData = menu || [];
	const { asPath, locale } = useRouter()
	const [renderDelayed, setRenderDelayed] = useState(false)
	const transitionCompleted = usePageTransitionContext((state) => state.transitionCompleted)
	const othersSocials = useMemo(() =>
		globals?.rest.reduce((prev, acc) =>
			({ [acc.title.toLowerCase().slice(8)]: acc.socials, ...prev })
			, {}),
		[globals])
	const footerData = {
		socials: globals?.socials || [],
		socialsDigital: othersSocials?.digital || [],
		socialsDesign: othersSocials?.design || [],
		socialsMethod: othersSocials?.method || [],
		contact: {
			address: globals?.address || '',
			email: globals?.email || '',
			phone: globals?.phone || '',
		},
		buttons: {
			top: globals?.toTopButton || '',
			cookies: globals?.cookiesButton || '',
			policy: globals?.policyButton
		},
		...footer
	}
	//TODO: refactor DATO global items so footer and menu both uses socials
	// useNextRouterViewTransitions(router);

	useEffect(() => {
		setRenderDelayed(true)
	}, []);
	return (
		<GlobalsProvider data={globals}>
			<Redux store={store}>
				<Seo data={data} />
				<Head>
					<meta name="viewport" content="width=device-width, initial-scale=1"/>
					<link rel="icon" href="/favicon.svg"/>
				</Head>
				{renderDelayed && (
					<>
						<Debug/>
						<PageTransition>
							<LoadedProvider key={asPath}>
								<Theme lightTheme={lightTheme} darkTheme={darkTheme} forceLight={true}>
									<Scroll ready={transitionCompleted} updateDependencies={[transitionCompleted, locale]}>
										<Layout globals={globals} cookies={cookiesData} menu={menuData} data={data} footer={footerData}>
											<Component {...pageProps} />
											{/*<SpeedInsights />*/}
										</Layout>
									</Scroll>
								</Theme>
							</LoadedProvider>
						</PageTransition>
					</>
				)}
			</Redux>
		</GlobalsProvider>
	);
}
