import React, {useCallback, useRef, useState, memo, useEffect} from 'react';
import MockupVideo from "../../../assets/video/band-white-horizontal.mp4";
import { UseCanvas } from "@14islands/r3f-scroll-rig";
import useVisible3D from "../../../hooks/useVisible3D";
import HTMLImage from "../../common/Image";

import dynamic from "next/dynamic";
const TrackImageVideo = dynamic(() => import('./TrackImageVideo'), {
    loading: () => {},
    ssr: false
});

const DEFAULT_SIZES = '100%';

const ImageVideo = ({
        id,
        src = '',
        video = MockupVideo,
        alt = '',
        className = '',
        sizes = DEFAULT_SIZES,
        visible = true,
        webGL=false,
        children,
        ...props
    }) => {
    const ImageRef = useRef();
    const visible3D = useVisible3D(webGL && visible);
    const [hover, setHover] = useState(false)

    const handlePointerEnter = useCallback(() => {
        setHover(true);
    }, []);
    const handlePointerLeave = useCallback(() => {
        setHover(false);
    }, [])

    return (
        <>
            <HTMLImage
                ref={ImageRef}
                id={id}
                src={src}
                sizes={sizes}
                visible={visible}
                alt={alt}
                className={`${className} ${visible3D ? `transparent-3d` : '' } `}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
                {...props}
            >
                {children}
            </HTMLImage>
            {visible3D && (
                <UseCanvas hover={hover} video={video} src={src} el={ImageRef} >
                    <TrackImageVideo />
                </UseCanvas>
            )}
        </>
    );
};

export default memo(ImageVideo);
