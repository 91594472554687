import {
    getColorScheme,
    getCssSizeValue, getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Button, Image, Reveal, useIsMobile} from "../../../index";
import {gsap, useGSAP} from "@repo/utils/gsap"
import {useRef} from "react";
import {useRouter} from "next/router";

const StyledWrapper = styled.section`
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--secondary-color);
    gap: ${getCssSizeValue(140, BREAKPOINTS.MOBILE)};
   
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(115, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        gap: ${getCssSizeValue(200, BREAKPOINTS.DESKTOP)};
    }
`

const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        flex-direction: row;
    }

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
    }
`

const StyledSide = styled.div`
    display: flex;
    position: relative;
    &.left {
        cursor: pointer;
        justify-content: flex-end;
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            flex: 1;
            justify-content: unset;
            align-items: center;
        }
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        }
        
        .image-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${props => props.theme.colors.white};
            height: ${getCssSizeValue(224, BREAKPOINTS.MOBILE)};
            width: 80%;
            
            @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
                height: 80%;
                width: 100%;
            }

            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
                height: 100%;
            }
        }
    }
    &.right {
        z-index: 2;
        flex-direction: column;

        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            width: 48%;
        }
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
            padding-bottom: ${getCssSizeValue(34, BREAKPOINTS.DESKTOP)};
            width: 40%;
        }
        
        .header {
            font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
            margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
            letter-spacing: -1.6px;
            
            @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
                font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
                margin-bottom: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
                margin-left: ${getCssSizeValue(-55, BREAKPOINTS.TABLET)};
                letter-spacing: -3px;
            }

            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
                margin-bottom: unset;
                font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(236, BREAKPOINTS.DESKTOP)};
                margin-left: ${getCssSizeValue(-80, BREAKPOINTS.DESKTOP)};
                letter-spacing: -4.64px;
            }
        }
        
        .description {
            font-weight: ${props => props.theme.fontWeight.book};
            font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(36, BREAKPOINTS.MOBILE)};
            
            @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
                width: 100%;
                font-weight: ${props => props.theme.fontWeight.book};
                font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
                margin-left: ${getCssSizeValue(-8, BREAKPOINTS.TABLET)};
            }

            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
                width: 75%;
                font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
                margin-left: ${getCssSizeValue(72, BREAKPOINTS.DESKTOP)};
            }
        }
        
        .button {
            margin-top: ${getCssSizeValue(38, BREAKPOINTS.MOBILE)};
            
            @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
                margin-left: ${getCssSizeValue(-8, BREAKPOINTS.TABLET)};
                margin-top: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
            }
            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
                margin-left: ${getCssSizeValue(72, BREAKPOINTS.DESKTOP)};
                margin-top: ${getCssSizeValue(72, BREAKPOINTS.DESKTOP)};
            }
        }
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: 90%;
    height: auto;
`

function Tile({ item, buttonText }) {
    const router = useRouter()
    const description = item?.description || ''
    const header = item?.header || ''
    const buttonLink = item?.buttonLink?.slug || ''
    const imgSrc = item?.image?.url || ''
    const imgAlt = item?.image?.alt || ''
    const imgWidth = item?.image?.width || 0;
    const imgHeight = item?.image?.height || 1;
    const aspectRatio = imgWidth / imgHeight === 0 ? '16/9' : imgWidth / imgHeight;

    const isMobile = useIsMobile()
    const wrapperRef = useRef(null)

    useGSAP(() => {
        const mm = gsap.matchMedia();

        mm.add({
            small: '(max-width: 767px)',
            tablet: `(max-width: 1023px) and (min-width: 768px)`,
            desktop: `(min-width: 1024px) and (orientation: landscape)`
        }, (ctx) => {
            const { desktop, tablet } = ctx.conditions;

            gsap.set('.image-wrapper', {
                transformOrigin: desktop || tablet ? '0% 0%' : '100% 0%',
                scale: 0.6
            })

            gsap.to('.image-wrapper', {
                scrollTrigger: {
                    trigger: wrapperRef.current,
                    start: desktop ? 'top+=20% center' : tablet ? 'top center-=10%' : 'top center+=20%',
                    end: desktop ? 'bottom bottom-=10%' : tablet ? 'bottom bottom-=30%' : 'bottom bottom',
                },
                scale: 1,
            })
        })
    }, { scope: wrapperRef, dependencies: []})

    return (
        <TileWrapper ref={wrapperRef}>
            <StyledSide
                className='left'
                $aspectRatio={aspectRatio}
                onClick={() => router.push(`${router.asPath}/${buttonLink}`)}
            >
                <div className='image-wrapper'>
                    <StyledImage
                        src={imgSrc}
                        alt={imgAlt}
                        sizes={'100vw'}
                        $aspectRatio={aspectRatio}
                    />
                </div>
            </StyledSide>
            <StyledSide className='right'>
                <Reveal
                    onInView
                    startViewPort={isMobile ? 'bottom' : 'center'}
                    className='header'
                >
                    <p>{header}</p>
                </Reveal>
                <Reveal
                    onInView
                    startViewPort={isMobile ? 'bottom' : 'center'}
                    className='description'
                >
                    <div dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
                </Reveal>
                <Button as='link' href={`${router.asPath}/${buttonLink}`} variant='secondary'>{buttonText}</Button>
            </StyledSide>
        </TileWrapper>
    )
}

export default function TilesWithDescription({ data }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)
    const items = data?.items || []
    const buttonText = data?.buttonText || ''

    return (
        <StyledWrapper
            id={id}
            className={`${paddings} px-main`}
            data-color-scheme={colorScheme}
        >
            {items?.map((item, index) => (
                <Tile
                    key={item?.id || index}
                    item={item}
                    index={index}
                    buttonText={buttonText}
                />
            ))}
        </StyledWrapper>
    )
}