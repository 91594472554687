import {createPortal} from "react-dom";
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";
import {useState} from "react";
import {useScrollContext} from "../Scroll";

export const OVERLAY_CLASS = 'overlay'
function createWrapperAndAppendToBody(wrapperId) {
	const wrapperElement = document.createElement('div');
	if(wrapperId.length > 0){
		wrapperElement.setAttribute("id", wrapperId);
	}
	wrapperElement.setAttribute("class", OVERLAY_CLASS);
	document.body.appendChild(wrapperElement);
	return wrapperElement;
}

function Portal({ children, wrapperId = "portal-wrapper" }) {
	const [wrapperElement, setWrapperElement] = useState(null);

	useIsomorphicLayoutEffect(() => {
		let element = document.getElementById(wrapperId);
		let systemCreated = false;
		// if element is not found with wrapperId or wrapperId is not provided,
		// create and append to body
		if (!element) {
			systemCreated = true;
			element = createWrapperAndAppendToBody(wrapperId);
		}
		setWrapperElement(element);

		return () => {
			// delete the programatically created element
			if (systemCreated && element.parentNode) {
				element.parentNode.removeChild(element);
			}
		}
	}, [wrapperId]);

	// wrapperElement state will be null on the very first render.
	if (wrapperElement === null) return null;

	return createPortal(children, wrapperElement);
}

export default Portal
