import {useSelector} from "react-redux";
import {useMemo} from "react";
import {useRouter} from "next/router";
import {COLORS, THEME_MODES} from "../../styles/themeSettings";
import {useExtendedTheme} from "../components/Theme";

function useSpecifyColor(invert = false) {
    const isMenuOpen = useSelector(state => state.menu.open)
    const extendedTheme = useExtendedTheme()
    const { asPath } = useRouter()
    const isContactPage = useMemo(() => asPath.includes('/contact'), [asPath])

    return useMemo(() => {
        if (!extendedTheme) {
            if (isContactPage) {
                return invert ? COLORS.white : COLORS.twilightBlue
            }
            return invert ? 'var(--secondary-color)' : 'var(--primary-color)'
        }
        if (isMenuOpen) {
            if (isContactPage) {
                if (invert) {
                    return extendedTheme.mode === THEME_MODES.LIGHT ? COLORS.twilightBlue : COLORS.white
                }
                return extendedTheme.mode === THEME_MODES.LIGHT ? COLORS.white : COLORS.twilightBlue
            }
            if (invert) {
                return extendedTheme.mode === THEME_MODES.LIGHT ? COLORS.twilightBlue : COLORS.white
            }
            return extendedTheme.mode === THEME_MODES.LIGHT ? COLORS.white : COLORS.twilightBlue
        } else {
            if (isContactPage) {
                return invert ? COLORS.white : COLORS.twilightBlue
            } else {
                return invert ? 'var(--secondary-color)' : 'var(--primary-color)'
            }
        }
    }, [isMenuOpen, extendedTheme, isContactPage])
}

export default useSpecifyColor;
