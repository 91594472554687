import React, {useMemo} from 'react';
import styled from "styled-components";
import Text from "../common/Text";
import {getHtmlFromRedactorField} from "@repo/utils";
import PinnedElement from "./PinnedElement";
import {gsap} from "@repo/utils/gsap";
import {Reveal} from "../../../index";


const Wrapper = styled(PinnedElement)`
    margin-bottom: 200svh;
    
`
const Header = ({ data, endTrigger }) => {
    const header = getHtmlFromRedactorField(data?.endHeader || '')
    const animations = useMemo(() => [gsap.timeline({ paused: true })], [])
    // console.log(header);

    return (
        <Wrapper
            animations={animations}
            endTrigger={endTrigger}
            offset={0.5}
            end="+=100%"
        >
           <Reveal paused timeline={animations[0]} className="centered-in-blob">
               <Text
                   as="div"
                   dangerouslySetInnerHTML={header}
                   className="header"
               />
           </Reveal>
        </Wrapper>
    );
};

export default Header;
