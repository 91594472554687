import React, {useEffect, useRef, useState} from 'react';
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import {Icon, Text} from "@repo/ui";
import {BREAKPOINTS} from "@repo/ui/themeSettings";
import styled from 'styled-components';
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import {useGSAP} from "@gsap/react";
import {SplitText} from "@repo/utils/gsap";

const Wrapper = styled.div`
    --wrapper-width: ${getCssSizeValue(180, BREAKPOINTS.MOBILE)};
    position: absolute;
    bottom: 0;
    right: 0;
    width: var(--wrapper-width);
    aspect-ratio: 16/9;
    max-width: unset;
    z-index: 1;

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tabet || 768 }px) {
        --wrapper-width: ${getCssSizeValue(180, BREAKPOINTS.TABLET)};
        right: unset;
        left: calc(var(--paddingX) + ${getCssSizeValue(130, BREAKPOINTS.TABLET)});
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        --wrapper-width: ${getCssSizeValue(275, BREAKPOINTS.DESKTOP)};
        left: calc(var(--paddingX) + ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)});
    }
    
    .rive-wrapper{
        display: flex;
        position: absolute;
        inset: 0;
    }
    
    .icon{
        border-radius: 50%;
        --width: ${getCssSizeValue(241, BREAKPOINTS.DESKTOP)};
        position: absolute;
        left: 0;
        top: -30%;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        max-width: unset;
        aspect-ratio: 1;
        height: auto;
        width: ${getCssSizeValue(241, BREAKPOINTS.DESKTOP)};
        translate: -50% -50%;
        
        svg{
            animation: rotate infinite 10s linear;
        }
        
        span{
            position: absolute;
            inset: 20%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            row-gap: ${getCssSizeValue(11, BREAKPOINTS.DESKTOP)};
            .text{
                text-align: center;
                justify-content: center;
                font-size: ${getFontSize(13)};
                &.link{
                    margin: 0 20%;
                    position: relative;
                    font-weight: ${({ theme }) => theme?.fontWeight?.bold || 700};
                    > div.underline-animation{
                        &:before {
                            background-color: var(--text-color);
                        }
                    }
                    &.active{
                        > div.underline-animation{
                            &:before {
                                transform-origin: 0 50%;
                                transform: scale3d(1, 1, 1);
                            }
                        }
                    }
                }
                &:not(&.link){
                    width: 100%;
                }
            }
        }
    }
    
    &.clicked{
        .icon{
            animation: fade-in var(--transition-duration) var(--ease);
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
        }
    }
    
    @keyframes rotate {
        from{
            rotate: 0deg;
        }
        to{
            rotate: 360deg;
        }
    };
`


const STATE_MACHINE_NAME = "State Machine 1";

const RIVE_INIT = {
    src: "/stopka_pleogroup.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: STATE_MACHINE_NAME,
    // This is optional.Provides additional layout control.
    layout: new Layout({
        fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
        alignment: Alignment.Center,
    }),
    autoplay: true,
}

const PleonianinRive = ({ data }) => {
    const WrapperRef = useRef()
    const { tagInfo='', tagLink='' } = data
    const TagLinkRef = useRef()
    const [tagLinkHover, setTagLinkHover] = useState(false)
    const redactorTagInfo = getHtmlFromRedactorField(tagInfo)
    const [clicked, setClicked] = useState(false)
    const { rive, RiveComponent }  = useRive({
        ...RIVE_INIT,
        onStateChange: (event) => {
            const state = event.data[0]
            if(state === 'Click'){
                setClicked(true)
            } else {
                setClicked(false)
            }
        },

    });



    useEffect(() => {
        function handleOutsideClick(event){
            const isNotOutside = event.target === WrapperRef.current || WrapperRef.current.contains(event.target)
            if(isNotOutside) return
            setClicked(false)
            if(rive){
                rive.load(RIVE_INIT)
            }
        }

        if(clicked){
            window.addEventListener('click', handleOutsideClick, true)
        }

        return () => {
            window.removeEventListener('click', handleOutsideClick, true)
        }
    }, [clicked, rive]);

    useGSAP(() => {
        let split = new SplitText(TagLinkRef.current, { type: 'lines', linesClass: 'underline-animation no-hover' });
    }, {
        dependencies: []
    })

    return (
        <Wrapper
            ref={WrapperRef}
            className={`${clicked ? 'clicked' : ''}`}
            data-cursor={CURSOR_TYPES.HIDE}

        >
            <RiveComponent
                className="rive-wrapper"
            />
            <Icon name={ICONS.WE_ARE_PLEONIANS_TAG} className="no-color">
                <span>
                    <Text as="div" dangerouslySetInnerHTML={redactorTagInfo} />
                    <Text
                        ref={TagLinkRef}
                        as="a"
                        href={tagLink.url}
                        target="_blank"
                        className={`${tagLinkHover ? 'active' : ''} link`}
                        onPointerEnter={() => {
                            setTagLinkHover(true)
                        }}
                        onPointerLeave={() => {
                            setTagLinkHover(false)
                        }}
                    >
                        {tagLink.text}
                    </Text>
                </span>
            </Icon>
        </Wrapper>
    );
};

export default PleonianinRive;
