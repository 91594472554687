import React, {useRef} from 'react';
import styled from 'styled-components';
import Image from "./Image";
import PageContainer from "../pageContainer/PageContainer";
import Video from "./Video";
import {getColorScheme, getPaddingsClassFromData} from "@repo/utils";
import { delay } from "@repo/utils/gsap";
import {useScaleFadeIn} from "../../animations/ScaleFadeIn";

// import dynamic from 'next/dynamic';
// const PageContainer = dynamic(() => import('../pageContainer/PageContainer'), { ssr: false });
// const Video3D = dynamic(() => import('./Video3D'), { ssr: false });


const StyledGalleryWrapper = styled.div`
	color: black;
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	row-gap: 15px;
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		grid-template-columns: 1fr 1fr;
		column-gap: 30px;
		row-gap: 30px;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		grid-template-columns: ${({columns}) => `repeat(${columns}, 1fr)`};
		column-gap: 30px;
	}
	@media(min-width: 1920px) {
		max-width: 1920px;
		margin: 0 auto;
	}
`;
const StyledGalleryItem = styled.div`
  	aspect-ratio: ${({ aspectRatio }) => aspectRatio};
	overflow: hidden;
	position: relative;
	cursor: ${({ theme }) => (theme.mode !== 'a11y' ? 'none' : 'auto')};
	border-radius: 15px;
	@media (max-width: 767px) {
		//aspect-ratio: 9/16;
	}
`;
const Item = ({ data, index, autoplay, aspectRatio}) => {
	const ItemRef = useRef()

	useScaleFadeIn({
		el: ItemRef,
		onInView: true,
		animationVars:{
			delay: index * delay
		}
	})



	return (
		<StyledGalleryItem
			ref={ItemRef}
			id={data.id}
			aspectRatio={aspectRatio}
			// data-cursor="mode-photo"
		>
			{data.mimeType.includes('image') ? (
				<Image
					src={data.url}
					alt="gallery-item"
					fill
					sizes={'100vw'}
					// placeholder="blur"
					// blurDataURL={blurDataURL}
				/>
			) : null}
			{data.mimeType.includes('video') ? (
				<Video
					// parentId={galleryItem.sys.id}
					src={data.url}
					autoplay={autoplay}
				/>
			) : null}
		</StyledGalleryItem>
	)
}

const StyledGallery = ({ data }) => {
	const gallery = data?.assets || [];
	const columns = gallery.length || 1;
	// const { videoAutoplay } = data;
	const videoAutoplay = true;
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)


	return (
		<PageContainer className="full-width" data-color-scheme={colorScheme}>
			<StyledGalleryWrapper
				className={`${paddings} px-main`}
				columns={columns}
			>
				{gallery.map((galleryItem, index) => {
					const assetWidth = galleryItem?.width || 0;
					const assetHeight = galleryItem?.height || 1;
					const aspectRatio = assetWidth / assetHeight === 0 ? '16/9' : assetWidth / assetHeight;
					return (
						<Item
							data={galleryItem}
							key={galleryItem?.id || index}
							index={index}
							aspectRatio={aspectRatio}
							autoplay={videoAutoplay}
						/>
					);
				})}
			</StyledGalleryWrapper>
		</PageContainer>
	);
};
export default StyledGallery;
