import React from 'react';
import styled from 'styled-components';
import SingleScopeDropdownMarquee from "./SingleScopeDropdownMarquee";
import {getColorScheme, getPaddingsClassFromData, getIdWithoutSpecialSymbols} from "@repo/utils";

const StyledProjectScopeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	transition: background-color var(--color-transition-duration) var(--ease);
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		//padding: 0 30px;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
		justify-content: center;
		column-gap: 50px;
	}
`;

const StyledRowsWrapper = styled.div`
	width: 100%;
`;

const ProjectScope = ({ data }) => {
	const id = getIdWithoutSpecialSymbols(data?.id);
	const scopeData = data?.stages || [];
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	console.log(id);
	return (
		<StyledProjectScopeWrapper
			id={id}
			data-color-scheme={colorScheme}
			className={`${paddings}`}
			key="wrapper"
		>
			<StyledRowsWrapper>
				{scopeData.map((item, index) => {
					const key  = item?.id || index
					const title = item?.name || '';
					const description = item?.description || '';
					const iconUrl = item?.image?.image || {};
					return <SingleScopeDropdownMarquee key={key} title={title} description={description} icon={iconUrl} />;
				})}
			</StyledRowsWrapper>
		</StyledProjectScopeWrapper>
	);
};

export default ProjectScope;
