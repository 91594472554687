import React, {createContext, useContext, useEffect, useMemo, useRef, useState} from 'react';
import Numbers from "./Numbers";
import OurValues from "./OurValues";
import styled from 'styled-components';
import Blob from "./Blob";
import {DEFAULT_COLOR_SCHEME, THEME_COLOR_SCHEMES} from "../../../styles/themeSettings";
import { useExtendedTheme, useIsDesktop, useIsMobile, useWindowSize, WhoWeAreCommon} from "../../../index";
import { useGSAP, ScrollTrigger } from "@repo/utils/gsap"
import { setCssVars} from "@repo/utils";
import PortalLens from "../common/StaticLens/PortalLens";


const PleoContextCT = createContext({
    numbers: { current: 0 },
    ourValues: { current: 0 },
    lensVisible: false
});


const DarkContainer = styled.div`
    --mobile-lens-offset-top: 30svh;
    position: relative;
    display: flex;
    width: 100%;
    z-index: 1;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 20svh;
    align-items: center;

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-bottom: 50svh;
    }
`


export const ID = 'pleo-context'

const PleoContext = ({ data }) => {
    // console.log(data);
    const { numbersSlides: numbersData, valuesCards, ...whoWeAre } = data
    // console.log(id,numbersData,whoWeAreData);
    const ContainerRef = useRef()
    const ValuesRef = useRef()
    const VideoRef = useRef()
    const extendedTheme = useExtendedTheme()
    const isMobile = useIsMobile()
    const isDesktop = useIsDesktop()
    const ValuesProgress = useRef(0)
    const NumbersProgress = useRef(0)
    const [ContainerInView, setContainerInView] = useState(false)
    const [underDarkContainer, setUnderDarkContainer] = useState(false)
    const [inThirdSlide, setInThirdSlide] = useState(true)
    const [showVideo, setShowVideo] = useState(false)
    const windowSize = useWindowSize()
    const LensVisible = useMemo(() => inThirdSlide && !underDarkContainer,[underDarkContainer, inThirdSlide])
    const context = useMemo(() => ({
        numbers: NumbersProgress,
        ourValues: ValuesProgress,
        lensVisible: LensVisible,
    }), [LensVisible])


    useGSAP(() => {
        // console.log(windowSize.width, window.innerWidth);
        ScrollTrigger.create({
            id: 'dark-container',
            trigger: ContainerRef.current,
            start: 'top center',
            end: 'bottom bottom',
            // markers: true,
            onEnter: () => {
                setContainerInView(true);
            },
            onEnterBack: () => {
                setContainerInView(true);
                setUnderDarkContainer(false);
            },
            onLeave: () => {
                setContainerInView(false);
                setUnderDarkContainer(true);
            },
            onLeaveBack: () => {
                setContainerInView(false);
            },
        })
    },{
        dependencies: [LensVisible, windowSize.width],
        revertOnUpdate: true,
        scope: ContainerRef.current
    })

    useGSAP(() => {
        if(!isDesktop){
            ScrollTrigger.create({
                id: 'pin-video',
                trigger: ContainerRef.current,
                // endTrigger: ValuesRef.current,
                start: 'top center',
                end: '98% bottom',
                pin: VideoRef.current,
                onEnter: () => {
                    setShowVideo(true)
                },
                onEnterBack: () => {
                    setShowVideo(true)
                },
                onLeave: () => {
                    setShowVideo(false)
                },
                onLeaveBack: () => {
                    setShowVideo(false)
                },
                onUpdate: () => {
                    setCssVars(VideoRef.current, { 'pg-numbers': context.numbers.current })
                    setCssVars(VideoRef.current, { 'pg-values': context.ourValues.current })
                }
            })
        }
    }, {
        dependencies: [isMobile,isDesktop, windowSize.width],
        revertOnUpdate: true,
    })

    useEffect(() => {
        extendedTheme.setTheme({
            colorScheme: ContainerInView ? THEME_COLOR_SCHEMES.PLEO_EXTRA : DEFAULT_COLOR_SCHEME
        })

    },[ContainerInView])

    useEffect(() => {
        return () => {
            extendedTheme.setTheme({
                colorScheme: DEFAULT_COLOR_SCHEME
            })
        }
    }, []);


    return (
        <PleoContextCT.Provider value={context}>
            <WhoWeAreCommon data={whoWeAre} setInThirdSlide={setInThirdSlide}/>
            <DarkContainer ref={ContainerRef}>
                <Numbers data={numbersData}/>
                <OurValues ref={ValuesRef} data={valuesCards}/>
                <PortalLens
                    visible={LensVisible}
                    mobile={{
                        ref: VideoRef,
                        visible: showVideo && extendedTheme.colorScheme === THEME_COLOR_SCHEMES.PLEO_EXTRA,
                        autoplay: ContainerInView,
                        className: ID
                    }}
                    context={context}
                >
                    <Blob  />
                </PortalLens>
            </DarkContainer>
        </PleoContextCT.Provider>
    );
};

export function usePleoContext(selector) {
    const context = useContext(PleoContextCT);
    if (typeof context === 'undefined') {
        throw new Error('usePleoContext must be used within a PleoContextCTProvider');
    }
    if(typeof selector === 'function'){
        return selector(context)
    }
    return context;
}


export default PleoContext;
