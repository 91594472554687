import CustomInput from "./CustomInput";
import styled from "styled-components";
import {getCssSizeValue, validateEmail} from "@repo/utils";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import {useRouter} from "next/router";

const StyledInputsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
    row-gap: ${getCssSizeValue(48, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		row-gap: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
	}
`;

const validationErrors = {
    pl: {
        required: 'pole wymagane',
        invalid: 'błędny adres email',
    },
    en: {
        required: 'field required',
        invalid: 'invalid email address',
    },
};

const Form = ({ data, formData, setFormData, handleSubmit, id = '', submissionState = null }) => {
    const { locale } = useRouter();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleTelInputChange = (e) => {
        const val = e.target.value;
        const regex = /^[0-9-+() ]*$/;
        if (regex.test(val)) {
            setFormData((prevData) => ({ ...prevData, phone: val }));
        }
    };

    return (
        <form onSubmit={handleSubmit} id={id}>
            <StyledInputsWrapper>
                {data.map((item) => {
                    const name = item?.name || ''
                    const placeholder = item?.placeholder || ''
                    const required = item?.required
                    const inputType = item?.inputType || ''
                    const finalPlaceholder = required ? `${placeholder.toLowerCase()}*` : placeholder.toLowerCase();
                    const isTextarea = inputType === 'textarea';
                    const isTelInput = inputType === 'tel';

                    return (
                        <CustomInput
                            key={item.id}
                            isTextarea={isTextarea}
                            placeholder={finalPlaceholder}
                            type={inputType}
                            name={name}
                            value={formData[name]}
                            required={required}
                            onChange={isTelInput ? handleTelInputChange : handleInputChange}
                            validate={inputType === 'email' ? validateEmail : (value) => value && value.trim() !== ''}
                            submissionState={submissionState}
                            errorMessage={
                                inputType === 'email'
                                    ? `${validationErrors[locale].invalid}`
                                    : `${validationErrors[locale].required}`
                            }
                            isTelInput={isTelInput}
                            locale={locale}
                        />
                    );
                })}
            </StyledInputsWrapper>
        </form>
    )
}

export default Form;