import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { CURSOR_TYPES, LOCALES } from "@repo/utils/constants";
import {Button, useHideTopBarOnScroll} from "@repo/ui";
import {setCookie} from "cookies-next";

const StyledLangSelect = styled(Button)`
	position: relative;
    font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
    color: var(--secondary-color);
`;

const LanguageChange = () => {
    const router = useRouter();
    useHideTopBarOnScroll()

    const localeTo = useMemo(() => (router.locale === LOCALES.PL ? LOCALES.EN : LOCALES.PL), [router.locale]);

    const handleChangeLocale = () => {
        setTimeout(() => {
            setCookie('NEXT_LOCALE', localeTo, { maxAge: 60 * 60 * 24 * 365 });
            router.push(router.asPath, router.asPath, { locale: localeTo });
        }, 1000);
    }

    return (
        <StyledLangSelect
            variant='unstyled'
            data-cursor={CURSOR_TYPES.LINK}
            className="underline-animation"
            onClick={handleChangeLocale}
        >
            {localeTo.toLowerCase()}
        </StyledLangSelect>
    );
};

export default LanguageChange;
