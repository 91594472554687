import {
    getAspectRatioFromImage,
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getOldCssSizeValue
} from '@repo/utils';
import styled from 'styled-components';
import React from 'react';
import Button from "../common/Button";
import {ICONS} from "@repo/utils/constants";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Icon} from "../../../index";

const StyledWrapper = styled.div`
	color: var(--secondary-color);
	position: relative;
	display: flex;
	flex-direction: column;
	padding-top: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
		padding-top: ${getCssSizeValue(138, BREAKPOINTS.TABLET)};
	}
	
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		padding-top: ${getCssSizeValue(160, BREAKPOINTS.DESKTOP)};
    }
	
	.icon{
        width: 15px;
        max-width: unset;
    }
    
    .small-text{
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        color: var(--secondary-color) !important;
        letter-spacing: normal;
        line-height: normal;

		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
		}
    }
`;

const StyledHeaderLogoWrapper = styled.div`
	display: flex;
    flex-direction: column;
    padding-top: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        padding-top: ${getCssSizeValue(82, BREAKPOINTS.TABLET)};
        
    }
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
    }
`

const StyledTag = styled.div`
    font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(16, BREAKPOINTS.TABLET)};
        padding-bottom: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        padding-bottom: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
    }
`

const StyledHeader = styled.div`
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(136, BREAKPOINTS.DESKTOP)};
        letter-spacing: -4.64px;
    }
`

const StyledJobDescription = styled.div`
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(18, BREAKPOINTS.MOBILE)};
    padding-top: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
        padding-top: ${getCssSizeValue(140, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(38, BREAKPOINTS.DESKTOP)};
        padding-top: ${getCssSizeValue(180, BREAKPOINTS.DESKTOP)};
    }
`

const JobOfferBanner = ({ data }) => {
    const {header, tag, goBackButtonText, jobDescription } = data;

    return (
        <StyledWrapper className='px-main'>
            <Button as="link" href='/about' variant="unstyled" className="small-text">
                <Icon name={ICONS.BUTTON_ARROW_LEFT}/>
                {goBackButtonText}
            </Button>
            <StyledHeaderLogoWrapper>
                <StyledTag>{tag}</StyledTag>
                <StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
                <StyledJobDescription  dangerouslySetInnerHTML={getHtmlFromRedactorField(jobDescription)} />
            </StyledHeaderLogoWrapper>
        </StyledWrapper>
    );
};

export default JobOfferBanner;
