import styled from "styled-components";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {useRef} from "react";
import { useGSAP, gsap } from "@repo/utils/gsap";
import {Reveal} from "../../../index";

const StyledWrapper = styled.div`
    background: ${props => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};

    padding: ${getCssSizeValue(42, BREAKPOINTS.MOBILE)} ${getCssSizeValue(35, BREAKPOINTS.MOBILE)} ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        gap: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        padding: ${getCssSizeValue(42, BREAKPOINTS.TABLET)} ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
        padding: ${getCssSizeValue(42, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
    }
`

const StyledTitleAndNumber = styled.div`
    display: flex;
    flex-direction: column;
    
    gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        flex-direction: row;
        justify-content: space-between;
    }
    
    .title {
        letter-spacing: -0.88px;
        font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(22, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            letter-spacing: -0.96px;
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            letter-spacing: -1.36px;
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    }
    
    .number {
        min-width: fit-content;
        letter-spacing: -1.6px;
        font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(40, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            letter-spacing: -3px;
            font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(75, BREAKPOINTS.TABLET)};
        }
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            letter-spacing: -4.64px;
            font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(105, BREAKPOINTS.DESKTOP)};
        }
    }
`

const StyledDescription = styled.div`
    border-top: 1px solid var(--secondary-color);
    padding-top: ${getCssSizeValue(21, BREAKPOINTS.MOBILE)};
    letter-spacing: -0.64px;
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        letter-spacing: unset;
        padding-top: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
        font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: ${getCssSizeValue(25, BREAKPOINTS.DESKTOP)};
        font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
    }
`

export default function SingleNumberBlock({item, index}) {
    const title = item?.title || ''
    const numberText = item?.number || ''
    const description = item?.description || ''
    const wrapperRef = useRef(null)
    const numberRef = useRef(null)

    useGSAP(() => {
        gsap.to(numberRef.current, {
            scrambleText: { text: numberText, chars: "0123456789" },
            duration: 2,
            ease: "power2.inOut",
            scrollTrigger: {
                trigger: wrapperRef.current,
                start: "top-=100% bottom",
            }
        })
    }, { scope: wrapperRef, dependencies: [index] })

    return (
        <Reveal onInView>
            <StyledWrapper
                ref={wrapperRef}
            >
                <StyledTitleAndNumber>
                    <div className='title' dangerouslySetInnerHTML={getHtmlFromRedactorField(title)} />
                    <div className='number' ref={numberRef}>{numberText}</div>
                </StyledTitleAndNumber>
                <StyledDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
            </StyledWrapper>
        </Reveal>
    )
}