import styled from "styled-components";
import { BREAKPOINTS, theme } from "../../../styles/themeSettings";
import { getCssSizeValue } from "@repo/utils";
import {useEffect, useMemo, useRef, useState} from "react";
import AboutTeamVideo from '../../assets/video/about-team-person-1.mp4';
import {CURSOR_TYPES} from "@repo/utils/constants";
import {gsap} from "@repo/utils/gsap";
import {useIsMobile} from "../../../index";

const StyledCardContainerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 58%;
    min-width: 0;
    flex: 0 0 100%;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        flex: 0 0 45%;
        height: 70%;
        &.expanded {
            flex: auto;
            margin-left: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
            margin-right: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
            min-width: unset;
        }
    }
    
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        flex: 0 0 33%;
        height: 75%;
        
        &.expanded {
            flex: 0 0 fit-content;
            margin-left: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
            margin-right: ${getCssSizeValue(25, BREAKPOINTS.DESKTOP)};
        }
    }
`

const StyledCardAndIndicatorWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: all .5s;
    height: 100%;
    width: auto;
    aspect-ratio: 640 / 850;

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        align-items: center;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        align-items: flex-end;
    }
    
    &.active {
        cursor: pointer;
    }
`

const StyledActiveItemIndicator = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background: ${theme.colors.white};
    height: 100%;
    width: 100%;
    transition: all .2s;
    will-change: transform;
    transform: ${props => props.$isCardHovered ? 'scaleX(1.02) scaleY(1.03)' : 'scaleX(1) scaleY(1)'};
    &.indicator-not-active {
        transform: scale(0);
    }
`

const StyledContentCard = styled.div`
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    transition: all 0.4s;
    padding-top: 5%;
    will-change: height, width, transform;
    
    &.not-active {
        aspect-ratio: 640 / 850;
        width: auto;
        transform: scale(0.5) !important;
    }
    
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding-top: 10%;
        padding-bottom: 10%;

        &.not-active {
            align-items: center;
            transform: scale(0.6) !important;
        }
    }
    
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: 5%;
        height: 100%;
        transform: scale(1);
        &.not-active {
            transform: scale(0.5) translateY(46%) !important;
        }
    }
`

const StyledPersonImageAndPictogramWrapper = styled.div`
    position: absolute;
    left: 15%;
    transition: all .3s;
    height: 70%;
    width: auto;
    will-change: transform;
    transform: translateX(-14%);

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        position: relative;
        left: unset;
        transform: translateX(-22%);
        height: 70%;
        width: auto;

        &.not-active {
            transform: translateX(0);
        }
    }
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        position: absolute;
        left: 26%;
        transform: translateX(-35%);
        height: 60%;
        width: auto;

       &.not-active {
            left: unset;
       }
   }
`

const StyledPictogramWrapper = styled.div`
    user-select: none;
    background: ${theme.colors.lightGray};
    border-radius: 50%;
    border: 2px solid ${theme.colors.lightGray};
    position: absolute;
    overflow: hidden;
    transition: all .6s;
    display: flex;
    align-items: center;
    justify-content: center;

    bottom: -10%;
    right: -27%;
    height: 15svh;
    width: 15svh;
    will-change: transform;
    
    img {
        transition: all .6s;
        height: 100%;
        transform: ${props => props.$isCardHovered ? 'scale(1.1)' : 'scale(1)'};
    }
    
    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        bottom: -10%;
        right: -25%;
        height: 12svh;
        width: 12svh;
        &.not-active {
            transform: scale(0.8) translate(-230%, 30%);
        }
    }
    
   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        bottom: -10%;
        right: -28.5%;
        height: 17svh;
        width: 17svh;
        &.not-active {
            transform: translate(-190%, 20%);
        }
    }
`

const StyledVideo = styled.video`
    height: 100%;
    width: auto;
    display: block;
    
    &.black-filter {
        filter: grayscale(100%);
    }
`;

const StyledName = styled.div`
    position: absolute;
    bottom: 10%;
    color: ${theme.colors.twilightBlue};
    font-size: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)};
    line-height: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
    font-style: italic;
    font-weight: 200;
    letter-spacing: -2.8px;
    width: 100%;
    will-change: transform;
    user-select: none;
    transition: all .4s;

    &.not-active {
        font-size: ${getCssSizeValue(34, BREAKPOINTS.MOBILE)};
        font-weight: 300;
        text-align: center;
        letter-spacing: -1.36px;
    }

    &.active {
        transform: translate(-4%, -45%);
    }

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        bottom: 22%;
        font-size: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;

        &.active {
            transform: translate(-4%, -45%);
        }
        
        &.not-active {
            font-size: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
            letter-spacing: -0.96px;
        }
    }

   @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        position: absolute;
        bottom: 25%;
        font-size: ${getCssSizeValue(190, BREAKPOINTS.DESKTOP)};
        letter-spacing: -9.84px;
        
        &.active {
            transform: translate(-10%, -40%);
        }

        &.not-active {
            font-size: ${getCssSizeValue(55, BREAKPOINTS.DESKTOP)};
            letter-spacing: -1.36px;
        }
    }
`

const StyledJobTitle = styled.div`
    position: absolute;
    bottom: 8%;
    user-select: none;
    text-align: center;
    color: ${theme.colors.twilightBlue};
    font-weight: 600;
    text-transform: uppercase;
    font-size: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    transition: all .1s;
    opacity: 0;
    will-change: transform;
    margin-top: ${getCssSizeValue(-10, BREAKPOINTS.MOBILE)};
    
    &.active {
        opacity: 1;
    }

    @media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
        margin-top: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {  
        bottom: 10%;
       font-size: ${getCssSizeValue(21, BREAKPOINTS.DESKTOP)};
       margin-top: ${getCssSizeValue(55, BREAKPOINTS.DESKTOP)};
    }
`

export default function Card({
    item,
    index = 0,
    selectedSnap = 0, // Acts as active, when beeing displayed in subcarousel
    cardsLength = 1,
    setIsSubCarouselExpanded,
    isSubCarousel = false,
    isMainCardOnExpandedCarouses = false,
    isExpanded = false,
    onPrevButtonClick = () => {},
    onNextButtonClick = () => {},
    ...props
}) {
    const name = item?.name || ''
    const jobTitle = item?.jobTitle || ''
    const pictogramUrl = item?.pictogram?.url || ''
    const pictogramAlt = item?.pictogram?.alt || ''
    const videoUrl = item?.video?.url || ''
    const videoAlt = item?.video?.alt || ''

    const isMobile = useIsMobile()

    const temporaryVideo = useMemo(() => {
        return AboutTeamVideo
    }, [isExpanded, item])

    const [isCardHovered, setIsCardHovered] = useState(false)
    const videoRef = useRef(null)

    const isActive = index === selectedSnap
    const activeClassName = isActive ? 'active' : 'not-active'
    const indicatorClassName = isActive ? 'indicator-active' : 'indicator-not-active'
    const nextItemClassName =  index === cardsLength + 1 ? 'next-item' : ''
    const previousItemClassName = index === cardsLength - 1 ? 'previous-item' : ''
    const isExpandedClassName = isExpanded ? 'expanded' : 'not-expanded'

    useEffect(() => {
        const playVideo = async () => {
            try {
                await videoRef.current.play();
            } catch (err) {}
        };

        const pauseVideo = () => {
            videoRef.current.pause();
            videoRef.current.style.display = 'block';
        };

        if (isMobile) {
            isActive ? playVideo() : pauseVideo();
        } else if (videoRef.current) {
            if (isActive) {
                playVideo();
            } else {
                pauseVideo();
            }
        }
    }, [isActive, isMobile]);

    useEffect(() => {
        if (!isMainCardOnExpandedCarouses) return;

        const playVideo = async () => {
            try {
                await videoRef.current.play();
            } catch (err) {}
        };

        const loadAndPlayVideo = async () => {
            try {
                videoRef.current.src = videoUrl || temporaryVideo;
                await videoRef.current.load();
                await playVideo();
            } catch (err) {}
        };

        loadAndPlayVideo();
    }, [videoUrl, temporaryVideo, isMainCardOnExpandedCarouses]);

    const onCardClick = () => {
        const isNextCard = index > selectedSnap
        const isPreviousCard = index < selectedSnap
        const isLastCard = index === 0 && selectedSnap === cardsLength - 1
        const isFirstCard = index === cardsLength - 1 && selectedSnap === 0

        if (isActive) {
            gsap.to('.menu-top-row', { y: '-100%', duration: 0.2, ease: 'power2.in' })
            setIsCardHovered(false)
            !isSubCarousel && setIsSubCarouselExpanded(!isSubCarousel)
            return;
        }
        if (isNextCard && !isFirstCard || isLastCard) {
            if (typeof onNextButtonClick !== 'function') return;
            onNextButtonClick()
            return;
        }
        if (isPreviousCard || isFirstCard) {
            if (typeof onPrevButtonClick !== 'function') return;
            onPrevButtonClick()
        }
    }

    const dataCursorAttributeType = useMemo(() => {
        return isSubCarousel ? CURSOR_TYPES.SLIDER : isActive ? CURSOR_TYPES.SLIDER_OPEN : CURSOR_TYPES.SLIDER
    }, [isActive])

    return (
        <StyledCardContainerWrapper key={index} {...props} $isExpanded={isSubCarousel} className={`${isSubCarousel ? 'expanded' : ''}`}>
            <StyledCardAndIndicatorWrapper data-cursor={dataCursorAttributeType}  className={`${activeClassName} ${isActive ? 'active-animate-element' : ''}`}
               onMouseEnter={() => !isSubCarousel && setIsCardHovered(true)}
               onMouseLeave={() => !isSubCarousel && setIsCardHovered(false)}
               onClick={onCardClick}
            >
                <StyledContentCard className={`${activeClassName} ${previousItemClassName} ${nextItemClassName}`}>
                    <StyledPersonImageAndPictogramWrapper className={`${activeClassName}`}>
                        <StyledVideo ref={videoRef} muted loop controls={false} autoPlay={false} playsInline className={!isMainCardOnExpandedCarouses ? 'black-filter' : ''}>
                            <source src={videoUrl ? videoUrl : temporaryVideo} type="video/mp4"/>
                        </StyledVideo>
                        <StyledPictogramWrapper $isCardHovered={isCardHovered} className={`${activeClassName}`}>
                            <img src={pictogramUrl} alt={pictogramAlt} />
                        </StyledPictogramWrapper>
                    </StyledPersonImageAndPictogramWrapper>
                    <StyledName className={`${activeClassName} ${isExpandedClassName}`}>{name}</StyledName>
                    <StyledJobTitle className={`${activeClassName}`}>{jobTitle}</StyledJobTitle>
                </StyledContentCard>
                <StyledActiveItemIndicator $isCardHovered={isCardHovered} className={`${indicatorClassName}`} />
            </StyledCardAndIndicatorWrapper>
        </StyledCardContainerWrapper>
    )
}
