import { Matrix4, Quaternion, Vector3 } from 'three';

const upDirection = new Vector3(0, 1, 0);
const matrix = new Matrix4();
const lookRotationQuaternion = new Quaternion();
export default function lookRotation(eye, target) {
    //function based on unity lookRotation
    const distance = eye.distanceTo(target);
    if (distance < 0.1) return lookRotationQuaternion; //prevents resetting rotation to 0
    matrix.lookAt(eye, target, upDirection);
    lookRotationQuaternion.setFromRotationMatrix(matrix);
    return lookRotationQuaternion;
}
