import {getAspectRatioFromImage, getCssSizeValue, getFontSize} from '@repo/utils';
import styled from 'styled-components';
import React from 'react';
import Icon from '../../common/Icon/Icon';
import Button from "../../common/Button";
import {ICONS} from "@repo/utils/constants";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import Image from "../../common/Image";

const StyledWrapper = styled.div`
	color: var(--secondary-color);
	position: relative;
	height: 100svh;
	display: flex;
	flex-direction: column;
	padding-top: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
		padding-top: ${getCssSizeValue(138, BREAKPOINTS.TABLET)};
	}
	
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		padding-top: ${getCssSizeValue(160, BREAKPOINTS.DESKTOP)};
    }
	
	.icon{
        width: 15px;
        max-width: unset;
    }
    
    .small-text{
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        color: var(--secondary-color) !important;
        letter-spacing: normal;
        line-height: normal;

		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
		}
    }
`;

const StyledHeaderLogoWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
	}
`

const StyledHeader = styled.div`
	flex: 1;
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
	padding-top: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
		padding-top: ${getCssSizeValue(82, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(136, BREAKPOINTS.DESKTOP)};
        letter-spacing: -4.64px;
		padding-top: ${getCssSizeValue(230, BREAKPOINTS.DESKTOP)};
    }
`
const StyledImageWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	margin-top: ${getCssSizeValue(80, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
		margin-top: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		margin-top: unset;
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: 100%;
    height: auto;

	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
		width: 80%;
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
    	width: 100%;
	}
`

const Banner = ({ data }) => {
	const {header, goBackButtonText, icon} = data;
	const iconUrl = icon?.url || '';
	const iconAlt = icon?.alt || '';
	const aspectRatio = getAspectRatioFromImage(icon);


	return (
		<StyledWrapper className='px-main'>
			<Button as="link" href='/approach' variant="unstyled" className="small-text">
				<Icon name={ICONS.BUTTON_ARROW_LEFT}/>
				{goBackButtonText}
			</Button>
			<StyledHeaderLogoWrapper>
				<StyledHeader>{header}</StyledHeader>
				<StyledImageWrapper>
					<StyledImage
						src={iconUrl}
						alt={iconAlt}
						sizes={'100vw'}
						priority
						$aspectRatio={aspectRatio}
						fit="contain"
					/>
				</StyledImageWrapper>
			</StyledHeaderLogoWrapper>
		</StyledWrapper>
	);
};

export default Banner;
