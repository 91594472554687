import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import styled from 'styled-components';
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {CURSOR_TYPES} from "@repo/utils/constants";
import CommonImage from '../common/Image';
import {Button} from "../../../index";
import DateInfo from "../common/DateInfo";
import {getButtonBlockData} from "../common/Button";

const Container = styled.div`
    color: var(--secondary-color);
    p.read-time {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(54, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
        }
    p.title {
        font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(34, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(34, BREAKPOINTS.TABLET)};
        }
    }
`

const StyledEmblaContainer = styled.div`
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	display: flex;
	touch-action: pan-y;
    padding-left: var(--paddingX);
    padding-right: var(--paddingX);
	gap: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		gap: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
    }
`;

const StyledEmblaSlide = styled.div`
	flex: 0 0 auto;
    width: ${getCssSizeValue(310, BREAKPOINTS.MOBILE)};
	max-width: 100%;
	min-width: 0;
	position: relative;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(422, BREAKPOINTS.TABLET)};
    }
`;

const Image = styled(CommonImage)`
	overflow: hidden;
	position: relative;
`

const HeaderAndButtonWrapper = styled.div`
    margin-bottom: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(84, BREAKPOINTS.TABLET)};
    }
`

const Header = styled.div`
    font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.regular};
    margin-bottom: ${getCssSizeValue(36, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(36, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        font-size: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(56, BREAKPOINTS.DESKTOP)};
    }
`

const SingleCard = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    aspect-ratio: ${({aspectRatio}) => aspectRatio ? aspectRatio : 16/9};
`

const StyledPleoTag = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 106px;
    height: 33px;
    z-index: 2;
    border-radius: 0 0 0 13px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.white} !important;
    div, p {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(54, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
        }
    }
    &.pleo-digital {
        background-color: #E84F44;
    }
    &.pleo-design {
        background-color: #2AC1D7;
    }
    &.pleo-method {
        background-color: #9747FF;
    }
	&.pleo-group {
		background-color: #1a303c;
	}
`


const CategoriesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 10px;
    p.category {
        text-transform: uppercase;
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        }
    }
`

const CarouselContainer = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
`

const StyledReadTimeAndDateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
        margin-top: ${getCssSizeValue(18, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
        margin-top: ${getCssSizeValue(18, BREAKPOINTS.DESKTOP)};
        margin-bottom: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};
    }
	p {
		line-height: normal!important;
	}
`

function NewsThumbnailCarousel({ data, locale, handleClick, handleClickBlogPage, colorScheme, hidePleoTags }) {
    const id = getIdWithoutSpecialSymbols(data?.id);
    const [emblaRef] = useEmblaCarousel({ dragFree: true, align: 'start' });
    // const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)
    const buttonData = getButtonBlockData(data)
    const header = data?.header || '';
    const news = data?.chooseNews || [];
    const readingTimeText = locale === 'pl' ? 'przeczytasz w' : 'read';
    return (
        <Container
            id={id}
            className={`${paddings}`}
            data-cursor={CURSOR_TYPES.SLIDER}
            data-color-scheme={colorScheme}
        >
            <HeaderAndButtonWrapper className="px-main">
                <Header>{header}</Header>
                <Button variant="primary" onClick={() => handleClickBlogPage(buttonData.link)}>{buttonData.text}</Button>
            </HeaderAndButtonWrapper>
            <CarouselContainer ref={emblaRef}>
            <StyledEmblaContainer>
                {news.map((item, key) => {
                    const title = item?.blogPageName || '';
                    const createdAt = item?._createdAt || '';
                    const slug = item?.slug || '';
                    const wordsCounter = item?.wordsCount?.words || 1;
                    const wordsPerMinute = 200;
                    const readingTimeInMinutes = Math.ceil(wordsCounter / wordsPerMinute) || 1;
                    const assetUrl = item?.thumbnailImage?.url || '';
                    const assetWidth = item?.thumbnailImage?.width || 0;
                    const assetHeight = item?.thumbnailImage?.height || 1;
                    const aspectRatio = assetWidth / assetHeight === 0 ? '16/9' : assetWidth / assetHeight;
                    const pleoCategory = item?.pleoCategory || '';
                    const categories = item?.categories || [];
                    const categoriesWithoutDashes = categories.map((item) => item.replace(/-/g, ' '));
                    const readTimeFullText = locale === 'pl' ? `${readingTimeText} ${readingTimeInMinutes} min` : `${readingTimeInMinutes} min ${readingTimeText}`;
                    return (
                        <StyledEmblaSlide key={`${title}`} onClick={() => handleClick(slug)}>
                            <SingleCard aspectRatio={aspectRatio}>
                                {!hidePleoTags && <StyledPleoTag className={pleoCategory}>pleo {pleoCategory.split('-')[1]}</StyledPleoTag>}
                                <Image aspectRatio={aspectRatio} src={assetUrl} sizes={"100%"}/>
                            </SingleCard>
                            <CategoriesWrapper>
                                {categoriesWithoutDashes.map((item) => <p className="category" key={item}>{item}</p>)}
                            </CategoriesWrapper>
                            <StyledReadTimeAndDateWrapper>
                                <p className="read-time">{readTimeFullText}</p>
                                <DateInfo date={createdAt} locale={locale}/>
                            </StyledReadTimeAndDateWrapper>
                            <p className="title">{title}</p>
                        </StyledEmblaSlide>
                    );
                })}
            </StyledEmblaContainer>
            </CarouselContainer>
        </Container>
    );
}

export default NewsThumbnailCarousel;
