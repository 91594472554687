import React, { forwardRef } from 'react';
import styled, {css} from 'styled-components';
import useColor from "../../hooks/useColor";

export const TEXT_VARIANTS = {
	NORMAL: 'normal',
	BOLD: 'bold',
	ITALIC: 'italic'
}

const Paragraph = styled.p`
	display: block;
	font-family: var(--font);
	font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
	color: ${({ $color }) => $color || 'var(--secondary-color)'};
	width: fit-content;
	
	&.${TEXT_VARIANTS.ITALIC} {
		font-style: italic;
		//padding-right: 1.5vw;
	}
	&.thin{
		font-weight: ${({ theme }) => theme?.fontWeight?.thin || 100};
	}
	&.light{
		font-weight: ${({ theme }) => theme?.fontWeight?.light || 200};
	}
	&.book{
		font-weight: ${({ theme }) => theme?.fontWeight?.book || 300};
	}
	&.semi-bold{
		font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
	}
	&.bold{
		font-weight: ${({ theme }) => theme?.fontWeight?.bold || 700};
	}
	&.heavy{
		font-weight: ${({ theme }) => theme?.fontWeight?.heavy || 800};
	}
	&.black{
		font-weight: ${({ theme }) => theme?.fontWeight?.black || 900};
	}
	
	&.uppercase{
		text-transform: uppercase;
	}
	
	&.transparent-3d{
		//opacity: 0;
		//color: white;
		color: transparent;
	}
`;


const Text = forwardRef(function Text({ className='', children, color='secondary', ...props }, ref) {
	const cl = useColor(color)
	return (
		<Paragraph ref={ref} className={`${className} text`} $color={cl} {...props}>
			{children}
		</Paragraph>
	);
});

export default Text;
