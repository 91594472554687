import React, {useRef} from 'react';
import styled from 'styled-components';
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getPaddingsClassFromData,
    removeTags
} from '@repo/utils';
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Reveal, {REVEAL_CLASSNAME} from "../../animations/Reveal";


const StyledBigHeaderDescriptionTwoColumnsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;
    font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
	
	.${REVEAL_CLASSNAME}-child{
		width: 100% !important;
	}
    
	.header{
		width: 100%;
		color: var(--secondary-color);
		margin-bottom: ${getCssSizeValue(59, BREAKPOINTS.MOBILE)};
		font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
		height: fit-content;
        letter-spacing: -1.6px;
	}
	
	.desc, a{
		width: 100%;
		color: var(--secondary-color);
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
		height: fit-content;
	}
	
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		.header{
			font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
			margin-bottom: ${getCssSizeValue(51, BREAKPOINTS.TABLET)};
            letter-spacing: -3px;
		}
        
        .desc-wrapper {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        
		.desc, a{
            width: 72%;
			font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
		}
	}
    
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
		justify-content: space-between;
		.header{
			font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(136, BREAKPOINTS.DESKTOP)};
            letter-spacing: -4.64px;
			margin-bottom: 0;
		}

        .desc-wrapper {
            width: 100%;
        }
        
		.desc, a{
            width: 100%;
			font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
			//width: 50%;
		}
	}
    
	a {
		text-decoration: underline;
		text-decoration-color: var(--secondary-color);
		text-decoration-style: solid;
		text-decoration-thickness: 1px;
		color: var(--secondary-color);
	}
`;


const BigHeaderDescriptionTwoColumns = ({ data, textModuleId }) => {
    const header = data?.header || '';
    const description = data?.description || '';
    const redactorHeader = getHtmlFromRedactorField(removeTags(header, ['p', 'span']));
    const redactorDesc = getHtmlFromRedactorField(removeTags(description, ['p', 'span']));
    const colorScheme = getColorScheme(data)
    const paddings = getPaddingsClassFromData(data)
    const HeaderRef = useRef()
    const DescRef = useRef()

    return (
        <StyledBigHeaderDescriptionTwoColumnsWrapper
            id={textModuleId}
            data-color-scheme={colorScheme}
            className={`${paddings} px-main`}
        >
            {!!header && (
                <Reveal onInView className="header" >
                    <div ref={HeaderRef} dangerouslySetInnerHTML={redactorHeader}/>
                </Reveal>
            )}
            {!!description && (
                <div className='desc-wrapper'>
                    <Reveal onInView>
                            <div className="desc" ref={DescRef} dangerouslySetInnerHTML={redactorDesc}/>
                    </Reveal>
                </div>
            )}
        </StyledBigHeaderDescriptionTwoColumnsWrapper>
    );
};

export default BigHeaderDescriptionTwoColumns;
