import React, {memo} from 'react';
import styled from 'styled-components';
import {getCssSizeValue, getFontSize, getSlug} from "@repo/utils";
import {BREAKPOINTS, THEME_COLOR_SCHEMES} from "@repo/ui/themeSettings";
import {Icon as IconComponent, Link, Text, Video, Button, Reveal, useIsDesktop, useScrollContext} from "@repo/ui";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import MockupVideo from '../../assets/video/mockup_fb.mp4'
import {useDispatch, useSelector} from "react-redux";
import {setShowCookies} from "@/state/showCookiesSlice";
import PleonianinRive from "@/components/Footer/PleonianinRive";
import LanguageChange from "@/components/Menu/shared/LanguageChange";

const Wrapper = styled.footer`
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: 100%;

    .video{
        position: relative;
        aspect-ratio: 390/197;
        width: inherit;
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            aspect-ratio: 772/390;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
            aspect-ratio: 1920/744;
        }
    }
    
`

const Content = styled.div`
    --text-color: ${({ theme }) => theme.colors.black };
    --first-row-gap: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.white};
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    row-gap: ${getCssSizeValue(51, BREAKPOINTS.MOBILE)};
    --padding-top: ${getCssSizeValue(64, BREAKPOINTS.MOBILE)};
    --padding-bottom: ${getCssSizeValue(22, BREAKPOINTS.MOBILE)};
    overflow: hidden;
    
    a, button{
        color: var(--text-color);
        &:before{
            background: var(--text-color);
        }
    }
    
    a{
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        flex-wrap: wrap;
       
        &.arrow{
            width:fit-content;
            min-width: ${getCssSizeValue(137, BREAKPOINTS.MOBILE)};
            column-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
        }

        div.arrow-up{
            padding: 2px;
            width: ${getFontSize(15, BREAKPOINTS.MOBILE)};
            transform: translateY(0%) rotate(45deg);
            transition: transform var(--transition-duration) var(--ease);
        }

        &:hover{
            div.arrow-up{
                transform: translateX(50%) translateY(-50%) rotate(45deg);
            }
        }
    }
    
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tabet || 768 }px) {
        --padding-top: ${getCssSizeValue(64, BREAKPOINTS.TABLET)};
        --padding-bottom: ${getCssSizeValue(28, BREAKPOINTS.TABLET)};
        row-gap: ${getCssSizeValue(170, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        --padding-top: ${getCssSizeValue(61, BREAKPOINTS.DESKTOP)};
        --padding-bottom: var(--padding-top);
        a.arrow{
            min-width: 150px;
        }
    }
`

const Row = styled.div`
    position: relative;
    width: fit-content;
    display: flex;
    
    &.navigation{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        row-gap: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    }
    
    &.bottom{
        flex-direction: column;
        width: 100%;
        align-items: start;
        column-gap: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
        row-gap: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
        button, p {
            font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        }
    }
    
    &.pair{
        row-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
        flex-direction: column;
    }
    
    &.ui{
        order: 2;
    }
    
    &.contact{
        flex-direction: column-reverse;
        width: 100%;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        &.navigation{
            row-gap: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
        }
        &.bottom{
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        }
        
        &.pair{
            column-gap: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
            flex-direction: row;
        }

        &.contact{
            justify-content: space-between;
            flex-direction: row-reverse;
        }
        &.policies{
            margin-left: auto;
            order: 3;
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        &.navigation{
            width: fit-content;
            gap: ${getCssSizeValue(117, BREAKPOINTS.DESKTOP)};
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            grid-template-columns: repeat(5,1fr);
            a{
                &:not(&.header){
                    font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
                    --size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
                }
            }
        }
        
        &.bottom{
            column-gap: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
            flex-direction: row;
            a,button{
                font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            }
        }
        
        
        &.pair{
            column-gap: ${getCssSizeValue(75, BREAKPOINTS.DESKTOP)};
        }
        
        &.ui{
            order: 0;
            margin-right: ${getCssSizeValue(240, BREAKPOINTS.DESKTOP)};
        }
         &.contact{
             flex-direction: row;
             justify-content: start;
             width: fit-content;
         }
    }
    
`

const Column = styled.div`
    --column-row-gap: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};
    display: flex;
    flex-direction: column;
    row-gap: var(--column-row-gap);
    &.lower{
        text-transform: lowercase;
    }
    &:not(&.lower){
        grid-column: span 2;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        &:not(&.lower){
            grid-column: unset;
        }
    }
`

const Icon = styled(IconComponent)`
    &.arrow-right{
        width: 18px;
        height: auto;
        margin-bottom: var(--first-row-gap);
    }
    
    &.pleonians-recruit{
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(40%);
        width: ${getCssSizeValue(180, BREAKPOINTS.MOBILE)};
        height: ${getCssSizeValue(180, BREAKPOINTS.MOBILE)};
        max-width: unset;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        &.pleonians-recruit{
            right: unset;
            left: calc(var(--paddingX) + ${getCssSizeValue(130, BREAKPOINTS.TABLET)});
        }
    }
    
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        &.pleonians-recruit{
            left: calc(var(--paddingX) + ${getCssSizeValue(150, BREAKPOINTS.DESKTOP)});
        }
    }
`

const Header = styled.a`
    display: none;
    width: fit-content;
    position: relative;
    @media (min-width: ${({ theme }) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        --line-height: ${getFontSize(40)};
        font-size: ${getFontSize(18)};
        //line-height: var(--line-height);
        margin-top: calc(var(--first-row-gap) * -1 - var(--line-height) * 0.5);
        display: block;
        margin-bottom: calc(var(--first-row-gap) - var(--column-row-gap) - var(--line-height) * 0.5);
    }
`

const links = {
    group: 'https://pleogroup.com/',
    method: 'http://pleomethod.com/',
    digital: 'http://pleodigital.com/',
    design: 'http://pleodesign.com/',
}

const Socials = ({ data, className='', type='group' }) => {
    const link = links[type] || '#'
    return (
        <Column className={`lower ${className}`}>
           <Header href={link} target="_blank" className="header underline-animation" data-cursor={CURSOR_TYPES.LINK}>
               pleo {type}
           </Header>
            {data.map((social, index) => (
                <Button
                    as="a"
                    key={index}
                    href={social?.link || ''}
                    target="_blank"
                    className="arrow"
                    variant="unstyled"
                    data-cursor={CURSOR_TYPES.HIDE}
                >
                    {social?.title || ''}
                    <Icon name={ICONS.ARROWUP} color="black"/>
                </Button>
            ))}
        </Column>
    )
}


const Footer = ({ data = {} }) => {
    const {
        socials = [],
        socialsDigital = [],
        socialsDesign = [],
        socialsMethod = [],
        links = [],
        contact= {},
        buttons = {}
    } = data;

    const buttonTop = buttons?.top || 'back to top'
    const buttonCookies = buttons?.cookies || 'Cookies'
    const buttonPolicy = buttons?.policy?.text || ''
    const buttonPolicyLink = buttons?.policy?.link?.slug || ''
    const address = contact?.address || ''
    const email = contact?.email || ''
    const tagInfo = data?.tagInfo || ''
    const tagLink = { text: data?.tagLink?.text || '', url: data?.tagLink?.url || ''}
    const { instance: { __lenis } } = useScrollContext()
    const dispatch = useDispatch();
    const { isShown, isCookiesModalExpanded } = useSelector(state => state.showCookies)

    return (
           <Wrapper data-cursor={CURSOR_TYPES.HTML} data-color-scheme={THEME_COLOR_SCHEMES.PLEO_MAIN}>
               <Video src={MockupVideo} autoplay />
               <Content className="px-main" >
                   <Reveal onInView childProps={{
                       style: {
                           rowGap: 0
                       }
                   }}>
                       <Icon name={ICONS.ARROWRIGHT}/>
                       <Row className="navigation">
                           <Column>
                               {links.map((link, index) => {
                                   const href = getSlug(`/${link?.slug || ''}`)
                                   return (
                                       <Link
                                           key={index}
                                           href={href}
                                           data-cursor={CURSOR_TYPES.LINK}
                                           className="underline-animation"
                                       >
                                           {link?.name || ''}
                                       </Link>
                                   )
                               })}
                           </Column>
                           <Socials data={socials}/>
                           <Socials data={socialsDigital} type="digital" />
                           <Socials data={socialsDesign} type="design"/>
                           <Socials data={socialsMethod} type="method"/>
                       </Row>
                   </Reveal>
                   <Row className="bottom">
                       <Row className="pair ui">
                           <Button
                               variant="unstyled"
                               data-cursor={CURSOR_TYPES.LINK}
                               className="underline-animation"
                               onClick={() => {
                                   __lenis.scrollTo(0, { lock: true })
                               }}
                           >
                               <Reveal onInView>
                                   {buttonTop}
                               </Reveal>
                           </Button>
                       </Row>
                       <Row className="pair contact">
                           <Button
                               as="a"
                               href={`https://maps.app.goo.gl/5kg8GMHZZG8Jc3A96`}
                               target="_blank"
                               className="arrow underline-animation"
                               variant="unstyled"
                               data-cursor={CURSOR_TYPES.LINK}
                           >
                               <Reveal onInView>
                                   {address}
                               </Reveal>
                           </Button>
                           <Button
                               as="a"
                               href={`mailto:${email}`}
                               className="arrow"
                               variant="unstyled"
                           >
                               <Reveal onInView>
                                   {email}
                               </Reveal>
                               <Icon name={ICONS.ARROWUP}/>
                           </Button>
                       </Row>
                       <Row className="pair policies">
                           <Button
                               variant="unstyled"
                               data-cursor={CURSOR_TYPES.LINK}
                               className="underline-animation"
                               onClick={() => {
                                   if (isShown || isCookiesModalExpanded) {
                                       return
                                   }

                                   dispatch(setShowCookies(true))
                               }}
                           >
                               <Reveal onInView>
                                   {buttonCookies}
                               </Reveal>
                           </Button>
                           <Link
                               href={`/${buttonPolicyLink}`}
                               data-cursor={CURSOR_TYPES.LINK}
                               className="underline-animation"
                           >
                               <Reveal onInView>
                                   {buttonPolicy}
                               </Reveal>
                           </Link>
                           <LanguageChange/>
                       </Row>
                   </Row>
                   <PleonianinRive
                       data={{
                           tagInfo,
                           tagLink
                       }}
                   />
               </Content>
           </Wrapper>
    );
};

export default memo(Footer);
