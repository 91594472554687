
import {useSelector} from "react-redux";
import {theme} from "../../styles/themeSettings";

export default function useLogoColorVariable() {
    const open = useSelector(state => state.menu.open)

    return open ? theme.colors.twilightBlue : 'var(--secondary-color)'

}
