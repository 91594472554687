import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin: 0 auto;
	max-width: 100%;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		max-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px;
	}
	&.full-width {
		margin: 0;
		max-width: 100%;
		padding: 0;
		width: 100%;
		&.padding-left {
			@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
				padding-left: 32px;
			}
		}
		&.padding-right {
			@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
				padding-right: 32px;
			}
		}
	}
	&.overflow-y-hidden {
		overflow-y: hidden;
	}
	&.relative {
		position: relative;
	}
	.inner-wrapper {
		margin: 0 auto;
		padding-left: 15px;
		padding-right: 15px;
		@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			max-width: 750px;
		}
		@media (min-width: 992px) {
			max-width: 970px;
		}
		@media (min-width: 1200px) {
			max-width: 1170px;
		}
		@media (min-width: 1500px) {
			max-width: 1484px;
		}
	}
	.inner-wrapper-small {
		margin: 0 auto;
		padding-left: 30px;
		padding-right: 30px;
		@media (${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
			padding-left: 0;
			padding-right: 0;
			max-width: 690px;
		}
		@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
			//max-width: 1160px;
			max-width: 960px;
		}
	}
`;
const PageContainer = ({ children, className, id, ...props }) => {
	return (
		<Container className={className} id={id} {...props}>
			{children}
		</Container>
	);
};

export default PageContainer;
