import {useRef, useState} from "react";
import { v4  } from 'uuid';
import { gsap, ScrollTrigger, useGSAP } from "@repo/utils/gsap";
import {useWindowSize} from "../../index";

//TODO: fix this fucking not predictable shit
//tends to make st jump

export default function useInView({
      id: passedId = '',
      pinnedContainer = undefined,
      disabled = false,
      once = true,
      trigger = { start: 'center bottom', end: 'center bottom' },
      progress = null,
      markers = false,
      pin = false,
      pinSpacing = true,
      revert=true
    }) {
    const ref = useRef(null);
    const [uuid] = useState(() => v4());
    const [inView, setInView] = useState(disabled);
    const [above, setAbove] = useState(false);
    const [under, setUnder] = useState(true);
    const [id] = useState(() => `trigger-${passedId}-${uuid}`);
    const [intersected, setIntersected] = useState(disabled);
    const windowSize = useWindowSize();
    gsap.registerPlugin(ScrollTrigger);

    //TODO: investigate for better props handling, in general passing extra props (like pinnedContainer) breaks logic

    //TODO: can reproduce with global update like theme color scheme, sometimes update caused jumping back up so the user
    //TODO: couldn't scroll to the very end of the page

    //TODO: components with this issue: PinnedTextWithImage & ProjectSlider


    useGSAP(() => {
        if(!disabled){
           if(ref?.current){
               ScrollTrigger.create({
                   id,
                   trigger: ref.current,
                   start: `${trigger.start}`,
                   end: `${trigger.end}`,
                   markers,
                   // markers: true,
                   pin,
                   pinSpacing,
                   once,
                   pinnedContainer: pinnedContainer?.current,
                   onEnter: () => {
                       setAbove(false);
                       setUnder(false);
                       setIntersected(true)
                   },
                   onEnterBack: () => {
                       setAbove(false);
                       setUnder(false);
                       setIntersected(true)
                   },
                   onLeave: () => {
                       setAbove(true);
                       setUnder(false);
                   },
                   onLeaveBack: () => {
                       setAbove(false);
                       setUnder(true);
                   },
                   onUpdate: ({ progress: stProgress, isActive }) => {
                       setInView(isActive)
                       if (progress) {
                           progress.current = stProgress;
                       }
                   },
               });
           }
        }
    },{
        dependencies: [disabled, windowSize.width],
        revertOnUpdate: revert,
        scope: ref.current
    })

    return { ref, inView, under, above, intersected };
}
