import React, {useRef} from 'react';
import {useThree} from '@react-three/fiber';
import {Color} from 'three';
import {gsap, useGSAP} from '@repo/utils/gsap';
import {COLOR_TRANSITION} from '../../../styles/globalStyles';
import {useRouter} from "next/router";
import useColor from "../../hooks/useColor";

function useWebGLBackgroundColor() {
	const { asPath } = useRouter()
	const colorName = asPath.includes('/contact') ? 'twilightBlue' : 'primary'
	return useColor(colorName, true)
}

const Background = () => {
	const viewport = useThree((s) => s.viewport);
	const MaterialRef = useRef();
	const color = useWebGLBackgroundColor()

	useGSAP(() => {
		const cl = new Color(color);
	 	gsap.to(MaterialRef.current.color, {
	 		r: cl.r,
	 		g: cl.g,
	 		b: cl.b,
	 		duration: COLOR_TRANSITION,
	 	});
	 }, { dependencies: [color], revertOnUpdate: false });
	return (
		<mesh scale={[viewport.width, viewport.height, 1]}>
			<planeGeometry />
			<meshBasicMaterial ref={MaterialRef} />
		</mesh>
	);
};

export default Background;
