import React, {useImperativeHandle, useMemo, memo, useRef, useEffect} from 'react';
import {REVEAL_CLASSNAME} from "./Reveal";
import {gsap, delay, useGSAP, SplitText, duration} from "@repo/utils/gsap";
import useInView from "../hooks/useInView";
import useAnimationDebug from "./useAnimationDebug";
import {useIsomorphicLayoutEffect} from "../../index";

const AnimateLinesReveal = ({ target, timeline = null, vars = {} }) => {
    const tl = timeline || gsap.timeline();
    const type ='lines'
    const splitChild = new SplitText(target, { type, [`${type}Class`]: `${REVEAL_CLASSNAME}-child` })
    const splitParent = new SplitText(target, { type, [`${type}Class`]: `${REVEAL_CLASSNAME}-parent axis-y` })

    tl.from(splitChild[type], {
            y: '100%',
            duration: duration,
            stagger: delay,
            ...vars,
        },0);
    return tl;
};

export function useLinesReveal({
       el,
       onInView = false,
       id: passedId = '',
       animationVars,
       debug: forceDebug=false,
       once = true,
       timeline=undefined,
       disabled=false,
       start= 'top',
       pinnedContainer={ current: undefined }
    }){
    const debug = useAnimationDebug(forceDebug)
    const { ref: InViewRef, intersected } = useInView({
        id: `lines-${REVEAL_CLASSNAME}-${passedId}`,
        trigger: {
            start: debug ? `${start} center` : `${start} bottom`,
            end: debug ? `${start} center` : `${start} bottom`
        },
        disabled: !onInView || disabled,
        markers: debug,
        once: debug ? !debug : once,
        pinnedContainer
    });
    const animationTL = useMemo(() => timeline || gsap.timeline(), [timeline]);
    const memoAnimationVars = useRef(animationVars)

    useIsomorphicLayoutEffect(() => {
        memoAnimationVars.current = animationVars
    },[animationVars]);

    useImperativeHandle(InViewRef, () => el?.current, [])

    useGSAP(() => {
        if(!disabled){
            animationTL.pause()
            AnimateLinesReveal({
                target: InViewRef.current,
                vars:{
                    duration: duration * 2,
                    ...memoAnimationVars.current
                },
                timeline: animationTL
            })
            if(intersected){
                animationTL.play()
            }
        }
    }, {
        dependencies: [intersected, disabled, animationTL],
        revertOnUpdate: true
    })
}
const LinesReveal = () => {
    return (
        <div>

        </div>
    );
};

export default LinesReveal;
