import { gsap } from '../../../utils/gsap/index';

const defaultParams = {
	duration: 0.7,
	autoAlpha: 1,
	translateY: 0,
	translateX: 0,
	webkitFilter: 'blur(0px)',
};
const fadeIn = (object, customParams = {}) => {
	const tl = gsap.timeline();
	object.timeline = tl;
	tl.to(object, {
		...defaultParams,
		...customParams,
		ease: 'power3.inOut',
	});
};

export default fadeIn;
