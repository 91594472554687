import styled from "styled-components";
import DarkVideo from '../../assets/video/contact-banner-horizontal.mp4'
import LightVideo from '../../assets/video/band-white-horizontal.mp4'
import { useMemo, Suspense } from "react";
import Text3DBanner  from "./Text3DBanner";
import {getIdWithoutSpecialSymbols} from "@repo/utils";
import Video from "../WebGL/Video/Video";
// import dynamic from "next/dynamic";
// const Video = dynamic(() => import ('../WebGL/Video/Video'), { ssr:false, loading: () => null });

const Banner = styled(Text3DBanner)`
    .video{
        position: absolute !important;
        inset: 0;
        z-index: 0;
    }
`

export const VideoBanner = ({ data, webGL=false }) => {
    const { banner, video=true } = data;
    const id = getIdWithoutSpecialSymbols(data?.id);
    const src = useMemo(() => {
        return banner?.variant === 'light' ? LightVideo : DarkVideo
    },[banner])

    return (
        <Suspense fallback={null}>
            <Banner
                data={banner}
                webGL={webGL}
            >
                {video && <Video key={id} id={`video-banner-${id}`} src={src} autoplay webGL={webGL} />}
            </Banner>
        </Suspense>
    )
}

export default VideoBanner;
