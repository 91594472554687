import styled from "styled-components";
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import {BREAKPOINTS, LOGO_COLORS} from "../../../styles/themeSettings";
import {Icon, Reveal} from "../../../index";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import React, {useMemo} from "react";
import Image from "../common/Image";

const StyledWrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    background-color: var(--primary-color);
    transition: background-color var(--color-transition-duration) var(--ease);
`

const StyledTopBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    gap: 15px;
    margin-bottom: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
`

const StyledHeader = styled.div`
    text-align: right;
    font-weight: 400;
    color: var(--secondary-color);
    transition: color var(--color-transition-duration) var(--ease);
	font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
	max-width: ${getCssSizeValue(320, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        line-height: normal;
		font-size: ${getFontSize(28, BREAKPOINTS.TABLET)};
		max-width: ${getCssSizeValue(360, BREAKPOINTS.TABLET)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		font-size: ${getFontSize(48)};
        line-height: ${getFontSize(56)};
		max-width: ${getCssSizeValue(750)};
	}
`

const StyledIconWrapper = styled.div`
    height: 26px;
    width: 12px;
`

const StyledImageWrapper = styled.div`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio};
    width: 60%;
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: 50%;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: 20%;
    }
`

const StyledText = styled.a`
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -1.28px;
    color: ${({theme}) => theme.colors.twilightBlue};
	font-size: ${getFontSize(32, BREAKPOINTS.MOBILE)};

    text-decoration: none;
    color: ${({theme}) => theme.colors.twilightBlue};
    
    &.phone-number {
        &::first-letter {
            font-style: italic;
            font-weight: 300;
        }
    }
    
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        letter-spacing: -2.56px;
		font-size: ${getFontSize(64, BREAKPOINTS.TABLET)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        letter-spacing: -6.16px;
		font-size: ${getFontSize(154)};
        line-height: ${getFontSize(140)};
	}
`

const UnderlineAnimationWrapper = styled.div`
    display: block;
    position: relative;
    width: fit-content;
    margin-bottom: 20px;

    &:before {
        background: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
    }
    
    &:hover {
        &:before {
            background: ${({ theme }) => theme?.colors?.twilightBlue || 'var(--secondary-color)'};
        }
    }
`

export default function QuickContact({ data }) {
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data);
    const header = data?.header || ''
    const email = data?.email || ''
    const phoneNumber = data?.phoneNumber || ''
    const imageUrl = data?.image?.url || ''
    const imageAlt = data?.image?.alt || ''
    const imageWidth = data?.image?.width || 0
    const imageHeight = data?.image?.height || 1

    const id = getIdWithoutSpecialSymbols(data?.id);

    const aspectRatio = useMemo(() => {
        return imageWidth / imageHeight === 0 ? '16/9' : imageWidth / imageHeight;
    }, [imageHeight, imageWidth])

    return (
        <StyledWrapper
            id={id}
            className={`${paddings} px-main`}
            data-logo-color={LOGO_COLORS.DARK}
            data-cursor={CURSOR_TYPES.HTML}
            data-color-scheme={colorScheme}
        >
            <StyledTopBar
                className='quick-contact-top-bar'
            >
                <StyledHeader>
                    <Reveal onInView>
                        {header}
                    </Reveal>
                </StyledHeader>
                <StyledIconWrapper>
                    <Icon
                        name={ICONS.ARROWDOWN}
                        color='primary'
                    />
                </StyledIconWrapper>
            </StyledTopBar>
            <div
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                className='quick-contact-image-wrapper'
            >
                <StyledImageWrapper
                    $aspectRatio={aspectRatio}
                >
                    <Image
                        src={imageUrl}
                        alt={imageAlt}
                        fill
                        sizes={'100vw'}
                    />
                </StyledImageWrapper>
            </div>
            <div
                className='quick-contact-bottom-bar'
            >
                <UnderlineAnimationWrapper
                    data-cursor={CURSOR_TYPES.LINK}
                    className='phone-number underline-animation'
                >
                    <StyledText
                        href={`tel:${phoneNumber}`}
                    >
                        {phoneNumber}
                    </StyledText>
                </UnderlineAnimationWrapper>
                <UnderlineAnimationWrapper
                    data-cursor={CURSOR_TYPES.LINK}
                    className='phone-number underline-animation'
                >
                    <StyledText
                        href={`mailto: ${email}`}
                    >
                        {email}
                    </StyledText>
                </UnderlineAnimationWrapper>
            </div>
        </StyledWrapper>
    )
}
