import {useContext} from "react";

export default function useCustomContext(context, selector){
	const ct = useContext(context);
	if (typeof ct === "undefined") {
		throw new Error(
			"Context is undefined"
		);
	}
	let target = ct
	if (typeof selector === 'function') {
		target = selector(ct);
	}
	if(typeof selector === 'string'){
		const prop = ct[selector]
		if(typeof prop === 'undefined') {
			console.error(`"${selector}" prop is not part of this context, use one of existing props:`, ct)
		} else {
			target = prop
		}
	}
	return target
}
