import {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {useGSAP} from "@repo/utils/gsap";
import {useDispatch, useSelector} from "react-redux";
import {useMenuCurveSvgPaths} from "@repo/ui";
import {setIsMenuOpen} from "@/state/menuSlice";
import {
    closeMenuAnimation,
    differentRouteChangeOutsideMenuCurtainAnimation,
    initialCurtainAnimation,
    initialCurtainState,
    isDifferentRouteChangeFromMenuCurtainAnimation,
    loadedCurtainUpAnimation,
} from "../../animations/pageTransitionAnimations";
import {useRouter} from "next/router";
import Curve from "../Curve";
import Loader from "./Loader";

const StyledBlock = styled.div`
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    height: 100dvh;
    width: 100%;
    z-index: 30;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PageTransitionContext = createContext({ transitionCompleted: false })

export default function PageTransition({children}) {
    const router = useRouter();
    const { menuTransition } = useSelector(state => state.menu)
    const dispatch = useDispatch();
    const svgPaths = useMenuCurveSvgPaths()
    const [isLoaded, setIsLoaded] = useState(false)

    const [displayChildren, setDisplayChildren] = useState(children);
    const [transitionCompleted, setTransitionCompleted] = useState(false);
    const [isProjectToProjectOutsideMenuRouteChangeState, setIsProjectToProjectOutsideMenuRouteChangeState] = useState(true);

    const container = useRef(null);
    const tl = useRef(null);
    const initialRender = useRef(true)

    const context = useMemo(() => ({
        transitionCompleted,
        isProjectToProjectOutsideMenuRouteChangeState,
        isLoaded,
        setIsLoaded
    }), [transitionCompleted, isProjectToProjectOutsideMenuRouteChangeState, isLoaded, setIsLoaded])

    useEffect(() => {
        setDisplayChildren(children)
    }, [router.locale]);

    useGSAP(() => {
        if (!svgPaths) return

        if (initialRender.current) {
            // console.log('initial animation')
            if (isLoaded) {
                tl.current = initialCurtainAnimation(svgPaths, () => {
                    setTransitionCompleted(true)
                })
                initialRender.current = false
            } else {
                tl.current = initialCurtainState(svgPaths, () => {
                    setDisplayChildren(children)
                    setTransitionCompleted(false)
                })
            }

            return tl.current.restart();
        }

        let isSameRouteTransition, isDifferentRouteChangeFromMenu, isDifferentRouteChangeOutsideMenu, isSameRouteChangeFromMenu, isProjectToProjectOutsideMenuRouteChange = false

        if (isLoaded) {
            isSameRouteTransition = children.key === displayChildren.key
            isDifferentRouteChangeFromMenu = !isSameRouteTransition && menuTransition
            isDifferentRouteChangeOutsideMenu = !isSameRouteTransition && !menuTransition
            isSameRouteChangeFromMenu = isSameRouteTransition && menuTransition
            isProjectToProjectOutsideMenuRouteChange = children.key.includes('/projects/') && displayChildren.key.includes('/projects/') && !menuTransition;
            setIsProjectToProjectOutsideMenuRouteChangeState(isProjectToProjectOutsideMenuRouteChange)
        }

        if (isProjectToProjectOutsideMenuRouteChangeState && isProjectToProjectOutsideMenuRouteChange) {
            setTransitionCompleted(true)
            setDisplayChildren(children)

            return
        }

        if (isDifferentRouteChangeFromMenu) {
            // console.log('isDifferentRouteChangeFromMenu')
            tl.current = isDifferentRouteChangeFromMenuCurtainAnimation(svgPaths, () => {
                setDisplayChildren(children)
                dispatch(setIsMenuOpen(false))
                setTransitionCompleted(false)
                setIsLoaded(false)
            }, dispatch)

            return tl.current.restart();
        }

        if (isDifferentRouteChangeOutsideMenu) {
            // console.log('isDifferentRouteChangeOutsideMenu')
            tl.current = differentRouteChangeOutsideMenuCurtainAnimation(svgPaths, () => {
                dispatch(setIsMenuOpen(false))
                setTransitionCompleted(false)
                setDisplayChildren(children)
                setIsLoaded(false)
            }, dispatch)

            return tl.current.restart();
        }

        if (isSameRouteChangeFromMenu) {
            tl.current = closeMenuAnimation(svgPaths, dispatch)
            dispatch(setIsMenuOpen(false))
            setTransitionCompleted(true)

            return tl.current.restart();
        }

        if (isLoaded) {
            // console.log('loadedCurtainUpAnimation')
            tl.current = loadedCurtainUpAnimation(svgPaths, () => {
                setTransitionCompleted(true)
            })

            return tl.current.restart();
        }
    }, {scope: container, dependencies: [children, svgPaths, isLoaded]});

    return (
        <PageTransitionContext.Provider value={context}>
            <div ref={container}>
                <StyledBlock className='curtain-page-transition-block'>
                    <Loader />
                    <Curve variant='open' svgPaths={svgPaths} id='transition'/>
                    <Curve variant='close' svgPaths={svgPaths} id='transition'/>
                </StyledBlock>
                {displayChildren}
            </div>
        </PageTransitionContext.Provider>
    )
}

export function usePageTransitionContext(selector) {
    const context = useContext(PageTransitionContext)
    if (typeof context === 'undefined') {
        throw new Error('useTransitionContext must be used within a TransitionContextProvider');
    }
    if(typeof selector === 'function'){
        return selector(context)
    }
    return context;
}
