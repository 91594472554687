import { useEffect, useState } from 'react';
import {useSelector} from "react-redux";

export default function useQueryElement (selector = '') {
	const { open: isMenuOpen } = useSelector(state => state.menu)
	const [el, setEl] = useState(null);

	useEffect(() => {
		const el = document.querySelector(selector);
		if (el) setEl(el);
	}, [isMenuOpen]);

	return el;
};
