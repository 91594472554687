import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useProjectsContext } from "./index";
import styled from "styled-components";
import { Option } from './Options'
import { partition } from "@repo/utils";
import {useEventListener, useIsMobile} from "../../../index";
import {useScrollbar} from "@14islands/r3f-scroll-rig";
import {CURSOR_TYPES} from "@repo/utils/constants";

const FiltersContainer = styled.div`
	display: flex;
	position: relative;
	column-gap: 16px;
    z-index: 1;

`

const ExpandContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    width: 100%;
    transform-origin: bottom;
    transition: all var(--transition-duration) var(--ease);
    z-index: 0;
    pointer-events: none;
    max-height: 47px;
    border-radius: 7px;
    padding: 0 16px;
    opacity: 0;
    backdrop-filter: blur(20px);
    background-color: rgba(231, 231, 231, 0.4);
    .filter{
        column-gap: 10px;
        opacity: 0;
        transition: opacity 0.2s var(--ease);
        flex-direction: row-reverse;
        align-self: start;
    }
    
    &.expanded{
        .filter{
            opacity: 1;
            transition: opacity 0.2s var(--ease);
        }
        opacity: 1;
        transition: all var(--transition-duration) var(--ease);
        max-height: 300px;
        pointer-events: auto;
        row-gap: 12px;
        padding: 16px 16px 60px;
    }
`
function getFilterData(filter){
    return {
        id: filter?.projectCategoryId || '',
        name: filter?.projectCategory || ''
    }
}

const Classic = ({ data, onFilterClick }) => {
    const activeFilter = useProjectsContext((state) => state.filter)
    return (
        <FiltersContainer>
            {data.map((filter) => {
                const { id: filterId, name: filterName } = getFilterData(filter)
                return (
                    <Option
                        className={`filter ${activeFilter === filterId ? 'active' : ''}`}
                        key={filterId}
                        id={`filter-${filterId}`}
                        onClick={() => {
                            onFilterClick(filterId)
                        }}
                        title={filterName}
                    >
                        <p className='underline-animation' data-cursor={CURSOR_TYPES.LINK} >{filterName}</p>
                    </Option>
                );
            })}
        </FiltersContainer>
    )
}

const Expandable = ({ data, onFilterClick }) => {
    const TargetRef = useRef()
    const ExpandRef = useRef()
    const activeFilter = useProjectsContext((state) => state.filter)
    const [expanded, setExpanded] = useState(false)
    const { onScroll } = useScrollbar()
    const [current, rest] = useMemo(() => partition(data, (filter) => getFilterData(filter).id === activeFilter),[data, activeFilter])
    const currentData = getFilterData(current[0])
    const handleOutsideClick = useCallback((event) => {
        if(event.target === TargetRef.current ||
            event.target.contains(TargetRef.current) ||
            event.target === ExpandRef.current ||
            event.target.contains(ExpandRef.current)
        ) return
        setExpanded(false)
    },[])

    useEventListener('click', handleOutsideClick)

    useEffect(() => {
        return onScroll(() => {
            setExpanded(false)
        })
    }, []);

    return (
        <>
            <FiltersContainer >
                <Option
                    ref={TargetRef}
                    className={`filter active`}
                    id={`filter-${currentData.id}`}
                    title={currentData.name}
                    onClick={() => {
                        setExpanded((prevState) => !prevState)
                    }}
                >
                    {currentData.name}
                </Option>
            </FiltersContainer>
            <ExpandContainer ref={ExpandRef} className={`${expanded ? 'expanded' : ''}`}>
                {rest.map((filter) => {
                   const { id: filterId, name: filterName } = getFilterData(filter)
                    return (
                        <Option
                            className={`filter ${activeFilter === filterId ? 'active' : ''}`}
                            key={filterId}
                            id={`filter-${filterId}`}
                            onClick={() => {
                                setExpanded(false)
                                onFilterClick(filterId)
                            }}
                            title={filterName}
                        >
                            {filterName}
                        </Option>
                    );
                })}
            </ExpandContainer>
        </>
    )
}

const Filters = ({ data, onFilterClick, setFiltration }) => {
    const isMobile = useIsMobile()
    const toggleFilter = useCallback((id) => {
        if (!id) return;
        //if all is not selected, set all to false and selected to true
        setFiltration((prevState) => {
            const updatedState = Object.keys(prevState).reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr]: curr === id ? !prevState[curr] : false,
                }),
                {},
            );
            //if all filters are false, set all to true
            if (Object.values(updatedState).every((value) => !value)) updatedState.all = true;
            return updatedState;
        });
    }, []);

    function onClick(id){
        onFilterClick()
        toggleFilter(id)
    }

    return !isMobile ? <Classic data={data} onFilterClick={onClick} /> : <Expandable data={data} onFilterClick={onClick} />
}

export default Filters;
