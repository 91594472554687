import {useMemo, useRef} from "react";
import {gsap, ScrollTrigger, useGSAP} from "@repo/utils/gsap";
import styled from "styled-components";
import {getCssSizeValue, getFontSize, getIdWithoutSpecialSymbols, getOldCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {useLogoColorVariable} from "../../../index";
import useIsDesktop from "../../hooks/useIsDesktop";
import {useRouter} from "next/router";

const StyledCommonHeadingWrapper = styled.div`
	position: fixed;
	width: 100vw;
	min-height: 63px;
	z-index: 49;
	padding-top: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
		padding-top: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
		padding-bottom: ${getCssSizeValue(15, BREAKPOINTS.TABLET)};
		min-height: 108px;
	}
`

const StyledCommonHeader = styled.div`
    left: var(--paddingX);
    width: fit-content;
    z-index: 50;
    color: ${({color}) => color};
    transition: all 0.3s ease-in-out;
    font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    text-transform: uppercase;
    opacity: 0;
    @media(min-width: 768px) {
        font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
    }
    @media (min-width: 1280px) {
        font-size: ${getFontSize(21, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
    }
    @media(min-width: 1920px) { // remove from "REM rule" on big screens
        font-size: ${getOldCssSizeValue(21, BREAKPOINTS.DESKTOP)};
        line-height: ${getOldCssSizeValue(24, BREAKPOINTS.DESKTOP)};
    }
`;

export default function StickyHeading({stickyHeadingsSelectors = [], isVisible = true}) {
    const commonHeaderRef = useRef();
    const colorVariable = useLogoColorVariable()
    const isDesktop = useIsDesktop();
    const { locale } = useRouter()
    const stickyHeadingsData = useMemo(() => {
        return stickyHeadingsSelectors.map((el) => {
            return {
                id: el?.module?.id?.replace(/[^a-zA-Z ]/g, "") || '',
                text: el?.headingText || '',
                typename: el?.module?.__typename || '' // "CommonBlobSloganRecord"
            };
        })
    }, [stickyHeadingsSelectors])

    // const sectionHeadersSelectors = useMemo(() => {
    //     let selectors = [];
    //     stickyHeadingsData.forEach((item) => {
    //         if(item.typename === "CommonBlobSloganRecord") {
    //             selectors.push(`#${item.id}`)
    //             selectors.push('#blob-slogan')
    //         } else {
    //             selectors.push( `#${item.id}`)
    //         }
    //     })
    //     return selectors;
    //     // stickyHeadingsData.map((item) => {
    //     //     if(item.typename === "CommonBlobSloganRecord") {
    //     //         '#blob-slogan'
    //     //     } else {
    //     //         `#${item.id}`
    //     //     }
    //     // })
    // }, [stickyHeadingsData, stickyHeadingsSelectors])
    const numbersTextStickyHeading = locale === 'pl' ? 'Liczby' : 'Numbers';
    const ourValuesTextStickyHeading = locale === 'pl' ? 'Wartości' : 'Values';
    const sectionHeadersSelectorsWithData = useMemo(() => {
        let selectors = [];
        stickyHeadingsData.forEach((item) => {
            // if(item.typename === "CommonBlobSloganRecord") {
            if(item.typename === "DigitalAboutTeamModuleRecord") {
                selectors.push({
                    id: `#${item.id}`,
                    text: `${item.text}`
                })
                selectors.push({
                   id: '#pleo-numbers',
                   text: numbersTextStickyHeading
                })
                selectors.push({
                   id: '#our-values-slider',
                   text: ourValuesTextStickyHeading
                })
            } else {
                selectors.push( {
                    id: `#${item.id}`,
                    text: `${item.text}`
                })
            }
        })
        return selectors;
        // stickyHeadingsData.map((item) => {
        //     if(item.typename === "CommonBlobSloganRecord") {
        //         '#blob-slogan'
        //     } else {
        //         `#${item.id}`
        //     }
        // })
    }, [stickyHeadingsData, stickyHeadingsSelectors])

    useGSAP(() => {
       let timeout = setTimeout(() => {
            sectionHeadersSelectorsWithData.forEach((el, key) => {
                const id = el?.id || ''
                if(id.length <= 1) return
                ScrollTrigger.create({
                    id,
                    trigger: id,
                    // start: 'top top',
                    start: 'top top+=100px',
                    // pin: true,
                    markers: false,
                    pinSpacing: false,
                    invalidateOnRefresh: true,
                    // endTrigger: key < sectionHeadersSelectors.length - 1 ? sectionHeadersSelectors[key + 1] : sectionHeadersSelectors[sectionHeadersSelectors.length - 1],
                    // endTrigger: key < sectionHeadersSelectors.length - 1 ? el : sectionHeadersSelectors[sectionHeadersSelectors.length - 1],
                    endTrigger: id,
                    // end: 'top top',
                    // end:  key < sectionHeadersSelectors.length - 1 ? 'bottom top' : 'bottom top+=30%',
                    end:  key < sectionHeadersSelectorsWithData.length - 1 ? 'bottom top+=100px' : 'bottom top+=30%',
                    onEnter: () => {
                        if(key < sectionHeadersSelectorsWithData.length - 1) {
                            // const nextSt = ScrollTrigger.getById(sectionHeadersSelectors[key + 1])
                            const nextSt = ScrollTrigger.getById(sectionHeadersSelectorsWithData[key + 1].id)
                            nextSt.refresh();
                        }
                        gsap.to(commonHeaderRef.current, {
                            duration: 0.6,
                            ease: "power2.inOut",
                            text: {
                                // value: stickyHeadingsData?.[key]?.text,
                                value: sectionHeadersSelectorsWithData?.[key]?.text,
                            },
                        });
                    },
                    onLeaveBack: () => {
                        gsap.to(commonHeaderRef.current, {
                            duration: 0.6,
                            ease: "power2.inOut",
                            text: {
                                // value: key === 0 ? '' : stickyHeadingsData?.[key -1]?.text,
                                value: key === 0 ? '' : sectionHeadersSelectorsWithData?.[key -1]?.text,
                            },
                        });
                    },
                    onLeave: () => {
                        gsap.to(commonHeaderRef.current, {
                            duration: 0.6,
                            ease: "power2.inOut",
                            text: {
                                value: '',
                            },
                        });
                    },
                    // end: document.getElementById(sectionHeadersSelectors[key + 1]).offsetTop,
                })
            })
        }, 1000)

        return () => {
           clearTimeout(timeout)
        }
    },{ dependencies: [sectionHeadersSelectorsWithData, isDesktop]})

    return (
        (isVisible) && (
            <StyledCommonHeadingWrapper className='px-main'>
                <StyledCommonHeader color={colorVariable} id='sticky-heading' ref={commonHeaderRef}></StyledCommonHeader>
            </StyledCommonHeadingWrapper>
        )
    )
}
