import React, { useMemo } from 'react';
import styled from "styled-components";
import {getCssSizeValue, getFontSize, getIdWithoutSpecialSymbols, partition} from "@repo/utils";
import { CMS_ELEMENT_TYPENAME, CONTENT_TYPES } from "@repo/utils/constants";
import { useProjectsContext } from "./";
import { BREAKPOINTS } from "../../../styles/themeSettings";
import { useIsDesktop } from "../../../index";


import TextSmallAsset from "../common/TextSmallAsset";
import TextWithBreaks from "../common/TextWithBreaks";
import Project from "./Project";

// import dynamic from "next/dynamic";
// import { Loading as LoadingProject } from './Project'
// const TextWithBreaks = dynamic(() => import('../common/TextWithBreaks'));
// const TextSmallAsset = dynamic(() => import('../common/TextSmallAsset'));
// const Project = dynamic(() => import('./Project', { ssr: false, loading: () => <LoadingProject/> }));

const Container = styled.div`
    order: ${({ $order }) => $order || 0};
    position: relative;
    z-index: 1;

    &.tiles {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--gapx) var(--gapy);
        .column{
            row-gap: var(--gapx);
            &.indent{
                padding-top: calc(50% - var(--gapx) * 1.5);
            }
        }
       
    }

    &.list {
        display: flex;
        flex-direction: column;
        
        div.horizontal-header {
            order: 100;
        }
    }


    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        &.tiles {
            grid-template-columns: 1fr 1fr;
        }
        &.list {
            .column{
                display: contents;
            }
        }
    }
`

const Column = styled.div`
    display: ${({ $hide }) => $hide ? 'none !important' : 'contents'};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        display: flex;
        flex-direction: column;
    }
`


const AssetText = styled(TextSmallAsset)`
    order: ${({ $order }) => $order || 0};
    padding-top: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
    line-height: 24px;
    align-items: end;
    text-align: right;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(34)};
        padding-top: ${getCssSizeValue(180)};
    }
`

const Text = styled(TextWithBreaks)`
    order: ${({ $order }) => $order || 0};
    --font-size: ${getFontSize(50, BREAKPOINTS.MOBILE)};
    letter-spacing: calc(var(--font-size) * -0.04);
    color: var(--secondary-color);
    padding: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)} 0;
    font-size: var(--font-size);
    line-height: ${getFontSize(58, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding: ${getCssSizeValue(50, BREAKPOINTS.TABLET)} 0;
        --font-size:  ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding: ${getCssSizeValue(160)} 0;
        --font-size: ${getFontSize(116)};
        line-height: ${getFontSize(136)};
    }
`


const Content = ({ data = {}, webGL=false, index, type='' }) => {
    const isList = useProjectsContext((state) => state.isList)
    const elementType = data[CMS_ELEMENT_TYPENAME];
    if(!elementType) return null
    if(elementType.includes('SingleProject')){
        return <Project data={data} index={index} webGL={webGL} type={type}  />;
    }
    switch (elementType){
        case CONTENT_TYPES.TEXT_WITH_BREAK:
            if(isList) return null
            return <Text data={data.text} $order={index}/>
        case CONTENT_TYPES.TEXT_SMALL_IMAGE:
            if(isList) return null
            return <AssetText data={data} $order={index}/>
        default:
            return null
    }
}

function reversePairs(array) {
    if(!Array.isArray(array)) return []

    // Creating a copy of the original array using slice()
    let result = array.slice();

    // Loop over the array increment by 2
    for(let i = 0; i < array.length; i += 2){

        // Check if the next element exist
        if(result[i + 1] !== undefined){

            // Swap array elements
            [result[i], result[i + 1]] = [result[i + 1], result[i]];

        }
    }

    return result;
}

const Grid = ({ data, webGL=false, index, type='' }) => {
    const { grid, indent= 'left' } = data;
    const id = getIdWithoutSpecialSymbols(data?.id || '');
    const isList = useProjectsContext((state) => state.isList)
    const isDesktop = useIsDesktop()
    const [left, right] = useMemo(() => {
        //reverse left & right column on mobile or isList
        //added to match design concept
        const source = isList || !isDesktop ? reversePairs(grid) : grid;
        //add indexes to the element
        const indexed = source.map((item, index) => ({ ...item, index }))
        //split data in columns
        return partition(indexed, (el, index) => index % 2 === 0)
    },[grid, isDesktop,isList])

    return (
        <Container id={id} className={`${isList ? 'list' : 'tiles'}`} $order={index} >
            <Column className={`column left ${(indent === 'right' || indent === 'none') ? '' : 'indent'}`} $hide={left.length <= 0} >
                {left.map((project, index) =>
                    <Content
                        key={project?.id || project?.index || index}
                        data={project}
                        index={project?.index || index}
                        webGL={webGL}
                        type={type}
                    />
                )}
            </Column>
            <Column className={`column right ${(indent === 'left' || indent === 'none') ? '' : 'indent'}`} $hide={right.length <= 0} >
                {right.map((project, index) =>
                    <Content
                        key={project?.id || project?.index || index}
                        data={project}
                        index={project?.index || index}
                        webGL={webGL}
                        type={type}
                    />
                )}
            </Column>
        </Container>
    )
};

export default Grid;
