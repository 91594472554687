import {useCallback, useEffect, useRef} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import styled from 'styled-components';
import useIsDesktop from '../../hooks/useIsDesktop';
import {CURSOR_TYPES} from "@repo/utils/constants";
import {
	getColorScheme,
	getCssSizeValue,
	getIdWithoutSpecialSymbols,
	getPaddingsClassFromData,
	setCssVars
} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {useCursor, usePageTransitionContext, useWindowSize} from "../../../index";
import useAnimationFrame from "../../hooks/useAnimationFrame";
import {easing} from "maath";
import {transform} from "framer-motion";
import TouchReactiveCursor from "../Cursor/TouchReactiveCursor";
import useSelectedSnapDisplay from "../../hooks/useSelectedSnapDisplay";

const StyledWrapper = styled.div`
	position: relative;
`

const Container = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	user-select: none;
`

const StyledEmblaContainer = styled.div`
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	display: flex;
	touch-action: pan-y;
	gap: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		gap: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
    }

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
    	gap: 40px;
	}
`;

const StyledEmblaSlide = styled.div`
	flex: 0 0 auto;
	max-width: 100%;
	min-width: 0;
	position: relative;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
		flex: 0 0 auto;
		max-width: 80%;
	}
`;

const StyledEmblaImg = styled.img`
	display: block;
	max-width: 100%;
	height: auto;
`;

const StyledCurrentElementText = styled.p`
	color: var(--secondary-color);
	font-size: 14px;
	font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
	line-height: 21px;
	margin-top: 20px;

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
		letter-spacing: 9px;
		font-size: 13px;
	}
`;

const StyledProgressWrapper = styled.div`
display: flex;
	justify-content: center;
	gap: 15px;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
		display: flex;
		justify-content: center;
		gap: 37px;
		margin-top: 50px;
	}
`;

const StyledEmblaProgress = styled.div`
	z-index: 1;
	background: var(--secondary-color);
	overflow: hidden;    
	pointer-events: none;
	height: 0.2rem;
	width: 8rem;
	max-width: 90%;
	border-radius: 1px;
	margin: 30px 0;
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
		height: 0.3rem;
		border-radius: 2px;
		width: 11rem;
		max-width: 90%;
		margin: 30px 0;
	}
`;

const StyledEmblaProgressBar = styled.div`
	background: white;
	width: 100%;
	height: 100%;
	
	transition: all .2s ease-in-out;
`;

function getInitialAndLeaveCursorPosition(AssetRef) {
	const assetBounds = AssetRef.current.getBoundingClientRect();
	const centerX = assetBounds.left + assetBounds.width / 2;
	const centerY = assetBounds.height / 2;

	return { centerX, centerY }
}

const ProjectSlider = ({ data }) => {
	const id = getIdWithoutSpecialSymbols(data?.id);
	const paddings = getPaddingsClassFromData(data)
	const colorScheme = getColorScheme(data)
	const slidesData = data?.slides || [];

	const isDesktop = useIsDesktop();
	const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: false, align: 'center' });

	const { selectedSnap, snapCount } = useSelectedSnapDisplay(emblaApi)

	const windowSize = useWindowSize()
	const AssetRef = useRef(null)
	const CursorRef = useRef(null)
	const cursor = useCursor()

	const CursorPos = useRef({
		x: 0,
		y: 0
	})

	const CursorPrevPos = useRef({
		x: 0,
		y: 0
	})

	const initialRef = useRef(true)
	const { transitionCompleted } = usePageTransitionContext()

	useEffect(() => {
		if (!isDesktop || !AssetRef.current || !CursorRef.current) return;

		if (initialRef.current) {
			initialRef.current = false
			return
		}

		const { centerX, centerY } = getInitialAndLeaveCursorPosition(AssetRef)

		CursorPos.current = {
			x: centerX,
			y: -centerY
		}
	}, [windowSize, isDesktop, initialRef.current, AssetRef.current, CursorRef.current, transitionCompleted]);

	const handleOnMouseMove = useCallback(() => {
		if(!isDesktop) return
		const pos = cursor.position
		const bounds = AssetRef.current.getBoundingClientRect()
		const boundsCenter = (bounds.top + bounds.height / 2) - bounds.height / 2
		const translatedX = transform([-windowSize.width / 2, windowSize.width / 2], [0, windowSize.width])(pos.x);

		CursorPos.current = {
			x: translatedX,
			y: boundsCenter - windowSize.height/2 + pos.y
		}
	},[cursor, windowSize.height, isDesktop])

	const handleOnMouseLeave = useCallback(() => {
		if (!isDesktop || !AssetRef.current || !CursorRef.current || !emblaApi) return;

		const { centerX, centerY } = getInitialAndLeaveCursorPosition(AssetRef)

		CursorPos.current = {
			x: centerX,
			y: -centerY
		}
	}, [isDesktop, emblaApi, windowSize.width, windowSize.height]);

	useAnimationFrame((time, delta) => {
		if(!isDesktop || !CursorRef.current) return
		const diff = Math.abs(CursorPrevPos.current.x - CursorPos.current.x)
		if(diff < 0.0001) return
		easing.damp2(CursorPrevPos.current, CursorPos.current, 0.1, delta)
		setCssVars(CursorRef.current, { x: `${CursorPrevPos.current.x}px`, y: `${CursorPrevPos.current.y}px`});
	},[isDesktop, CursorRef])

	return (
		<StyledWrapper
			className={`${paddings} px-main`}
			data-color-scheme={colorScheme}
		>
			{isDesktop && (
				<TouchReactiveCursor
					cursorText={CURSOR_TYPES.SLIDER}
					isSnapCursor
					ref={CursorRef}
				/>
			)}
			<Container
				id={id}
				ref={emblaRef}
				onPointerMove={handleOnMouseMove}
				onPointerLeave={handleOnMouseLeave}
				data-cursor={CURSOR_TYPES.HIDE}
			>
					<StyledEmblaContainer
					>
						{slidesData.map((item, key) => {
							const imgUrl = item?.url || '';
							return (
								<StyledEmblaSlide
									ref={key === selectedSnap ? AssetRef : null}
									key={`${imgUrl}${key}`}
								>
									<StyledEmblaImg src={imgUrl} alt={key} />
								</StyledEmblaSlide>
							);
						})}
					</StyledEmblaContainer>
					<StyledProgressWrapper>
						<StyledEmblaProgress>
							<StyledEmblaProgressBar
								style={{
									transform: `translate3d(${ ((selectedSnap + 1 )/snapCount) * 100 }%, 0px, 0px)`,
								}}
							/>
						</StyledEmblaProgress>
						<StyledCurrentElementText>{`${selectedSnap + 1}/${snapCount}`}</StyledCurrentElementText>
					</StyledProgressWrapper>
			</Container>
		</StyledWrapper>
	);
};

export default ProjectSlider;
