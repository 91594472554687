import React, { useEffect, useRef } from 'react';

export default function HTMLWithClickHandler({ htmlString, onLinkClick, StyledContainer }) {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const elements = container.querySelectorAll('[data-link]');
        let link

        elements.forEach(el => {
            link = el.getAttribute('data-link');
            if (link) {
                el.style.cursor = 'pointer';
                el.addEventListener('click', () => onLinkClick(link));
                el.removeAttribute('data-link');
            }
        });

        return () => {
            elements.forEach(el => {
                el.removeEventListener('click', () => onLinkClick(link));
            });
        };
    }, [htmlString, onLinkClick]);

    return <StyledContainer ref={containerRef} dangerouslySetInnerHTML={{ __html: htmlString }} />;
}