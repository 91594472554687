import { useRef, useEffect } from 'react';

//hook to handle animation on every frame
//function provided to callback receives two arguments: time and deltaTime
export default function useAnimationFrame(callback, props = []) {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = useRef();
    const previousTimeRef = useRef();

    useEffect(() => {
        const animate = (time) => {
            if (previousTimeRef.current !== undefined) {
                const deltaTime = time - previousTimeRef.current;
                callback(time / 1000, deltaTime / 1000); //seconds
            }
            previousTimeRef.current = time;
            requestRef.current = requestAnimationFrame(animate);
        };

        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, props);
}
