import React from 'react';
import styled from "styled-components";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";
import {CircleIcon} from "../../../index";
import Button from "./Button";

const LinkButton = ({ external=false, ...props }) => {
    const tag = external ? 'a' : 'link'
    return (
        <Button id="top-row-story-button" as={tag} data-cursor={CURSOR_TYPES.CIRCLE} {...props}>
            <CircleIcon name={ICONS.THICK_WAVE} />
        </Button>
    )
}

const StyledButton = styled(LinkButton)`
    aspect-ratio: 1;
    display: flex;
    align-self: center;
    border-radius: 50%;
    .icon{
        --size: calc(35px/1.5);
        box-shadow: 0 0 2px 4px transparent;
        border-width: 3px;
    }
    //translate: 0 25%;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        .icon{
            --size: calc(43px/1.5);
        }
    }
`
const StoryButton = ({ variant = 'unstyled', href='/story', external=false }) => (
    <StyledButton variant={variant} href={href} external={external} />
)

export default StoryButton;
