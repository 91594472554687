import React from 'react';
import styled from 'styled-components';
import NextLink from 'next/link';
import {getFontSize} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const StyledLink = styled(NextLink)`
	transition: color var(--color-transition-duration) var(--ease);
	text-decoration: none;
	position: relative;
	//height: 100%;
	color: var(--secondary-color);

	&.underline-animation {
		max-width: max-content;
	}

	&.margin-top {
		margin-top: 20px;
	}
`;

const Link = ({ style, children, ...props }) => {
	return (
		<StyledLink style={style} scroll={false} {...props}>
			{children}
		</StyledLink>
	);
};
export default Link;
