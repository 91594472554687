import React, {useMemo} from 'react';
import styled from 'styled-components';
import Text from '../../common/Text'
import Image from "../../common/Image";
import {getFontSize, getCssSizeValue, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import Button, {getButtonBlockData} from "../Button";
import { CONTENT_TYPES as COMMON_CONTENT_TYPES } from '@repo/utils/constants';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${getCssSizeValue(241, BREAKPOINTS.MOBILE)};
    row-gap: ${getCssSizeValue(23, BREAKPOINTS.MOBILE)};
    height: 100%;
    
    .text{
        font-size: var(--font-size);
        //letter-spacing: calc(var(--font-size) * -0.04);
    }
    .image{
        max-width: ${getCssSizeValue(154, BREAKPOINTS.MOBILE)};
        aspect-ratio: 1;
        height: auto;
    }
    .button{
        margin-top: auto;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: ${getCssSizeValue(446, BREAKPOINTS.TABLET)};
        row-gap: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
        .image{
            max-width: ${getCssSizeValue(210, BREAKPOINTS.TABLET)};
        }
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: ${getCssSizeValue(550, BREAKPOINTS.DESKTOP)};
        row-gap: ${getCssSizeValue(44, BREAKPOINTS.DESKTOP)};
        .image{
            max-width: ${getCssSizeValue(160, BREAKPOINTS.DESKTOP)};
        }
    }
`

const Top = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: inherit;
    aspect-ratio: 241/280;
    .text{
        margin-bottom: auto;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        aspect-ratio: 1.085;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        aspect-ratio: 580/450;
    }
`

const Header = styled(Text)`
    --font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    font-weight: ${({ theme }) => theme.fontWeight.bold || 700};
    letter-spacing: calc(var(--font-size) * -0.04);


    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        --font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(100, BREAKPOINTS.DESKTOP)};
    }
`

const Description = styled(Text)`
    //margin-top: auto;
    --font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
    font-weight: ${({ theme }) => theme.fontWeight.book || 300};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        --font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
    }
`

export function getCardData(data){
    const id = data?.id || ''
    const header = data?.header || ''
    const description = getHtmlFromRedactorField(data?.description || '')
    const soonMask = Boolean(data?.soonMask)
    const icon = {
        url: data?.icon?.url || '',
        alt: data?.icon?.alt || 'card-image',
        width: data?.icon?.width || 0,
        height: data?.icon?.height || 1,
    }
    const button = getButtonBlockData(data)
    return {
        id,
        header,
        description,
        icon,
        button,
        soonMask
    }
}
const Card = ({ data }) => {
    const { id, header, description, icon, button } = getCardData(data)

    const slug = useMemo(() => {
        return button.typename === COMMON_CONTENT_TYPES.SERVICE_PAGE_RECORD ? `/services/${button.link}` : button.link
    }, [data])

    return (
        <Wrapper id={id}>
            <Top>
                <Image src={icon.url} alt={icon.alt} fit="contain" focalPoint={{ x: 0, y: 0.5 }}/>
                <Header>
                    {header}
                </Header>
            </Top>
            <Description className="text" as="div" dangerouslySetInnerHTML={description}/>
            {button.exists && (
                <Button as="link" href={slug}>
                    {button.text}
                </Button>
            )}
        </Wrapper>
    );
};

export default Card;
