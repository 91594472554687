import { useState, useEffect } from 'react';

export function useMouseDown(listener) {
    const [mouseDown, setMouseDown] = useState(false);

    useEffect(() => {
        const handleMouseDown = () => {
            setMouseDown(true);
            if(listener){
                listener();
            }
        };

        const handleMouseUp = () => {
            setMouseDown(false);
        };

        window.addEventListener("mousedown", handleMouseDown);
        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mousedown", handleMouseDown);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [listener]);

    return mouseDown;
}