import React, {useEffect} from 'react';
import {useCursor, useIsDesktop} from "../../../../index";
import Portal from "./index";
import {UseCanvas} from "@14islands/r3f-scroll-rig";

const MOBILE_VIDEO = {
    ref: { current: null },
    visible: false,
    autoplay: false
}

const PortalLens = ({ visible = false, mobile= MOBILE_VIDEO, context= false, children, ...props }) => {
    const isDesktop = useIsDesktop()
    const portalLens = useCursor((state) => state.portalLens)

    useEffect(() => {
        portalLens.visible = visible
    },[visible])

    useEffect(() => {
        return () => {
            portalLens.visible = false
            portalLens.position.set(0,0,0)
            portalLens.scale.setScalar(1)
        }
    },[])

    return !isDesktop ? (
        <div>
            <Portal {...mobile} />
        </div>
    ) : (
        <UseCanvas _visible={visible} context={context} {...props}>
            {children}
        </UseCanvas>
    )
}

export default PortalLens;
