import Link from '../common/Link';
import WebGLImageVideo from '../WebGL/ImageVideo/ImageVideo';
import React, {memo, useCallback, useState} from 'react';
import styled, { css } from 'styled-components';
import Text from '../common/Text'
import {CMS_ELEMENT_TYPENAME, CURSOR_TYPES} from "@repo/utils/constants";
import MockupVideo from '../../assets/video/band-white-horizontal.mp4'
import {useProjectsContext} from "./";
import {
	capitalize,
	getCssSizeValue,
	getFontSize,
	getHtmlFromRedactorField,
	getStringRange,
	removeTags,
	lowercaseFirstLetter
} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {useExtendedTheme, useIsDesktop} from "../../../index";


export const Loading = styled.div`
	position: relative;
	display: flex;
	aspect-ratio: 4/3;
	width: calc(100vw - var(--paddingX) * 2);
`
const LinkStyles = css`
	display: flex;
	flex-direction: column;
	row-gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
	order: ${({ $order }) => $order || 0};
	text-decoration: none;
	
	.text {
		line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		//font-weight: ${({ theme }) => theme?.fontWeight?.book || 300};
		&.project-title {
			font-size: 24px;
		}
		&.project-subtitle {
			font-size: 16px;
		}
	}
	
	&.tile-item {
		width: 100%;
		max-width: calc(100vw - var(--paddingX) * 2);
		
		.video{
			display: none !important;
		}
		
		&.highlight{
			.project-image {
				aspect-ratio: 4/5;
			}
		}
		.project-image {
			width: 100%;
			aspect-ratio: 4/3;
		}
		.text {
			&.project-subtitle {
				opacity: 0.5;
			}
		}
	}

	&.list-item {
		padding: ${getCssSizeValue(39, BREAKPOINTS.MOBILE)} ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
		background-color: ${({ $color }) => $color};
		.text {
			transform: translateY(2px);
			color: white;
		}
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		row-gap: ${getCssSizeValue(45, BREAKPOINTS.TABLET)};
		&.list-item {
            padding: ${getCssSizeValue(62, BREAKPOINTS.TABLET)} ${getCssSizeValue(31, BREAKPOINTS.TABLET)};
        }
		.text {
			line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
			&.project-subtitle {
				font-size: 18px;
			}
		}
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		row-gap: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
		.text {
			line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
			&.project-subtitle {
				font-size: ${getCssSizeValue(18, BREAKPOINTS.DESKTOP)};
			}
		}
		.video{
			display: flex !important;
		}
		&.tile-item {
			width: 100%;
			height: fit-content;
			.titles{
				padding-left: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
			}
			p.project-title {
				font-size: ${getCssSizeValue(34, BREAKPOINTS.DESKTOP)};
			}
		}
		&.list-item {
			padding: ${getCssSizeValue(62, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(162, BREAKPOINTS.DESKTOP)};
			.text {
				color: white;
				&.project-title{
					font-size: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
				}
			}
		}
	}
`

const ExternalLink = styled.a`
	${LinkStyles}
`

const LocalLink = styled(Link)`
	${LinkStyles}
`;

const Titles = styled.div` 
	display: flex;
	flex-direction: column;
	width: 100%;
	row-gap: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)}; 
	align-items: start !important;

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		row-gap: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		row-gap: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
	}
	
`;


const SIZES = '(max-width: 1023px) 100vw, 50vw';

function validateVideoUrl(url) {
	if(typeof url !== 'string') return MockupVideo
	const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
		'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		'(\\:\\d+)?'+ // port
		'(\\/[-a-z\\d%@_.~+&:]*)*'+ // path
		'(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // query string
		'(\\#[-a-z\\d_]*)?$','i'); // fragment locator

	if (pattern.test(url)) {
		const extensions = ["mp4", "wav"];
		if (extensions.some(el => url.includes(el))) {
			return url;
		} else {
			return MockupVideo;
		}
	} else {
		return MockupVideo;
	}
}

const DynamicLink = ({ isLocal = true, children, ...props}) => {
	return isLocal ? (
		<LocalLink {...props}>
			{children}
		</LocalLink>
	) : (
		<ExternalLink target="_blank" {...props}>
			{children}
		</ExternalLink>
	)
}
const Project = ({ data, index = 0, className = '', webGL=false, type='digital',...props }) => {
	const [hover, setHover] = useState(false);
	const isList = useProjectsContext((state) => state.isList)
	const extendedTheme = useExtendedTheme()
	const isDesktop = useIsDesktop()
	const url = data?.coverPortrait?.url || '';
	const id = `project-${data?.slug || index}`;
	const slug = data?.slug || '';
	const year = data?.year || '';
	const color = data?.[`theme${capitalize(extendedTheme.mode)}`]?.mainPrimary.hex || 'var(--tertiary-color)';
	const arrayRange = data?.projectServiceRange || [];
	const highlight = data?.highlight || false;
	const range = getStringRange(arrayRange, year)
	const videoUrl = validateVideoUrl(data?.video?.url)
	const cursorAsset = data?.video || { url: '', width: 0, height: 1 };
	const projectName = data?.name || 'Project';
	const typename = data?.[CMS_ELEMENT_TYPENAME] || ''
	const description = removeTags(data?.description || 'Description', ['em', 'br'])
	const subtitle = getHtmlFromRedactorField((hover && range.length > 0) ? range : description);
	const pleoType = type.toString() || ''
	const isLocal = typename.includes(capitalize(pleoType))
	const baseLink = `/projects/${slug}`
	const formattedTypename = lowercaseFirstLetter(typename.replace('SingleProjectRecord', ''))
	const link = isLocal ? baseLink : `https://pleo-ultimate-monorepo-${formattedTypename}.vercel.app/pl${baseLink}`;

	const handlePointerEnter = useCallback(() => {
		if(!isDesktop) return
		setHover(true);
	}, [isDesktop]);
	const handlePointerLeave = useCallback(() => {
		if(!isDesktop) return
		setHover(false);
	}, [isDesktop]);

	return (
		<DynamicLink
			id={id}
			className={`project-item  ${highlight ? 'highlight' : ''} ${isList ? 'list-item' : 'tile-item'} ${hover ? 'active' : ''} ${className}`}
			onPointerEnter={handlePointerEnter}
			onPointerLeave={handlePointerLeave}
			data-cursor={isList ? CURSOR_TYPES.IMAGE : undefined}
			data-cursor-asset={JSON.stringify(cursorAsset)}
			$color={color}
			$order={index}
			href={link}
			isLocal={isLocal}
			{...props}
		>
			<WebGLImageVideo
				key={index}
				id={id}
				src={url}
				visible={!isList}
				data-cursor={!isList ? CURSOR_TYPES.HIDE : undefined}
				className="project-image"
				sizes={SIZES}
				video={videoUrl}
				webGL={webGL}
			/>
			<Titles className="titles">
				<Text className="project-title">{projectName || ''}</Text>
				<Text className="project-subtitle" as="div" dangerouslySetInnerHTML={subtitle} />
			</Titles>
		</DynamicLink>
	);
};

export default memo(Project);
