import React, {memo, useRef} from 'react';
import styled from 'styled-components';
import { useTracker } from '@14islands/r3f-scroll-rig';
import useTrackerMotionValue from '../../hooks/useTrackerMotionValue';
import { motion, useTransform } from 'framer-motion';
import { getFontSize, getHtmlFromRedactorField, getIdWithoutSpecialSymbols } from "@repo/utils";
import useWindowSize from "../../hooks/useWindowSize";
import { useIsDesktop } from "../../../index";
import { BREAKPOINTS } from "../../../styles/themeSettings";

const Wrapper = styled.div`
	--size: ${getFontSize(100, BREAKPOINTS.MOBILE)};
	position: relative;
	display: flex;
	width: 100%;
	overflow: hidden;
	height: calc(var(--size) * 1.25);
	
	div.header {
		position: absolute;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${({ $secondary }) => ($secondary ? 'var(--secondary-color)' : 'white')};
		height: ${({ $heightPercent }) => ($heightPercent ? `${$heightPercent}%` : 'inherit')};
		width: max-content;
		font-size: var(--size);
		font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
		text-transform: uppercase;
		div{
			height: 100%;
		}
		img{
			//height: var(--size);
			height: 100%;
			position: relative;
			margin: 0 8vw;
		}
		p{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		--size: ${getFontSize(300, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		--size:  ${getFontSize(300, BREAKPOINTS.DESKTOP)};
	}
`;

const HorizontalHeader = ({ text, secondary = false, heightPercent = 100, full=false, className='',id, direction = 'left', ...props }) => {
	const Ref = useRef(null);
	const idModule = getIdWithoutSpecialSymbols(id);
	const tracker = useTracker(Ref);
	const progress = useTrackerMotionValue(tracker);
	const isDesktop = useIsDesktop()
	const windowSize = useWindowSize()
	const width = windowSize?.width || 0
	const divider = isDesktop ? 2 : 1.25
	const offset = width / divider //50% of vw on desktop, 80% on mobiles
	const x = direction === 'right'
		? useTransform(progress, [0, 1], [`-${tracker.bounds.width - offset}px`, `${offset}px`])
		: useTransform(progress, [0, 1], [`${offset}px`, `-${tracker.bounds.width - offset}px`])
	const opacity = useTransform(progress, [0, 0.3, 0.7, 1], ['0.25', '1', '1', '0.25']);
	const redactor = getHtmlFromRedactorField(text)

	return (
		<Wrapper
			id={idModule}
			className={`horizontal-header ${className} ${full ? 'full-width' : ''}`}
			$secondary={secondary}
			$heightPercent={heightPercent}
			{...props}
		>
			<div
				ref={Ref}
				className="header"
			>
				<motion.div
					style={{x, opacity}}
					dangerouslySetInnerHTML={redactor}
				/>
			</div>
		</Wrapper>
	);
};

export default memo(HorizontalHeader);
