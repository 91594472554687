import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {getCookies, setCookie} from 'cookies-next';
import { setShowCookies, setIsCookiesModalExpanded } from '@/state/showCookiesSlice';
import {getCssSizeValue, getFontSize } from "@repo/utils";
import { hotjar } from 'react-hotjar'
import {BREAKPOINTS} from "@repo/ui/themeSettings";
import {replaceDataColorWithStyle} from "@repo/utils";
import {useRouter} from "next/router";
import HTMLWithClickHandler from "../common/HTMLWithClickHandler";
import {Button, CookiesExtended, fadeIn} from "../../../index";
import Image from "../common/Image";

const CookiesWrapper = styled.div`
	position: fixed;
	z-index: 15;
	flex-direction: column;
	display: flex;
	justify-content: space-between;
	border-radius: 19px;
	background-color: ${({theme}) => theme.colors.twilightBlue};
	color: ${({theme}) => theme.colors.white};
	opacity: 0;

    height: auto;
	min-height: ${getCssSizeValue(250, BREAKPOINTS.MOBILE)};
	left: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	right: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	bottom: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
	padding:
		${getCssSizeValue(25, BREAKPOINTS.MOBILE)}
		${getCssSizeValue(15, BREAKPOINTS.MOBILE)}
		${getCssSizeValue(25, BREAKPOINTS.MOBILE)}
	;

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		width: ${getCssSizeValue(570, BREAKPOINTS.TABLET)};
		min-height: ${getCssSizeValue(250, BREAKPOINTS.TABLET)};
		left: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
		right: unset;
		bottom: ${getCssSizeValue(25, BREAKPOINTS.TABLET)};
		padding:
			${getCssSizeValue(30, BREAKPOINTS.TABLET)}
			${getCssSizeValue(20, BREAKPOINTS.TABLET)}
			${getCssSizeValue(30, BREAKPOINTS.TABLET)}
		;
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: ${getCssSizeValue(580)};
		min-height: ${getCssSizeValue(300)};
		left: ${getCssSizeValue(50)};
		bottom: ${getCssSizeValue(30)};
		padding:
			${getCssSizeValue(42)}
			${getCssSizeValue(34)}
			${getCssSizeValue(34)}
		;
	}
`;

const StyledSvgWrapper = styled.div`
	position: absolute;
	border-radius: 100%;
	top: -10px;
	background: inherit;
	width: ${getCssSizeValue(75, BREAKPOINTS.MOBILE)};
	height: ${getCssSizeValue(75, BREAKPOINTS.MOBILE)};
	left: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
	overflow: hidden;
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		top: -15px;
		width: ${getCssSizeValue(85, BREAKPOINTS.TABLET)};
		height: ${getCssSizeValue(85, BREAKPOINTS.TABLET)};
		left: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: ${getCssSizeValue(95)};
		height: ${getCssSizeValue(95)};
		left: ${getCssSizeValue(35)};
    }
`

const StyledHeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledHeaderText = styled.div`
	font-weight: 900;

	margin-left: ${getCssSizeValue(90, BREAKPOINTS.MOBILE)};
	font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
	letter-spacing: -0.112px;
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		margin-left: ${getCssSizeValue(110, BREAKPOINTS.TABLET)};
		font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
		margin-bottom: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		margin-left: ${getCssSizeValue(110)};
		font-size: ${getFontSize(28)};
		margin-bottom: ${getCssSizeValue(35)};
	}
`

const StyledDescriptionText = styled.div`
    &, a {
        font-weight: 400;
        letter-spacing: -0.072px;
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)} !important;
            line-height: ${getFontSize(22, BREAKPOINTS.TABLET)}; 
        }
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(16)};
            line-height: ${getFontSize(25)};
        }
    }
`

const ButtonsWrapper = styled.div`
	display: flex;
	gap: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
	flex-wrap: nowrap;
	margin-top: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
	button { 
		text-transform: lowercase;
	}
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768 }px) {
		margin-top: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		margin-top: ${getCssSizeValue(35)};
        gap: ${getCssSizeValue(15, BREAKPOINTS.DESKTOP)};
	}
	
`

const COOKIE_OPTIONS = {
    maxAge: 60 * 60 * 24 * 365
}

const Cookies = ({ data }) => {
    const router = useRouter()
    const dispatch = useDispatch();
    const { isShown, isCookiesModalExpanded } = useSelector(state => state.showCookies)
    const [cookies, setCookies] = useState({
        hasConsent: false,
        analytics: false,
        marketing: false,
        functional: false
    });
    const extended = {
        acceptAllButton: data?.acceptAllButton || '',
        acceptSelectedButton: data?.acceptSelectedButton || '',
        backButton: data?.backButton || '',
        configButton: data?.configButton || '',
        list: data?.addCookies || [],
        generalButton: data?.generalButton || '',
        generalDescription: data?.generalDescription || '',
    };

    const description = data?.description || ''
    const coloredDescription = replaceDataColorWithStyle(description)

    const CookiesRef = useRef(null);

    useEffect(() => {
        if (isShown) {
            fadeIn(CookiesRef.current);
        }
    }, [isShown]);

    const handleConfig = () => {
        dispatch(setShowCookies(!isShown));
        dispatch(setIsCookiesModalExpanded(!isCookiesModalExpanded))
    };
    const setStorageCookies = () => {
        setCookie('hasConsent', true, COOKIE_OPTIONS);
        setCookie('analytics', cookies.analytics, COOKIE_OPTIONS);
        setCookie('marketing', cookies.marketing, COOKIE_OPTIONS);
        setCookie('functional', cookies.functional, COOKIE_OPTIONS);

        if(cookies.analytics){
            // console.log('hotjar init');
            hotjar.initialize({
                id:process.env.HJ_ID,
                sv: process.env.HJ_SV
            })
        }
    };

    const handleClose = () => {
        dispatch(setShowCookies(false));
        dispatch(setIsCookiesModalExpanded(false))
        setStorageCookies();
    };

    useEffect(() => {
        if (cookies.hasConsent === true) handleClose();
    }, [cookies.hasConsent]);

    useEffect(() => {
        const prevCookies = getCookies();
        if (Object.keys(prevCookies).length !== 0) {
            //if prevCookies exist set them accordingly
            if (prevCookies.marketing) {
                //convert to boolean and set
                const boolean = prevCookies.marketing === 'true';
                setCookies((prevState) => ({ ...prevState, marketing: boolean }));
            }
            if (prevCookies.analytics) {
                //convert to boolean and set
                const boolean = prevCookies.analytics === 'true';
                setCookies((prevState) => ({ ...prevState, analytics: boolean }));
            }
            if (prevCookies.hasConsent) {
                //convert to boolean and set
                const boolean = prevCookies.hasConsent === 'true';
                setCookies((prevState) => ({ ...prevState, hasConsent: boolean }));
                if (prevCookies.hasConsent === 'true') return;
            }
            if (prevCookies.functional) {
                //convert to boolean and set
                const boolean = prevCookies.functional === 'true';
                setCookies((prevState) => ({ ...prevState, functional: boolean }));
                if (prevCookies.functional === 'true') return;
            }
            dispatch(setShowCookies(true));
        } else {
            dispatch(setShowCookies(true));
            setCookie('hasConsent', cookies.hasConsent, COOKIE_OPTIONS);
        }
    }, []);

    const handleLinkClick = (link) => {
        router.push(`/${link}`)
    };


    return (
        <>
            {isShown ? (
                <CookiesWrapper ref={CookiesRef}>
                    <StyledSvgWrapper>
                        <Image src={data?.image?.url || ''} alt={data?.image?.alt || ''} sizes='100px' fill priority/>
                    </StyledSvgWrapper>
                    <StyledHeaderWrapper>
                        <StyledHeaderText>{data?.header || ''}</StyledHeaderText>
                        <HTMLWithClickHandler
                            htmlString={coloredDescription}
                            onLinkClick={handleLinkClick}
                            StyledContainer={StyledDescriptionText}
                        />
                    </StyledHeaderWrapper>
                    <ButtonsWrapper>
                        <Button onClick={handleConfig}>{data?.configButton || ''}</Button>
                        <Button
                            onClick={() => {
                                setCookies({
                                    hasConsent: true,
                                    analytics: true,
                                    marketing: true,
                                    functional: true
                                });
                                dispatch(setShowCookies(false));
                                dispatch(setIsCookiesModalExpanded(false))
                            }}
                        >
                            {data?.acceptAllButton || ''}
                        </Button>
                    </ButtonsWrapper>
                </CookiesWrapper>
            ) : null}
            {isCookiesModalExpanded ? (
                <CookiesExtended
                    handleConfig={handleConfig}
                    showExtended={isCookiesModalExpanded}
                    cookies={cookies}
                    setCookies={setCookies}
                    handleClose={handleClose}
                    extended={extended}
                />
            ) : null}
        </>
    );
};

export default Cookies;
