import {useEffect, useRef} from 'react';

export default function useEventListener(event = '', callback = undefined, element = window) {
	if (!element) {
		throw new Error('Target is not specified');
	}
	if (!event) {
		throw new Error('Event is not specified');
	}
	if (!callback) {
		throw new Error('callback is not specified');
	}
	if (typeof callback !== 'function') {
		throw new Error('callback is not a function');
	}
	const memoCallback = useRef();
	useEffect(() => {
		memoCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const listener = (e) => memoCallback.current(e);
		element.addEventListener(event, listener, true);
		return () => {
			element.removeEventListener(event, listener, true);
		};
	}, [element, event]);
}
