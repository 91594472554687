import styled from "styled-components";
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize, getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import Form from "../common/Form/Form";
import {useEffect, useRef, useState} from "react";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Button, Icon} from "../../../index";
import {useRouter} from "next/router";
import {ICONS} from "@repo/utils/constants";
import {useForm} from "@formspree/react";
import Modal from "../Modal/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import ContactModalRive from "../Rive/ContactModalRive";

const validateAttachment = (attachment) => {
    return attachment && attachment.size <= 5 * 1024 * 1024; // Max size 5MB
};

const StyledWrapper = styled.div`
    color: var(--secondary-color);
    position: relative;
`

const StyledAttachFile = styled.span`
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(240, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        font-weight: ${props => props.theme.fontWeight.bold};
        font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
    }
    
    .icon {
        width: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        height: ${getFontSize(12, BREAKPOINTS.MOBILE)};
    }
`

const StyledClearFileInput = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	right: 0;
	top: -50px;
	cursor: pointer;
	z-index: 1;
	svg {
		width: 10px;
		margin-right: 14px;
		position: absolute;
		top: calc(50% - 5px);
		left: -21px;
	}
	}
	p {
		color: ${props => props.theme.colors.twilightBlue};
		font-size: 16px;
		font-weight: 400;
	}
`;

const StyledFileInputWrapper = styled.div`
    margin-top: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin-top: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        margin-top: ${getCssSizeValue(90)};
        margin-bottom: ${getCssSizeValue(55)};
    }

    .file { 
		opacity: 0;
		width: 0;
		position: absolute;
	}

	.file-name {
		position: absolute;
		top: -50px;
		color: ${props => props.theme.colors.twilightBlue};
		left: 0;
		line-height: 18px;
		width: calc(100% - 80px);
	}

	.file-name.error {
		color: ${props => props.theme.colors.red};
	}
	span {
		transition: opacity 0.3s ease-in-out;
		opacity: 1;
		&.add-file-hidden {
			transition: opacity 0.3s ease-in-out;
			opacity: 0;
		}
	}
`;

const StyledSubmitButtonRecaptchaAndTermsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 4;
    background-color: var(--primary-color);
    transition: background-color var(--color-transition-duration) var(--ease);
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};;
    }
`

const StyledTermsWrapper = styled.div`
    color: ${props => props.theme.colors.darkDarkGray};
    font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
    
	span.show-more {
		position: relative;
		cursor: pointer;
        text-decoration: underline;
        font-weight: 800;
	}
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(12, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(21, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(12, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(21, BREAKPOINTS.DESKTOP)};
    }
`

const StyledExtendedProcessingInfo = styled.div`
    margin-top: 30px;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in-out;
    
    &.visible {
        height: calc(${getCssSizeValue(400, BREAKPOINTS.MOBILE)});

        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            height: calc(${getCssSizeValue(250, BREAKPOINTS.TABLET)});
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            height: calc(${getCssSizeValue(100, BREAKPOINTS.DESKTOP)});
        }
    }
`

const StyledRecaptchaWrapper = styled.div`
	height: 78px;
    margin-bottom: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		height: 78px;
        margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
	}
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-bottom: unset;
		height: 78px;
	}
`;

const initialFormData = {
    name: '',
    email: '',
    message: '',
    attachment: null
}

export default function JobOfferForm({ data }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)
    const attachFileText = data?.attachFileText || ''
    const buttonText = data?.buttonText || ''
    const inputs = data?.inputs || []
    const modalHeader = data?.modalHeader || ''
    const modalText = data?.modalText || ''
    const modalButtonText = data?.modalButtonText || ''
    const termsText = data?.termsText || ''
    const termsShowMoreText = data?.termsShowMoreText || ''
    const termsRestOfText = data?.termsRestOfText || ''

    const { locale } = useRouter()

    const [formData, setFormData] = useState(initialFormData);

    const [fileName, setFileName] = useState(null);
    const [isFileSizeValid, setIsFileSizeValid] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isCaptchaValidated, setIsCaptchaValidated] = useState();
    const [isFullInfoVisible , setIsFullInfoVisible] = useState(false)

    const [state, handleSubmit, reset] = useForm(process.env.NEXT_FORMSPREE_KEY_JOB_OFFER_FORM, {
        data: {
            attachment: formData.attachment
        }
    })

    useEffect(() => {
        setIsModalVisible(state.succeeded)
        if (state.succeeded) {
            setFormData(initialFormData)
        }
    }, [state]);

    const handleFileChange = (e) => {
        const isFileSizeValid = validateAttachment(e.target.files[0]);
        setIsFileSizeValid(isFileSizeValid);
        if (isFileSizeValid) {
            setFormData((prevData) => ({ ...prevData, attachment: e.target.files[0] }));
            const [file] = e.target.files;
            const { name: fileName } = file;
            setFileName(fileName);
        } else {
            setFileName(locale === 'pl' ? 'Rozmiar pliku przekracza 5Mb' : 'File size exceeds 5Mb');
        }
    };

    const handleClearFileInput = () => {
        if (fileName) {
            setFormData((prevData) => ({ ...prevData, attachment: null }));
            setFileName(null);
        }
    };

    return (
        <StyledWrapper
            id={id}
            className={`${paddings} px-main`}
            data-color-scheme={colorScheme}
        >
            <Modal
                isVisible={isModalVisible}
                handleCloseButton={() => reset()}
                data={{
                    header: modalHeader,
                    text: modalText,
                    buttonText: modalButtonText
                }}
                Rive={ContactModalRive}
            />
            <Form
                id='job-offer-form'
                data={inputs}
                formData={formData}
                setFormData={setFormData}
                handleSubmit={handleSubmit}
                submissionState={state}
            />

            <StyledFileInputWrapper>
                {!fileName ? (
                    <label htmlFor='file'>
                        <StyledAttachFile>
                            {attachFileText}
                        </StyledAttachFile>
                    </label>
                ) : (
                    <StyledAttachFile>
                        {fileName}
                        <Icon
                            name={ICONS.CROSS}
                            onClick={handleClearFileInput}
                        />
                    </StyledAttachFile>
                )}
                <input
                    form='job-offer-form'
                    disabled={fileName}
                    type="file"
                    name="attachment"
                    id="file"
                    accept="image/jpeg, image/png, application/pdf"
                    className="file"
                    onChange={handleFileChange}
                />
            </StyledFileInputWrapper>

            <StyledSubmitButtonRecaptchaAndTermsWrapper
                className='form-submit-button-wrapper'
            >
                <StyledTermsWrapper>
                    <div dangerouslySetInnerHTML={getHtmlFromRedactorField(termsText)} />
                    <span
                        onClick={() => setIsFullInfoVisible(!isFullInfoVisible)}
                        className="show-more"
                    >
                        {termsShowMoreText}
                    </span>
                    <StyledExtendedProcessingInfo className={isFullInfoVisible ? 'visible' : ''}>
                        <div dangerouslySetInnerHTML={getHtmlFromRedactorField(termsRestOfText)} />
                    </StyledExtendedProcessingInfo>
                </StyledTermsWrapper>
                <StyledRecaptchaWrapper>
                    <ReCAPTCHA
                        sitekey={process.env.NEXT_RECAPTCHA_SITEKEY}
                        theme="light"
                        hl={locale}
                        onChange={(val) => setIsCaptchaValidated(val)}
                    />
                </StyledRecaptchaWrapper>
                <Button disabled={!isCaptchaValidated} type='submit' variant='tertiary' form='job-offer-form' style={{ textTransform: 'lowercase' }}>
                    {buttonText}
                </Button>
            </StyledSubmitButtonRecaptchaAndTermsWrapper>
        </StyledWrapper>
    )
}