import { useCallback, useEffect, useState } from 'react'

export default function useSelectedSnapDisplay (emblaApi)  {
    const [selectedSnap, setSelectedSnap] = useState(0)
    const [snapCount, setSnapCount] = useState(0)

    const updateScrollSnapState = useCallback((emblaApi) => {
        setSnapCount(emblaApi.scrollSnapList().length)
        setSelectedSnap(emblaApi.selectedScrollSnap())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        updateScrollSnapState(emblaApi)
        emblaApi.on('select', updateScrollSnapState)
        emblaApi.on('reInit', updateScrollSnapState)
    }, [emblaApi, updateScrollSnapState])

    return {
        selectedSnap,
        snapCount
    }
}