import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {getCssSizeValue, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Button} from "../../../index";
import {AnimatePresence, motion} from "framer-motion";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";

const StyledBackground = styled(motion.div)`
	height: 100svh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99;
	background-color: rgba(5, 29, 39, 0.66);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    padding: 0 var(--paddingX);
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding: unset;
    }
`;

const StyledModalWrapper = styled.div`
    position: relative;
	width: 100%;
	max-width: ${getCssSizeValue(370, BREAKPOINTS.MOBILE)};
	border-radius: 19px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
    background: ${({theme}) => theme.colors.white};
    padding: 
            ${getCssSizeValue(62, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(30, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(40, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(35, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: ${getCssSizeValue(470, BREAKPOINTS.TABLET)};
        max-width: ${getCssSizeValue(470, BREAKPOINTS.TABLET)};
        padding:
                ${getCssSizeValue(64, BREAKPOINTS.TABLET)}
                ${getCssSizeValue(56, BREAKPOINTS.TABLET)}
                ${getCssSizeValue(49, BREAKPOINTS.TABLET)}
                ${getCssSizeValue(45, BREAKPOINTS.TABLET)};
	}
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		width: ${getCssSizeValue(527, BREAKPOINTS.DESKTOP)};
		max-width: ${getCssSizeValue(527, BREAKPOINTS.DESKTOP)};
        padding:
                ${getCssSizeValue(66, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(68, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(83, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(57, BREAKPOINTS.DESKTOP)};
	}

    .rive-wrapper {
        position: absolute;
        overflow: hidden;
        display: flex;
        background: ${props => props.theme.colors.lightGray};
        z-index: 2;
        border-radius: 100%;
        top: ${getCssSizeValue(-62, BREAKPOINTS.MOBILE)};
        right: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
        height: ${getCssSizeValue(124, BREAKPOINTS.MOBILE)};
        width: ${getCssSizeValue(124, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            top: -15%;
            right: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
            height: ${getCssSizeValue(156, BREAKPOINTS.TABLET)};
            width: ${getCssSizeValue(156, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
            top: -15%;
            right: ${getCssSizeValue(37, BREAKPOINTS.DESKTOP)};
            height: ${getCssSizeValue(156, BREAKPOINTS.DESKTOP)};
            width: ${getCssSizeValue(156, BREAKPOINTS.DESKTOP)};
        }
    }
`;


const StyledHeading = styled.p`
    color: ${({theme}) => theme.colors.twilightBlue};
	font-size: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
    line-height: ${getCssSizeValue(36, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getCssSizeValue(44, BREAKPOINTS.TABLET)};
        line-height: ${getCssSizeValue(56, BREAKPOINTS.TABLET)};
		margin-bottom: ${getCssSizeValue(45, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
        font-size: ${getCssSizeValue(48, BREAKPOINTS.DESKTOP)};
        line-height: ${getCssSizeValue(56, BREAKPOINTS.DESKTOP)};
		margin-bottom: ${getCssSizeValue(45, BREAKPOINTS.DESKTOP)};
	}
`;

const StyledDescription = styled.div`
    color: ${({theme}) => theme.colors.twilightBlue};
	font-size: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)};
	line-height: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getCssSizeValue(22, BREAKPOINTS.TABLET)};
		line-height: ${getCssSizeValue(41, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
		font-size: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
		line-height: ${getCssSizeValue(41, BREAKPOINTS.DESKTOP)};
	}
`;

const StyledButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
    
	margin-top: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		margin-top: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
        column-gap: ${getCssSizeValue(15, BREAKPOINTS.TABLET)};
	}
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		margin-top: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
        column-gap: ${getCssSizeValue(15)};
	}
`;

const Modal = ({ data, isVisible, Rive = null, handleCloseButton }) => {
    const header = data?.header || '';
    const text = data?.text || '';
    const buttonText = data?.buttonText || '';
    const buttonTextOpen = data?.buttonTextOpen || '';
    const externalUrl = data?.externalUrl || '';
    const modalRef = useRef();

    const handleClickOutside = (e) => {
        if (modalRef?.current?.contains(e.target)) {
            return;
        }
        if (isVisible) {
            handleCloseButton();
        }
    };

    const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
            handleCloseButton();
        }
    };

    const handleClickOpenInNewTab = () => {
        const newWindow = window.open(externalUrl, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, false);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, false);
        };
    }, [isVisible]);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('keydown', handleEscapeKey);
        } else {
            document.removeEventListener('keydown', handleEscapeKey);
        }

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isVisible]);

    return (
        <AnimatePresence mode='wait'>
            {isVisible && (
                <StyledBackground
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <StyledModalWrapper ref={modalRef}>
                        { Rive && <Rive className='rive-wrapper' /> }
                        <StyledHeading dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}/>
                        <StyledDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
                        {buttonTextOpen && <StyledButtonWrapper onClick={() => handleCloseButton()}>
                            <Button variant='tertiary'>{buttonText}</Button>
                            {buttonTextOpen && <Button variant='tertiary' type="button" onClick={handleClickOpenInNewTab}>{buttonTextOpen}</Button>}
                        </StyledButtonWrapper> }
                        {!buttonTextOpen && (
                            <StyledButtonWrapper onClick={() => handleCloseButton()}>
                                <Button type="submit" variant='tertiary'>{buttonText}</Button>
                            </StyledButtonWrapper>
                        )}
                    </StyledModalWrapper>
                </StyledBackground>
            )}
        </AnimatePresence>
    );
};

export default Modal;
