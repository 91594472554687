import React, {forwardRef, startTransition, memo, useImperativeHandle, useRef} from 'react';
import {defaults} from "../Blob/Blob";
import {useCursor} from "../../Cursor/Cursor";
import {useFrame} from "@react-three/fiber";
import {PORTAL_LENS_RADIUS} from "@repo/utils/constants";

export const LensGeometry = forwardRef(function Geometry(props, ref) {
    return <sphereGeometry ref={ref} args={[PORTAL_LENS_RADIUS, 32,32]}/>
})

const MATERIAL = {
    // color: 'red',
    color: 'rgb(255,255,255)',
    transparent: true,
    transmission: 1,
    // transmission: 0.5,
    clearcoat: 0,
    // roughness: 0.1,
    roughness: 0,
    metalness: 0,
    reflectivity: 0.1,
    thickness: 0,
    // envMapIntensity: 0.5,
    // iridescence: 0.3,
    // iridescenceIOR: 1.65,
    // ior: 1.5,
    // emissive: 'rgb(0,0,0)',
    // sheenColor: 'rgb(255,0,0)',
    // sheenRoughness: 1,
    // sheen: 1,
    // specularIntensity: 1,
    // flatShading: true,
    // depthWrite: false,
};

const StaticLens = () => {
    const MeshRef = useRef()
    const { lens, portalLens } = useCursor()

    useImperativeHandle(portalLens, () => MeshRef.current, [])
    // console.log(MeshRef);

    useFrame(() => {
        const { scale, position, visible } = portalLens
        //apply shared props

        startTransition(() => {
            MeshRef.current.scale.copy(scale)
            MeshRef.current.position.copy(position)
            MeshRef.current.visible = visible
        })

    })

    return (
        <>
            <mesh ref={MeshRef}>
                <LensGeometry/>
                <meshPhysicalMaterial
                    {...MATERIAL}
                />
                {/*<MeshTransmissionMaterial*/}
                {/*    buffer={lens.fbo?.texture}*/}
                {/*    ior={defaults.ior}*/}
                {/*    thickness={defaults.thickness}*/}
                {/*    chromaticAberration={defaults.chromaticAberration}*/}
                {/*    distortion={defaults.distortion}*/}
                {/*    temporalDistortion={defaults.temporalDistortion}*/}
                {/*    distortionScale={defaults.distortionScale}*/}
                {/*    samples={4}*/}
                {/*    anisotropy={0.1}*/}
                {/*    iridescence={1}*/}
                {/*    iridescenceIOR={defaults.ior}*/}
                {/*    iridescenceThicknessRange={[0, 1400]}*/}
                {/*    envMapIntensity={0.1}*/}
                {/*    transparent*/}
                {/*/>*/}
            </mesh>
        </>
    );
};

export default memo(StaticLens);
