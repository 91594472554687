import {
	useCallback,
	useEffect,
	useMemo,
	useState,
	memo,
	createContext,
	useContext
} from 'react';
import { ThemeProvider } from 'styled-components';
import { setCssVars} from '@repo/utils';
import {GlobalStyles, Paddings, Typography, useIsDesktop, useMobileDevice, useVisible3D} from "../../index";
import {
	initialPalette,
	PLEO_PREFIX,
	THEME_MODES,
	theme as initialTheme,
	DEFAULT_COLOR_SCHEME, DEFAULT_MODE
} from "../../styles/themeSettings";

function getColorPalette(palette, colorScheme = DEFAULT_COLOR_SCHEME) {
	return {
		primary: palette[colorScheme]?.primary || initialPalette.light[colorScheme].primary,
		secondary: palette[colorScheme]?.secondary || initialPalette.light[colorScheme].secondary,
		tertiary: palette[colorScheme]?.tertiary || initialPalette.light[colorScheme].tertiary,
	};
}

function setColorPalette(palette) {
	return setCssVars(document.documentElement, {
		'primary-color': palette?.primary,
		'secondary-color': palette?.secondary,
		'tertiary-color': palette?.tertiary,
	});
}


function validateCmsTheme(theme) {
	return {
		main: {
			primary: theme?.mainPrimary?.hex,
			secondary: theme?.mainSecondary?.hex,
			tertiary: theme?.mainTertiary?.hex,
		},
		extra: {
			primary: theme?.extraPrimary?.hex,
			secondary: theme?.extraSecondary?.hex,
			tertiary: theme?.extraTertiary?.hex,
		},
	};
}

const ExtendedTheme = createContext({
	mode:DEFAULT_MODE,
	colorScheme: DEFAULT_COLOR_SCHEME,
	palette: initialTheme.palette,
	setTheme: () => {}
});


const STORAGE_KEY = 'theme';

const Theme = ({ children, darkTheme = null, lightTheme = null, forceLight = false }) => {
	const [mode, setMode] = useState(DEFAULT_MODE); //'light', 'dark', 'a11y'
	const [colorScheme, setColorScheme] = useState(DEFAULT_COLOR_SCHEME); //'main', 'extra', 'pleo-main', 'pleo-extra'
	const [palette, setPalette] = useState(initialTheme.palette);
	const [isStyledLoaded, setIsStyledLoaded] = useState(false);
	const isMobileDevice = useMobileDevice()
	const isDesktop = useIsDesktop()
	const visible3D = useVisible3D()
	//provide props to change mode and color scheme
	//example props: { mode: 'dark', colorScheme: 'extra'}
	const setTheme = useCallback(
		(props = { mode: DEFAULT_MODE, colorScheme: DEFAULT_MODE, palette: initialTheme.palette}) =>
			Object.keys(props).forEach((key) => {
				if (!props[key]) throw new Error('setTheme props must be defined');
				if (key === 'mode') {
					localStorage.setItem(STORAGE_KEY, props[key]);
					setMode(props[key]);
				}
				if (key === 'colorScheme') {
					setColorScheme(props[key]);
				}
				if (key === 'palette') {
					setPalette(props[key]);
				}
			}),
		[],
	);

	const context = useMemo(() =>
		({
			...initialTheme,
			isDesktop,
			isMobileDevice,
			visible3D,
			setTheme
		}), [isMobileDevice, isDesktop, visible3D]);

	const extendedContext = useMemo(() =>
		({
			mode,
			colorScheme,
			setTheme,
			palette,
		}), [mode, colorScheme, palette]);

	useEffect(() => {
		if(!forceLight) {
			//grab local storage previous theme key and if exists set it
			const previousTheme = localStorage.getItem(STORAGE_KEY);
			if (!previousTheme || previousTheme === 'null') {
				const media = window.matchMedia('(prefers-color-scheme: dark)');
				if (media.matches && mode !== THEME_MODES.DARK) {
					setTheme({mode: THEME_MODES.DARK})
				}
			} else {
				if (mode !== previousTheme) {
					setTheme({mode: previousTheme})
				}
			}
		}
	}, []);

	// creates custom themes based on CMS data
	useEffect(() => {
		let palette;
		if (mode === THEME_MODES.A11Y) {
			palette = getColorPalette(initialPalette, mode); //a11y palette
		} else {
			//if CMS theme exists, use it, otherwise use predefined theme
			const cmsTheme = mode === THEME_MODES.LIGHT ? lightTheme : darkTheme;
			const pleoTheme = colorScheme.includes(PLEO_PREFIX); //check if color scheme is company palette
			//if it is use it and cut prefix
			const cs = pleoTheme ? colorScheme.substring(PLEO_PREFIX.length) : colorScheme;
			const colors = pleoTheme || !cmsTheme ? initialPalette[mode] : validateCmsTheme(cmsTheme);
			palette = getColorPalette(colors, cs); //main or extra palette
		}

		setPalette(palette)
	}, [colorScheme, mode, darkTheme, lightTheme]);


	useEffect(() => {
		setColorPalette(palette); //set palette in css variables
	},[palette])


	useEffect(() => {
		setIsStyledLoaded(true);
	}, []);


	return isStyledLoaded && (
		<ExtendedTheme.Provider value={extendedContext}>
			<ThemeProvider theme={context}>
				{children}
				<GlobalStyles />
				<Paddings />
				<Typography />
			</ThemeProvider>
		</ExtendedTheme.Provider>
	);
};

export function useExtendedTheme(selector) {
	const context = useContext(ExtendedTheme);
	if (typeof context === 'undefined') {
		throw new Error('useExtendedTheme must be used within a ExtendedTheme rovider');
	}
	if(typeof selector === 'function'){
		return selector(context)
	}
	return context;
}

export default memo(Theme);
