import React, {forwardRef, memo, useImperativeHandle, useRef} from 'react';
import NextImage from 'next/image';
import styled from 'styled-components';

const DEFAULT_FOCAL_POINT = { X: 0.5, Y: 0.5 };
const DEFAULT_SIZES = '100%';

const Container = styled.figure`
	display: flex;
	width: 100%;
	height: 100%;
	position: relative; //When using next js image with fill prop, the parent element must have position: relative
	overflow: hidden;
	z-index: 1;
	margin: 0;

	&.transparent-3d{
		opacity: 0;
		//visibility: hidden;
	}
`;

const defaultAlt = 'image'

// eslint-disable-next-line no-redeclare
const Image = forwardRef(function Image(
	{
		src = '',
		alt: passedAlt = defaultAlt,
		sizes = DEFAULT_SIZES,
		priority = false,
		placeholder = 'empty',
		blurDataURL = '',
		fit = 'cover',
		focalPoint = { x: DEFAULT_FOCAL_POINT.X, y: DEFAULT_FOCAL_POINT.Y },
		fill=true,
		children,
		visible=true,
		className='',
		...props
	},
	fref,
) {
	const ContainerRef= useRef()

	useImperativeHandle(fref, () => ContainerRef.current, [])
	const alt = passedAlt || defaultAlt
	if(!visible) return null
	return (
		<Container ref={ContainerRef} className={`image ${className}`} {...props}>
			<NextImage
				src={src}
				fill={fill}
				priority={priority}
				placeholder={placeholder}
				blurDataURL={blurDataURL}
				alt={alt}
				sizes={sizes}
				style={{
					objectFit: fit,
					objectPosition: `${(focalPoint?.x) * 100}% ${(focalPoint?.y) * 100}%`,
				}}
			/>
			{children}
		</Container>
	);
});

export default memo(Image);
