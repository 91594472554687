import styled from "styled-components";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Button, Reveal} from "../../../index";
import useIsDesktop from "../../hooks/useIsDesktop";
import {useRouter} from "next/router";
import {useCallback, useState} from "react";
import {multilineUnderlineCss} from "../../../styles/globalStyles";
import {CURSOR_TYPES} from "@repo/utils/constants";

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        grid-template-columns: repeat(2, 1fr);
        gap: ${getCssSizeValue(20, BREAKPOINTS.TABLET)}; 
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        grid-template-columns: repeat(4, 1fr);
        gap: ${getCssSizeValue(48)};
    }
`

const Card = styled.div`
    cursor: pointer;
    background: ${props => props.theme.colors.white};
    padding:
        ${getCssSizeValue(42, BREAKPOINTS.MOBILE)}
        ${getCssSizeValue(35, BREAKPOINTS.MOBILE)}
        ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    display: flex;
    flex-direction: column;
    gap: ${getCssSizeValue(78, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};
        padding:
                ${getCssSizeValue(42, BREAKPOINTS.TABLET)}
                ${getCssSizeValue(30, BREAKPOINTS.TABLET)}
                ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
        gap: ${getCssSizeValue(120)};
        padding:
                ${getCssSizeValue(42, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)}
                ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
    }
    
    .header-level {
        .header {
            ${multilineUnderlineCss};
            
            letter-spacing: -0.88px;
            font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
            margin-bottom: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    
            @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
                letter-spacing: -0.96px;
                font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
                margin-bottom: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
            }
        
            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
                letter-spacing: unset;
                width: 90%;
                font-weight: ${props => props.theme.fontWeight.bold};
                font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
                margin-bottom: ${getCssSizeValue(25)};
            }
        }
        
        .level {
            display: flex;
            gap: 2px;
        }
    }
    
    .description-button {
        .description {
            padding-top: 20px;
            border-top: 1px solid var(--secondary-color);
            font-weight: ${props => props.theme.fontWeight.regular};
            font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
    
            @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
                font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
            }
        
            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
                font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
                margin-bottom: ${getCssSizeValue(32)};
            }
        }
    }

    transition: transform var(--transition-duration) var(--ease);
    &.hover {
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) {
            transform: scale(1.02);
        }
    }
`

const Pill = styled.div`
    height: 2px;
    width: 30px;
    background: ${props => props.theme.colors.dimmedGray};
    
    &.one {
        &:first-child {
            background: ${props => props.theme.colors.designBlue};
        }    
    }
    
    &.two {
        &:first-child, &:nth-child(2) {
            background: ${props => props.theme.colors.gold};
        }
    }
    
    &.three {
        background: ${props => props.theme.colors.red};
    }
`

const ADVANCEMENT_LEVELS = {
    ONE: 'one',
    TWO: 'two',
    THREE: 'three'
}

export default function JobOffersGrid({ jobOffers = [] }) {
    const [hoverIndex, setHoverIndex] = useState(null)
    const isDesktop = useIsDesktop()
    const router = useRouter()

    const handleClick = useCallback((slug) => {
        router.push(`/about/${slug}`)
    }, [router, jobOffers])

    return (
        <Grid>
            {jobOffers.map((item, index) => {
                const id = item?.id || index
                const slug = item?.slug || ''
                const header = item?.card?.header || ''
                const level = item?.card?.level || ''
                const description = item?.card?.description || ''
                const buttonText = item?.card?.buttonText || ''
                const className = ADVANCEMENT_LEVELS[level]

                return (
                    <Card
                        onClick={() => handleClick(slug)}
                        key={id}
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                        className={`${hoverIndex === index ? 'hover' : ''}`}
                    >
                        <Reveal
                            onInView
                        >
                            <div className='header-level'>
                                <div 
                                    className='header'
                                    data-cursor={CURSOR_TYPES.HIDE}
                                >
                                    <div
                                        className='multiline-underline-animation'
                                        dangerouslySetInnerHTML={getHtmlFromRedactorField(header)}
                                    />
                                </div>
                                <div className='level'>
                                    {Array.from({ length: 3 }).map((_, index) => {
                                        return <Pill className={`pill ${className}`} key={index}></Pill>
                                    })}
                                </div>
                            </div>
                            <div className='description-button'>
                                <div className='description' dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
                                {isDesktop && <Button onClick={() => handleClick(slug)} variant='tertiary'>{buttonText}</Button> }
                            </div>
                        </Reveal>
                    </Card>
                )
            })}
        </Grid>
    )
}