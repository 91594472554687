import React from 'react';
import styled from "styled-components";
import Image from "../common/Image";
import Text from "../common/Text";
import Link from "../common/Link";
import {getCssSizeValue, getFontSize, getPrefix, getSlug} from "@repo/utils";
import {getCardData} from "../common/CardsSlider/Card";
import {CMS_ELEMENT_TYPENAME, CURSOR_TYPES, ICONS,} from "@repo/utils/constants";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Icon} from "../../../index";

const Wrapper = styled(Link)`
    --column-gap: ${getCssSizeValue(25,BREAKPOINTS.MOBILE)};
    --half-width: calc(50% - var(--column-gap) * 0.5);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    justify-content: space-between;
    word-break: break-word;
    column-gap: var(--column-gap);
    padding:
            ${getCssSizeValue(20, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(24, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        padding: 
                ${getCssSizeValue(30, BREAKPOINTS.TABLET)} 
                ${getCssSizeValue(20, BREAKPOINTS.TABLET)} 
                ${getCssSizeValue(44, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        flex-direction: column;
        padding: ${getCssSizeValue(51)} ${getCssSizeValue(42)};
        aspect-ratio: 410/640;

        &:hover{
            .image{
                transform: scale(1.1);
            }
        }
    }
    
    .icon {
        max-width: unset;
        height: auto;
        width: 100%;
    }
`

const IconAndHeaderWrapper = styled.div`
    display: flex;
    gap: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(6, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        gap: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(12, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        flex-direction: column;
        margin-bottom: unset;
    }
`

const CustomIcon = styled(Image)`
    --size: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    aspect-ratio: 1;
    transition: transform var(--transition-duration) var(--ease);
    transform-origin: left top;
    width: var(--size);
    height: var(--size);

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        --size: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(180)};
    }
`

const Header = styled(Text)`
    font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(44)};
    }
    
    &.soon-mask {
        opacity: 0.22;
    }
`

const Description = styled.div`
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
    font-weight: ${({ theme }) => theme?.fontWeight?.book|| 300};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(31, BREAKPOINTS.TABLET)};
        padding: 0 ${getCssSizeValue(32, BREAKPOINTS.TABLET)} 0 ${getCssSizeValue(17, BREAKPOINTS.TABLET)};
    }
        
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding: unset;
        font-size: ${getFontSize(24)};
        line-height: ${getFontSize(36)};
    }
`

const Tile = ({ data }) => {
    const typename = data?.[CMS_ELEMENT_TYPENAME] || ''
    const card = getCardData(data?.card || {})
    const slug = getSlug(data?.slug || '')
    const prefix = getPrefix(typename)
    const href = `${prefix}/${slug}`
    const { header, description, soonMask, icon } = card

    return (
        <Wrapper href={href} data-cursor={soonMask ? CURSOR_TYPES.SOON : CURSOR_TYPES.CHECK}>
            <IconAndHeaderWrapper>
                <CustomIcon src={icon.url} alt={icon.alt} fit="contain"/>
                <Header className={`${soonMask ? 'soon-mask' : ''}`}>
                    {header}
                </Header>
            </IconAndHeaderWrapper>
            {soonMask ? (
                <Icon name={ICONS.SOON_MASK} />
            ) : (
                <Description dangerouslySetInnerHTML={description}/>
            )}
        </Wrapper>
    );
};

export default Tile;
