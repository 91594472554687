import React, {forwardRef} from 'react';
import styled from "styled-components";
import {getCssSizeValue, getFontSize} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const Wrapper = styled.div`
	--size:  ${getFontSize(40, BREAKPOINTS.MOBILE)};
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 70svh;
	transition: color var(--color-transition-duration) var(--ease);
	padding-top: ${getCssSizeValue(134, BREAKPOINTS.MOBILE)};
	padding-bottom: ${getCssSizeValue(87, BREAKPOINTS.MOBILE)};
	font-size: var(--size);
	line-height: ${getFontSize(58, BREAKPOINTS.MOBILE)};
	font-weight: ${({ theme }) => theme?.fontWeight?.regular || 400};
	letter-spacing: calc(var(--size) * -0.04);

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		padding-top: ${getCssSizeValue(170, BREAKPOINTS.TABLET)};
		padding-bottom: ${getCssSizeValue(157, BREAKPOINTS.TABLET)};
		--size: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
		line-height: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		padding-top: ${getCssSizeValue(217, BREAKPOINTS.DESKTOP)};
		padding-bottom: ${getCssSizeValue(217, BREAKPOINTS.DESKTOP)};
		--size: ${getCssSizeValue(116, BREAKPOINTS.DESKTOP)};
		line-height: ${getCssSizeValue(136, BREAKPOINTS.DESKTOP)};
		min-height: 100svh;
	}
`;

const Banner = forwardRef(function Banner({ children, className='',...props }, fref){
    return (
        <Wrapper ref={fref} className={`px-main ${className}`} {...props} >
            {children}
        </Wrapper>
    );
})

export default Banner;
