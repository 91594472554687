import React, {useRef} from 'react';
import styled from 'styled-components';
import {
	getColorScheme,
	getCssSizeValue,
	getFontSize,
	getHtmlFromRedactorField,
	getPaddingsClassFromData,
	removeTags
} from '@repo/utils';
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Reveal, {REVEAL_CLASSNAME} from "../../animations/Reveal";


const StyledHeaderDescriptionTwoColumnsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;
	
	.${REVEAL_CLASSNAME}-child{
		width: 100% !important;
	}
	.header{
		width: 100%;
		color: var(--secondary-color);
		font-weight: ${({ theme }) => theme?.fontWeight?.semiBold || 600};
		margin-bottom: ${getCssSizeValue(59, BREAKPOINTS.MOBILE)};
		font-size: ${getFontSize(22, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		height: fit-content;
	}
	
	.desc, a{
		width: 100%;
		//color: #152731;
		color: var(--secondary-color);
		font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(32, BREAKPOINTS.MOBILE)};
		font-weight: 400;
		height: fit-content;
	}
	
	@media(min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		.header{
			font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
			margin-bottom: ${getCssSizeValue(51, BREAKPOINTS.TABLET)};
		}
		.desc, a{
			font-size: ${getFontSize(28, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
		}
	}


	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
		justify-content: space-between;
		.header{
			font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(66, BREAKPOINTS.DESKTOP)};
			width: 50%;
			margin-bottom: 0;
		}
		.desc, a{
			font-size: ${getFontSize(42, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(62, BREAKPOINTS.DESKTOP)};
			width: 50%;
		}
	}
	a {
		text-decoration: underline;
		text-decoration-color: var(--secondary-color);
		text-decoration-style: solid;
		text-decoration-thickness: 1px;
		color: var(--secondary-color);
	}
`;


const HeaderDescriptionTwoColumns = ({ data, textModuleId }) => {
	const header = data?.header || '';
	const description = data?.description || '';
	// const redactorHeader = getHtmlFromRedactorField(description);
	const redactorHeader = getHtmlFromRedactorField(removeTags(header, ['p', 'span'])); //tag removing so animation works fine
	// const redactorDesc = getHtmlFromRedactorField(description);
	const redactorDesc = getHtmlFromRedactorField(removeTags(description, ['p', 'span'])); //tag removing so animation works fine
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	const HeaderRef = useRef()
	const DescRef = useRef()

	// useLinesReveal({
	// 	el:HeaderRef,
	// 	onInView: true,
	// 	disabled: !header
	// })
	//
	// useLinesReveal({
	// 	el:DescRef,
	// 	onInView: true,
	// 	disabled: !description
	// })

	return (
		<StyledHeaderDescriptionTwoColumnsWrapper
			id={textModuleId}
			data-color-scheme={colorScheme}
			className={`${paddings} px-main`}
		>
			{!!header && (
				<Reveal onInView className="header" >
					<div ref={HeaderRef} dangerouslySetInnerHTML={redactorHeader}/>
				</Reveal>
			)}
			{!!description && (
				<Reveal onInView className="desc" >
					<div ref={DescRef} dangerouslySetInnerHTML={redactorDesc}/>
				</Reveal>
			)}
		</StyledHeaderDescriptionTwoColumnsWrapper>
	);
};

export default HeaderDescriptionTwoColumns;
