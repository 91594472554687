import styled from "styled-components";
import {getAspectRatioFromImage, getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Image} from "../../../index";
import Button from "../common/Button";
import {useCallback, useMemo} from "react";
import Video from "../common/Video";

const EmblaSlide = styled.div`
    user-select: none;
    display: flex;
    flex-direction: column-reverse;
    flex: 0 0 100%;
    gap: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        flex-direction: row;
        gap: unset;
        justify-content: space-between;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
         gap: ${getCssSizeValue(50)};
    }
    
    .image-button-wrapper {
        display: flex;
        flex-direction: column;
        gap: ${getCssSizeValue(39, BREAKPOINTS.MOBILE)};
                    
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            width: 35%;
            gap: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            width: 20%;
            gap: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
        }

        .video {
            aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
            width: 100%;
            height: auto;
        }
    }

    .text-author-wrapper {
        display: flex;
        flex-direction: column;

        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            gap: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
            width: 51%;
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            gap: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
            flex: 1;
        }
        
        .text {
            font-style: italic;
            font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(28, BREAKPOINTS.MOBILE)};
            
            @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
                font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(28, BREAKPOINTS.TABLET)};
            }

            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
                font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(41, BREAKPOINTS.DESKTOP)};
            }
        }
        
        .author {
            font-weight: ${props => props.theme.fontWeight.semiBold};
            font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(36, BREAKPOINTS.MOBILE)};
            
            @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
                font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
                line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
            }

            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
                font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
                line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            }
        }
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: 100%;
    height: auto;
`

const StyledVideo = styled(Video)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: 100%;
    height: auto;
`

const MEDIA_TYPE = {
    VIDEO: 'video',
    IMAGE: 'image'
}

function Media({ client }) {
    const linkedProjectImage = client?.project?.coverPortrait || null
    const linkedProjectVideo = client?.project?.video || null
    const externalAsset = client?.asset || null

    const mediaData = useMemo(() => {
        let result = {
            src: '',
            aspectRatio: '16/9',
            type: null
        }
        if (externalAsset) {
            const assetMimeType = externalAsset?.mimeType || '';
            const aspectRatio = getAspectRatioFromImage(externalAsset)
            if (assetMimeType.startsWith('video/')) {
                result = {
                    src: externalAsset?.url || '',
                    aspectRatio,
                    type: MEDIA_TYPE.VIDEO,
                }
            } else {
                result = {
                    src: externalAsset?.url || '',
                    aspectRatio,
                    type: MEDIA_TYPE.IMAGE,
                }
            }
        } else if (linkedProjectVideo) {
            const aspectRatio = getAspectRatioFromImage(linkedProjectVideo)
            result = {
                src: linkedProjectVideo?.url || '',
                aspectRatio,
                type: MEDIA_TYPE.VIDEO,
            }
        } else if (linkedProjectImage) {
            const aspectRatio = getAspectRatioFromImage(linkedProjectImage)
            result = {
                src: linkedProjectImage?.url || '',
                aspectRatio,
                type: MEDIA_TYPE.IMAGE,
            }
        }

        return result
    }, [externalAsset, linkedProjectVideo, linkedProjectImage])


    const renderMedia = useCallback(() => {
        let result = null;
        const { src, aspectRatio, type } = mediaData

        if (!type) return null;

        if (type === MEDIA_TYPE.IMAGE) {
            result =  <StyledImage
                src={src}
                sizes={'100vw'}
                $aspectRatio={aspectRatio}
            />
        }

        if (type === MEDIA_TYPE.VIDEO) {
            result = <StyledVideo
                src={src}
                autoplay
                $aspectRatio={aspectRatio}
            />
        }

        return result
    }, [mediaData])

    return <>
        {renderMedia()}
    </>
}


export default function Client({ client, buttonText }) {
    const text = client?.text || ''
    const author = client?.author || ''
    const externalAsset = client?.asset || null
    const slug = client?.project?.slug || ''

    return (
        <EmblaSlide>
            <div className='image-button-wrapper'>
                <Media client={client} />
                {!externalAsset && (
                    <Button as='link' href={`/projects/${slug}`} variant='tertiary'>{buttonText}</Button>
                )}
            </div>
            <div className='text-author-wrapper'>
                <div className='text' dangerouslySetInnerHTML={getHtmlFromRedactorField(text)} />
                <div className='author'>{author}</div>
            </div>
        </EmblaSlide>
    )
}