import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { gsap } from '@repo/utils/gsap';
import {useRouter} from "next/router";
import {usePageTransitionContext} from "../components/PageTransition";
import {moveTopBar} from "../animations/pageTransitionAnimations";

export default function useHideTopBarOnScroll() {
    const { asPath } = useRouter()

    const { transitionCompleted } = usePageTransitionContext()

    const [scrollState, setScrollState] = useState({
        isVisible: true,
        lastScrollY: 0,
    });
    const [isInitialRender, setIsInitialRender] = useState(true);

    const scrollYRef = useRef(0);
    const isMenuOpen = useSelector((state) => state.menu.open);

    const topRowElRef = useRef(null);
    const stickyHeadingRef = useRef(null);

    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;
        const viewportHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const bottomOffset = documentHeight - (currentScrollY + viewportHeight);

        const isScrollingUp = currentScrollY < scrollYRef.current;
        const shouldShow = (isScrollingUp || currentScrollY < 10) && bottomOffset > 60;

        if (shouldShow !== scrollState.isVisible) {
            setScrollState({
                isVisible: shouldShow,
                lastScrollY: currentScrollY,
            });
        }

        scrollYRef.current = currentScrollY;
    }, [scrollState.isVisible]);

    useEffect(() => {
        const throttledHandleScroll = throttle(handleScroll, 100);
        window.addEventListener("scroll", throttledHandleScroll);
        return () => window.removeEventListener("scroll", throttledHandleScroll);
    }, [handleScroll]);

    useEffect(() => {
        topRowElRef.current = document.getElementById("menu-top-row");
        stickyHeadingRef.current = document.getElementById("sticky-heading");
    }, [isInitialRender, asPath]);

    useEffect(() => {
        setIsInitialRender(false);
    }, []);

    useEffect(() => {
        if (isInitialRender || !topRowElRef.current || !stickyHeadingRef.current || !transitionCompleted) return;

        const animationDuration = 0.1;

        if (scrollState.isVisible || isMenuOpen) {
            gsap.to(topRowElRef.current, {
                ease: 'none',
                duration: animationDuration,
                y: '0'
            });
            stickyHeadingRef.current.style.opacity = '0';
        } else {
            gsap.to(topRowElRef.current, {
                ease: 'none',
                duration: animationDuration,
                y: '-120'
            });
            stickyHeadingRef.current.style.opacity = '1';
        }
    }, [scrollState.isVisible, isMenuOpen, isInitialRender, transitionCompleted]);

}

function throttle(func, delay) {
    let lastCall = 0;
    return function(...args) {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return func(...args);
    };
}