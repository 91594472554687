import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {
    formatHTML,
    getOnlyBreaksTexts,
    getStoryItems,
    removePrefixes, setStoryItems,
    TITLE_BREAK_ACTIVE_PREFIX,
    TITLE_BREAK_HIDDEN_PREFIX,
} from "../common/TextWithBreaks/utils";
import {useRouter} from "next/router";
import {getCssSizeValue, getPaddingsClassFromData} from "@repo/utils";
import {PADDING} from "@repo/utils/constants";
import TextWithBreaks from "../common/TextWithBreaks";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import Button from "../common/Button";

const Wrapper = styled.div`
    width: 100%;
    min-height: 100svh;
    position: relative;
    display: flex;
    gap: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    flex-direction: column;

    .active{
        color: crimson;
    }
    .hidden{
        opacity: 0.2;
    }
    
    .button{
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(150, BREAKPOINTS.DESKTOP)};
    }
    
    .text-with-breaks{
        letter-spacing: -0.04em;
        font-size: ${getCssSizeValue(26.5, BREAKPOINTS.MOBILE)};
        line-height: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            font-size: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
            line-height: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getCssSizeValue(116, BREAKPOINTS.DESKTOP)};
            line-height: ${getCssSizeValue(136, BREAKPOINTS.DESKTOP)};
        }
    }
`


function checkOccurrences(str, array) {
    array.forEach(item => {
        const groupId = item?.groupId;
        const word = item.word;
        const breakId = item?.break?.id || ''

        const regex = new RegExp(`(^|[^\\wąćęłńóśźż])(${word})([^\\wąćęłńóśźż]|$)`, 'giu');
        let foundMatch = false
        str = str.replace(regex, (match, prev, wordMatch, after) => {
            if (prev === '>' || after === '<' || foundMatch) return match; // If inside HTML tag or already found, avoid
            foundMatch = true
            let className = item.active ? TITLE_BREAK_ACTIVE_PREFIX : TITLE_BREAK_HIDDEN_PREFIX
            return `${prev}<em class="${className} group-${groupId} data-break-${breakId}">${wordMatch}</em>${after}`;
            // return `${prev}<em>${wordMatch}</em>${after}`;
            // return `${prev}<em>${wordMatch}</em>${after}`;
        });
    });
    return str;
}

const Story = ({ data, items }) => {
    const [words, setWords] = useState([])
    const paddings = getPaddingsClassFromData(data)
    const story = useMemo(() => data?.story || '', [data])
    const resetText = data?.resetButton?.text || ''

    const getWords = useCallback(() => {
        const storageItems = getStoryItems()
        const updatedWords = items.map((item) => {
            const onlyBreaksTexts = getOnlyBreaksTexts(formatHTML(item?.text?.text || ''));
            if (onlyBreaksTexts.length <= 0) return [];
            const storageItem = storageItems.find((storageItem) => storageItem?.id === item?.id);
            return onlyBreaksTexts.map(({text}, textIndex) => {
                const active = storageItem?.indexes?.includes(textIndex) || false;
                const currBreak = item?.text?.breaks?.[textIndex] || {}
                const groupId = item?.id || ''
                return {
                    groupId,
                    word: removePrefixes(text),
                    active,
                    break: currBreak
                };
            });
        }).flat().filter((item) => item !== null)

        setWords(updatedWords)
    }, [items])


    const resetStory = useCallback(() => {
        setStoryItems([]) //reset local storage
        getWords()
    },[getWords])


    const redactorWords = useMemo(() => {
        // console.log(words);
        // console.log(words.map((item) => item.word));
        return checkOccurrences(story, words)
    }, [story, words])

    useEffect(() => {
        getWords()
    }, [getWords]);

    const breaks = useMemo(() => {
        //get breaks texts in the order they appear in the story
        const onlyBreakTexts = getOnlyBreaksTexts(formatHTML(redactorWords))
        return items
            .map((item) => item.text.breaks) //get all breaks
            .flat() //remove nesting
            .filter((item) => onlyBreakTexts.find((el) => el.breakId === item.id)) //filter unused

        //Sorting finally not used
        // Create a map for the ordering
        // const orderMap = new Map(onlyBreakTexts.map((item, index) => [item.breakId, index]));  //assign index to the id of the break
        // return onlyBreaks.sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id)) //sort breaks in the order of corresponding texts in the story
    },[items, redactorWords])

    // console.log(redactorWords);

    return (
        // <Wrapper className={`${paddings} `}>
        <Wrapper className={`${paddings} px-main`}>
            {/*<Typography data={{ text: redactorWords }} animation={false}/>*/}
            <TextWithBreaks
                key={JSON.stringify(words)}
                variant="static"
                data={{
                    text: redactorWords,
                    breaks
                }}
            />
            <Button variant='tertiary' onClick={resetStory}>
                {resetText}
            </Button>
        </Wrapper>
    );
};

export default Story;
