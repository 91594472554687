import {
    getAspectRatioFromImage,
    getColorScheme,
    getCssSizeValue,
    getFontSize,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import styled from "styled-components";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import {Image, Reveal, useIsMobile} from "../../../index";
import {useState} from "react";

const StyledWrapper = styled.div`
    position: relative;
    color: var(--secondary-color);
    display: grid;
    grid-template-columns: 1fr;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        padding-left: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
        padding-right: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
        grid-template-columns: repeat(5, 1fr);
        row-gap: ${getCssSizeValue(34, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-left: ${getCssSizeValue(330)};
        padding-right: ${getCssSizeValue(330)};
        grid-template-columns: repeat(6, 1fr);
        row-gap: ${getCssSizeValue(50)};
    }
`

const StyledTileWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${getCssSizeValue(31, BREAKPOINTS.MOBILE)};
    border-top: 1px solid ${props => props.theme.colors.white};
    padding: ${getCssSizeValue(17, BREAKPOINTS.MOBILE)} 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        border: unset;
        width: auto;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: ${getCssSizeValue(17, BREAKPOINTS.TABLET)};
        padding: unset;
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(23)};
    }
    
    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props => props.theme.colors.white};
        width: ${getCssSizeValue(48, BREAKPOINTS.MOBILE)};
        height: ${getCssSizeValue(48, BREAKPOINTS.MOBILE)};
        
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            margin-top: unset;
            width: ${getCssSizeValue(122, BREAKPOINTS.TABLET)};
            height: ${getCssSizeValue(122, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            width: ${getCssSizeValue(172)};
            height: ${getCssSizeValue(172)};
        } 
    }
    
    .text {
        text-transform: uppercase;
        letter-spacing: -0.56px;
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(12, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(16, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(14)};
        }
    }
`

const StyledImage = styled(Image)`
    aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '16/9'};
    width: 80%;
    height: auto;
    transition: scale var(--transition-duration) var(--ease);
    &.hovered {
        scale: 1.1;
    }
`

function formatTileText(text) {
    return `[${text}]`
}

function Tile({ item }) {
    const text = formatTileText(item?.name) || ''
    const imgUrl = item?.icon?.url || ''
    const imgAlt = item?.icon?.alt || ''
    const aspectRatio = getAspectRatioFromImage(item?.icon || {})

    const [isHovered, setIsHovered] = useState(false)

    return (
        <StyledTileWrapper>
            <Reveal onInView>
                <div className='icon-wrapper'
                     onMouseEnter={() => setIsHovered(true)}
                     onMouseLeave={() => setIsHovered(false)}
                >
                    <StyledImage
                        src={imgUrl}
                        alt={imgAlt}
                        sizes={'100vw'}
                        $aspectRatio={aspectRatio}
                        className={`${isHovered ? 'hovered' : ''}`}
                    />
                </div>
                <p className='text'>
                    {text}
                </p>
            </Reveal>
        </StyledTileWrapper>
    )
}

export default function TechnologiesTiles({ data }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)
    const technologies = data?.technologies || []

    const isMobile = useIsMobile()

    return (
        <StyledWrapper
            id={id}
            className={`${paddings} ${isMobile ? 'px-main' : ''}`}
            data-color-scheme={colorScheme}
        >
            {technologies.map(item => <Tile key={item?.id || ''} item={item} />)}
        </StyledWrapper>
    )
}