import React from 'react';

function SectionHeaderWave() {
	return (
		<svg width="198" height="22" viewBox="0 0 198 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				id="header-wave-hover"
				d="M4.5 4.37793C9.27129 4.37793 9.27129 18.6918 14.0347 18.6918C18.798 18.6918 18.806
				4.37793 23.5772 4.37793C28.3485 4.37793 28.3485 18.6918 33.1119 18.6918C37.8753 18.6918
				37.8832 4.37793 42.6545 4.37793C47.4258 4.37793 47.4258 18.6918 52.1971 18.6918C56.9684
				18.6918 56.9684 4.37793 61.7397 4.37793C66.511 4.37793 66.511 18.6918 71.2823 18.6918C76.0535
				18.6918 76.0535 4.37793 80.8248 4.37793C85.5961 4.37793 85.5961 18.6918 90.3674 18.6918C95.1387
				18.6918 95.1387 4.37793 99.91 4.37793C104.681 4.37793 104.681 18.6918 109.453 18.6918C114.224
				18.6918 114.224 4.37793 118.995 4.37793C123.766 4.37793 123.766 18.6918 128.538 18.6918C133.309
				18.6918 133.309 4.37793 138.08 4.37793C142.852 4.37793 142.852 18.6918 147.623 18.6918C152.394
				18.6918 152.394 4.37793 157.166 4.37793C161.937 4.37793 161.937 18.6918 166.7 18.6918C171.464
				18.6918 171.471 4.37793 176.243 4.37793C181.014 4.37793 181.014 18.6918 185.785 18.6918C190.557
				18.6918 190.557 4.37793 195.328 4.37793"
				stroke="#04232F"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default SectionHeaderWave;
