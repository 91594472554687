import styled from "styled-components";
import { useGSAP } from "@gsap/react";
import {useEffect, useMemo, useRef, useState} from "react";
import { ScrollTrigger, gsap } from '@repo/utils/gsap'
import Image from "next/image";
import useIsDesktop from "../../hooks/useIsDesktop";
import useIsMobile from "../../hooks/useIsMobile";
import {BREAKPOINTS, LOGO_COLORS, theme} from "../../../styles/themeSettings";
import useTopBarHeight from "../../hooks/useTopBarHeight";
import useIsTablet from "../../hooks/useIsTablet";
import { ChipsList } from "./ChipsList";
import Form from "../common/Form/Form";
import {
    getCssSizeValue,
    getFontSize,
    getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingClass
} from "@repo/utils";
import {CURSOR_TYPES} from "@repo/utils/constants";
import HorizontalHeader from "../common/HorizontalHeader";
import {Button} from "../../../index";
import ReCAPTCHA from 'react-google-recaptcha';
import {useRouter} from "next/router";
import Modal from "../Modal/Modal";
import { useForm } from '@formspree/react';
import ContactModalRive from "../Rive/ContactModalRive";

const StyledWrapper = styled.div`
    width: 100%;
    position: relative;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    transition: background-color var(--color-transition-duration) var(--ease), color var(--color-transition-duration) var(--ease);
`

const HorizontalHeaderWrapper = styled.div`
    background-color: var(--primary-color);
    transition: background-color var(--color-transition-duration) var(--ease);
    position: relative;
    z-index: 5;
    .header{
        color: var(--secondary-color) !important;
        transition: color var(--color-transition-duration) var(--ease);
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: ${getCssSizeValue(160)};;
    }    
`

const StyledTopBar = styled.div`
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        display: flex;
        align-items: flex-start;
        gap: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: ${getCssSizeValue(300)};
        gap: ${getCssSizeValue(50)};
    }
`

const StyledTopBarHeaderWrapper = styled.div`
    flex: 1;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        max-width: 45%;
    }    
`

const StyledTopBarSvgWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex: 1;
    justify-content: center;
    margin-bottom: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        justify-content: flex-start;
        margin-bottom: unset;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        transform: unset;
    }
`

const StyledBlock = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    background: var(--primary-color);
    transition: background var(--color-transition-duration) var(--ease);
    height: ${({$height}) => `${$height}px`};
    width: 100%;
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        height: 15svh;
    }
`

const StyledContent = styled.div`
    position: relative;
    background: ${theme.colors.twilightBlue};
    display: flex;
    height: 100%;
    gap: 40px;
`

const StyledColumn = styled.div`
`

const StyledHeaderText = styled.div`
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(69, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(28, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(48)};
        line-height: ${getFontSize(56)};
    }
`

const StyledStepContainer = styled.div`
    width: 100%;
    margin-bottom: 100px;
`

const StyledStepHeader = styled.div`
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    background-color: var(--primary-color);
    transition: background-color var(--color-transition-duration) var(--ease);
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(28, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
        font-weight: 400;
        padding-bottom: ${getCssSizeValue(10, BREAKPOINTS.TABLET)};
        margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
        &.step-one-header {
            margin-bottom: 200px;
        }
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-weight: 300;
        padding-bottom: 16px;
        font-size: ${getFontSize(48)};
        line-height: ${getFontSize(56)};
        margin-bottom:  ${getCssSizeValue(130)};
        &.step-one-header {
            margin-bottom: ${getCssSizeValue(300)};
        }
    }
`

const StyledStepContent = styled.div`
    margin-top: 30px;
    position: relative;
    z-index: 2;
    width: 100%;
    &.flex {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin-top: unset;
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        &.flex {
            gap: 15px;
        }
        &.chips-list {
            width: 100%;
        }
    }
`

const StyledSubmitButtonRecaptchaAndTermsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 4;
    background-color: var(--primary-color);
    transition: background-color var(--color-transition-duration) var(--ease);
    margin-top: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        margin-top: ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-top: ${getCssSizeValue(150, BREAKPOINTS.DESKTOP)};
        gap: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};;
    }
`

const StyledTermsWrapper = styled.div`
    color: ${props => props.theme.colors.darkDarkGray};
    font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
    
	span.show-more {
		position: relative;
		cursor: pointer;
        text-decoration: underline;
        font-weight: 800;
	}
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        font-size: ${getFontSize(12, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(21, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(12, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(21, BREAKPOINTS.DESKTOP)};
    }
`

const StyledExtendedProcessingInfo = styled.div`
    margin-top: 30px;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in-out;
    
    &.visible {
        height: calc(${getCssSizeValue(400, BREAKPOINTS.MOBILE)});

        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            height: calc(${getCssSizeValue(250, BREAKPOINTS.TABLET)});
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            height: calc(${getCssSizeValue(150, BREAKPOINTS.DESKTOP)});
        }
    }
`

const StyledRecaptchaWrapper = styled.div`
	height: 78px;
    margin-bottom: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		height: 78px;
        margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
	}
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-bottom: unset;
		height: 78px;
	}
`;

const initialFormData = {
    type: [],
    name: '',
    email: '',
    message: '',
    budget: ''
}

const ContactForm = ({ data }) => {
    const isDesktop = useIsDesktop()
    const isTablet = useIsTablet()
    const isMobile = useIsMobile()
    const paddingTop = data?.paddings?.top || '';
    const paddingBottom = data?.paddings?.bottom || '';
    const headerText = data?.headerText || '';
    const carouselText = data?.carouselText || '';
    const topBarImageUrl = data?.topBarImage?.url || '';
    const topBarImageAlt = data?.topBarImage?.alt || '';
    const buttonText = data?.buttonText || '';
    const stepOneHeader = data?.stepOneHeader || '';
    const stepOneOptions = data?.stepOneOptions || [];
    const stepTwoHeader = data?.stepTwoHeader || '';
    const stepTwoOptions = data?.stepTwoOptions || [];
    const stepThreeHeader = data?.stepThreeHeader || '';
    const stepThreeOptions = data?.stepThreeOptions || [];
    const modalHeader = data?.modalHeader || ''
    const modalText = data?.modalText || ''
    const modalButtonText = data?.modalButtonText || ''

    const termsText = data?.termsText || ''
    const termsShowMoreText = data?.termsShowMoreText || ''
    const termsRestOfText = data?.termsRestOfText || ''

    const [isFullInfoVisible , setIsFullInfoVisible] = useState(false)
    const [isCaptchaValidated, setIsCaptchaValidated] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { locale } = useRouter();
    const wrapperRef = useRef(null);

    const topBarHeight = useTopBarHeight()
    const id = getIdWithoutSpecialSymbols(data?.id);

    const [formData, setFormData] = useState(initialFormData);

    const [state, handleSubmit, reset] = useForm(process.env.NEXT_FORMSPREE_KEY_CONTACT_FORM, {
        data: {
            type: formData.type.join(' '),
            budget: formData.budget
        }
    })

    useEffect(() => {
        setIsModalVisible(state.succeeded)
        if (state.succeeded) {
            setFormData(initialFormData)
        }
    }, [state]);

    useGSAP(() => {
        if (!isMobile) {
            gsap.to('.contact-form-header', {
                scrollTrigger: {
                    trigger: '.contact-form-header',
                    start: `top ${isDesktop ? 'top+=25%' : isMobile ? '' : 'top+=16%%'}`,
                    end: 'top top+=100',
                    pin: true,
                    scrub: 0.2,
                },
                autoAlpha: 0
            })
        }

        ScrollTrigger.create({
            trigger: '.contact-form-block',
            start: "top top",
            endTrigger: '.form-submit-button-wrapper',
            end: isDesktop ? 'top top+=200' : 'top top+=100',
            pin: true,
            pinSpacing: false,
        })

        const headersToPin = ['.step-one-header', '.step-two-header', '.step-three-header']

        headersToPin.forEach((header, index) => {
            ScrollTrigger.create({
                trigger: header,
                start: `top ${isDesktop || isTablet ? 'center-=35%' : `top+=${topBarHeight - 3}`}`,
                endTrigger: '.form-submit-button-wrapper',
                end: isDesktop ? 'top top+=100' : 'top-=15% top',
                pin: true,
                pinSpacing: false
            });
        });
    }, { scope: wrapperRef, dependencies: [isDesktop, isTablet, isMobile, topBarHeight] })

    const onTypeChipClick = (value) => {
        setFormData((prevData) => {
            const newType = prevData.type.includes(value)
                ? prevData.type.filter((item) => item !== value)
                : [...prevData.type, value];

            return { ...prevData, type: newType };
        })
    }

    return (
        <StyledWrapper
            className={`${getPaddingClass(paddingTop, 'top')}`}
            data-logo-color={LOGO_COLORS.LIGHT}
            data-cursor={CURSOR_TYPES.HTML}
        >
            <Modal
                isVisible={isModalVisible}
                handleCloseButton={() => reset()}
                data={{
                    header: modalHeader,
                    text: modalText,
                    buttonText: modalButtonText
                }}
                Rive={ContactModalRive}
            />
            <div
                id={id}
                ref={wrapperRef}
                className='px-main'
            >
                <StyledTopBar>
                    <StyledTopBarHeaderWrapper>
                        <StyledHeaderText
                            className='contact-form-header'
                        >
                            {headerText}
                        </StyledHeaderText>
                    </StyledTopBarHeaderWrapper>
                    <StyledTopBarSvgWrapper>
                        <Image
                            src={topBarImageUrl}
                            alt={topBarImageAlt}
                            width={isDesktop ? 170 : isTablet ? 150 : 90}
                            height={isDesktop ? 170 : isTablet ? 150 : 90}
                        />
                    </StyledTopBarSvgWrapper>
                </StyledTopBar>

                <StyledContent>
                    <StyledColumn>
                        <StyledBlock
                            className='contact-form-block'
                            $height={topBarHeight}
                        />
                        <StyledStepContainer>
                            <StyledStepHeader
                                className='step-one-header'
                            >
                                {stepOneHeader}
                            </StyledStepHeader>
                            <StyledStepContent
                                className='flex chips-list'
                            >
                                <ChipsList
                                    data={stepOneOptions}
                                    onClick={(value) => onTypeChipClick(value)}
                                    selectedValue={formData.type}
                                />
                            </StyledStepContent>
                        </StyledStepContainer>

                        <StyledStepContainer>
                            <StyledStepHeader
                                className='step-two-header'
                            >
                                {stepTwoHeader}
                            </StyledStepHeader>
                            <StyledStepContent>
                                <Form
                                    id='contact-form'
                                    data={stepTwoOptions}
                                    formData={formData}
                                    setFormData={setFormData}
                                    handleSubmit={handleSubmit}
                                    submissionState={state}
                                />
                            </StyledStepContent>
                        </StyledStepContainer>

                        <StyledStepContainer>
                            <StyledStepHeader
                                className='step-three-header'
                            >
                                {stepThreeHeader}
                            </StyledStepHeader>
                            <StyledStepContent
                                className='flex chips-list'
                            >
                                <ChipsList
                                    data={stepThreeOptions}
                                    onClick={(value) => setFormData((prevData) => ({ ...prevData, budget: value }))}
                                    selectedValue={formData.budget}
                                />
                            </StyledStepContent>
                        </StyledStepContainer>

                        <StyledSubmitButtonRecaptchaAndTermsWrapper
                            className='form-submit-button-wrapper'
                        >
                            <StyledTermsWrapper>
                                <div dangerouslySetInnerHTML={getHtmlFromRedactorField(termsText)} />
                                <span
                                    onClick={() => setIsFullInfoVisible(!isFullInfoVisible)}
                                    className="show-more"
                                >
                                    {termsShowMoreText}
                                </span>
                                <StyledExtendedProcessingInfo className={isFullInfoVisible ? 'visible' : ''}>
                                    <div dangerouslySetInnerHTML={getHtmlFromRedactorField(termsRestOfText)} />
                                </StyledExtendedProcessingInfo>
                            </StyledTermsWrapper>
                            <StyledRecaptchaWrapper>
                                <ReCAPTCHA
                                    sitekey={process.env.NEXT_RECAPTCHA_SITEKEY}
                                    theme="dark"
                                    hl={locale}
                                    onChange={(val) => setIsCaptchaValidated(val)}
                                />
                            </StyledRecaptchaWrapper>
                            <Button disabled={!isCaptchaValidated} type='submit' variant='primary' form='contact-form' style={{ textTransform: 'lowercase' }}>
                                {buttonText}
                            </Button>
                        </StyledSubmitButtonRecaptchaAndTermsWrapper>
                    </StyledColumn>
                </StyledContent>
            </div>
            <HorizontalHeaderWrapper
                className={`${getPaddingClass(paddingBottom, 'bottom')}`}
            >
                <HorizontalHeader
                    text={carouselText}
                    id={data?.id} direction='left'
                />
            </HorizontalHeaderWrapper>
        </StyledWrapper>
    )
}

export default ContactForm;
