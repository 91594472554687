import React, {forwardRef, memo, useImperativeHandle, useRef, useState} from 'react';
import {ScrollScene, UseCanvas, useScrollRig, useTracker} from '@14islands/r3f-scroll-rig';
import {useTexture, useVideoTexture} from '@react-three/drei';
import {gsap, ScrollTrigger} from '@repo/utils/gsap';
import useVisible3D from '../../hooks/useVisible3D';
import fragmentShader from "../../../shaders/FloatingVideo/fragment.glsl";
import vertexShader from "../../../shaders/FloatingVideo/vertex.glsl";
import {useFrame} from "@react-three/fiber";
import * as THREE from 'three';
import styled from 'styled-components';
import useIsomorphicLayoutEffect from "../../hooks/useIsomorphicLayoutEffect";
import MockupVideo from "../../assets/video/band-white-horizontal.mp4";
import useMediaQuery from "../../hooks/useMediaQuery";
import HTMLImage from '../common/Image';
import {useIsDesktop} from "../../../index";

const DEFAULT_SIZES = '100vw';

const StyledHtmlImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        opacity: 0;
    }
`

const StyledHtmlVideoWrapper = styled.div`
    width: 100%;
    position: relative;
    opacity: 1;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        opacity: 0;
    }
    video {
        object-fit: cover;
        width: 100%;
    }
`

const StyledVideoTargetWrapper = styled.div`
	position: relative;
	z-index: 5;
	width: 0;
    height: 0;
	padding: 0;
	margin: 0;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        width: calc(100vw - var(--paddingX)); // TODO return to this value
        aspect-ratio: 16/9;
        height: auto;
    }
`
gsap.registerPlugin(ScrollTrigger)


export const FloatingVideo3D = memo(function FloatingVideo3D({ src, video, isSingleAssetImage,  ...props }) {
    const { scale } = props;
    const MeshRef = useRef();
    const MaterialRef = useRef();
    const texture = src ? useTexture(src) : null; // image texture
    const videoTexture = useVideoTexture(video);
    const isDesktop =  useIsDesktop()
    const [uniforms] = useState(() => ({
        uTime: {
            value: 0
        },
        uCorner: {
            value: 0,
        },
        uTexture: {
            value: texture
        },
        uVideoTexture: {
            value: videoTexture
        },
        uIsSingleAssetImage: {
            value: isSingleAssetImage
        },
        uPower: {
            value: 0,
        },
        uPowerY: {
            value: 0,
        },
        uFrequency:
            {
                value: new THREE.Vector2(7, 2.5)
            },
        uFollowTarget: {
            value: false,
        },
        uProgress: {
            value: 0
        },
        uMeshScale: {
            value: new THREE.Vector2(1, 1),
        } ,
        uMeshPosition: {
            value: new THREE.Vector2(0, 0),
        } ,
        uViewSize: {
            value: new THREE.Vector2(1, 1),
        }
    }));
    const trackerTarget = useTracker(props.target)
    const htmlItemTrack = useTracker(props.track)
    const [isSTEnded, setIsSTEnded] = useState(false)
    useIsomorphicLayoutEffect(() => {
        if(isDesktop) {
            let context = gsap.context(() => {
                const threePosition = new THREE.Vector3(trackerTarget.position.x, trackerTarget.position.y, trackerTarget.position.z)
                const htmlItemTrackPosition = new THREE.Vector3(htmlItemTrack.position.x, htmlItemTrack.position.y, htmlItemTrack.position.z)
                const diff = new THREE.Vector3().subVectors(threePosition, htmlItemTrackPosition)
                diff.x = diff.x / (scale?.x || 1)
                diff.y = diff.y / (scale?.y || 1)
                diff.z = diff.z / (scale?.z || 1)
                const tl = gsap.timeline();
                tl.to(MeshRef.current.position, {
                    x: diff.x,
                    y: diff.y,
                    z: diff.z,
                    ease: "sine.inOut",
                    scrollTrigger: {
                        trigger: props.target.current,
                        start: 'top bottom',
                        end: 'bottom bottom',
                        // markers: true,
                        scrub: true,
                        onEnterBack: () => {
                            setIsSTEnded(false)
                        },
                    }
                }, 0)
                    .to(MeshRef.current.scale, {
                        x: trackerTarget.scale.x / (scale?.x || 1),
                        y: trackerTarget.scale.y / (scale?.y || 1),
                        z: trackerTarget.scale.z / (scale?.z || 1),
                        ease: "sine.inOut",
                        scrollTrigger: {
                            trigger: props.target.current,
                            start: 'top bottom',
                            end: 'bottom bottom',
                            // markers: true,
                            scrub: true,
                            onUpdate: ({progress}) => {
                                uniforms.uPower.value = progress;
                            },
                            onLeave: () => {
                                setIsSTEnded(true)
                                const flipSt = ScrollTrigger.getById('flip-animation');
                                flipSt?.refresh();
                            }
                        }
                    }, 0)
            })
            return () => {
                context.revert()
            }
        }
    }, [trackerTarget, htmlItemTrack, scale, isDesktop])

    useFrame((state) => {
        const { elapsedTime} = state.clock;
        // uniforms.uTime.value = elapsedTime * Math.sin(elapsedTime * 0.05);
        // MeshRef.current.position.y -= 0.01
        uniforms.uTime.value = elapsedTime;
        uniforms.uCorner.value = 10
        // uniforms.uPower.value = ProgressRef.current;
        const threePosition = new THREE.Vector3(trackerTarget.position.x, trackerTarget.position.y, trackerTarget.position.z)
        const htmlItemTrackPosition = new THREE.Vector3(htmlItemTrack.position.x, htmlItemTrack.position.y, htmlItemTrack.position.z)
        const diff = new THREE.Vector3().subVectors(threePosition, htmlItemTrackPosition)
        diff.y = diff.y/(scale?.y || 1)
        diff.x = diff.x/(scale?.x || 1)
        if(isSTEnded) {
            MeshRef.current.position.x = diff.x;
            MeshRef.current.position.y = diff.y;
        }
    })

    return (
        <mesh ref={MeshRef}>
            {/*<meshBasicMaterial ref={MaterialRef} color="red" toneMapped={false}/>*/}
            <planeGeometry args={[1, 1, 80, 80]}/>
            <shaderMaterial
                ref={MaterialRef}
                // wireframe
                fragmentShader={fragmentShader}
                vertexShader={vertexShader}
                uniforms={uniforms}
            />
        </mesh>

    );
})

const TrackImage = memo(function TrackImage({el, src, video, target, isDesktop, isSingleAssetImage }){
    return (
        <ScrollScene track={el} target={target} as="group" hideOffscreen={false}>
            {(props) =>
                <group name={`${src}-video`} {...props}>
                    <FloatingVideo3D src={src} video={video} isSingleAssetImage={isSingleAssetImage} isDesktop={isDesktop} {...props} />
                </group>
            }
        </ScrollScene>
    )
})


// eslint-disable-next-line no-redeclare
const FloatingVideo = forwardRef(function FloatingVideo({ id, src='', isSingleAssetImage = false, video=MockupVideo, alt = '', className = '', sizes=DEFAULT_SIZES, visible = true, children, ...props }, fref) {
    const HtmlVideoRef = useRef();
    const VideoTargetRef = useRef();
    const visible3D = useVisible3D(visible);
    const isDesktop = useIsDesktop()
    useImperativeHandle(fref, () => HtmlVideoRef.current, []);

    return (
        <>
            {isSingleAssetImage &&  <StyledHtmlImageWrapper>
                <HTMLImage
                    ref={HtmlVideoRef}
                    id={id}
                    src={src}
                    sizes={sizes}
                    visible={visible}
                    alt={alt}
                    className={`${className} transparent-3d`}
                    {...props}
                >
                    {children}
                </HTMLImage>
            </StyledHtmlImageWrapper>}
            {!isSingleAssetImage && <StyledHtmlVideoWrapper ref={HtmlVideoRef}>
                <video
                    preload="auto"
                    muted
                    loop
                    autoPlay={!isDesktop}
                    playsInline
                >
                    <source src={video} type="video/mp4" />
                </video>
            </StyledHtmlVideoWrapper>}
            <StyledVideoTargetWrapper ref={VideoTargetRef} />
            {visible3D && (
                <UseCanvas src={src} el={HtmlVideoRef} target={VideoTargetRef} >
                    <TrackImage src={src} video={video} isDesktop={isDesktop} isSingleAssetImage={isSingleAssetImage} />
                </UseCanvas>
            )}
        </>
    );
})
export default FloatingVideo;
