import React from 'react';

function ArrowTopDark() {
	return (
		<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.3536 6.64645C14.1583 6.45118 13.8417 6.45118 13.6464 6.64645L10.4645 9.82843C10.2692 10.0237 10.2692 10.3403
			10.4645 10.5355C10.6597 10.7308 10.9763 10.7308 11.1716 10.5355L14 7.70711L16.8284 10.5355C17.0237 10.7308 17.3403 10.7308
			17.5355 10.5355C17.7308 10.3403 17.7308 10.0237 17.5355 9.82843L14.3536 6.64645ZM14.5 22L14.5 7L13.5 7L13.5 22L14.5 22Z"
				fill="#01232F"
			/>
		</svg>
	);
}

export default ArrowTopDark;
