import React, {useRef} from 'react';
import {useIsDesktop, useWindowSize} from "../../../../index";
import { ScrollTrigger, useGSAP} from "@repo/utils/gsap";
import styled from 'styled-components';

const Placeholder = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 300svh;
    &.under-content {
        z-index: -1;
        display: none;
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            display: flex;
        }
    }
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        height: 100svh;
    }
`

const Transition = ({ id = 'foo', start='center', onUpdate, st=true, className= '' }) => {
    const PlaceholderRef = useRef()
    const windowSize = useWindowSize()
    const isDesktop = useIsDesktop()

    useGSAP(() => {
        if(st){
            ScrollTrigger.create({
                id: `${id}-transition`,
                trigger: PlaceholderRef.current,
                start: `top ${start}`,
                end: '100%',
                // markers: true,
                onUpdate: onUpdate
            })
        }
    },{
        dependencies: [id, st, start, windowSize.width, isDesktop],
        revertOnUpdate: true,
        scope: PlaceholderRef.current
    })


    return (
        <Placeholder className={className} ref={PlaceholderRef} />
    );
};

export default Transition;
