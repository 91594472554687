import React, { forwardRef } from 'react';
import { CMS_ELEMENT_TYPENAME, CONTENT_TYPES } from "@repo/utils/constants";
import styled from "styled-components";
import { getCssSizeValue, handleRef } from "@repo/utils";
import { BREAKPOINTS } from "../../../styles/themeSettings";
import { useProjectsContext } from "./index";
import Grid from "./Grid";
import Project from "./Project";
import HorizontalHeader from "../common/HorizontalHeader";

// import { Loading as LoadingHeader } from '../common/HorizontalHeader'
// import { Loading as LoadingProject } from './Project'
// import dynamic from "next/dynamic";
// const Project = dynamic(() => import('./Project',  { ssr: false, loading: () => <LoadingProject/> }));
// const HorizontalHeader = dynamic(() => import('../common/HorizontalHeader', { ssr: false, loading: () => <LoadingHeader/> }));
// const Grid = dynamic(() => import('./Grid',  { ssr: false }));

const Wrapper = styled.div`
    --gapx: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    --gapy: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
    display: flex;
    flex-direction: column;
    z-index: 0;
    padding-top: ${getCssSizeValue(80, BREAKPOINTS.MOBILE)};
    width: 100%;
    &.tiles{
        row-gap: var(--gapx);
    }

    &.list{
       .horizontal-header{
           margin: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)} 0;
       }
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        padding-top: ${getCssSizeValue(125, BREAKPOINTS.TABLET)};
        --gapx: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};
        --gapy: ${getCssSizeValue(70, BREAKPOINTS.MOBILE)};

        &.list{
            .horizontal-header{
                margin: ${getCssSizeValue(100, BREAKPOINTS.TABLET)} 0;
            }
        }
        
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding-top: 0;
        --gapx: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
        --gapy: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};

        > .project-item.tile-item{
            .project-image{
                aspect-ratio: 1699/954 !important;
            }
        }
        &.list{
            padding-top: ${getCssSizeValue(146, BREAKPOINTS.DESKTOP)};
            
            .horizontal-header{
                margin: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)} 0;
            }
        }
        &.tiles{
            padding-bottom: ${getCssSizeValue(222, BREAKPOINTS.DESKTOP)};
        }
    }
`

const Header = styled(HorizontalHeader)`
    order: ${({ $order }) => $order || 0};
`


const Content = forwardRef(function Content({ data = [], webGL=false, type='' }, ref){
    const isList = useProjectsContext((state) => state.isList)
    return (
        <Wrapper ref={(wrapperRef) => handleRef(ref, wrapperRef)} className={!isList ? 'px-main tiles' : 'list'}>
            {data?.map((el, index) => {
                if (!el) return null;
                const elementType = el[CMS_ELEMENT_TYPENAME];
                if(elementType.includes('SingleProject')){
                    return <Project index={index} key={el?.id || index} data={el} webGL={webGL} type={type} />;
                }
                switch (elementType) {
                    case CONTENT_TYPES.HORIZONTAL_HEADER:
                        return <Header $order={isList ? data.length : index} key={el?.id || index} id={el?.id} text={el?.text || ''} full={!isList} />
                    case CONTENT_TYPES.PROJECTS_GRID:
                        return <Grid index={index} key={el?.id || index} data={el} webGL={webGL} type={type} />;
                    default:
                        return null;
                }
            })}
        </Wrapper>
    )
})

export default Content;
