import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';

import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import {getFontSize} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";


dayjs.extend(localizedFormat);
dayjs.extend(localeData);

const StyledDateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    p.date {
        position: relative;
        text-transform: capitalize;
        margin-left: 24px;
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(20, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.desktop || 1024}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 3px;
            height: 3px;
            background-color: var(--secondary-color);
            border-radius: 50%;
            left: -13px;
        }
    }
    &.mobile-card {
        p.date {
            margin-left: 0;
        }
        p:before {
            display: none;
        }
        @media (min-width: ${({theme}) => theme?.breakpoints?.tablet || 768}px) {
            p.date {
                margin-left: 24px;
            }
            p:before {
                display: block;
            }
        }
    }
`

const DateInfo = ({date, locale, className}) => {
    const dayJsDate = dayjs(date);
    const formattedDate = dayJsDate.locale(locale).format("MMM DD, YYYY")
    return (
        <StyledDateWrapper className={className}>
            <p className="date">{formattedDate}</p>
        </StyledDateWrapper>
    );
};

export default DateInfo;
