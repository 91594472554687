import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import Slide from "./Slide";
import Counter from "./Counter";
import {ScrollTrigger, useGSAP, gsap} from "@repo/utils/gsap";
import {clamp, getCssSizeValue, getRangeProgress} from "@repo/utils";
import {usePleoContext} from "../index";
import Transition from "../../common/StaticLens/Transition";
import {useIsDesktop, useIsMobile} from "../../../../index";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import CommonIcon from "../../common/Icon/Icon";
import {ICONS} from "@repo/utils/constants";
import Reveal, {REVEAL_CLASSNAME} from "../../../animations/Reveal";


const Container = styled.div`
    --local-padding-x: var(--paddingX);
    position: relative;
    display: flex;
    width: 100%;
    height: 100svh;
    z-index: 1;
    align-items: center;
    color: var(--secondary-color);
    justify-content: center;
    //background-color: crimson;
    //opacity: 0.2;
    padding: 0 var(--local-padding-x);
    //max-width: 1920px;

    > div.${REVEAL_CLASSNAME}-parent {
        display: flex;
        position: relative;
        height: auto;
        
        &.slide{
            width: 100%;
            transform: translateY(40vw);
        }
        
        &.icon{
            position: absolute;
            width: ${getCssSizeValue(120, BREAKPOINTS.MOBILE)};
            aspect-ratio: 1;
            top: var(--mobile-lens-offset-top, 30svh);
            left: var(--local-padding-x);
            //top: 0;
            transform: translateY(-25%);
        }

        div.${REVEAL_CLASSNAME}-child {
            display: flex;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        justify-content: end;
        column-gap: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
        > div.${REVEAL_CLASSNAME}-parent{
            &.slide{
                width: auto;
                transform: unset;
                padding-left: 5vw;
            }
           &.icon{
               width: ${getCssSizeValue(168, BREAKPOINTS.TABLET)};
               top: unset;
               transform: translateX(25%);
           }
           
        }
    }

    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --local-padding-x: ${getCssSizeValue(250)};
        > div.${REVEAL_CLASSNAME}-parent{
           &.icon{
               left: calc(var(--local-padding-x) + ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)});
               width: ${getCssSizeValue(300)};
           }
        }
    }

    @media (min-width: 1921px) {
        --local-padding-x: ${getCssSizeValue(310)};
    }
`

const Icon = styled(CommonIcon)`
    --curr: ${({ $curr }) => $curr || 0}; 
    --length: ${({ $length }) => $length || 1}; 
    position: absolute;
    width: 100%;
    aspect-ratio: 1;
    height: auto;
    max-width: unset;
    left: 0;
    //left: var(--local-padding-x);
    border-radius: 50%;
    overflow: hidden;

    svg{
        g{
            transform: translateX(calc(var(--curr) / var(--length) * 100%));
            transition: transform var(--color-transition-duration) var(--ease);
        }
    }
    
`


const Numbers = forwardRef(function Numbers({ data, ...props }, fref){
    const [index, setIndex] = useState(0)
    const currentSlide = useMemo(() => data[index],[data, index])
    const ContainerRef = useRef()
    const pleoContext = usePleoContext()
    const isMobile = useIsMobile()
    const isDesktop = useIsDesktop()
    const [tls] = useState(() => ([
        gsap.timeline({ paused: true }),
        gsap.timeline({ paused: true }),
        gsap.timeline({ paused: true })
    ]))
    const updateContext = useCallback(({ progress }) => {
        pleoContext.numbers.current = progress
    },[])

    useImperativeHandle(fref, () => ContainerRef.current, [])


    useGSAP(() => {
        const multiplier = isDesktop ? 2 : 3

        ScrollTrigger.create({
            id: 'numbers',
            trigger: ContainerRef.current,
            start: 'top top',
            end: `${multiplier * data.length}00%`,
            pin: true,
            pinSpacing: true,
            scrub: true,
            invalidateOnRefresh: true,
            // markers: true,
            // pinSpacer: PinSpacerRef.current,
            onUpdate: ({ progress }) => {
                //manage revealing of the whole slide
                //progress alternates at enter and leave
                const breakpoint = 0.2 //how long should the animation last, percentage of the main progress
                let revealProgress
                if(progress < (1 - breakpoint)){
                    //enter
                    revealProgress = getRangeProgress(progress, 0,breakpoint)
                } else {
                    //leave
                    revealProgress = 1 - getRangeProgress(progress, 1 - breakpoint,1)
                }
                tls.forEach((tl) => tl.progress(revealProgress))

                //sets the index of the current slide, returns an int in the range 0-3
                const i = clamp(Math.floor(progress * data.length), 0, 3)
                setIndex(i)
            }
        })

    },{
        dependencies: [data, isDesktop],
        scope: ContainerRef.current,
        revertOnUpdate: true
    })


    return (
       <>
           <Transition id="numbers" onUpdate={updateContext} />
           <Container id={'pleo-numbers'} ref={ContainerRef} >
               <Reveal timeline={tls[0]} paused className="slide">
                   <Slide data={currentSlide} pinnedContainer={ContainerRef}/>
               </Reveal>
               <Reveal timeline={tls[1]} paused className="icon">
                   <Icon name={ICONS.WAVY_CIRCLE} className="no-color" $length={data.length} $curr={index}/>
               </Reveal>
               {!isMobile && (
                   <Reveal timeline={tls[2]} paused className="counter">
                       <Counter data={data} current={index}/>
                   </Reveal>
               )}
           </Container>
       </>
    )

});

export default Numbers;
