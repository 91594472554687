import {useEffect, useState} from 'react';
import styled from 'styled-components';
import { getCssSizeValue} from "@repo/utils";
import classNames from 'classnames';
import {BREAKPOINTS, COLORS, theme} from "../../../../styles/themeSettings";

const StyledSingleInputWrapper = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        min-height: 54px;
    }
    &:before {
        content: '';
        width: 100%;
        height: 1px;
        bottom: 5px;
        position: absolute;
        background-color: ${({ error }) => (error ? theme.colors.red : 'var(--secondary-color)')};
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            bottom: 1px;
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            bottom: 9px;
        }
    }
    p {
        color: ${({ error }) => (error ? theme.colors.red : 'var(--secondary-color)')};
    }
    textarea,
    textarea::placeholder,
    textarea.message-not-empty {
        resize: none;
        font-size: 16px;
        line-height: 30px;
        min-height: 60px;
        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            font-size: 18px;
            line-height: 32px;
            min-height: 72px;
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            transition: min-height 0.3s ease-out;
            font-size: 18px;
            line-height: 32px;
            min-height: 210px;
        }
    }
    textarea::placeholder {
        color: var(--secondary-color);
        opacity: 0.33;
    }
    textarea:focus,
    textarea.message-not-empty:focus {
        min-height: ${getCssSizeValue(200, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
            min-height: ${getCssSizeValue(200, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            min-height: ${getCssSizeValue(210, BREAKPOINTS.DESKTOP)};
        }
    }
    textarea.message-not-empty {
        min-height: ${getCssSizeValue(200, BREAKPOINTS.MOBILE)};
    }
`;

const StyledInput = styled.input`
    background-color: transparent;
    color: ${({error}) => error ? theme.colors.red : 'var(--secondary-color)'};
    border: none;
    width: 100%;
    line-height: normal;
    position: relative;
    caret-color: var(--secondary-color);
    font-size: 22px;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: 38px;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
        -webkit-background-clip: text;
        -webkit-text-fill-color: var(--secondary-color);
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px transparent;
    }
`;

const StyledSpan = styled.span`
    position: absolute;
    pointer-events: none;
    top: 0;
    font-weight: 300;
    transition: all .1s ease-in-out;
    color: ${({error}) => error ? theme.colors.red :  'var(--secondary-color)'};
    font-size: 22px;

    ${({ value }) =>
        value && value.length && `
            font-size: 14px;
            transform: translateY(-120%);
        `
    };


    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: 38px;
        
        ${({ value }) =>
            value && value.length && `
                font-size: 18px;
            `
        };
    }
`;

const StyledErrorText = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    color: ${({ theme }) => theme.colors.red};
    //margin-top: 5px;
    font-size: 14px;
    font-weight: 600;
    //line-height: 54px;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: 14px;
    }    
`;

const StyledMessagePlaceholder = styled.p`
    color: ${({ error, theme }) => (error ? 'red' : 'var(--secondary-color)')};
`;

const CustomInput = ({
        name,
        className,
        placeholder,
        type,
        value,
        required,
        onChange,
        validate,
        errorMessage,
        isTextarea,
        isTelInput,
        submissionState,
        locale,
    }) => {
    const [isTouched, setIsTouched] = useState(false);
    const showError = required && isTouched && validate && !validate(value);
    const handleBlur = () => {
        setIsTouched(true);
    };
    const finalClassName = classNames({
        textarea: isTextarea,
        // 'message-not-empty': isTextarea && value.length > 0,
    });
    const textareaPlaceholder = {
        pl: 'max 450 znaków',
        en: 'max 450 characters',
    };

    useEffect(() => {
        if (submissionState.succeeded) {
            setIsTouched(false)
        }
    }, [submissionState]);

    return (
        <StyledSingleInputWrapper error={showError}>
            <StyledSpan value={value} error={showError}>
                {placeholder}
            </StyledSpan>
            <StyledInput
                as={isTextarea ? 'textarea' : 'input'}
                className={finalClassName}
                maxLength={isTextarea ? 450 : ''}
                type={type}
                name={name}
                // placeholder={isTextarea ? textareaPlaceholder[locale] : null}
                value={value}
                pattern={isTelInput ? '^[0-9-+s()]*$' : null}
                required={required}
                onChange={onChange}
                error={showError}
                onBlur={handleBlur}
            />
            {showError && <StyledErrorText>{errorMessage}</StyledErrorText>}
        </StyledSingleInputWrapper>
    );
};

export default CustomInput;
