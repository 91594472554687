import React from 'react';
import styled from 'styled-components';

const StyledMargin = styled.span`
	display: inline-block;
	padding-top: 100px;
	width: 100%;
	background-color: transparent;
	&.extra-large {
		padding-top: 100px;
		@media (min-width: 500px) {
			padding-top: 143px;
		}
		@media (min-width: 992px) {
			padding-top: 210px;
		}
		@media (min-width: 1200px) {
			padding-top: 225px;
		}
		@media (min-width: 1550px) {
			padding-top: 255px;
		}
	}
	&.large {
		padding-top: 170px;
		@media (max-width: 1200px) {
			padding-top: 100px;
		}
		@media (max-width: 375px) {
			padding-top: 70px;
		}
	}
	&.medium {
		padding-top: 100px;
		@media (max-width: 1200px) {
			padding-top: 70px;
		}
		@media (max-width: 375px) {
			padding-top: 40px;
		}
	}
	&.small {
		padding-top: 70px;
		@media (max-width: 1200px) {
			padding-top: 40px;
		}
	}
	&.extra-small {
		padding-top: 40px;
	}
`;
const Margin = ({ size = 'medium' }) => <StyledMargin className={size.toLowerCase()} />;

export default Margin;
