import React from 'react';
import {Leva} from "leva";
import {useIsDebug} from "../../index";
import {Stats} from "@react-three/drei";

const Debug = () => {
    const isDebug = useIsDebug();

    return (
        <>
            <Leva hidden={!isDebug} collapsed={false} />
            {isDebug && <Stats/>}
        </>
    );
};
 
export default Debug;
