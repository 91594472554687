import styled from 'styled-components';
import {
	getColorScheme,
	getCssSizeValue,
	getPaddingClass,
	getHtmlFromRedactorField,
	getIdWithoutSpecialSymbols,
	getFontSize
} from '@repo/utils';
import { ScrollTrigger, gsap, useGSAP } from '../../../../../utils/gsap';
import { useRef } from 'react';
import {BREAKPOINTS} from '../../../../styles/themeSettings';
import {useTopBarHeight, Video} from "../../../../index";
import MockupVideo from '../../../assets/video/band-white-horizontal.mp4'

const StyledTechnologiesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
	width: 100%;
	height: auto;
`;

const StyledRow = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 3;
	gap: 40px;
	padding-bottom: ${getCssSizeValue(45, BREAKPOINTS.MOBILE)};
	
	&.project-technologies-table-content {
		position: relative;
		justify-content: flex-end;
		min-height: 100svh;
		z-index: 2;
		background: transparent;
	}
	
	&.first-row {
		padding-top: ${({$paddingTop}) => `${$paddingTop * 1.2}px`};
		background-color: var(--project-color, var(--primary-color));
		transition: background-color var(--color-transition-duration) var(--ease);
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		gap: 60px;
		padding-bottom: unset;
	}
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		flex-direction: row;
		justify-content: space-between;
	}
`

const StyledHeader = styled.div`
	font-weight: 400;
	color: var(--secondary-color);
	--font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
	font-size: var(--font-size);
	letter-spacing: calc(var(--font-size) * -0.04);
	line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};

	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		--font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
	}

	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		--font-size: ${getFontSize(116)};
		line-height: ${getFontSize(136)};
	}
`

const StyledTextRight = styled.div`
	color: var(--secondary-color);
	font-weight: 400;
	font-size: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)};
	max-width: ${getCssSizeValue(270, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		font-size: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
		max-width: ${getCssSizeValue(360, BREAKPOINTS.TABLET)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		text-align: right;
		font-size: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
		max-width: ${getCssSizeValue(270, BREAKPOINTS.DESKTOP)};
	}
`

const StyledTechnologiesText = styled.div`
	position: relative;
	z-index: 3;
	margin: 25svh 0;
	
	font-weight: 700;
	text-align: right;
	text-transform: uppercase;
	color: var(--secondary-color);

	letter-spacing: -1.6px;
	font-size: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
	 line-height: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
		letter-spacing: -3px;
		font-size: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
		 line-height: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
    }
	
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
		letter-spacing: -4.64px;
		font-size: ${getCssSizeValue(116, BREAKPOINTS.DESKTOP)};
		line-height: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
	}
`

const StyledVideoWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
	width: 100vw;
	height: 100svh;
`

const ProjectTechnologiesTable = ({ data }) => {
	const id = getIdWithoutSpecialSymbols(data?.id);
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingClass(data) //TODO: tu chyba powinna być użyta funkcja getPaddingClassFromData
	const header = data?.text || '';
	const textRight = data?.textRight || ''
	const technologiesText = data?.technologiesText || '';
	const videoSrc = data?.video?.url || '';
	const videoAlt = data?.video?.alt || '';
	const topBarHeight = useTopBarHeight()

	const wrapperRef = useRef(null);

	useGSAP(() => {
		const elementsToPin = gsap.utils.toArray(['.first-row', '.video-wrapper'])

		elementsToPin.forEach((el) => {
			ScrollTrigger.create({
				trigger: '.first-row',
				pin: el,
				start: `top top`,
				endTrigger: wrapperRef.current,
				end: 'bottom top',
				pinSpacing: false
			})
		})
	}, { scope: wrapperRef })


	return (
		<StyledTechnologiesWrapper
			id={id}
			ref={wrapperRef}
			data-color-scheme={colorScheme}
			// className={`${paddings}`}
		>
			<StyledRow $paddingTop={topBarHeight} className={`first-row px-main`}>
				<StyledHeader>{header}</StyledHeader>
				<StyledTextRight>{textRight}</StyledTextRight>
			</StyledRow>
			<StyledRow className='project-technologies-table-content'>
				<StyledVideoWrapper className='video-wrapper'>
					<Video src={videoSrc !== '' ? videoSrc : MockupVideo} />
				</StyledVideoWrapper>
				<StyledTechnologiesText className='px-main' dangerouslySetInnerHTML={getHtmlFromRedactorField(technologiesText)}/>
			</StyledRow>
		</StyledTechnologiesWrapper>
	);
};

export default ProjectTechnologiesTable;
