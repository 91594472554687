/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import { useEffect, useState } from 'react';

/**
 * Custom hook that tells you whether a given media query is active.
 *
 * Inspired by https://usehooks.com/useMedia/
 * https://gist.github.com/gragland/ed8cac563f5df71d78f4a1fefa8c5633
 */

export default function useMediaQuery(query) {
	const [matches, setMatches] = useState(false);
	useEffect(
		() => {
			const mediaQuery = window.matchMedia(query);
			setMatches(mediaQuery.matches);
			const handler = (event) => setMatches(event.matches);
			mediaQuery.addEventListener('change', handler);
			return () => mediaQuery.removeEventListener('change', handler);
		},
		[], // Empty array ensures effect is only run on mount and unmount
	);
	return matches;
}
