import styled from "styled-components";
import {
    getColorScheme,
    getCssSizeValue,
    getFontSize, getHtmlFromRedactorField,
    getIdWithoutSpecialSymbols,
    getPaddingsClassFromData
} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";
import useEmblaCarousel from "embla-carousel-react";
import Client from "./Client";
import {Icon, Reveal, useIsDesktop, useIsMobile} from "../../../index";
import {CURSOR_TYPES, ICONS} from "@repo/utils/constants";

const StyledWrapper = styled.section`
    position: relative;
    color: var(--secondary-color);
`

const StyledContainer = styled.div`
    background: ${props => props.theme.colors.white};

    padding:
            ${getCssSizeValue(50, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(20, BREAKPOINTS.MOBILE)}
            ${getCssSizeValue(68, BREAKPOINTS.MOBILE)};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        padding: 
            ${getCssSizeValue(50, BREAKPOINTS.TABLET)}
            ${getCssSizeValue(42, BREAKPOINTS.TABLET)}
            ${getCssSizeValue(100, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        padding: 
            ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)} 
            ${getCssSizeValue(120, BREAKPOINTS.DESKTOP)}
            ${getCssSizeValue(110, BREAKPOINTS.DESKTOP)};
    }
`

const StyledHeader = styled.div`
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(57, BREAKPOINTS.MOBILE)};
    letter-spacing: -1.6px;
    
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        font-size: ${getFontSize(75, BREAKPOINTS.TABLET)};
        line-height: ${getFontSize(88, BREAKPOINTS.TABLET)};
        letter-spacing: -3px;
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        font-size: ${getFontSize(116, BREAKPOINTS.DESKTOP)};
        line-height: ${getFontSize(136, BREAKPOINTS.DESKTOP)};
        letter-spacing: -4.64px;
    }
`

const StyledQuotationMarkAndArrowsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        margin-top: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        margin-top: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
    }
    
    .quotation-mark {
        line-height: 50px;
        font-size: ${getFontSize(84, BREAKPOINTS.MOBILE)};
        font-weight: ${props => props.theme.fontWeight.black};
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(84, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(84, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            font-size: ${getFontSize(84, BREAKPOINTS.DESKTOP)};
        }
    }

    .icons-wrapper {
        display: flex;
        gap: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
        
        @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
            gap: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
            gap: ${getCssSizeValue(52, BREAKPOINTS.DESKTOP)};
        }
        
        .icon {
            width: ${getCssSizeValue(34, BREAKPOINTS.MOBILE)};
            
            @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
                width: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
            }

            @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
                width: ${getCssSizeValue(102, BREAKPOINTS.DESKTOP)};
            }
        }
    }
`

const StyledCarouselContainer = styled.div`
    width: 100%;
`

const EmblaViewPort = styled.div`
	overflow: hidden;
`

const EmblaContainer = styled.div`
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y pinch-zoom;
    gap: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
`

export default function ClientsAboutUs({ data }) {
    const id = getIdWithoutSpecialSymbols(data?.id || '')
    const paddings = getPaddingsClassFromData(data)
    const colorScheme = getColorScheme(data)

    const header = data?.header || ''
    const buttonText = data?.buttonText || ''
    const clients = data?.clients || []

    const isMobile = useIsMobile()
    const isDesktop = useIsDesktop()

    const [emblaRef, emblaApi] = useEmblaCarousel( { loop: true, align: 'start' })

    return (
        <StyledWrapper
            id={id}
            className={`${paddings} ${!isMobile ? 'px-main' : ''}`}
            data-color-scheme={colorScheme}
        >
            <StyledContainer>
                <Reveal onInView>
                    <StyledHeader dangerouslySetInnerHTML={getHtmlFromRedactorField(header)} />
                </Reveal>
                <Reveal onInView>
                    <StyledQuotationMarkAndArrowsWrapper>
                        <div className='quotation-mark'>“</div>
                        <div className='icons-wrapper'>
                            <div onClick={() => emblaApi.scrollPrev()}>
                                <Icon name={isDesktop ? ICONS.LONG_THIN_ARROW_LEFT : ICONS.SHORT_THIN_ARROW_LEFT} />
                            </div>
                            <div onClick={() => emblaApi.scrollNext()}>
                                <Icon name={isDesktop ? ICONS.LONG_THIN_ARROW_RIGHT : ICONS.SHORT_THIN_ARROW_RIGHT} />
                            </div>
                        </div>
                    </StyledQuotationMarkAndArrowsWrapper>
                </Reveal>
                <StyledCarouselContainer data-cursor={CURSOR_TYPES.SLIDER}>
                    <EmblaViewPort ref={emblaRef}>
                        <EmblaContainer>
                            {clients.map(client => <Client key={client?.id || ''} client={client} buttonText={buttonText} /> )}
                        </EmblaContainer>
                    </EmblaViewPort>
                </StyledCarouselContainer>
            </StyledContainer>
        </StyledWrapper>
    )
}