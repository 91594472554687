import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import ReactPlayer from 'react-player/lazy';
import PageContainer from "../pageContainer/PageContainer";
import {getColorScheme, getPaddingsClassFromData, getIdWithoutSpecialSymbols} from "@repo/utils";

// import dynamic from 'next/dynamic';
// const PageContainer = dynamic(() => import('../pageContainer/PageContainer'), { ssr: false });

const StyledSingleAssetWrapper = styled.div`
	display: grid;
	//grid-template-columns: ${({ children }) => (children.length > 1 ? 'repeat(2, 1fr)' : '1fr')};
	grid-template-columns: 1fr;
	column-gap: 30px;
	//height: 800px;
	position: relative;
	@media (min-width: 992px) {
		grid-template-columns: 1fr 1fr;
	}
`;

const StyledAssetItem = styled.div`
	aspect-ratio: ${({ aspectRatio }) => aspectRatio};
	overflow: hidden;
	position: relative;
	&:first-of-type {
		@media (max-width: 991px) {
			margin-bottom: 100px;
		}
	}
	@media (max-width: 767px) {
		//aspect-ratio: 9/16;
	}
`;

const TwoAssetsInRow = ({ data }) => {
	const items = data?.items || [];
	const id = getIdWithoutSpecialSymbols(data?.id);
	const colorScheme = getColorScheme(data)
	const paddings = getPaddingsClassFromData(data)
	return (
		<PageContainer id={id} className="full-width" data-color-scheme={colorScheme}>
			<StyledSingleAssetWrapper
				className={`${paddings} inner-wrapper`}
				// data-scroll
				// data-color-scheme={colorScheme}
			>
				{items.map((item) => {
					console.log(item);
					const externalAssetUrl = item?.assetUrl?.url || '';
					const isExternalAsset = externalAssetUrl !== '';
					const asset = item?.asset || '';
					const assetWidth = asset?.width || 0;
					const assetHeight = asset?.height || 1;
					const aspectRatio = assetWidth / assetHeight === 0 ? '16/9' : assetWidth / assetHeight;
					const assetType = asset?.mimeType || '';
					const isAssetTypeImage = (assetType && assetType.includes('image')) || false;
					const assetUrl = asset?.url || '';
					const finalAssetUrl = isExternalAsset ? externalAssetUrl : assetUrl;
					return (
						<>
							<StyledAssetItem aspectRatio={aspectRatio} key={finalAssetUrl}>
								{isAssetTypeImage && !isExternalAsset && <Image src={finalAssetUrl} alt="asset" fill sizes={'100vw'} />}
								{(isExternalAsset || !isAssetTypeImage) && (
									<>
										<ReactPlayer
											width="100%"
											height="100%"
											url={externalAssetUrl}
											autoplay
											muted
											loop
											playing
											playsinline
										/>
									</>
								)}
							</StyledAssetItem>
						</>
					);
				})}
			</StyledSingleAssetWrapper>
		</PageContainer>
	);
};

export default TwoAssetsInRow;
