import { useEffect, useMemo, useState } from "react";
import { CURSOR_ASSET_SIZE, CURSOR_TYPES } from "@repo/utils/constants";

export const ENTER_DIRECTIONS = {
    TOP: 'top',
    BOTTOM: 'bottom'
}

const calculateEntryDirection = (event, el) => {
    const rect = el.getBoundingClientRect();
    const mouseY = event.clientY;
    const fromTop = mouseY < rect.top + rect.height / 2;
    return fromTop ? ENTER_DIRECTIONS.TOP : ENTER_DIRECTIONS.BOTTOM;
};

const useCursorAsset = (target) => {
    const [entryDirection, setEntryDirection] = useState(ENTER_DIRECTIONS.TOP);

    useEffect(() => {
        const { el, type } = target;

        if (target && el && type === CURSOR_TYPES.IMAGE) {
            el.addEventListener('mouseenter', (event) => {
                setEntryDirection(calculateEntryDirection(event, target.el));
            });
        }
    }, [target]);

    const { cursorAsset, cursorAssetSize, cursorType } = useMemo(() => {
        const { el, type } = target;

        if (type !== CURSOR_TYPES.IMAGE || !el) {
            return {
                cursorAsset: null,
                cursorAssetSize: null,
                cursorType: null,
            }
        }

        const dataCursorAsset = el.getAttribute('data-cursor-asset')
        const parsedDataCursorAsset = JSON.parse(dataCursorAsset);

        const dataVideoSize = el?.getAttribute('data-video-size')
        const size = dataVideoSize ? dataVideoSize : CURSOR_ASSET_SIZE.LARGE;

        return {
            cursorAsset: parsedDataCursorAsset,
            cursorAssetSize: size,
            cursorType: type,
        };
    }, [target]);

    return { cursorAsset, cursorAssetSize, cursorType, entryDirection };
};

export default useCursorAsset;