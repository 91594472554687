import React, {forwardRef} from 'react';
import styled from "styled-components";
import Icon from "./Icon";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "../../../../styles/themeSettings";
import {ICONS} from "@repo/utils/constants";

const StyledIcon = styled(Icon)`
    --fallbackSize: ${getCssSizeValue(33, BREAKPOINTS.MOBILE)};
    //--size: var(--circle-size, var(--fallbackSize));
    --size: var(--circle-size,var(--fallbackSize));
    --border-width: 2px;
    //--shadow-color: transparent;
    border: solid var(--border-width) white;
    //box-shadow: 0px 0px 2px 4px transparent;

    overflow: hidden;
    max-width: unset;
    width: var(--size);
    height: var(--size);
	border-radius: calc(var(--size) / 2);
    transition: border 0.7s ease-in-out;
    cursor: pointer;
	svg {
		height: auto;
		z-index: 2;
		width: inherit;
		pointer-events: none;
		path {
			transition: all var(--color-transition-duration) var(--ease);
		}
	}
    
    .icon-wave{
        svg {
            transform: scale(1.225);
            path {
                transform: translateX(10%);
            }
        }
    }
	@media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        --border-width: ${getCssSizeValue(2.5, BREAKPOINTS.TABLET)};
        --fallbackSize: ${getCssSizeValue(44, BREAKPOINTS.TABLET)};
	}
    
    
	@media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        --fallbackSize: 76px;
        //--border-width: ${getCssSizeValue(2.5, BREAKPOINTS.DESKTOP)};
        &.responsive{
            --fallbackSize: ${getCssSizeValue(76, BREAKPOINTS.DESKTOP)};
        }
	}
`;
const CircleIcon = forwardRef(function CircleIcon({ children, name=ICONS.WAVE,...props}, ref){
    const { ['data-cursor']: dataCursor , ...rest } = props
    return (
        <StyledIcon ref={ref} name={name} data-cursor={dataCursor} {...rest}>
            {children}
        </StyledIcon>
    );
})

export default CircleIcon;
