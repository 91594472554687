import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import useInView from "../hooks/useInView";
import { duration, gsap, useGSAP} from "@repo/utils/gsap";
import styled from "styled-components";
import useAnimationDebug from "./useAnimationDebug";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

const AnimateScaleFadeIn = ({ target, timeline = null, vars = {} }) => {
    const tl = timeline || gsap.timeline();
    const alphaTarget = target
    const scaleTarget = target.children[0]
    tl
        .fromTo(alphaTarget, {
            autoAlpha: 0,
        },
        {
            autoAlpha: 1,
            ...vars,
        },0)
        .fromTo(scaleTarget, {
            scale: 0.75
        },{
            scale: 1,
        }, 0);
    return tl;
};

export function useScaleFadeIn({
        el,
        onInView = false,
        id: passedId = '',
        animationVars,
        debug: forceDebug=false,
        once = true,
        start= 'top',
        timeline=undefined,
    }) {
    const debug = useAnimationDebug(forceDebug)
    const { ref: InViewRef, intersected } = useInView({
        id: `scale-fade-${passedId}`,
        trigger: {
            start: debug ? `${start} center` : `${start} bottom`,
            end: debug ? `${start} center` : `${start} bottom`
        },
        disabled: !onInView,
        markers: debug,
        once: debug ? !debug : once,
    });
    const animationTL = useMemo(() => timeline || gsap.timeline(), [timeline]);
    const memoAnimationVars = useRef(animationVars)

    useEffect(() => {
        memoAnimationVars.current = animationVars
    });
    useImperativeHandle(InViewRef, () => el?.current, [])

    useGSAP(() => {
        animationTL.pause()
        AnimateScaleFadeIn({
            target: InViewRef.current,
            vars:{
                duration: duration * 2,
                ...memoAnimationVars.current
            },
            timeline: animationTL
        })
        if(intersected){
            animationTL.play()
        }
    },{
        dependencies: [intersected, animationTL],
        revertOnUpdate: true
    })
}
const ScaleFadeIn = ({
         onInView = false,
         id='',
         animationVars,
         debug=false,
         once = true,
         timeline=undefined,
         children,
         ...props
    }) => {
    const WrapperRef = useRef()
    useScaleFadeIn({
        el: WrapperRef,
        id,
        onInView,
        animationVars,
        debug,
        once,
        timeline,
        ...props
    })

    return (
        <Wrapper ref={WrapperRef}>
            {children}
        </Wrapper>
    )
};

export default ScaleFadeIn;
