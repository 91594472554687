import { createGlobalStyle } from 'styled-components';
import { getCssSizeValue } from '@repo/utils';
import { BREAKPOINTS } from './themeSettings';
import {PADDING} from "@repo/utils/constants";


const PaddingsStyles = createGlobalStyle`
    .px {
        &-main {
            padding-left: var(--paddingX);
            padding-right: var(--paddingX);
        }
    }
	
	
	//Mobile
	:root {
		--paddingX: ${getCssSizeValue(15, BREAKPOINTS.MOBILE)};
	}

	.pt {
		&-${PADDING.EXTRA_SMALL} {
			padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xs, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.SMALL} {
			padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].s, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.MEDIUM} {
			padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].m, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.LARGE} {
			padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].l, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.EXTRA_LARGE}{
			padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xl, BREAKPOINTS.MOBILE)};
		}
	}

	.pb {
		&-${PADDING.EXTRA_SMALL} {
			padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xs, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.SMALL} {
			padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].s, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.MEDIUM} {
			padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].m, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.LARGE} {
			padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].l, BREAKPOINTS.MOBILE)};
		}

		&-${PADDING.EXTRA_LARGE}{
			padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xl, BREAKPOINTS.MOBILE)};
		}
	}
	
	//Tablet
	@media (min-width:  ${({ theme }) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		:root {
            --paddingX: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
		}
		.pt {
			&-${PADDING.EXTRA_SMALL} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xs, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.SMALL} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].s, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.MEDIUM} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].m, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.LARGE} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].l, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.EXTRA_LARGE}{
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xl, BREAKPOINTS.TABLET)};
			}
		}

		.pb {
			&-${PADDING.EXTRA_SMALL} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xs, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.SMALL} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].s, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.MEDIUM} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].m, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.LARGE} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].l, BREAKPOINTS.TABLET)};
			}

			&-${PADDING.EXTRA_LARGE}{
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xl, BREAKPOINTS.TABLET)};
			}
		}
	}

	//Desktop
    @media (min-width:  ${({ theme }) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        :root {
            --paddingX: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
        }
		

		.pt {
			&-${PADDING.EXTRA_SMALL} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xs, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.SMALL} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].s, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.MEDIUM} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].m, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.LARGE} {
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].l, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.EXTRA_LARGE}{
				padding-top: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xl, BREAKPOINTS.DESKTOP)};
			}
		}
	
		.pb {
			&-${PADDING.EXTRA_SMALL} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xs, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.SMALL} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].s, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.MEDIUM} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].m, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.LARGE} {
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].l, BREAKPOINTS.DESKTOP)};
			}
	
			&-${PADDING.EXTRA_LARGE}{
				padding-bottom: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xl, BREAKPOINTS.DESKTOP)};
			}
		}
	}
`;

export default PaddingsStyles;
