import styled from "styled-components";
import {useMemo} from "react";
import {getCssSizeValue} from "@repo/utils";
import {BREAKPOINTS} from "../../../styles/themeSettings";

const StyledProgressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
    transition: all .2s;
    transform: translateY(-2px);
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        gap: ${getCssSizeValue(38, BREAKPOINTS.TABLET)};
        &.hide {
            transform: translateY(100%);
            opacity: 0;
        }
    }
    
    @media (min-width: ${({ theme }) => theme?.breakpoints?.desktop || 1024}px) and (orientation: landscape) {
        transform: translateY(-22svh);
    }
`;

const StyledCurrentElementText = styled.p`
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: 9px;
    user-select: none;
`;

const EmblaProgress = styled.div`
    background-color: ${props => props.theme.colors.white};
    position: relative;
    height: 4px;
    justify-self: flex-end;
    align-self: center;
    width: ${getCssSizeValue(90, BREAKPOINTS.MOBILE)};
    max-width: 90%;
    overflow: hidden;
    border-radius: 2px;
    @media (min-width: ${({ theme }) => theme?.breakpoints?.tablet || 768}px) {
        width: ${getCssSizeValue(140, BREAKPOINTS.TABLET)};
    }
`

const EmblaProgressBar = styled.div`
    background-color: var(--secondary-color);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
    transition: all .1s;
`

export default function ProgressBar({ selectedSnap, snapCount, isSubCarouselExpanded = false, isCardsDataDuplicated = false }) {
    const scrollProgress = useMemo(() => {
        let result;
        if (isCardsDataDuplicated) {
            if (selectedSnap === 0) {
                result = (1 / (snapCount / 2)) * 100;
            } else {
                result = ((selectedSnap % (snapCount / 2) + 1) / (snapCount / 2)) * 100;
            }
        } else {
            result = ((selectedSnap + 1) / snapCount) * 100;
        }

        return result
    }, [isCardsDataDuplicated, selectedSnap, snapCount]);
    const selectedSnapText = useMemo(() => {
        let result;
        if (isCardsDataDuplicated) {
            if (selectedSnap === 0) {
                result = 1
            }
            result = ((selectedSnap % (snapCount / 2)) + 1);
        } else {
            result = selectedSnap + 1;
        }
        return result;
    }, [isCardsDataDuplicated, selectedSnap, snapCount]);

    return (
        <StyledProgressWrapper className={`${isSubCarouselExpanded ? 'hide' : ''}`}>
            <EmblaProgress>
                <EmblaProgressBar
                    className='flip-element-1'
                    style={{
                        transform: `translate3d(${scrollProgress}%, 0px, 0px)`,
                    }}
                />
            </EmblaProgress>
            <StyledCurrentElementText>{`${selectedSnapText}/${isCardsDataDuplicated ? snapCount / 2 : snapCount}`}</StyledCurrentElementText>
        </StyledProgressWrapper>
    )
}
