/*
 * Copyright (C) 2023. PLEO Interactive sp. z o.o. All rights reserved.
 * This code and its associated files are the proprietary property of PLEO Interactive  sp. z o.o and may not be copied, modified, or distributed without written permission.
 */

import useMediaQuery from './useMediaQuery';
import { useTheme } from 'styled-components';

export default function useIsMobile() {
	const theme = useTheme();
	 return useMediaQuery(`(max-width:${theme?.breakpoints?.tablet - 1 || 767}px)`);
}
